import BaseComponent from '../BaseComponent';
import PropTypes from 'prop-types';
import {createRef} from 'react';
import VerticalLayout from "../layouts/VerticalLayout";
import React from "react";

class AccordionItem extends BaseComponent {

  constructor(props) {
    super(props);
    this.contentRef = createRef();
    this.state = {
      accordionState: (this.props.opened === true ? 'open' : 'close')
    }
  }

  componentDidMount() {
    if (this.state.accordionState === 'open') {
      this.doExpandContent();
    }
  }

  doCollapseContent() {
    this.contentRef.current.style.height = 0;
    this.state.accordionState = 'close';
  }

  doExpandContent() {
    let clientHeight = this.contentRef.current.getElementsByClassName('accordion-item-content')[0].clientHeight;
    if (this.setPropsOnAccordionItemHead()) {
      // clientHeight += 26;
    }
    this.contentRef.current.style.height = clientHeight + 'px';
    this.state.accordionState = 'open';
  }

  fireStateChange(accordionState) {
    if (this.props.onStateChange !== undefined) {
      this.props.onStateChange(accordionState);
    }
  }

  setAccordionState = (accordionState) => {
    if (accordionState === 'open') {
      this.doExpandContent();
    } else {
      this.doCollapseContent();
    }
    this.fireStateChange(accordionState);
  }

  setPropsOnAccordionItemHead() {
    if (this.props.accordionItemHead) {
      return React.cloneElement(this.props.accordionItemHead,
        {onChange: this.setAccordionState, opened: (this.state.accordionState === 'open')});
    }
  }

  render() {
    let accordionItemHead;
    if (this.setPropsOnAccordionItemHead()) {
      accordionItemHead = <div className='w-full p-1 cursor-pointer'>{this.setPropsOnAccordionItemHead()}</div>
    }
    return <VerticalLayout styleValue={this.props.styleValue} className='h-full'>
      {accordionItemHead && accordionItemHead}
      <div ref={this.contentRef} className='transition-[height] ease-in-out h-0 duration-500'>
        <div className='accordion-item-content py-3'>
          {this.props.children}
        </div>
      </div>
    </VerticalLayout>
  }
}

AccordionItem.propTypes = {
  accordionItemHead: PropTypes.node,
  onStateChange: PropTypes.func,
  accordionState: PropTypes.oneOf(['close', 'open']),
  opened: PropTypes.bool,
  styleValue: PropTypes.object
}

export default AccordionItem;
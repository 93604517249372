import BaseComponent from "../../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import Window from "../../../../../lib/components/window/Window";

class WindowTest extends BaseComponent {

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>
      <VerticalLayout className={' self-center'}>
        <h1>Window</h1>
        <Window showCloseButton title='hahahahahah'>
        </Window>
      </VerticalLayout>
    </div>
  }
}

export default WindowTest;

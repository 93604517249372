
class DataSourceDtoProcessEvents {

  constructor(onSuccessListeners, onErrorListeners, successCallBack, errorCallBack) {
    this.onSuccessListeners = onSuccessListeners;
    this.onErrorListeners = onErrorListeners;
    this.successCallBack = successCallBack;
    this.errorCallBack = errorCallBack;
  }

  doResponse(response) {
    if (response.success) {
      if (this.successCallBack !== undefined && this.successCallBack !== null) {
        this.successCallBack(response);
      }
      if (this.onSuccessListeners !== undefined && this.onSuccessListeners !== null) {
        this.onSuccessListeners.fire(response);
      }
    } else {
      if (this.onErrorListeners !== undefined && this.onErrorListeners !== null) {
        this.onErrorListeners.fire(response);
      }
      if (this.errorCallBack !== undefined && this.errorCallBack !== null) {
        this.errorCallBack(response);
      }
    }
  }

}

export default DataSourceDtoProcessEvents;

import React, {createRef} from "react";
import PropTypes from "prop-types";
import BaseComponent from "../../../../lib/components/BaseComponent";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import GF from "../../../../lib/utils/GF";
import InputFieldGenerator from "../entity/fields/InputFieldGenerator";
import DataType from "../../../../lib/model/types/DataType";
import shortid from "shortid";
import CampaignService from "../../../model/service/campaign/CampaignService";

class VariablesContent extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      fieldValues: {} // Store the values of input fields
    };
    this.inputFieldGenerator = new InputFieldGenerator((e, id, value) => this.handleInputChange(e, id, value));
    this.fieldRefs = [];
  }

  handleInputChange(e, variableName, value) {
    let fieldValue;
    if (typeof value === 'object' && value.hasOwnProperty('currentTarget')) {
      fieldValue = value.currentTarget.value;
    } else {
      fieldValue = value;
    }
    if (GF.checkNotNull(this.props.onVariablesChange)) {
      this.props.onVariablesChange(this.props.variableGroup, variableName, fieldValue);
    }
  };

  createFields() {
    let fields = [];
    for (let variableKey in this.props.variableGroup.variable) {
      let variable = this.props.variableGroup.variable[variableKey];
      let variableValue = null;
      if (this.props.variableValues !== undefined) {
        variableValue = this.props.variableValues[variable.name];
      }
      let field = this.createField(variable, variableKey, variableValue);
      if (field !== null) {
        this.state.fieldValues[variable.name] = variableValue.value;
        fields.push(field);
      }
    }
    return fields;
  }

  createField(variable, variableKey, variableValue) {
    let fieldRef = createRef();
    if (variableValue !== undefined) {
      let value = this.initDefaultFieldValue(variableValue);
      let field;
      let dtoProperties = CampaignService.createCampaignRedirectProperty(this.props.campaignId);
      if (variable.type.type === DataType.STRING && variable.type.isHtml) {
        field =
          <HorizontalLayout key={shortid()}>{this.inputFieldGenerator.initField(variable.type, variable.name, value, variable.description,
            fieldRef, dtoProperties)}</HorizontalLayout>;
      } else {
        field =
          <HorizontalLayout key={shortid()} className='w-[100%] lg:w-[48%]' widthFit>{this.inputFieldGenerator.initField(variable.type, variable.name,
            value, variable.description, fieldRef, dtoProperties)}</HorizontalLayout>;
      }
      this.fieldRefs.push(fieldRef);
      return field;
    }
    return null;
  }

  initDefaultFieldValue(variableValue) {
    let variableNew = null;
    switch (variableValue.variableDef.type.type) {
      case DataType.BOOLEAN:
        variableNew = variableValue.value === "1" || variableValue.value === "true" || variableValue.value ===
          "ja" || variableValue.value === "JA" || variableValue.value === 1 || variableValue.value === true;
        break;
      case DataType.INTEGER:
        variableNew = parseInt(variableValue.value);
        break;
      default:
        variableNew = variableValue.value;
        break;
    }
    return variableNew;
  }

  render() {
    let fields = this.createFields();

    return (<div>
      <HorizontalLayout className='flex-wrap justify-between gap-4'>
        {fields}
      </HorizontalLayout>
    </div>);
  }
}

VariablesContent.propTypes = {
  variableGroup: PropTypes.any, variableValues: PropTypes.any, onVariablesChange: PropTypes.func, campaignId: PropTypes.any
};

export default VariablesContent;

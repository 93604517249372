import BaseComponent from "../../../../../lib/components/BaseComponent";
import {Database} from "react-feather";
import MenuItemGroup from "../../../../../lib/components/menu/MenuItemGroup";
import MenuItem from "../../../../../lib/components/menu/MenuItem";
import Menu from "../../../../../lib/components/menu/Menu";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import HorizontalLayout from "../../../../../lib/components/layouts/HorizontalLayout";
import InputSearchDropDown from "../../../../../appl/view/components/inputFields/dropdown/InputSearchDropDown";

class DropDownsTest extends BaseComponent {

  render() {

    return <VerticalLayout className='sandbox-container h-full gap-4'>
      <h1>Dropdowns test</h1>
      <HorizontalLayout className='gap-x-6 h-screen'>

        <InputSearchDropDown inputLayout='inline' labelElement='Dropdown'>
          <Menu className='text-cn-color-white'>
            <MenuItemGroup>
              <MenuItem option='1' searchValue='Peer'><HorizontalLayout><Database/>Peerwehwethwhwrzhwhwrrhwrzh</HorizontalLayout></MenuItem>
              <MenuItem option='2' searchValue='Farzad'>Farzadwhwerhwrgbghztrdfghjnhzgtfv</MenuItem>
            </MenuItemGroup>
            <MenuItemGroup>
              <MenuItem option='3' searchValue='est'>est</MenuItem>
              <MenuItem option='4' searchValue='st'>st</MenuItem>
            </MenuItemGroup>
          </Menu>
        </InputSearchDropDown>

        <InputSearchDropDown inputLayout='outline'>
          <Menu className='text-cn-color-white'>
            <MenuItemGroup>
              <MenuItem option='1' searchValue='Peer'><HorizontalLayout><Database/>Peerwehwethwehwrzhwrhwrzh</HorizontalLayout></MenuItem>
            </MenuItemGroup>
            <MenuItemGroup>
              <MenuItem option='3' searchValue='est'>est</MenuItem>
              <MenuItem option='4' searchValue='st'>st</MenuItem>
            </MenuItemGroup>
          </Menu>
        </InputSearchDropDown>
      </HorizontalLayout>
    </VerticalLayout>

  }

}

export default DropDownsTest;

import BaseComponent from "../../../../lib/components/BaseComponent";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import './Layout.css'
import PropTypes from "prop-types";
import React, {createRef} from "react";
import CM from "../../../model/system/CM";

class Layout extends BaseComponent {

  static sideBarWidthOpen = '220px';
  static sideBarWidthClose = '70px';
  static topNavHeight = '70px';

  constructor(props) {
    super(props);
    this.topNavContainerRef = createRef();
    this.sideBarRef = createRef();
    this.sideBarContainerRef = createRef();
    this.contentContainerRef = createRef();
    this.state = {
      theme: CM.get().getTheme(),
      topNavHeight: (this.props.topNavHeight !== undefined ? this.props.topNavHeight : Layout.topNavHeight),
      sideBarWidthOpen: (this.props.sideBarWidthOpen !== undefined ? this.props.sideBarWidthOpen : Layout.sideBarWidthOpen),
      sideBarWidthClose: (this.props.sideBarWidthClose !== undefined ? this.props.sideBarWidthClose : Layout.sideBarWidthClose)
    }
    CM.get().addThemeChangeListener((theme) => this.onChangeTheme(theme))
  }

  componentDidMount() {
    this.topNavContainerRef.current.style.height = this.state.topNavHeight;
    this.private_setRightWidth(this.state.sideBarWidthOpen);

    let topNavHeight = parseInt(this.state.topNavHeight.replace('px', ''));
    this.contentContainerRef.current.style.height = (window.innerHeight - topNavHeight).toString() + 'px';

    window.addEventListener('resize', (e) => {
      if (this.contentContainerRef.current != null) {
        this.contentContainerRef.current.style.height = (window.innerHeight - topNavHeight).toString() + 'px';
      }
    });
  }

  componentWillUnmount() {
    CM.get().resetSessionValues();
  }

  doCollapse() {
    this.private_setRightWidth(Layout.sideBarWidthClose);
  }

  doExpand() {
    this.private_setRightWidth(Layout.sideBarWidthOpen);
  }

  onChangeTheme(theme) {
    this.state.theme = theme;
    this.setState(this.state);
  }

  onSideBarClick(e) {
    if (this.sideBarContainerRef.current.bodyRef.current.style.minWidth === this.state.sideBarWidthClose) {
      this.private_setRightWidth(this.state.sideBarWidthOpen);
    } else {
      this.private_setRightWidth(this.state.sideBarWidthClose);
    }
  }

  private_setRightWidth(sideBarWidth) {
    this.sideBarContainerRef.current.bodyRef.current.style.minWidth = sideBarWidth;
  }

  render() {
    let bgDiv;
    if (this.state.theme === CM.STD_DARK) {
      bgDiv = <div className='fixed top-0 left-0 w-screen h-screen bg-bg-img-layout-dark bg-cover bg-center'/>;
    } else {
      bgDiv = <div className='fixed top-0 left-0 w-screen h-screen bg-bg-img-layout-light bg-cover bg-center'/>;
    }
    let sideBar = React.cloneElement(this.props.sideBar, {ref: this.sideBarRef});

    return <>
      {bgDiv}
      <HorizontalLayout className='absolute h-screen animate__animated animate__fadeIn'>
        <VerticalLayout className='overflow-hidden'>
          {/* z-10 class weil im cner overview account wechsel dropdown nicht von campaign cards verdeckt wird*/}
          <div ref={this.topNavContainerRef} className='backdrop-blur-sm hidden sm:block z-10'>{this.props.topNav}</div>
          <div ref={this.contentContainerRef} className='rounded-tr-[20px] border-t border-r'>
            {this.props.children}
          </div>
        </VerticalLayout>
        <VerticalLayout ref={this.sideBarContainerRef} className='h-screen backdrop-blur-sm grow-0 cn-layout-sidebar overflow-y-auto hidden sm:block' widthFit>
          {sideBar}
        </VerticalLayout>
      </HorizontalLayout>
    </>;
  }
}

Layout.propTypes = {
  topNav: PropTypes.node,
  sideBar: PropTypes.node,
  children: PropTypes.node,
  topNavHeight: PropTypes.string,
  sideBarWidthOpen: PropTypes.string,
  sideBarWidthClose: PropTypes.string
}
export default Layout;

import PropTypes from "prop-types";
import SelectableDragList from "../../../../../lib/components/selectableDragList/SelectableDragList";
import SelectableItem from "../../../../../lib/components/selectableDragList/SelectableItem";
import HorizontalLayout from "../../../../../lib/components/layouts/HorizontalLayout";
import shortid from "shortid";
import {createRef} from "react";
import FieldName from "../../../../../lib/model/query/FieldName";
import DataType from "../../../../../lib/model/types/DataType";
import Card from "../../card/Card";

class TableCard extends Card {

  fieldRefList = [];

  constructor(props) {
    super(props);
    this.selectableDragListRef = createRef();
    this.entityDef = this.props.dataSource.getEntityDef();
  }

  componentDidMount() {
    this.selectableDragListRef.current.setDefaultSelectedTargets();
  }

  buildFieldName(fieldName, relationName) {
    let fieldNameObj = new FieldName(fieldName, relationName);
    return fieldNameObj.getFullFieldName();
  }

  getSelectedFields() {
    let selectedFieldsRaw = this.selectableDragListRef.current.getData();
    let selectedFields = [];
    for (let fieldName in selectedFieldsRaw) {
      selectedFields.push(fieldName);
    }
    return selectedFields;
  }

  render() {
    let fieldElements = [];
    for (let index in this.entityDef.fieldsDef) {
      let fieldDef = this.entityDef.fieldsDef[index];
      if (fieldDef.type.type !== DataType.DATA_EXT && fieldDef.type.type !== DataType.OBJECT) {
        let fieldRef = createRef();
        this.fieldRefList.push(fieldRef);
        fieldElements.push(<SelectableItem ref={fieldRef} active={true} elementId={this.buildFieldName(fieldDef.name, this.entityDef.baseDef.name)} key={shortid()}>{fieldDef.description}</SelectableItem>);
      }
    }
    this.state.cardTitle = this.entityDef.baseDef.description;
    this.state.cardContent = <SelectableDragList title={'System'} ref={this.selectableDragListRef}>
      <HorizontalLayout className='flex-wrap gap-2'>
        {fieldElements}
      </HorizontalLayout>
    </SelectableDragList>;

    return super.render();
  }
}

TableCard.propTypes = {
  className: PropTypes.string, dataSource: PropTypes.object
}
export default TableCard;

import BaseComponent from "../../../../../lib/components/BaseComponent";
import TabBarHorizontal from "../../../../../lib/components/tabbar/TabBarHorizontal";
import ButtonTab from "../../../../../lib/components/tabbar/ButtonTab";
import TabContent from "../../../../../lib/components/tabbar/TabContent";
import LabelTab from "../../../../../lib/components/tabbar/LabelTab";
import Window from "../../../../../lib/components/window/Window";
import TabContainerHorizontal from "../../../../../lib/components/tabbar/TabContainerHorizontal";

class TabBarTest extends BaseComponent {

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>

      <TabContainerHorizontal
        tabBar={<TabBarHorizontal>
          <ButtonTab>test</ButtonTab>
          <ButtonTab>2</ButtonTab>
          <ButtonTab>sdfsdf</ButtonTab>
          <ButtonTab>sdfdsfsadf</ButtonTab>
          <ButtonTab>sdfsdfsaf</ButtonTab>
          <ButtonTab>2dsfadsfs</ButtonTab>
          <ButtonTab>2fdsfdsfcds</ButtonTab>
          <ButtonTab>2dsfdsfsdf</ButtonTab>
          <ButtonTab>2dsfsadf</ButtonTab>
          <ButtonTab>2fsdfsdf</ButtonTab>
          <ButtonTab>2sdfasdf</ButtonTab>
        </TabBarHorizontal>}>
        <TabContent>
          <Window>
            <div className="ml-4">Test Buttons</div>
          </Window>
        </TabContent>
      </TabContainerHorizontal>

      <Window>
        <TabContainerHorizontal
          tabBar={<TabBarHorizontal>
            <LabelTab>test</LabelTab>
            <LabelTab>2</LabelTab>
          </TabBarHorizontal>}>
          <TabContent>
            <div className="ml-4">Test Labels</div>
          </TabContent>
        </TabContainerHorizontal>
      </Window>

    </div>
  }
}

export default TabBarTest;

import PropTypes from 'prop-types';
import {Circle, Plus} from 'react-feather';
import BaseComponent from "../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import DialogNewCampaign from "../../../../lib/components/dialogs/DialogNewCampaign";
import T from "../../../model/system/text_translations/Translator";
import InputSearch from "../inputFields/InputSearch";
import InputLayoutType from "../inputFields/InputLayoutType";
import {createRef} from "react";
import GF from "../../../../lib/utils/GF";
import CM from "../../../model/system/CM";

export default class CampaignCardNew extends BaseComponent {

  static STATUS_ALL = 0;
  static STATUS_EDITING = 1;
  static STATUS_MAINTENANCE = 2;
  static STATUS_PUBLISHED = 3;
  static STATUS_FINISHED = 4;

  constructor(props) {
    super(props);
    this.inputSearchRef = createRef();
  }

  onNewButtonClick() {
    CM.get().setMainDialog(<DialogNewCampaign onSaveButtonClick={(e) => this.onCampaignCreatButtonClick(
      e)} onCloseButtonClick={() => this.onDialogCloseButtonClick()} title='new Campaign Dialog'/>)
  }

  onCampaignCreatButtonClick(e) {
    if (this.props.reloadCampaigns !== undefined) {
      this.props.reloadCampaigns();
    }
  }

  onDialogCloseButtonClick() {
    CM.get().closeMainDialog();
  }

  onChange(e, id, value) {
    if (GF.checkNotNull(this.props.onFilterInputChange)) {
      this.props.onFilterInputChange(e, id, value);
    }
    this.toggleResetButtonVisibility(true);
  }

  onResetFiltersButtonClick(e) {
    this.inputSearchRef.current.clear();
    let element = document.getElementsByClassName('campaign-card-new-selected-filter')[0];
    if (element !== undefined) {
      element.classList.remove('campaign-card-new-selected-filter');
    }
    this.toggleResetButtonVisibility(false);
    this.props.onResetFilters(e);
  }

  onStatusFilterClick(e, statusFilter) {
    this.toggleSelectedFilter(e);
    if (this.props.onStatusFilterChange) {
      this.props.onStatusFilterChange(e, statusFilter);
    }
    this.toggleResetButtonVisibility(true);
  }

  toggleResetButtonVisibility(visible) {
    if (visible) {
      document.getElementById('campaign-card-new-reset-filter').classList.remove('invisible');
    } else {
      document.getElementById('campaign-card-new-reset-filter').classList.add('invisible');
    }
  }

  toggleSelectedFilter(e) {
    let prevSelectedFilter = e.currentTarget.parentElement.getElementsByClassName('campaign-card-new-selected-filter')[0];
    if (prevSelectedFilter !== undefined) {
      prevSelectedFilter.classList.remove('campaign-card-new-selected-filter');
    }
    e.currentTarget.classList.add('campaign-card-new-selected-filter');
  }

  createStatusFilterButtons(status) {
    let selected = '';
    if (this.props.statusFilterDefaultValue === status) {
      selected = ' campaign-card-new-selected-filter';
    }
    if (status === CampaignCardNew.STATUS_EDITING) {
      return <div onClick={(e) => this.onStatusFilterClick(e, CampaignCardNew.STATUS_EDITING)}
                  className={'campaign-card-new-status-filter' + selected}>
        <Circle className='p-1 fill-cn-color-blue-690 stroke-cn-color-blue-690'/> {T.label_filter_editing()}</div>
    } else if (status === CampaignCardNew.STATUS_MAINTENANCE) {
      return <div onClick={(e) => this.onStatusFilterClick(e, CampaignCardNew.STATUS_MAINTENANCE)}
                  className={'campaign-card-new-status-filter' + selected}>
        <Circle className='p-1 fill-amber-500 stroke-amber-500'/> {T.label_filter_maintenance()}</div>
    } else if (status === CampaignCardNew.STATUS_PUBLISHED) {
      return <div onClick={(e) => this.onStatusFilterClick(e, CampaignCardNew.STATUS_PUBLISHED)}
                  className={'campaign-card-new-status-filter' + selected}>
        <Circle className='p-1 fill-cn-color-primary stroke-cn-color-primary'/> {T.label_filter_published()}</div>
    } else {
      return <div onClick={(e) => this.onStatusFilterClick(e, CampaignCardNew.STATUS_FINISHED)}
                  className={'campaign-card-new-status-filter' + selected}>
        <Circle className='p-1 fill-cn-color-static-red stroke-cn-color-static-red'/> {T.label_filter_closed()}</div>
    }
  }

  render() {
    let showResetButton = '';
    if (this.props.searchInputDefaultValue === '' && this.props.statusFilterDefaultValue === CampaignCardNew.STATUS_ALL) {
      showResetButton = ' invisible';
    } else if (this.props.searchInputDefaultValue === null && this.props.statusFilterDefaultValue === null) {
      showResetButton = ' invisible';
    }
    return <>
      <VerticalLayout widthFit
                      className='cn-shadow-window w-[280px] h-[500px] px-10 justify-evenly rounded-[20px] rounded-tl-none bg-cn-color-black text-cn-color-white'>
        <VerticalLayout onClick={(event) => this.onNewButtonClick(event)}
                        className='justify-center self-center py-5 gap-5 items-center rounded-[20px] rounded-tl-none bg-cn-color-secondary cursor-pointer'>
          <Plus className='bg-white stroke-cn-color-secondary rounded-lg h-16 w-16'/>
          <h3 className='text-center text-cn-color-static-white'>{T.label_create_new_campaign()}</h3>
        </VerticalLayout>
        <InputSearch placeholder={T.label_search()} inputLayout={InputLayoutType.outline} ref={this.inputSearchRef}
                     onChange={(e, id, value) => this.onChange(e, id, value)} defaultValue={this.props.searchInputDefaultValue}/>
        <VerticalLayout className='cn-font-paragraph max-w-[200px] self-center px-3 cn-border-radius-20'>
          {this.createStatusFilterButtons(CampaignCardNew.STATUS_EDITING)}
          {this.createStatusFilterButtons(CampaignCardNew.STATUS_MAINTENANCE)}
          {this.createStatusFilterButtons(CampaignCardNew.STATUS_PUBLISHED)}
          {this.createStatusFilterButtons(CampaignCardNew.STATUS_FINISHED)}
        </VerticalLayout>
        <div id='campaign-card-new-reset-filter' onClick={(e) => this.onResetFiltersButtonClick(e)}
             className={'flex cursor-pointer bg-cn-color-static-red text-cn-color-static-white p-2 mt-2 cn-border-radius-8 justify-center' +
               showResetButton}>{T.label_reset_filter()}
        </div>
      </VerticalLayout></>;
  }
}

CampaignCardNew.propTypes = {
  statusFilterDefaultValue: PropTypes.oneOf([0, 1, 2, 3, 4]),
  onStatusFilterChange: PropTypes.func.isRequired,
  onFilterInputChange: PropTypes.func.isRequired,
  searchInputDefaultValue: PropTypes.string,
  reloadCampaigns: PropTypes.func,
  onResetFilters: PropTypes.func,
}

import BaseComponent from "../../../../../lib/components/BaseComponent";
import PropType from "prop-types";

class Modul extends BaseComponent {



  render() {

    return<div className=' px-5 py-5  w-full md:w-1/3  '>
        <div className="bg-cn-color-primary pt-10 pb-5 rounded-t-2xl text-center ">
          <h1 className='text-cn-color-spzeial-white font-cn-gotham-medium uppercase text-2xl'>{this.props.title}</h1>
        </div>
        <div className="bg-cn-color-landing-gray03 p-5 rounded-b-2xl h-100 flex flex-wrap h-auto">
          {this.props.children}
        </div>
    </div>
  }
}

Modul.propTypes = {
  title: PropType.string.isRequired,
}

export default Modul;

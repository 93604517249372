/**
 * NodeIterator
 * Voraussetzungen (interface) für ein Node ist:
 * es muss die Funktion isParentNode und getChildren implementiert werden!
 */
class NodeIterator {

  prepareWorkingNode(_node) {
    return _node;
  }

  connectChildToParentCallback(childNodesNew, childNodeNew, filter) {
    childNodesNew.push(childNodeNew);
  }

  parentNodeCallback(_workingNode, childNodesNew) {
    return _workingNode;
  }

  childNodeCallback(_workingNode) {
    return _workingNode;
  }

  iteratePredicates(_node, filter) {
    let node = null;
    let _workingNode = this.prepareWorkingNode(_node);

    if (_workingNode !== undefined && _workingNode !== null) {
      if (_workingNode.isParentNode()) {
        let childNodesNew = [];
        let childNodes = _workingNode.getChildren();
        if (childNodes !== undefined && childNodes !== null && childNodes.length) {
          for (let i = 0; i < childNodes.length; i++) {
            let childNode = childNodes[i];
            let childNodeNew = this.iteratePredicates(childNode, filter);
            if (childNodeNew !== null) {
              this.connectChildToParentCallback(childNodesNew, childNodeNew, filter);
            }
          }
        }
        if (childNodesNew.length) {
          node = this.parentNodeCallback(_workingNode, childNodesNew);
        }
      } else {
        node = this.childNodeCallback(_workingNode);
      }
    }
    return node;
  }
}

export default NodeIterator;

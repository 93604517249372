import PropTypes from "prop-types";
import Layout from "./Layout";
import {createRef} from "react";
import GF from "../../utils/GF";

class Grid extends Layout {

  constructor(props) {
    super(props);
    this.bodyRef = createRef();
  }

  onClick(e) {
    if (this.props.onClick !== undefined) {
      this.props.onClick(e);
    }
  }

  getContent() {
    let optionalClasses = this.props.className !== undefined ? this.props.className : '';
    let width = 'w-full ';
    if (this.props.widthFit) {
      width = '';
    }
    return <div id={GF.getValue(this.props.id)} ref={this.bodyRef} className={'grid ' + width + optionalClasses} onClick={(e) => this.onClick(
      e)} style={GF.getValue(this.props.styleValue, {})}>
      {this.props.children}
    </div>;
  }
}

Grid.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
  widthFit: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
  styleValue: PropTypes.object
}

export default Grid;

import BaseComponent from "../../../../../lib/components/BaseComponent";
import ColumnBox from "../../../../../lib/components/columnBox/ColumnBox";
import {CheckSquare} from "react-feather";
import ColumnLine from "../../../../../lib/components/columnBox/ColumnLine";


class DetailsTest extends BaseComponent {

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>
      <ColumnBox columnCount={5}>
        <ColumnLine label="Vorname" value="Max"></ColumnLine>
        <ColumnLine label="Nachname" value="Mustermann"></ColumnLine>
        <ColumnLine label="Strasse" value="Sägestrasse 21-23"></ColumnLine>
        <ColumnLine label="PLZ" value="3550"></ColumnLine>
        <ColumnLine label="Ort" value="Langnau"></ColumnLine>
        <ColumnLine label="Angemeldet" value={<CheckSquare/>}></ColumnLine>
        <ColumnLine label="Anzahl Plätze" value="3"></ColumnLine>
        <ColumnLine label="Essenswunsch" value="Vegan"></ColumnLine>
        <ColumnLine label="Ankunft" value="18:00 Uhr"></ColumnLine>
        <ColumnLine label="Anreise" value="Zug"></ColumnLine>
        <ColumnLine label="Kommentar" value="Danke :) Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. "></ColumnLine>
      </ColumnBox>

    </div>
  }
}

export default DetailsTest;

import BaseElementContainer from "./BaseElementContainer";
import VerticalLayout from "../layouts/VerticalLayout";
import PropTypes from "prop-types";
import React from "react";

class TabContent extends BaseElementContainer {

  constructor(props) {
    super(props);
    this.state.activeContent = null;
  }

  clearAll() {
    super.clearAll();
    this.state.activeContent = null;
  }

  setActiveElement(id = 'last') {
    let activeContent = null;
    for (let index in this.state.tabElements) {
      if (index === id && id !== 'last') {
        activeContent = this.state.tabElements[index].element;
        break;
      }
      activeContent = this.state.tabElements[index].element;
    }
    if (activeContent !== null) {
      this.state.actualKey = activeContent.props.id;
      this.state.activeContent = activeContent;
    }
    this.onTabChangeListeners.fire(null, id);
    this.setState(this.state);
  }

  render() {
    return <VerticalLayout className={this.props.className}>
      {this.state.activeContent}
    </VerticalLayout>
  }
}

TabContent.propTypes = {
  className: PropTypes.string, parent: PropTypes.object
}
export default TabContent;

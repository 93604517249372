/**
 * class It
 *
 * In dieser Klasse sind alle Übersetzungestexte für Italienisch hinterlegt.
 */
class It {
  label_add = 'Einfügen';
  label_create = 'Erstellen';
  label_cancel = 'Abbrechen';
  label_discard = 'Verwerfen';
  label_save = 'Speichern';
  label_edit = 'Editieren';
  label_delete = 'Löschen';
  label_copy = 'Datensatz Kopieren';
  label_duplicate = 'Duplicato';
  label_import = 'Importazione dell\'elenco Excel';
  label_do_import = 'importazione';
  label_clone = 'Klonen';
  label_update = 'Update';
  label_help = 'Help';
  label_settings = 'Settings';
  label_system = 'System';
  label_account = 'Account';
  action_change_thumbnail = 'Change thumbnail';
  action_logout = 'Sign out';
  action_overwrite = 'Riportare';
  action_synchronize = 'Synchronize';
  label_export = 'Exportieren';
  label_empty = 'Tabelle leeren';
  label_omsOverview = 'Versandübersicht';
  label_filter_editing = 'In Bearbeitung';
  label_filter_closed = 'Abgeschlossen';
  label_filter_published = 'Veröffentlicht';
  label_filter_maintenance = 'In Wartung';
  label_filter_open_simple = 'open simple Filter';
  label_filter_open_extended = 'open extended Filter';
  label_create_new_campaign = 'Neue Campaign erstellen';
  label_option_not_found = 'Nichts gefunden!';
  label_enter_text = 'Zeit eingeben:';
  label_hour = 'Hour';
  label_minute = 'Minute';
  component_upload_hint = 'Drag and Drop or browse to choose file';
  label_search = 'Tedesco';
  reset_filter = 'Filter zurücksetzen';
  label_tables = 'Tables';
  label_variant = 'Variant';
  label_functions = 'Functions';
  label_activate_analytics = 'Activate analytics';
  label_activities = 'Activities';
  label_overview = 'Overview';
  genericErrorMessage = 'Leider ist ein Fehler aufgetreten! Bitte wenden Sie Sich an die Vögeli AG';
  ext_filter_eq_label = 'è lo stesso';
  ext_filter_neq_label = 'non è uguale';
  ext_filter_gt_label = 'è maggiore di';
  ext_filter_lt_label = 'è minore di';
  ext_filter_geq_label = 'è maggiore o uguale a';
  ext_filter_leq_label = 'è minore o uguale a';
  ext_filter_like_label = 'contiene';
  ext_filter_and_label = 'E';
  ext_filter_or_label = 'Oppure';
  ext_filter_null_label = 'è vuoto';
  ext_filter_not_null_label = 'non è vuoto';
}

export default It;

/**
 * class VolatileManager
 */
class VM {

  static instance: VM;

  constructor() {
    this.clearDataAll();
  }

  static get() {
    if (!VM.instance) {
      VM.instance = new VM();
    }
    return VM.instance;
  }

  clearDataAll() {
    this.data = {};
  }

  clearData(kat, id = null) {
    if (this.data.hasOwnProperty(kat)) {
      let canDeleteDataBlob = true;
      if (id !== null) {
        let dataBlob = this.private_getDataBlob(kat);
        delete dataBlob[id];
        if (Object.keys(dataBlob).length > 0) {
          canDeleteDataBlob = false;
          this.data[kat] = dataBlob;
        }
      }
      if (canDeleteDataBlob) {
        delete this.data[kat];
      }
    }
  }

  getKatList() {
    return Object.keys(this.data);
  }

  findKat(_kat) {
    let katFound = [];
    let katList = this.getKatList();
    for (let index in katList) {
      let kat = katList[index];

      if (kat.includes(_kat)) {
        katFound.push(kat);
      }
    }
    return katFound;
  }

  getData(kat, id = null) {
    let data = null;
    if (this.data.hasOwnProperty(kat)) {
      let dataBlob = this.data[kat];
      if (id !== null) {
        if (dataBlob.hasOwnProperty(id)) {
          data = dataBlob[id];
        }
      } else {
        data = dataBlob;
      }
    }
    return data;
  }

  private_getDataBlob(kat) {
    let dataBlob = null;
    if (this.data.hasOwnProperty(kat)) {
      dataBlob = this.data[kat];
    }
    return dataBlob;
  }

  setData(kat, data, id = null) {
    let dataBlob = this.private_getDataBlob(kat);
    if (dataBlob === null) {
      dataBlob = {};
    }
    if (id !== null) {
      dataBlob[id] = data;
    } else {
      dataBlob = data;
    }
    this.data[kat] = dataBlob;
  }
}

export default VM;

import React from 'react';
import BaseComponent from "../BaseComponent";
import InputText from "../../../appl/view/components/inputFields/InputText";
import DialogBase from "./DialogBase";
import PropTypes from "prop-types";
import HorizontalLayout from "../layouts/HorizontalLayout";
import MenuItem from "../menu/MenuItem";
import ButtonSmall from "../../../appl/view/components/buttons/ButtonSmall";
import Menu from "../menu/Menu";
import MenuItemGroup from "../menu/MenuItemGroup";
import CM from "../../../appl/model/system/CM";
import Checkbox from "../checkbox/Checkbox";
import InputTextMultiline from "../../../appl/view/components/inputFields/InputTextMultiline";
import ReadCampaignCloneInfo from "../../../appl/model/service/campaign/dto/ReadCampaignCloneInfo";
import shortid from "shortid";
import CloneCampaign from "../../../appl/model/service/campaign/dto/CloneCampaign";
import VerticalLayout from "../layouts/VerticalLayout";
import {toast} from "react-toastify";
import InputSearchDropDown from "../../../appl/view/components/inputFields/dropdown/InputSearchDropDown";
import GF from "../../utils/GF";
import T from "../../../appl/model/system/text_translations/Translator";
import Loading from "../images/Loading";

class DialogCloneCampaign extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      domains: [],
      tables: [],
      variables: [],
      selectedDomain: null,
      selectedTables: [],
      selectedVariants: [],
      newCampaignName: null,
      privilege: '0',
      description: '',
      isLoading: false,
      cloneButtonText: T.label_clone()
    }
  }

  getStorageId() {
    return 'DialogCloneCampaign_' + this.props.campaignId;
  }

  componentDidMount() {
    let self = this;
    this.doDataCacheFetch(new ReadCampaignCloneInfo(this.props.campaignId), 'campaign', 'DialogCloneCampaign',
      (response) => {
        if (response.success) {
          self.state.domains = response.data.domains.records;
          self.state.selectedDomain = response.data.campaign.domainId;
          self.state.tables = response.data.tables.campaignTables;
          self.state.variables = response.data.variables;
          self.setState(self.state);
        } else {
          toast.error('Die Anfrage hat nicht funktioniert:\n' + response.message);
        }

      });
  }

  onCloneButtonClick(e) {
    if (!this.state.isLoading) {
      this.state.isLoading = true;
      let cloneCampaign = new CloneCampaign(this.props.campaignId, this.state.newCampaignName, this.state.description,
        this.state.selectedDomain, this.state.privilege, this.state.selectedVariants, this.state.selectedTables);
      this.state.cloneButtonText = <Loading/>
      this.setState(this.state);
      CM.get().doJsonAjax(CM.get().getServiceUrl('campaign'), cloneCampaign, (response) => {
        this.state.isLoading = false;
        this.state.cloneButtonText = T.label_clone();
        this.setState(this.state);
        if (response.success) {
          toast.success('Campaign wurde erfolgreich geklont.');
          if (this.props.onCloneButtonClick) {
            this.props.onCloneButtonClick(e);
          }
        } else {
          toast.error(response.message);
        }
      });
    }
  }

  render() {
    let defaultDomain;
    let domains = this.state.domains.map((domain) => {
      let id = domain['po2\\system\\db\\doctrine\\entity\\SysDomain#id'];
      let name = domain['po2\\system\\db\\doctrine\\entity\\SysDomain#name'];
      if (this.state.selectedDomain === id) {
        defaultDomain = name;
      }
      return <MenuItem searchValue={name} option={id}>{name}</MenuItem>
    });
    let tables = this.state.tables.map((table, index) => {
      this.state.selectedTables.push(table.name);
      return <Checkbox widthFit={true} _id={index} onChange={(e, id, checked) => {
        if (checked) {
          this.state.selectedTables.push(this.state.tables[id].name);
        } else {
          for (let tableIndex in this.state.selectedTables) {
            let value = this.state.selectedTables[tableIndex];
            if (value === this.state.tables[id].name) {
              this.state.selectedTables = GF.removeFromArray(this.state.selectedTables, tableIndex);
              break;
            }
          }
        }
      }} checked key={shortid()} type='checkbox' labelElement={table.label}/>
    });
    let variables = this.state.variables.map((variant, index) => {
      return <Checkbox widthFit={true} _id={index} onChange={(e, id, checked) => {
        if (checked) {
          for (let variantIndex in this.state.selectedVariants) {
            let value = this.state.selectedVariants[variantIndex];
            if (value === this.state.variables[id]) {
              this.state.selectedVariants = GF.removeFromArray(this.state.selectedVariants, variantIndex);
              break;
            }
          }
        } else {
          this.state.selectedVariants.push(this.state.variables[id]);
        }
      }} checked key={shortid()} type='checkbox' labelElement={variant}/>
    });
    return <DialogBase onCloseButtonClick={this.props.onCloseButtonClick} className='w-full xl:w-1/2 overflow-y-auto'
                       closable={this.props.closable} title='Campaign Klonen'>
      <div className='grid grid-cols-12 gap-x-5 mt-5'>
        <VerticalLayout className='col-span-full md:col-span-4 gap-y-3'>
          <InputText maxLength={28} labelElement='Name' onChange={(e) => this.state.newCampaignName = e.target.value}/>
          <InputSearchDropDown key={shortid()} inputLayout='inline' labelElement='Domain'
                               defaultValue={{option: defaultDomain, displayValue: defaultDomain}}
                               onChange={(e, id, data) => this.state.selectedDomain = data}>
            <Menu className='text-cn-color-white'>
              <MenuItemGroup>
                {domains}
              </MenuItemGroup>
            </Menu>
          </InputSearchDropDown>
          <InputSearchDropDown labelElement='Berechtigung' defaultValue={{option: 'Default', displayValue: 'Default'}}
                               onChange={(e, id, data) => this.state.privilege = data}>
            <Menu className='text-cn-color-white'>
              <MenuItemGroup>
                <MenuItem option='0' searchValue='Default'>Default</MenuItem>
                <MenuItem option='1' searchValue='Alle Benutzers'>Alle Benutzers</MenuItem>
                <MenuItem option='2' searchValue='Nur ich'>Nur ich</MenuItem>
              </MenuItemGroup>
            </Menu>
          </InputSearchDropDown>
        </VerticalLayout>
        <VerticalLayout className='col-span-full gap-y-3 md:col-span-8'>
          <InputTextMultiline onChange={(e, id, data) => this.state.description = data} height={136} labelElement='Beschreibung'/>
        </VerticalLayout>
      </div>

      <h3 className='mt-5'>{T.label_tables()}</h3>
      <HorizontalLayout className='items-center gap-3 flex-wrap'>
        {tables}
      </HorizontalLayout>
      <h3 className='mt-5'>Variablen</h3>
      <HorizontalLayout className='items-center gap-x-2 flex-wrap'>
        {variables}
      </HorizontalLayout>
      {/*faha bis die funktion richtig implementiert wird soll nicht angezeigt werden*/}
      {/*<Upload title='Bild Hochladen'/>*/}
      <div className='col-span-full pt-3 mt-6'>
        <HorizontalLayout className='gap-x-5 justify-end'>
          <ButtonSmall onClick={this.props.onCloseButtonClick} className='text-cn-color-white' color='light'>{T.label_cancel()}</ButtonSmall>
          <ButtonSmall onClick={(e) => this.onCloneButtonClick(
            e)} className='text-cn-color-white' color='secondary'>{this.state.cloneButtonText}</ButtonSmall>
        </HorizontalLayout>
      </div>

    </DialogBase>;
  }
}

DialogCloneCampaign.propTypes = {
  title: PropTypes.string,
  closable: PropTypes.bool,
  opened: PropTypes.bool,
  onCloseButtonClick: PropTypes.func,
  onCloneButtonClick: PropTypes.func
}
export default DialogCloneCampaign;
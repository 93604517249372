import PropTypes from "prop-types";
import BaseComponent from "../../../../../lib/components/BaseComponent";
import FieldSelectionCard from "./FieldSelectionCard";
import {createRef} from "react";
import GF from "../../../../../lib/utils/GF";
import VM from "../../../../../lib/model/system/VM";

class FieldSelectionContainer extends BaseComponent {

  constructor(props) {
    super(props);
    this.fieldSelectionCardRef = createRef();

    let storedState = VM.get().getData(this.props.vmKey, 'FieldSelectionContainer.state');
    if (storedState !== null) {
      this.state = storedState;
    } else {
      this.state = {
        fieldMappingDescription: [], fieldMappingFieldName: [], response: null, collectedValues: null, records: null
      };
    }
  }

  collectValues(records) {
    let collection = [];

    for (let rowId in records) {
      let record = records[rowId];
      rowId = parseInt(rowId);
      for (let fieldIndex in record) {
        let fieldValue = record[fieldIndex];
        fieldIndex = parseInt(fieldIndex);

        if (collection[fieldIndex] !== undefined && collection[fieldIndex] !== '') {
          collection[fieldIndex] += ',';
        } else {
          collection[fieldIndex] = '';
        }
        collection[fieldIndex] += fieldValue;
      }
    }
    return collection;
  }

  findColumnValue(fieldValue) {
    return this.state.fieldMappingDescription.indexOf(fieldValue);
  }

  getFieldMapping() {
    let fieldMapping = [];
    if (GF.checkNotNull(this.fieldSelectionCardRef.current)) {
      let result = this.fieldSelectionCardRef.current.readRecords();

      for (let index in result.rows) {
        let record = result.rows[index];

        if (record.fieldSelection !== -1) {
          fieldMapping.push(this.state.fieldMappingFieldName[record.fieldSelection]);
        } else {
          fieldMapping.push('');
        }
      }
    }
    return fieldMapping;
  }

  initFieldMappingDescription() {
    let entityDef = this.props.dataSource.getEntityDef();
    this.state.fieldMappingDescription[-1] = '';
    this.state.fieldMappingFieldName[-1] = '';
    for (let index in entityDef.fieldsDef) {
      let fieldDef = entityDef.fieldsDef[index];
      this.state.fieldMappingDescription.push(fieldDef.description);
      this.state.fieldMappingFieldName.push(fieldDef.name);
    }
  }

  initTable(response) {
    this.state.response = response;
    this.initFieldMappingDescription();
    this.state.collectedValues = this.collectValues(response.data.records);

    this.state.records = [];
    if (this.state.response !== null) {
      for (let fieldIndex in this.state.response.data.records[0]) {
        let fieldValue = this.state.response.data.records[0][fieldIndex];

        fieldIndex = parseInt(fieldIndex);
        this.state.records.push({
          column: 'Spalte ' + (fieldIndex + 1).toString(),
          value: this.state.collectedValues[fieldIndex] + ',...',
          fieldSelection: this.findColumnValue(fieldValue)
        });
      }
    }

    VM.get().setData(this.props.vmKey, this.state, 'FieldSelectionContainer.state');
    this.setState(this.state);
  }

  onReadDone(records) {
    this.state.records = records.data.records;
  }

  onUpdateRowsDone(records) {
    VM.get().setData(this.props.vmKey, this.state, 'FieldSelectionContainer.state');
  }

  render() {
    let fieldSelectionCard;
    if (this.state.response !== null) {
      fieldSelectionCard =
        <FieldSelectionCard ref={this.fieldSelectionCardRef} className='mb-12' fieldMapping={this.state.fieldMappingDescription} records={this.state.records} onReadDone={(records) => this.onReadDone(
          records)} onUpdateRowsDone={(records) => this.onUpdateRowsDone(records)}/>;
    } else {
      fieldSelectionCard = <div/>;
    }
    return fieldSelectionCard;
  }
}

FieldSelectionContainer.propTypes = {
  dataSource: PropTypes.object, vmKey: PropTypes.string
}
export default FieldSelectionContainer;

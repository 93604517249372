import BaseComponent from '../BaseComponent';
import VerticalLayout from '../layouts/VerticalLayout';
import Entity from '../../../appl/view/components/entity/Entity';
import PropTypes from 'prop-types';
import SysLastValueRead from '../../model/service/system/dto/SysLastValueRead';
import TableButtonGroup from '../../../appl/view/components/entity/tableButtonGroup/TableButtonGroup';
import {createRef} from 'react';
import DataSource from '../../model/service/dataSource/DataSource';
import MemoryTable from '../../model/system/data/memory/table/MemoryTable';
import MemoryDataProvider from '../../model/system/data/memory/MemoryDataProvider';
import EntityDef from '../../model/service/entity/EntityDef';
import DataType from '../../model/types/DataType';
import Records from "../../model/service/entity/dto/Records";
import JsonTransformer from "../../model/json/JsonTransformer";
import SystemService from "../../model/service/system/SystemService";
import Listener from "../../model/system/Listener";
import GF from "../../utils/GF";
import ButtonSmall from "../../../appl/view/components/buttons/ButtonSmall";
import shortid from "shortid";
import {Edit, Plus, Trash2} from "react-feather";
import React from "react";
import T from "../../../appl/model/system/text_translations/Translator";
import SysLastValueWrite from "../../model/service/system/dto/SysLastValueWrite";
import {toast} from "react-toastify";
import HorizontalLayout from "../layouts/HorizontalLayout";
import InputText from "../../../appl/view/components/inputFields/InputText";
import FilterProperties from "../../../appl/model/service/system/FilterProperties";
import SysLastValueDelete from "../../model/service/system/dto/SysLastValueDelete";
import Checkbox from "../checkbox/Checkbox";
import InputSearchDropDown from "../../../appl/view/components/inputFields/dropdown/InputSearchDropDown";
import Menu from "../menu/Menu";
import MenuItemGroup from "../menu/MenuItemGroup";
import MenuItem from "../menu/MenuItem";
import ReadList from "../../model/campaign/modules/ustore/v002/service/dto/ReadList";
import CampaignService from "../../../appl/model/service/campaign/CampaignService";
import UstoreModuleService from "../../model/campaign/modules/ustore/v002/service/UstoreModuleService";
import UpdateList from "../../model/campaign/modules/ustore/v002/service/dto/UpdateList";
import CreateList from "../../model/campaign/modules/ustore/v002/service/dto/CreateList";
import InputStateType from "../types/StateType";

class FilterManager extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      entity: null
    }
    this.dialogHook = null;
    this.memoryTable = null;
    this.memoryDataProvider = null;
    this.dataSource = null;
    this.records = [];
    this.filterManagerDialogInputRef = createRef();
    this.filterManagerDialogFilterTypeRef = createRef();
    this.filterManagerDialogUseForSendingRef = createRef();
    this.entityRef = createRef();
    this.addOnReadDoneListener = new Listener((records) => this.onReadDoneListener(records));
    this.addOnRowChangeListener = new Listener((records) => this.onRowChangeDone(records));

    this.sysLastValueId = 'cner21/campaign/' + this.props.campaignId + '/' + this.props.entityName + '/query/';
    this.entityDef = this.initEntityDef();
    this.filterValues = {};
  }

  componentDidMount() {
    this.initEntity();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (GF.checkNotNull(this.dataSource)) {
      this.dataSource.getDataSourceEvents().removeOnReadDoneListener(this.addOnReadDoneListener);
      this.dataSource.getDataSourceEvents().removeOnRowChangeListener(this.addOnRowChangeListener);
    }
  }

  openDialog(content) {
    this.dataSource = null;
    this.state.entity = null;
    this.dialogHook = content;
    this.setState(this.state);
  }

  closeDialog() {
    this.dialogHook = null;
    this.initEntity(() => {
      this.dataSource.read();
    });
  }

  addFilterToSendingList(listName, predicate, campaignId) {
    let readList = new ReadList();
    readList.setProperties(CampaignService.createCampaignRedirectProperty(campaignId));
    UstoreModuleService.doRequest(readList, (response) => {
      let found = false;
      for (let listNameFromResponse in response.data) {
        if (listNameFromResponse === listName) {
          found = true;
          break;
        }
      }
      let query = predicate;
      query.endRow = -1;
      if (found) {
        let updateList = new UpdateList(listName, query);
        updateList.setProperties(CampaignService.createCampaignRedirectProperty(campaignId));
        UstoreModuleService.doRequest(updateList, (response) => {
          toast.success('Versand wurde erfolgreich gespeichert');
        });
      } else {
        let createList = new CreateList(listName, query);
        createList.setProperties(CampaignService.createCampaignRedirectProperty(campaignId));
        UstoreModuleService.doRequest(createList, (response) => {
          toast.success('Versand wurde erfolgreich erstellt');
        });
      }
    });
  }

  onTypeChange(e, id, option, filterManagerDialogUseForSendingRef) {
    if (option.option === FilterProperties.TYPE_LIST) {
      filterManagerDialogUseForSendingRef.current.setValue(true);
      filterManagerDialogUseForSendingRef.current.setReadonly(true);
    } else {
      filterManagerDialogUseForSendingRef.current.setReadonly(false);
    }
  }

  onUseForSending(e, checked) {
    let options = {};
    options[FilterProperties.TYPE_QUERY] = {option: FilterProperties.TYPE_QUERY, displayValue: FilterProperties.TYPE_QUERY_DISPLAY};
    options[FilterProperties.TYPE_LIST] = {option: FilterProperties.TYPE_LIST, displayValue: FilterProperties.TYPE_LIST_DISPLAY};

    if (checked) {
      this.filterManagerDialogFilterTypeRef.current.setValue(FilterProperties.TYPE_LIST, options);
    } else {
      this.filterManagerDialogFilterTypeRef.current.setValue(FilterProperties.TYPE_QUERY, options);
    }
  }

  buildCreateOrEditView(readonly = false, defaultQueryName = '', defaultType = {option: FilterProperties.TYPE_QUERY, displayValue: FilterProperties.TYPE_QUERY_DISPLAY}, defaultUseForSending = false) {
    let inputTextState = InputStateType.none;
    if (GF.checkNotNull(readonly) && readonly === true) {
      inputTextState = InputStateType.readonly;
    }
    return <VerticalLayout>
      <div className='m-2'>
        <InputText ref={this.filterManagerDialogInputRef} labelElement='Name der Liste' state={inputTextState} defaultValue={defaultQueryName}/>
      </div>
      <div className='m-2'>
        <InputSearchDropDown ref={this.filterManagerDialogFilterTypeRef} labelElement='Filter-Art'
                             defaultValue={defaultType} onChange={(e, id, option) => this.onTypeChange(e, id, option, this.filterManagerDialogUseForSendingRef)} state={InputStateType.readonly}>
          <Menu>
            <MenuItemGroup>
              <MenuItem searchValue={FilterProperties.TYPE_QUERY_DISPLAY} option={{
                option: FilterProperties.TYPE_QUERY, displayValue: FilterProperties.TYPE_QUERY_DISPLAY
              }}>{FilterProperties.TYPE_QUERY_DISPLAY}</MenuItem>
              <MenuItem searchValue={FilterProperties.TYPE_LIST_DISPLAY} option={{
                option: FilterProperties.TYPE_LIST, displayValue: FilterProperties.TYPE_LIST_DISPLAY
              }}>{FilterProperties.TYPE_LIST_DISPLAY}</MenuItem>
            </MenuItemGroup>
          </Menu>
        </InputSearchDropDown>
      </div>
      <div className='m-2'>
        <Checkbox ref={this.filterManagerDialogUseForSendingRef} type='checkbox' labelElement='als Versandliste verwendbar' checked={defaultUseForSending} onChange={(e, id, checked) => this.onUseForSending(e, checked)}/>
      </div>
      <HorizontalLayout className='justify-between'>
        <ButtonSmall className='bg-cn-color-blue-690 m-2' onClick={(e) => {
          this.closeDialog();
        }}>Abbrechen</ButtonSmall>
        <ButtonSmall className='bg-cn-color-blue-690 m-2' onClick={(e) => this.writeSysLastValue()}>Speichern</ButtonSmall>
      </HorizontalLayout>
    </VerticalLayout>;
  }
  
  buildAskingDialog(text, onYes) {
    return <VerticalLayout>
      <div>{text}</div>
      <HorizontalLayout className='justify-between'>
        <ButtonSmall className='bg-cn-color-blue-690 m-2' onClick={(e) => {
          this.closeDialog();
        }}>Nein</ButtonSmall>
        <ButtonSmall className='bg-cn-color-static-red m-2' onClick={(e) => onYes(e)}>Ja</ButtonSmall>
      </HorizontalLayout>
    </VerticalLayout>;
  }

  initEntity(onInitDone) {
    this.readSysLastValue(() => {
      this.createDataSource();
      this.createEntity();
      this.setState(this.state);
      if (GF.checkNotNull(onInitDone)) {
        onInitDone();
      }
    });
  }

  readSysLastValue(onReadDone) {
    SystemService.doRequest(new SysLastValueRead(this.sysLastValueId), (response) => {
      this.memoryTable = new MemoryTable(this.entityDef);
      this.memoryDataProvider = new MemoryDataProvider(this.memoryTable);
      let recordsObj = this.createRecords(response.data.data);
      let records = recordsObj.getRecords();
      for (let index in records) {
        let record = records[index];
        this.memoryTable.create(record);
      }
      onReadDone();
    }, 'Es konnten keine Daten geliefert werden!');
  }

  createDataSource(response) {
    if (this.dataSource !== null) {
      this.dataSource.getDataSourceEvents().removeOnReadDoneListener(this.addOnReadDoneListener);
      this.dataSource.getDataSourceEvents().removeOnRowChangeListener(this.addOnRowChangeListener);
    }
    this.dataSource = DataSource.generateDatasource(this.entityDef, this.props.campaignId,
      (datasource, dto, dataSourceDtoProcessEvents) => {
        let response = this.memoryDataProvider.processDto(dto);
        dataSourceDtoProcessEvents.doResponse(response);
      });
    this.dataSource.getDataSourceEvents().addOnRowChangeListener(this.addOnRowChangeListener);
    this.dataSource.getDataSourceEvents().addOnReadDoneListener(this.addOnReadDoneListener);
  }

  createEntity() {
    let headerButtons;
    if (!GF.checkNotNull(this.props.readonly) || this.props.readonly === false) {
      headerButtons =
        <TableButtonGroup ref={createRef()} showExtendedFilterButton={false}><ButtonSmall tooltip='Aktualisiere Filter' key={shortid()} uid='UPDATE' onClick={(e) => this.actualizeSysLastValue(
          e)}>{
          <Edit/>}</ButtonSmall><ButtonSmall tooltip={T.label_add()} key={shortid()} uid='ADD' onClick={(e) => this.addSysLastValue(
          e)}>{
          <Plus/>}</ButtonSmall><ButtonSmall tooltip={T.label_delete()} key={shortid()} uid='DELETE' onClick={(e) => this.deleteSysLastValue(
          e)}>{<Trash2/>}</ButtonSmall></TableButtonGroup>;
    }
    this.state.entity =
      <Entity ref={this.entityRef} dataSource={this.dataSource} headerButtons={headerButtons} readonly={true}/>;
  }

  addSysLastValue(e) {
    this.openDialog(this.buildCreateOrEditView());
  }

  actualizeSysLastValue(e) {
    let currentRecords = this.dataSource.getCurrentRecords();
    let listName = '';
    let type = {option: FilterProperties.TYPE_QUERY, displayValue: FilterProperties.TYPE_QUERY_DISPLAY};
    let useForSending = false;
    if (GF.checkNotNull(currentRecords) && currentRecords.length > 0 && currentRecords[0] !== null) {
      listName = currentRecords[0].listName;
      switch (currentRecords[0].type) {
        case FilterProperties.TYPE_LIST_DISPLAY:
          type = {option: FilterProperties.TYPE_LIST, displayValue: FilterProperties.TYPE_LIST_DISPLAY};
          break;
        default:
          break;
      }
      useForSending = currentRecords[0].useForSending;
    }
    if (listName) {
      this.openDialog(this.buildCreateOrEditView(true, listName, type, useForSending));
    } else {
      toast.warning('Es ist keine Liste vorhanden!');
    }
  }

  deleteSysLastValue(e) {
    let currentRecords = this.dataSource.getCurrentRecords();
    if (currentRecords !== null && currentRecords.length > 0) {
      let currentRecord = currentRecords[0];
      if (currentRecord !== null) {
        this.openDialog(this.buildAskingDialog('Achtung willst du den Filter [' + currentRecord.listName + '] löschen?', (e) => {
          SystemService.doRequest(new SysLastValueDelete(this.sysLastValueId + currentRecord.listName), (response) => {
            toast.success('Das löschen des Filters ist erledigt!');
            this.closeDialog();
          }, 'Der Filter konnte nicht gelöscht werden!');
        }));
      } else {
        toast.warning('Es ist keine Liste vorhanden!');
      }
    }
  }

  initEntityDef() {
    let entityDef = new EntityDef();
    entityDef.addField('id', 'ID', DataType.INTEGER, true);
    entityDef.addField('listName', 'Listenname', DataType.STRING);
    // entityDef.addField('split', 'Teilen', DataType.BOOLEAN);
    entityDef.addField('useForSending', 'Versandliste', DataType.BOOLEAN);
    entityDef.addField('favorite', 'Favorit', DataType.BOOLEAN);
    entityDef.addField('type', 'Art', DataType.STRING);
    entityDef.addField('createdDate', 'Erstelldatum', DataType.DATETIME);
    return entityDef.getEntityDef();
  }

  createRecords(rawData) {
    this.records = [];
    for (let rawRecord of rawData) {
      let filterProperties = JsonTransformer.decodeString(rawRecord['value']);

      if (!GF.checkNotNull(this.props.showOnlyUseForSending) || this.props.showOnlyUseForSending === false ||
        (this.props.showOnlyUseForSending === true && filterProperties.isUseForSending())) {

        let type = '';
        switch (filterProperties.getType()) {
          case FilterProperties.TYPE_QUERY:
            type = FilterProperties.TYPE_QUERY_DISPLAY;
            break;
          case FilterProperties.TYPE_LIST:
            type = FilterProperties.TYPE_LIST_DISPLAY;
            break;
          default:
            break;
        }
        let newRecord = {
          listName: filterProperties.getListName(),
          useForSending: filterProperties.isUseForSending(),
          favorite: filterProperties.isFavorite(),
          type: type,
          createdDate: filterProperties.getCreatedDate()
        };
        this.records.push(newRecord);
        this.filterValues[filterProperties.getListName()] = filterProperties.getValue();
      }
    }
    return new Records(this.records);
  }

  getFilter(listName) {
    return this.filterValues[listName];
  }

  onReadDoneListener(records) {
    if (GF.checkNotNull(this.props.onChange)) {
      let records = this.dataSource.getCurrentRecords();
      let record = {listName: 'leer'};
      if (records.length > 0) {
        record = records[0];
      }
      this.props.onChange({record: record});
    }
    if (GF.checkNotNull(this.props.defaultListName) && this.props.defaultListName !== '') {
      let recordsAll = this.dataSource.getRecords();
      for (let index in recordsAll) {
        let record = recordsAll[index];

        if (record.listName === this.props.defaultListName) {
          this.dataSource.setCurrentRecIds([record.id]);
        }
      }
    }
  }

  onRowChangeDone(e) {
    if (GF.checkNotNull(this.props.onChange)) {
      this.props.onChange(e);
    }
  }

  onDoFilter(e) {
    if (GF.checkNotNull(this.props.onDoFilter)) {
      this.props.onDoFilter(e)
    }
  }

  writeSysLastValue(queryName = null, filterType = null, useForSending = null) {
    if (queryName === null) {
      queryName = this.filterManagerDialogInputRef.current.getValue();
    }
    if (filterType === null) {
      filterType = this.filterManagerDialogFilterTypeRef.current.getValue();
      if (typeof filterType === 'object') {
        filterType = filterType.option;
      }
    }
    if (useForSending === null) {
      useForSending = this.filterManagerDialogUseForSendingRef.current.getValue();
    }
    let filterProperties = new FilterProperties(queryName, useForSending, false, filterType, this.props.query,
      GF.dateTimeToString(new Date()));
    SystemService.doRequest(new SysLastValueWrite(this.sysLastValueId + queryName, filterProperties), (response) => {
      toast.success('Das erstellen oder aktualisiieren des Filters war erfolgreich!');
      if (filterType === FilterProperties.TYPE_LIST) {
        this.addFilterToSendingList(queryName, this.props.query, this.props.campaignId);
      }
      this.closeDialog();
    }, 'Der Filter konnte nicht erstellt oder aktualisiert werden!');
  }

  setBackgroundColor(color) {
    if (GF.checkNotNull(this.props.parentRef) && GF.checkNotNull(this.props.parentRef.current) &&
      GF.checkNotNull(this.props.parentRef.current.state.className) &&
      GF.checkNotNull(this.props.parentRef.current.state.className)) {

      this.props.parentRef.current.state.className = color;
      this.props.parentRef.current.setState(this.props.parentRef.current.state);
    }
  }

  setTitle(title) {
    if (GF.checkNotNull(this.props.parentRef) && GF.checkNotNull(this.props.parentRef.current) &&
      GF.checkNotNull(this.props.parentRef.current.state) &&
      GF.checkNotNull(this.props.parentRef.current.state.title)) {

      this.props.parentRef.current.state.title = title;
      this.props.parentRef.current.setState(this.props.parentRef.current.state);
    }
  }

  render() {
    let content;
    if (this.dialogHook !== null) {
      this.setTitle('Filter erstellen');
      content = this.dialogHook;
    } else {
      this.setTitle('Gespeicherte Filter');
      let btnAction;
      if (!GF.checkNotNull(this.props.showActionButton) || this.props.showActionButton === true) {
        btnAction = <HorizontalLayout className='justify-end'>
          <ButtonSmall className='bg-cn-color-blue-690 mt-2 justify-end' onClick={(e) => this.onDoFilter(
            e)}>Anwenden</ButtonSmall>
        </HorizontalLayout>;
      }
      content = <VerticalLayout>
        {this.state.entity}
        {btnAction}
      </VerticalLayout>;
    }
    return <VerticalLayout>
      {content}
    </VerticalLayout>;
  }
}

FilterManager.propTypes = {
  campaignId: PropTypes.number.isRequired,
  entityName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  defaultListName: PropTypes.string,
  onDoFilter: PropTypes.func,
  query: PropTypes.object,
  entity: PropTypes.object,
  readonly: PropTypes.bool,
  showActionButton: PropTypes.bool,
  showOnlyUseForSending: PropTypes.bool,
  parentRef: PropTypes.object
}
export default FilterManager;

import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import VerticalLayout from "../layouts/VerticalLayout";
import GF from "../../utils/GF";

class Card extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      className: GF.getValue(this.props.className),
      cardTitle: GF.getValue(this.props.cardTitle),
      cardContent: GF.getValue(this.props.cardContent)
    }
  }

  render() {
    return <VerticalLayout className={this.state.className}>
      {this.state.cardTitle}
      {this.state.cardContent}
    </VerticalLayout>;
  }
}

Card.propTypes = {
  className: PropTypes.string, cardTitle: PropTypes.any, cardContent: PropTypes.any
}
export default Card;

import BaseComponent from "../../../../lib/components/BaseComponent";
import Window from "../../../../lib/components/window/Window";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import {Key} from "react-feather";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import InputText from "../inputFields/InputText";
import VerticalSeparator from "../seperator/VerticalSeparator";
import ButtonSmall from "../buttons/ButtonSmall";
import TabContainerHorizontal from "../../../../lib/components/tabbar/TabContainerHorizontal";
import React, {createRef} from "react";
import ButtonTab from "../../../../lib/components/tabbar/ButtonTab";
import TabBarHorizontal from "../../../../lib/components/tabbar/TabBarHorizontal";
import TabContent from "../../../../lib/components/tabbar/TabContent";
import EntityForm from "../entity/form/EntityForm";
import EntityDescribe from "../../../../lib/model/service/entity/dto/EntityDescribe";
import CM from "../../../model/system/CM";
import shortid from "shortid";
import Authorization from "./forms/Authorization";
import {toast} from "react-toastify";
import SystemService from "../../../../lib/model/service/system/SystemService";
import GetAccountInfo from "../../../../lib/model/service/system/dto/GetAccountInfo";
import GF from "../../../../lib/utils/GF";
import Get from "../../../../lib/model/service/resource/dto/Get";
import Upload from "../../../../lib/components/Upload";
import SaveAccountImage from "../../../../lib/model/service/system/dto/SaveAccountImage";

class AccountPage extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      accountData: null
    }
    this.tabContainerRef = createRef();
    this.entityUserFormRef = createRef();
    this.entityUserGroupFormRef = createRef();
    this.profileImageRef = createRef();
  }

  onUserAuthorizationClick(e) {
    let recId = -1;
    let currentRecords = this.entityUserFormRef.current.entityRef.current.dataSource.getCurrentRecIds();
    if (currentRecords.length > 0) {
      recId = parseInt(currentRecords[0]);
    }
    this.tabContainerRef.current.addElement(<ButtonTab>Berechtigung (Benutzer)</ButtonTab>,
      <Authorization recid={recId} mode='user'/>);
  }

  onUserGroupAuthorizationClick(e) {
    let recId = -1;
    let currentRecords = this.entityUserGroupFormRef.current.entityRef.current.dataSource.getCurrentRecIds();
    if (currentRecords.length > 0) {
      recId = parseInt(currentRecords[0]);
    }
    if (recId) {
      this.tabContainerRef.current.addElement(<ButtonTab>Berechtigung (Benutzergruppe)</ButtonTab>,
        <Authorization recid={recId} mode='userGroup'/>);
    } else {
      toast.warning('Es ist kein Datensatz ausgewählt!')
    }
  }

  componentDidMount() {
    CM.get().doJsonAjax(CM.get().getServiceUrl(SystemService.NAME),
      new GetAccountInfo(CM.get().getUserContext().accounts[0]), (response) => {
        if (response.success) {
          this.state.accountData = response.data;
          this.setState(this.state);
        }
      });

    this.addAccountTableToTabLayout(() => {
      this.addUserGroupsTableToTabLayout(() => {
        this.addUsersTableToTabLayout();
      });
    });
  }

  addAccountTableToTabLayout(onDoneCallback) {
    let self = this;
    let describeSysAccountTable = new EntityDescribe(['po2\\system\\db\\doctrine\\entity\\SysAccount']);
    CM.get().doJsonAjax(CM.get().getServiceUrl(describeSysAccountTable._serviceName), describeSysAccountTable,
      (response) => {
        if (response.success) {
          this.tabContainerRef.current.addElement(<ButtonTab hideCloseButton>Account</ButtonTab>,
            <EntityForm ref={this.entityUserGroupFormRef} tabId={'SysAccount:SysAccount'} entityDef={response.data.serviceDescription.entityDefs[0]}
                        tabMainContainer={self.tabContainerRef.current}/>);
        } else {
          toast.error(response.message);
        }
        onDoneCallback();
      });

  }

  addUserGroupsTableToTabLayout(onDoneCallback) {
    let self = this;
    let describeSysUserGroupTable = new EntityDescribe(['po2\\system\\db\\doctrine\\entity\\SysUserGroup']);
    CM.get().doJsonAjax(CM.get().getServiceUrl(describeSysUserGroupTable._serviceName), describeSysUserGroupTable,
      (response) => {
        if (response.success) {
          let additionalTableButtons = [
            <ButtonSmall key={shortid()} uid='authorization' onClick={(e) => this.onUserGroupAuthorizationClick(e)}>{
              <Key/>}</ButtonSmall>];
          this.tabContainerRef.current.addElement(<ButtonTab hideCloseButton>Benutzergruppen</ButtonTab>,
            <EntityForm ref={this.entityUserGroupFormRef} tabId={'SysUserGroup:SysUserGroup'}
                        entityDef={response.data.serviceDescription.entityDefs[0]} tabMainContainer={self.tabContainerRef.current}
                        additionalTableButtons={additionalTableButtons}/>);
        } else {
          toast.error(response.message);
        }
        onDoneCallback();
      });
  }

  addUsersTableToTabLayout() {
    let self = this;
    let describeSysUserTable = new EntityDescribe(['po2\\system\\db\\doctrine\\entity\\SysUser']);
    CM.get().doJsonAjax(CM.get().getServiceUrl(describeSysUserTable._serviceName), describeSysUserTable, (response) => {
      if (response.success) {
        let additionalTableButtons = [
          <ButtonSmall key={shortid()} uid='authorization' onClick={(e) => this.onUserAuthorizationClick(e)}>{
            <Key/>}</ButtonSmall>];
        this.tabContainerRef.current.addElement(<ButtonTab hideCloseButton>Benutzer</ButtonTab>,
          <EntityForm ref={this.entityUserFormRef} tabId={'sysUser:sysUser'} entityDef={response.data.serviceDescription.entityDefs[0]}
                      tabMainContainer={self.tabContainerRef.current} additionalTableButtons={additionalTableButtons}/>);
      } else {
        toast.error(response.message);
      }
    });
  }

  onUploadDone(response) {
    if (response.success) {
      let resource = response.data.fileUploadEntities[0];
      CM.get().doJsonAjax(CM.get().getServiceUrl(SystemService.NAME),
        new SaveAccountImage('', Get.buildResourceDataString(resource.resourceId, resource.name)),
        (saveAccountResponse) => {
          if (saveAccountResponse.success) {
            this.state.profileImage =
              <img alt='profile' className='cn-border-radius-8 w-[250px] self-center' ref={this.profileImageRef}
                   src={Get.buildResourceLink(resource.resourceId, resource.name)}/>;
            this.setState(this.state)
            toast.success('Firmenlogo ist aktualisiert');
          } else {
            toast.error(saveAccountResponse.message);
          }
        });
    }
  }

  createAccountWindow() {
    if (this.state.accountData !== null) {
      let logo = '';
      if (this.state.accountData.logo !== undefined && this.state.accountData.logo !== null &&
        this.state.accountData.logo !== '') {
        logo = this.state.accountData.logo.split(':');
      }
      if (logo !== '') {
        this.state.profileImage =
          <img alt='profile' className='cn-border-radius-8 w-[250px] self-center' ref={this.profileImageRef}
               src={Get.buildResourceLink(logo[0], logo[1])}/>;
      }
      return <Window classNameContent='px-16 text-cn-color-white'>
        <HorizontalLayout widthFit className='gap-16 flex-col lg:flex-row'>
          <HorizontalLayout className='w-full lg:w-1/2 gap-16 flex-col lg:flex-row'>
            <div className='w-3/7 justify-self-center'>
              <VerticalLayout className='justify-between h-full'>
                <VerticalLayout className='gap-y-3'>
                  <h3>Firmenlogo</h3>
                  {this.state.profileImage}
                  <Upload className='flex gap-x-3 text-cn-color-white' onUploadDone={(response) => this.onUploadDone(
                    response)}/>

                  {/*<AccountButtonSmall />*/}
                </VerticalLayout>
                {/*<VerticalLayout className='gap-y-3'>*/}
                {/*  <h3>Favicon</h3>*/}
                {/*  <AccountButtonSmall>hochladen</AccountButtonSmall>*/}
                {/*</VerticalLayout>*/}
                {this.state.accountData.license && <VerticalLayout className='gap-y-3'>
                  <h3>Lizenz</h3>
                  <HorizontalLayout widthFit className='justify-between'>
                    <span className='cn-font-paragraph'>{GF.getValue(this.state.accountData.license.name, '')}</span>
                    {this.state.accountData.license.upgradable &&
                      <a href='mailto:vtools@voegeli.ch' className='text-cn-color-primary'>Upgrade</a>}
                  </HorizontalLayout>
                </VerticalLayout>}
                {/*{CM.get().getUserContext() !== null && CM.get().getUserContext().isRootUser && <ButtonSmall color='red' onClick={(e) => {*/}

                {/*  let deleteAccount = new DeleteAccount(CM.get().getUserContext().accounts[0]);*/}
                {/*  // CM.get().doJsonAjax(CM.get().getServiceUrl(SystemService.NAME), deleteAccount, (response) => {*/}
                {/*  //   if (response.success) {*/}
                {/*  //     alert('deleted');*/}
                {/*  //   }*/}
                {/*  // });*/}

                {/*}}>Account löschen*/}
                {/*</ButtonSmall>}*/}

              </VerticalLayout>
            </div>

            <VerticalLayout widthFit className='gap-y-3 w-full lg:w-3/4 justify-self-center justify-between'>
              <VerticalLayout className='gap-y-3'>
                <h3>Firma</h3>
                <InputText labelElement='Accountname' defaultValue={GF.getValue(this.state.accountData.accountName,
                  '')}/>
                <InputText labelElement='Bemerkungen'/>
                <InputText labelElement='Kundenreferenz Vögeli' defaultValue={GF.getValue(
                  this.state.accountData.customerRef, '')} state='disable'/>
              </VerticalLayout>
              {/*<VerticalLayout className='gap-y-3'>*/}
              {/*  <h3>Firmen Farben</h3>*/}
              {/*  <HorizontalLayout className='justify-between'>*/}
              {/*    <HorizontalLayout className='gap-x-2'>*/}
              {/*      <ColorTile color='#fff'/>*/}
              {/*      <ColorTile color='#f00'/>*/}
              {/*      <ColorTile color='#0f0'/>*/}
              {/*      <ColorTile color='#ff0'/>*/}
              {/*    </HorizontalLayout>*/}
              {/*    <SymboleTile><Plus/></SymboleTile>*/}
              {/*  </HorizontalLayout>*/}
              {/*</VerticalLayout>*/}
              {/*<VerticalLayout className='gap-y-3'>*/}
              {/*  <h3>Farben</h3>*/}
              {/*  <HorizontalLayout className='justify-between'>*/}
              {/*    <HorizontalLayout className='gap-x-2'>*/}
              {/*      <ColorTile color='#fff'/>*/}
              {/*      <ColorTile color='#f00'/>*/}
              {/*      <ColorTile color='#0f0'/>*/}
              {/*      <ColorTile color='#ff0'/>*/}
              {/*    </HorizontalLayout>*/}
              {/*    <SymboleTile><Plus/></SymboleTile>*/}
              {/*  </HorizontalLayout>*/}
              {/*</VerticalLayout>*/}
              {/*<VerticalLayout className='gap-y-3'>*/}
              {/*  <h3>Symbole</h3>*/}
              {/*  <HorizontalLayout className='justify-between'>*/}
              {/*    <HorizontalLayout className='gap-x-2'>*/}
              {/*      <SymbolTile><AlertCircle/></SymbolTile>*/}
              {/*      <SymbolTile><HelpCircle/></SymbolTile>*/}
              {/*      <SymbolTile><XCircle/></SymbolTile>*/}
              {/*      <SymbolTile><PlayCircle/></SymbolTile>*/}
              {/*      <SymbolTile><StopCircle/></SymbolTile>*/}
              {/*    </HorizontalLayout>*/}
              {/*    <SymboleTile><Plus/></SymboleTile>*/}
              {/*  </HorizontalLayout>*/}
              {/*</VerticalLayout>*/}
            </VerticalLayout>
          </HorizontalLayout>

          <VerticalSeparator className='hidden lg:block'/>
          <HorizontalLayout className='w-full lg:w-1/2 gap-16 flex-col lg:flex-row'>
            <VerticalLayout widthFit className='w-full lg:w-2/4 gap-y-3 justify-self-center'>
              <h3>Firmenadresse</h3>
              <InputText labelElement='Firma'/>
              <InputText labelElement='Firmenzusatz'/>
              <InputText labelElement='Adresse' defaultValue={this.state.accountData.address.street}/>
              <InputText labelElement='PLZ' defaultValue={this.state.accountData.address.zip}/>
              <InputText labelElement='Ort' defaultValue={this.state.accountData.address.place}/>
              <InputText labelElement='E-Mailadresse' defaultValue={this.state.accountData.address.email}/>
              <InputText labelElement='Telefon' defaultValue={this.state.accountData.address.phone}/>
            </VerticalLayout>
            {/*<VerticalLayout widthFit className='w-full lg:w-2/4 gap-y-3 justify-self-center'>*/}
            {/*  <h3>Firmen-Socials</h3>*/}
            {/*  <InputLink labelElement='Webseite'/>*/}
            {/*  <InputLink labelElement='Twitter'/>*/}
            {/*  <InputLink labelElement='Facebook'/>*/}
            {/*  <InputLink labelElement='Instagram'/>*/}
            {/*  <InputLink labelElement='Youtube'/>*/}
            {/*  <InputLink labelElement='LinkedIn'/>*/}
            {/*  <InputLink labelElement='Xing'/>*/}
            {/*</VerticalLayout>*/}

          </HorizontalLayout>
        </HorizontalLayout>

        {/*<HorizontalLayout className='justify-end gap-x-5 mt-5'>*/}
        {/*  <ButtonSmall color='light'>Abbrechen</ButtonSmall>*/}
        {/*  <ButtonSmall color='secondary'>{T.label_save()}</ButtonSmall>*/}
        {/*</HorizontalLayout>*/}
      </Window>
    }
    return <></>;
  }

  render() {
    return <VerticalLayout>
      {this.createAccountWindow()}
      <TabContainerHorizontal className='pt-2 h-full' ref={this.tabContainerRef} tabBar={
        <TabBarHorizontal/>} tabContent={<TabContent className='pb-[8px]'/>}/>
    </VerticalLayout>;
  }
}

export default AccountPage;
import Dto from "../service/Dto";
import GF from "../../utils/GF";

class Privilege extends Dto {

  static ALLOW_VISIBLE = 1;
  static ALLOW_VIEW = 2;
  static ALLOW_CREATE = 4;
  static ALLOW_EDIT = 8;
  static ALLOW_DELETE = 16;
  static ALLOW_SORT = 32;
  static ALLOW_FILTER = 64;
  static ALLOW_IMPORT = 128;
  static ALLOW_EXPORT = 256;
  static ALLOW_CONNECT = 512;
  static ALLOW_DISCONNECT = 1024;

  constructor() {
    super('po2\\types\\Privilege');
  }

  static isPrivilegeAllowed(privilegeValue, privilege) {
    return GF.isBitSet(privilegeValue, privilege);
  }
}

export default Privilege

import React from 'react';
import BaseComponent from '../BaseComponent';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import DialogHeader from './DialogHeader';
import GF from "../../utils/GF";

class DialogBase extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      title: GF.getValue(this.props.title), className: GF.getValue(this.props.className, '')
    };
  }

  addBackDrop = (e) => {
    if (e.code === 'Escape') {
      this.closeDialog(e);
    }
  };

  componentDidMount() {
    let element = document.querySelector('.dialog-content');
    element.style.setProperty('--animate-duration', '0.5s');
    document.querySelector('.dialog-fixed-background').style.setProperty('--animate-duration', '0.3s');
    window.addEventListener('keydown', this.addBackDrop);
  }

  onCloseButtonClick(e) {
    this.closeDialog(e);
  }

  closeDialog = (e) => {
    window.removeEventListener('keydown', this.addBackDrop);
    let dialogBackground = document.querySelector('.dialog-fixed-background');
    if (dialogBackground != null) {
      dialogBackground.classList.add('animate__fadeOut');
    }
    let dialogContent = document.querySelector('.dialog-content');
    if (dialogContent) {
      dialogContent.classList.add('animate__zoomOut');
    }
    setTimeout(() => {
      if (this.props.onCloseButtonClick !== undefined) {
        this.props.onCloseButtonClick(e);
      }
    }, 400);
  }

  render() {
    let {header, children} = this.props;
    let classes = this.state.className ? this.state.className : '';
    let dialogRoot = document.getElementById('root');
    let dialogContent = (
      <div className='dialog-fixed-background fixed inset-0 z-50 backdrop-brightness-50 backdrop-blur-sm flex justify-center items-center animate__animated animate__fadeIn'>
        <div className={`dialog-content w-screen lg:w-fit h-screen lg:h-fit  animate__animated animate__zoomIn border-2 border-cn-color-secondary cn-shadow-window p-3 bg-cn-color-black text-cn-color-white cn-border-radius-20  ${classes}`}>
          {header ||
            <DialogHeader title={this.state.title} closable={this.props.closable} onCloseButtonClick={(e) => this.onCloseButtonClick(e)}/>}
          {children}
        </div>
      </div>);
    return ReactDOM.createPortal(dialogContent, dialogRoot);
  }
}

DialogBase.propTypes = {
  title: PropTypes.string,
  closable: PropTypes.bool,
  className: PropTypes.string,
  onCloseButtonClick: PropTypes.func,
  header: PropTypes.node,
  children: PropTypes.node
};

export default DialogBase;

import React, {Component} from 'react';
import PropTypes from "prop-types";
import shortid from "shortid";
import ButtonTab from "../../../../lib/components/tabbar/ButtonTab";

class SettingsItem extends Component {

  onClick(e) {
    if (this.props.onClick !== undefined) {
      this.props.onClick(e);
    }
    this.props.parent.tabContainerRef.current.addElement(
      <ButtonTab hideCloseButton id={this.props.title}>{this.props.title}</ButtonTab>, this.props.children);
  }

  render() {
    return <>
      <div key={shortid()} className='w-64 h-64 flex justify-between items-center hover:bg-cn-color-gray-655 rounded-2xl flex-col p-5'
           onClick={(e) => this.onClick(e)}>
        <h3>{this.props.title}</h3>
        {this.props.img}
      </div>
      <div key={shortid()} className="bg-cn-color-white-960 w-[1px] my-5 last:hidden"></div>
    </>;
  }
}

SettingsItem.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.node,
  parent: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func
}

export default SettingsItem;

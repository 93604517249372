import Dto from "../../Dto";
import Credential from "../../dto/Credential";
import CM from "../../../../../appl/model/system/CM";

/**
 * class Get
 */
class Get extends Dto {

  static buildResourceLink = function(resourceId, name, properties = null) {
    let dtoGet = new Get(resourceId, name);
    let credential = new Credential(null, null, CM.get().getToken());
    dtoGet.setCredential(credential);
    let url = CM.get().getServiceUrl('Resource');
    url = url.substring(0, url.length - 1);
    dtoGet.properties = properties;
    let dtoGetString = JSON.stringify(dtoGet);
    url += '?dto=' + dtoGetString;

    return url;
  }

  static buildResourceDataString = function(resourceId, name) {
    return resourceId + ':' + name;
  }

  constructor(resourceId = '', name = '') {
    super('po2\\service\\resource\\dto\\Get');
    this.resourceId = resourceId;
    this.name = name;
  }
}

export default Get;

import Dto from "../../../../../lib/model/service/Dto";

class AddCampaignImg extends Dto {

  constructor(campaignId = 0, resourceId = '', resourceName = '') {
    super('appl\\service\\campaign\\dto\\AddCampaignImg');
    this.campaignId = campaignId;
    this.resourceId = resourceId;
    this.resourceName = resourceName;
  }
}

export default AddCampaignImg;

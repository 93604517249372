import Dto from "../../Dto";

/**
 * class Put
 */
class Put extends Dto {

  importId = undefined;

  constructor(importId = '') {
    super('po2\\service\\resource\\dto\\Put');
    this.importId = importId;
  }
}

export default Put;

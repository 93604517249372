import PropTypes from "prop-types";
import InputBase from "./InputBase";
import InputStateType from "../../../../lib/components/types/StateType";
import {createRef} from "react";

class InputTextMultiline extends InputBase {

  constructor(props) {
    super(props);
    this.inputElementRef = createRef();
  }

  getValue() {
    return this.inputElementRef.current.value;
  }

  render() {
    if (this.state.state === InputStateType.disable || this.state.state === InputStateType.readonly) {
      this.state.children = <textarea ref={this.inputElementRef} style={{
        height: this.props.height + 'px'
      }} className='w-full text-cn-color-white-960 bg-transparent resize-y focus:outline-0 inputtext-multiline-scroll' disabled defaultValue={this.props.defaultValue} onChange={(e) => this.fireOnChange(e)}/>;
    } else {
      this.state.children = <textarea ref={this.inputElementRef} style={{
        height: this.props.height + 'px'
      }} className='w-full text-cn-color-white-960 bg-transparent resize-y focus:outline-0 inputtext-multiline-scroll' defaultValue={this.props.defaultValue} onChange={(e) => this.fireOnChange(e)}/>;
    }

    return super.render();
  }
}

InputTextMultiline.propTypes = {
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  inputLayout: PropTypes.oneOf(['inline', 'outline', '']),
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  height: PropTypes.number
}
export default InputTextMultiline;

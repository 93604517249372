import BaseComponent from "../BaseComponent";
import './TabBar.css';
import HorizontalLayout from "../layouts/HorizontalLayout";


class LabelTab extends BaseComponent {

  render() {

    return <HorizontalLayout className="bg-transparent w-max">

      <a className="px-2 mx-1 focusLabel" href="#">{this.props.children}</a>

    </HorizontalLayout>

  }
}

export default LabelTab;

import InputBase from "../../../appl/view/components/inputFields/InputBase";
import PropType from "prop-types";
import shortid from "shortid";
import GF from "../../utils/GF";
import PropTypes from "prop-types";
import HorizontalLayout from "../layouts/HorizontalLayout";
import {createRef} from "react";

class Checkbox extends InputBase {

  constructor(props) {
    super(props);
    this.state.checked = GF.getValue(this.props.checked, (this.props.type === 'checkboxWithNoneState' ? null : false));
    this.state.readonly = GF.getValue(this.props.readonly, false);
    this.inputFieldRef = createRef();
  }

  onChange(e) {
    switch (this.props.type) {
      case 'switch':
        this.state.checked = e.currentTarget.checked;
        break;
      default:
        break;
    }
    this.fireOnChange(e, this.state.checked);
  }

  onClick(e) {
    switch (this.state.checked) {
      case null:
        this.state.checked = true;
        break;
      case false:
        if (this.props.type === 'checkboxWithNoneState') {
          this.state.checked = null;
        } else {
          this.state.checked = true;
        }
        break;
      case true:
        this.state.checked = false;
        break;
    }
    this.setState(this.state);
  }

  buildInputFav() {
    return <input ref={this.inputFieldRef} type="checkbox" className="appearance-none content-star checked:content-star-fill"/>;
  }

  buildInputSwitch() {
    let id = shortid();
    return <HorizontalLayout widthFit className="items-center justify-between peer">
      <label htmlFor={id} className="inline-flex relative items-center cursor-pointer mr-3 text-sm cn-font-paragraph text-cn-color-white">{this.props.labelElement}</label>
      <input ref={this.inputFieldRef} onChange={(e) => this.onChange(e)} id={id} type="checkbox" defaultChecked={this.props.checked}
             className="appearance-none cursor-pointer w-[40px] h-[20px] bg-cn-color-black-804 focus:outline-none focus:ring-0 focus:ring-cn-color-primary rounded-full checked:after:translate-x-[20px] checked:after:border-cn-color-white after:content-x after:checked:content-check after:relative after:block after:top-[2px] after:left-[2px] after:bg-cn-color-static-white after:border-cn-color-primary after:rounded-full after:h-[16px] after: after:w-[16px] after:transition-all checked:bg-cn-color-blue-690"/>
    </HorizontalLayout>;
  }

  buildInputDarkLight() {
    return <input ref={this.inputFieldRef} onChange={(e) => this.onChange(
      e)} type="checkbox" defaultChecked={this.state.checked} className="appearance-none cursor-pointer w-[76px] h-[38px] bg-cn-color-secondary focus:outline-none focus:ring-0 focus:ring-cn-color-primary rounded-full checked:after:translate-x-[38px] checked:after:border-cn-color-white  after:content-moon after:checked:content-sun after:relative after:flex after:item-center after:justify-center after:pt-1 after:top-[2px] after:left-[2px] after:bg-cn-color-static-white after:border-cn-color-primary after:rounded-full after:h-[34px] after:w-[34px] after:transition-all checked:bg-cn-color-primary"/>;
  }

  buildInputCheckBox() {
    let id = shortid();
    let description = "";
    if (typeof this.props.description !== 'undefined') {
      description = <p className=" mt-3 cn-font-description01 text-cn-color-background-gray01">{this.props.description}</p>
    }
    let checkClass = 'appearance-none w-[24px] min-w-[24px] h-[24px] rounded-[8px] border-cn-color-white border-[1px] ';
    switch (this.state.checked) {
      case null:
        checkClass += "bg-cn-color-blue-690 border-cn-color-blue-690 shadow-[0_0_0_1px_inset] shadow-cn-color-black-804 box-border bg-no-repeat bg-[center_top_5px] ";
        break;
      case true:
        checkClass += "bg-cn-color-blue-690 border-cn-color-blue-690 shadow-[0_0_0_1px_inset] shadow-cn-color-black-804 box-border bg-[url('lib/fonts/icons/check_white.svg')] bg-no-repeat bg-[center_top_5px] ";
        break;
      case false:
        break;
    }
    return <>
      <HorizontalLayout widthFit className="flex-row items-center">
        <input ref={this.inputFieldRef} onClick={(e) => this.onClick(e)} onChange={(e) => this.onChange(e)} id={id} type="checkbox" value="" className={checkClass}
               disabled={GF.getValue(this.state.readonly, false)} defaultChecked={this.state.checked}/>
        <label htmlFor={id} className="ml-2 text-sm cn-font-paragraph text-cn-color-white">{this.props.labelElement}</label>
      </HorizontalLayout>
      {description}
    </>;
  }

  buildInputDefault() {
    return (<input ref={this.inputFieldRef} onChange={(e) => this.onChange(
      e)} type='checkbox' className="appearance-none w-[24px] h-[24px] rounded-[8px] border-cn-color-white border-[1px] checked:bg-cn-color-blue-690 checked:border-cn-color-blue-690 checked:shadow-[0_0_0_1px_inset] checked:shadow-cn-color-black-804 box-border checked:bg-[url('lib/fonts/icons/check_white.svg')] checked:bg-no-repeat checked:bg-[center_top_5px]"/>)
  }

  getValue() {
    return this.state.checked;
  }

  setValue(checked) {
    this.state.checked = checked;
    this.setState(this.state);
  }

  setReadonly(readonly) {
    this.state.readonly = readonly;
    this.setState(this.state);
  }

  setFocus() {
    if (GF.checkNotNull(this.inputFieldRef.current)) {
      this.inputFieldRef.current.focus();
    }
  }

  render() {
    let checkBox;
    switch (this.props.type) {
      case 'fav':
        checkBox = this.buildInputFav();
        break;
      case 'switch':
        checkBox = this.buildInputSwitch();
        break;
      case 'darklight':
        checkBox = this.buildInputDarkLight();
        break;
      case 'checkbox':
      case 'checkboxWithNoneState':
        checkBox = this.buildInputCheckBox();
        break;
      default:
        this.buildInputDefault();
        break;
    }
    return checkBox;
  }
}

Checkbox.propTypes = {
  type: PropType.oneOf(['checkbox', 'checkboxWithNoneState', 'darklight', 'switch', 'fav']),
  labelElement: PropTypes.any,
  description: PropType.string,
  onChange: PropType.func,
  checked: PropType.bool,
  inputLayout: PropTypes.oneOf(['inline', 'outline', 'no_container', '']),
  readonly: PropType.bool,
  className: PropType.string,
  widthFit: PropType.bool
}

export default Checkbox;
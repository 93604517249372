import PropTypes from "prop-types";
import InputBase from "./InputBase";
import shortid from "shortid";
import ConstrainBase from "../../../model/validate/ConstrainBase";
import InputStateType from "../../../../lib/components/types/StateType";
import {Eye, EyeOff} from "react-feather";
import {createRef} from "react";

class InputPassword extends InputBase {

  constructor(props) {
    super(props);
    this.constrain = this.props.constrain;
    this.inputKey = shortid();
    this.inputElementRef = createRef();
  }

  getValue() {
    return this.inputElementRef.current.value;
  }

  onShowIconClick(e) {
    if (this.inputElementRef.current.type === 'password') {
      this.inputElementRef.current.type = 'text';
      this.state.iconRight = <EyeOff onClick={(e) => this.onShowIconClick(e)} className='self-center off mx-2'/>;
    } else {
      this.inputElementRef.current.type = 'password';
      this.state.iconRight = this.iconRight = <Eye onClick={(e) => this.onShowIconClick(e)} className='self-center on mx-2'/>;
    }
    this.setState(this.state);
  }

  render() {
    if (!this.state.iconRight) {
      this.state.iconRight = this.iconRight = <Eye onClick={(e) => this.onShowIconClick(e)} className='self-center on mx-2'/>;
    }
    let inputField;
    if (this.state.state === InputStateType.disable || this.state.state === InputStateType.readonly) {
      inputField =
        <input key={this.inputKey} ref={this.inputElementRef} type='password' className='bg-transparent focus:outline-0 w-full h-full tracking-widest font-[Verdana]' onBlur={(e) => this.onBlur(
          e)} onChange={(e) => this.fireOnChange(e)} defaultValue={this.state.defaultValue} disabled/>;
    } else {
      inputField =
        <input key={this.inputKey} ref={this.inputElementRef} type='password' className='bg-transparent focus:outline-0 w-full h-full tracking-widest font-[Verdana]' onBlur={(e) => this.onBlur(
          e)} onChange={(e) => this.fireOnChange(e)} defaultValue={this.state.defaultValue}/>;
    }
    this.state.children = [inputField];
    return super.render();
  }
}

InputPassword.propTypes = {
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  inputLayout: PropTypes.oneOf(['inline', 'outline', '']),
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  constrain: PropTypes.objectOf(ConstrainBase),
}
export default InputPassword;

import BaseComponent from "../../../../lib/components/BaseComponent";
import PropTypes from "prop-types";
import ButtonStd from "../../../../lib/components/buttons/ButtonStd";
import "./ButtonStyles.css";

class ButtonBase extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      color: (this.props.color ? this.props.color : ''),
      onClick: (this.props.onClick ? this.props.onClick : undefined),
      size: (this.props.size ? this.props.size : ''),
      isInGroup: (this.props.isInGroup ? this.props.isInGroup : false)
    }
  }

  onClick(e) {
    if (this.props.onClick !== undefined && this._status === BaseComponent.STATUS_ENABLED) {
      this.props.onClick(e, this.props.uid);
    }
  }

  render() {
    let buttonStyle = '';
    let buttonSize = ' button-large';
    if (this.state.size === 'small') {
      buttonSize = ' button-small';
    } else if (this.state.size === 'medium') {
      buttonSize = ' button-medium';
    }
    buttonStyle += buttonSize;
    if (this.state.isInGroup) {
      buttonStyle += ' button-group';
    }

    let buttonColor = this.state.color === undefined ? 'light' : this.state.color;
    switch (buttonColor) {
      case 'primary':
        buttonStyle += ' bg-cn-color-primary ';
        break;
      case 'secondary':
        buttonStyle += ' bg-cn-color-secondary text-cn-color-static-white ';
        break;
      case 'dark':
        buttonStyle += ' bg-cn-color-black-804 ';
        break;
      case 'noColor':
        break;
      case 'red':
        buttonStyle += ' bg-cn-color-static-red text-cn-color-static-white ';

        break;
      case 'light':
      default:
        buttonStyle += ' bg-cn-color-black-804 ';
    }
    if (!this.props.widthFull) {
      buttonStyle += ' w-fit ';
    }
    if (this.props.tooltip) {
      if (this.state.size === 'large') {
        buttonStyle += ' has-tooltip-lg';
      } else {
        buttonStyle += ' has-tooltip';
      }
    }
    buttonStyle += (this.props.className ? this.props.className : '');
    return <ButtonStd disabled={this.props.disabled} className={buttonStyle} onClick={(e) => this.onClick(e)}>
      <div className='tooltip'>{this.props.tooltip}</div>
      {this.props.children}
    </ButtonStd>
  }
}

ButtonBase.propTypes = {
  uid: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  isInGroup: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'dark', 'light']),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'large', 'medium', '']),
  widthFull: PropTypes.bool,
  tooltip: PropTypes.string
}

export default ButtonBase;

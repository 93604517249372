import CM from "../../../model/system/CM";
import BaseComponent from "../../../../lib/components/BaseComponent";
import Link from "../../../../lib/components/Link/Link";

class Nav extends BaseComponent {

  constructor(props) {
    super(props);
    this.menuIconBurger =
      <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
      </svg>;
    this.menuIconCross =
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>;

    this.state = {
      menuIsExpanded: false,
      navIsTransparent: true,
      headerLogoWhite: require('./img/logo-cner-weiss.png'),
      headerLogoColor: require('./img/logo-cner-color.png'),
      burgerIcon: this.menuIconBurger
    }
    this.initNavVisibility();
  }

  initNavVisibility() {
    let landingRegex = /(\/landing\/)(.*)/g;
    let result = landingRegex.exec(window.location.pathname);

    if (result !== null && result[2] !== '') {
      this.state.navIsTransparent = false;
    }
  }

  async onChangeLang(e) {
    CM.get().setLanguage(e.target.value);
  }

  async onChangeTheme(e) {
    CM.get().setTheme(e.target.value);
  }

  onMenuBurgerClick(e) {
    document.querySelector('.mobil-menu').classList.toggle('hidden');
    document.querySelector('.mobil-menu').classList.toggle('lg:hidden');

    if (this.state.menuIsExpanded === false) {
      this.state.menuIsExpanded = true;
      this.state.burgerIcon = this.menuIconCross;
    } else {
      this.state.menuIsExpanded = false;
      this.state.burgerIcon = this.menuIconBurger;
    }
    this.setState(this.state);
  }

  setNavToTransparent(e) {
    this.state.navIsTransparent = true;
    this.setState(this.state);
  }

  setNavToOpaque(e) {
    this.state.navIsTransparent = false;
    this.setState(this.state);
  }

  render() {
    let navBg;
    let logoIcon;
    if (this.state.navIsTransparent) {
      navBg = 'lg:shadow-none lg:bg-transparent text-cn-color-static-white';
      logoIcon = <img src={this.state.headerLogoWhite} alt="white" className="h-[60px] w-[51px] hidden lg:block"/>;
    } else {
      navBg = 'shadow lg:shadow ';
      logoIcon = <img src={this.state.headerLogoColor} alt="color" className="h-[60px] w-[51px]"/>;
    }

    return <nav className={'w-full inline-flex flex-col justify-between text-[0.9rem] items-center font-cn-gotham-medium fixed right-0 top-0 z-50 ' +
      navBg} onResize={this.onResize}>
      <div className="w-full p-5 lg:px-16 flex justify-between">
        <div className="inline-flex">
          <Link to='/' className="inline-flex items-center" onClick={(e) => this.setNavToTransparent(e)}>
            {logoIcon}
            <p className="uppercase mt-3 ml-10 text-lg align-baseline">Campaigner <sup>2.1</sup></p>
          </Link>
        </div>
        <div className="xl:w-5/12 lg:w-2/3 w-1/8 text-base uppercase mt-3 flex justify-end">
          {/*faha there is no point of having the option to see this content on mobile when the content does not look fine!*/}
          {/*<button type="button" className="inline-flex items-center text-cn-color-landing-gray01 p-2 ml-3 text-sm lg:text-cn-color-white rounded-lg lg:hidden focus:outline-none togglebtn " onClick={(e) => this.onMenuBurgerClick(*/}
          {/*  e)}>*/}
          {/*  <span className="sr-only">Open main menu</span>*/}
          {/*  {this.state.burgerIcon}*/}
          {/*</button>*/}
          <div className="items-center justify-between lg:gap-5 w-full lg:flex hidden">
            {/*<Link to='/landing/plan' onClick={(e) => this.setNavToOpaque(e)}>*/}
            {/*  <div>Pläne</div>*/}
            {/*</Link>*/}
            {/*<Link to='/landing/plan#whatthecamp' onClick={(e) => this.setNavToOpaque(e)}>*/}
            {/*  <div className="whitespace-nowrap">Über den Campaigner</div>*/}
            {/*</Link>*/}
            {/*<Link to='/landing/contact' onClick={(e) => this.setNavToOpaque(e)}>*/}
            {/*  <div>Kontakt</div>*/}
            {/*</Link>*/}
            {/*<Link to='/landing/references' onClick={(e) => this.setNavToOpaque(e)}>*/}
            {/*  <div>referenzen</div>*/}
            {/*</Link>*/}
            {/*<Link to='/' onClick={(e) => this.setNavToTransparent(e)}>*/}
            {/*  <div>Anmelden</div>*/}
            {/*</Link>*/}
          </div>

        </div>
      </div>

      {/*<div className={"hidden flex items-center justify-between w-full flex-col text-cn-color-landing-gray01 mobil-menu"}>*/}
      {/*  <Link to='/landing/plan' onClick={(e) => this.setNavToOpaque(e)}>*/}
      {/*    <div className="p-5">Pläne</div>*/}
      {/*  </Link>*/}
      {/*  <Link to='/landing/about' onClick={(e) => this.setNavToOpaque(e)}>*/}
      {/*    <div className="p-5">Über den Campaigner</div>*/}
      {/*  </Link>*/}
      {/*  <Link to='/landing/contact' onClick={(e) => this.setNavToOpaque(e)}>*/}
      {/*    <div className="p-5">Kontakt</div>*/}
      {/*  </Link>*/}
      {/*  <Link to='/landing/references' onClick={(e) => this.setNavToOpaque(e)}>*/}
      {/*    <div className="p-5">referenzen</div>*/}
      {/*  </Link>*/}
      {/*  <Link to='/landing/login' onClick={(e) => this.setNavToTransparent(e)}>*/}
      {/*    <div className="p-5">Anmelden</div>*/}
      {/*  </Link>*/}
      {/*</div>*/}
    </nav>
  }
}

export default Nav;

/**
 * class Validator
 */

class Validator {

  constructor(value, validationFunction, stateCallback) {
    this.value = value;
    this.validationFunction = validationFunction;
    this.stateCallback = stateCallback;
  }

  setValue(value) {
    this.value = value;
  }

  validate() {
    let validationResult = this.validationFunction(this.value);

    if (Object.getPrototypeOf(validationResult).constructor.name === 'ValidationResult') {
      this.stateCallback(validationResult);
    } else {
      throw new Error('Ist nicht vom Typ ValidatorState');
    }
  }
}

export default Validator;

import Dto from "../../Dto";

class ReadAuthorization extends Dto {

  constructor(sysAccountId = null, sysUserGroupId = null, sysUserId = null) {
    super('po2\\service\\system\\dto\\ReadAuthorization');
    this.sysAccountId = sysAccountId;
    this.sysUserGroupId = sysUserGroupId;
    this.sysUserId = sysUserId;
  }

  getSysAccountId() {
    return this.sysAccountId;
  }

  setSysAccountId(sysAccountId) {
    this.sysAccountId = sysAccountId;
  }

  getSysUserGroupId() {
    return this.sysUserGroupId;
  }

  setSysUserGroupId(sysUserGroupId) {
    this.sysUserGroupId = sysUserGroupId;
  }

  getSysUserId() {
    return this.sysUserId;
  }

  setSysUserId(sysUserId) {
    this.sysUserId = sysUserId;
  }
}

export default ReadAuthorization;
import BaseComponent from "../../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import Permissions from "../../../../../appl/view/components/permissions/Permissions";

class PermissionTest extends BaseComponent {

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>
      <VerticalLayout className=' self-center'>
        <Permissions />
      </VerticalLayout>
    </div>
  }
}

export default PermissionTest;

import  {Component} from 'react';
import CM from "../../appl/model/system/CM";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Position from "../model/system/Position";
import GF from "../utils/GF";
import VM from "../model/system/VM";

class BaseComponent extends Component {

  static loopThrowChildren(node, callback) {
    callback(node);
    if (node.props !== undefined && node.props !== null && node.props.children !== undefined && node.props.children !==
      null) {

      if (Array.isArray(node.props.children)) {
        for (let index in node.props.children) {
          let subNode = node.props.children[index];
          BaseComponent.loopThrowChildren(subNode, callback);
        }
      } else {
        callback(node.props.children);
      }
    }
  }

  static getPosition(element) {
    let top = -1;
    let left = -1;
    if (GF.checkNotNull(element)) {
      let clientRect = element.getBoundingClientRect();

      let body = document.body;
      let documentElement = document.documentElement;

      let scrollTop = window.pageYOffset || documentElement.scrollTop || body.scrollTop;
      let scrollLeft = window.pageXOffset || documentElement.scrollLeft || body.scrollLeft;

      let clientTop = documentElement.clientTop || body.clientTop || 0;
      let clientLeft = documentElement.clientLeft || body.clientLeft || 0;

      top = clientRect.top + scrollTop - clientTop;
      left = clientRect.left + scrollLeft - clientLeft;
    }
    return {top: Math.round(top), left: Math.round(left)};
  }

  static STATUS_ENABLED = 'enabled';
  static STATUS_DISABLED = 'disabled';

  _ismounted = false;
  _id = '';
  _properties = null;
  _children = [];
  _parent = null;
  _type = 'BaseComponent';
  _status = BaseComponent.STATUS_ENABLED;

  constructor(props) {
    super(props);
    this._id = GF.getValue(this.props._id, CM.get().createUniquId().toString());
    //this._children = this.props.children;
    // this._children = React.Children.map(this.props.children, (child) => {
    //   if (child !== undefined && child !== null) {
    //     return React.cloneElement(child, {_parent: this});
    //   }
    //   return child;
    // });

    this.position = new Position();

    // p steht für parent
    this.p = props.p;
    CM.get().addOnSetStateListener(() => this.onSetState());
  }

  componentDidMount() {
    this._ismounted = true;
    // if (this._children !== undefined && this._children !== null) {
    //   for (let i = 0; i < this._children.length; i++) {
    //     let child = this._children[i];
    //     //child._parent = this;
    //   }
    // }
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  doDataCacheFetch(dto, serviceName, id, callBack, forceFetch = false) {
    let self = this;
    let response = null;
    let data = this.pop(this.getStorageId(), id);
    if (data !== null) {
      response = data;
    }
    if (response === null || forceFetch) {
      CM.get().doJsonAjax(CM.get().getServiceUrl(serviceName), dto, function(response) {
        self.push(self.getStorageId(), response, id);
        callBack(response);
      });
    } else {
      callBack(response);
    }
  }

  getId() {
    return this._id;
  }

  getStatus() {
    return this._status;
  }

  getStorageId() {
    throw new DOMException(
      'Diese Methode [getStorageId] muss überschrieben werden, wenn mit [doDataCacheFetch] gefetcht werden will!');
  }

  getProperties() {
    return this._properties;
  }

  initBasePosition(reactElement = null) {
    this.position.top = -1;
    this.position.left = -1;
    this.position.width = -1;
    this.position.height = -1;
    try {
      if (this._ismounted) {
        if (reactElement === null) {
          reactElement = this;
        }
        let inputElement = ReactDOM.findDOMNode(reactElement);
        let position = inputElement.getBoundingClientRect();
        this.position.top = position.top;
        this.position.left = position.left;
        this.position.width = position.width;
        this.position.height = position.height;
      }
    } catch (ex) {
    }
  }

  async onSetState() {
    if (this._ismounted) {
      this.setState(this.state);
    }
  }

  setId(_id) {
    this._id = _id;
  }

  setProperties(_properties) {
    this._properties = _properties;
  }

  setStatus(status) {
    this._status = status;
  }

  clearCache(kat, id = null) {
    VM.get().clearData(kat, id);
  }

  pop(kat, id = null) {
    if (id !== null) {
      return VM.get().getData(kat, id);
    } else {
      return VM.get().getData(kat);
    }
  }

  push(kat, value, id = null) {
    if (id !== null) {
      VM.get().setData(kat, value, id);
    } else {
      VM.get().setData(kat, value);
    }
  }
}

BaseComponent.propTypes = {
  _id: PropTypes.string, _properties: PropTypes.object, p: PropTypes.object, children: PropTypes.any
};
export default BaseComponent;

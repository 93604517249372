import BaseComponent from "../BaseComponent";
import PropTypes from "prop-types";
import React from "react";
import Callback from "../../../appl/model/system/Callback";
import GF from "../../utils/GF";

class BaseElementContainer extends BaseComponent {

  constructor(props) {
    super(props);
    this.onTabChangeListeners = new Callback();
    this.onCloseListeners = new Callback();
    this.state = {
      tabElements: {}, actualKey: ''
    };
  }

  addElement(element, id, tabChangeListener) {
    if (!this.checkIfIdExists(id)) {
      if (GF.checkNotNull(tabChangeListener)) {
        this.onTabChangeListeners.addListener(tabChangeListener);
      }
      element = React.cloneElement(element, {
        id: id,
        key: id,
        onClick: (e, index) => this.private_onTabChange(e, index),
        onCloseClick: (e, index) => this.private_onCloseClick(e, index),
        parent: this
      });
      this.state.tabElements[id] = {element: element, onTabChangeListener: tabChangeListener};
    }
    return id;
  }

  checkIfIdExists(id) {
    let exists = false;
    if (id !== null) {
      exists = this.state.tabElements[id];
    }
    if (exists !== undefined) {
      return exists.element !== undefined && exists.element !== null;
    } else {
      return false;
    }
  }

  clearAll() {
    this.state.tabElements = {};
    this.state.actualKey = '';
  }

  private_onCloseClick(e, id) {
    this.props.parent.removeElement(id);
  }

  private_onTabChange(e, id) {
    this.props.parent.setActiveElement(id);
  }

  removeElement(id) {
    this.onTabChangeListeners.removeListener(this.state.tabElements[id].onTabChangeListener);
    this.doCloseEventFire(this.state.tabElements[id].element, id);
    Reflect.deleteProperty(this.state.tabElements, id);
  }

  doCloseEventFire(tabElement, id) {
    this.onCloseListeners.fire(null, id);

    if (GF.checkNotNull(tabElement) && GF.checkNotNull(tabElement.ref) && GF.checkNotNull(tabElement.ref.current) &&
      typeof tabElement.ref.current.onClose === "function") {

      tabElement.ref.current.onClose();
    }
  }

  setActiveElement(id) {
    let tabElementsNew = {};
    for (let index in this.state.tabElements) {
      let tabElement = this.state.tabElements[index];
      tabElement.element = React.cloneElement(tabElement.element, {
        isActive: index === id
      });
      tabElementsNew[index] = tabElement;
    }
    this.state.tabElements = tabElementsNew;

    this.onTabChangeListeners.fire(null, id);
    this.setState(this.state);
    return id;
  }

  overrideProps(id, props) {
    this.state.tabElements[id].element = React.cloneElement(this.state.tabElements[id].element, props);
  }
}

BaseElementContainer.propTypes = {
  parent: PropTypes.object
}
export default BaseElementContainer;

import BaseComponent from "../../../../../lib/components/BaseComponent";
import PropType from 'prop-types';

class LizenceOptionGroup extends BaseComponent {

  render() {
    return <div><h2 className='uppercase text-cn-color-primary font-cn-inter-regular text-xl font-bold pt-5'>{this.props.title}</h2>{this.props.children}</div>
  }
}

LizenceOptionGroup.propTypes = {
  title: PropType.string.isRequired
}

export default LizenceOptionGroup;

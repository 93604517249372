import PropTypes from "prop-types";
import BaseComponent from "../../../../lib/components/BaseComponent";
import {ChevronDown, ChevronUp} from "react-feather";
import SortField from "../../../../lib/model/query/SortField";

class EntityColumn extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      sortIcon: null, sortOption: SortField.NONE
    }
  }

  onDoubleClick(e) {

  }

  onClick(e) {
    if (this.props.showSort) {
      if (this.state.sortOption === SortField.NONE) {
        this.state.sortOption = SortField.DESC;
        this.state.sortIcon = <ChevronDown></ChevronDown>
      } else if (this.state.sortOption === SortField.DESC) {
        this.state.sortOption = SortField.ASC;
        this.state.sortIcon = <ChevronUp></ChevronUp>
      } else {
        this.state.sortOption = SortField.NONE;
        this.state.sortIcon = null;
      }
      this.setState(this.state);
      if (this.props.onSortChange !== undefined) {
        this.props.onSortChange(e, this.props.colOption, this.state.sortOption);
      }
    }
    if (this.props.onClick !== undefined) {
      this.props.onClick(e);
    }
  }

  render() {
    let classes = 'column';
    classes += this.props.className ? ' ' + this.props.className : '';
    let colSpan = this.props.colSpan ? this.props.colSpan : 1;

    return <td ref={this.reference} colSpan={colSpan} width={this.props.width} className={classes}
               onClick={(e) => this.onClick(e)} onDoubleClick={() => this.onDoubleClick()}>
      {this.props.showSort !== undefined ? <div className='flex cursor-pointer'>
        {this.props.children}
        {this.state.sortIcon}
      </div> : this.props.children}
    </td>;
  }
}

EntityColumn.propTypes = {
  colSpan: PropTypes.number,
  width: PropTypes.string,
  className: PropTypes.string,
  showSort: PropTypes.bool,
  onClick: PropTypes.func,
  colOption: PropTypes.string,
  onSortChange: PropTypes.func
}
export default EntityColumn;
import BaseComponent from "../../../../../lib/components/BaseComponent";
import Window from "../../../../../lib/components/window/Window";
import PropTypes from "prop-types";
import shortid from "shortid";
import DataSource from "../../../../../lib/model/service/dataSource/DataSource";
import DataSourceLink from "../../../../../lib/model/service/dataSource/DataSourceLink";
import Entity from "../Entity";
import TableButtonGroup from "../tableButtonGroup/TableButtonGroup";
import React, {createRef} from "react";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import ButtonSmall from "../../buttons/ButtonSmall";
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp} from "react-feather";
import EntityForm from "../form/EntityForm";
import Records from "../../../../../lib/model/service/entity/dto/Records";
import Listener from "../../../../../lib/model/system/Listener";

class RelationForm extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      entityElementLeft: null, entityElementRight: null, dataSourceListenersInitDone: false
    }
    this.storageId = 'RelationForm_' + shortid();
  }

  componentDidMount() {
    this.state.entityElementLeft = this.initTable();
    this.state.entityElementRight = this.initTable(true);
    this.setState(this.state);
  }

  getStorageId() {
    return this.storageId;
  }

  initTable(notExists = false) {
    let entityDef = this.props.relationInfo.relationEntityDef;

    let dataSource = DataSource.generateDatasource(entityDef, this.props.campaignId,
      (datasource, dto, dataSourceDtoProcessEvents) => DataSource.datasourceDataHandler(datasource, dto, dataSourceDtoProcessEvents));

    let foreignRelation = EntityForm.getForeignRelation(dataSource, this.props.relationInfo.relation /*this.props.relation*/);

    new DataSourceLink(this.props.dataSourceMaster, dataSource, foreignRelation, notExists);
    return <Entity ref={createRef()} join dataSource={dataSource} headerButtons={
      <TableButtonGroup ref={createRef()} editable={false}/>} tabContainer={this.props.tabMainContainer} parent={this} campaignId={this.props.campaignId} autoload={false}/>
  }

  moveRecordFromRightToLeft(e) {
    let dataSource = this.state.entityElementRight.ref.current.props.dataSource;
    let records = new Records(dataSource.getCurrentRecords());
    this.initDataSourceListener();
    dataSource.connect(this.props.dataSourceMaster.getCurrentRecords()[0], records, this.props.relationInfo.foreignRelation);
  }

  moveRecordFromRightToRight(e) {
    let dataSource = this.state.entityElementLeft.ref.current.props.dataSource;
    let records = new Records(dataSource.getCurrentRecords());
    this.initDataSourceListener();
    dataSource.disconnect(this.props.dataSourceMaster.getCurrentRecords()[0], records, this.props.relationInfo.foreignRelation);
  }

  initDataSourceListener() {
    if (!this.state.dataSourceListenersInitDone) {
      let dataSource = this.state.entityElementLeft.ref.current.props.dataSource;
      dataSource.dataSourceEvents.addOnConnectListener(new Listener((e) => this.refreshTables(e)));
      dataSource.dataSourceEvents.addOnDisconnectListener(new Listener((e) => this.refreshTables(e)));
      dataSource = this.state.entityElementRight.ref.current.props.dataSource;
      dataSource.dataSourceEvents.addOnConnectListener(new Listener((e) => this.refreshTables(e)));
      dataSource.dataSourceEvents.addOnDisconnectListener(new Listener((e) => this.refreshTables(e)));
      this.state.dataSourceListenersInitDone = true;
    }
  }

  refreshTables(e) {
    this.state.entityElementRight.ref.current.doReadTable();
    this.state.entityElementLeft.ref.current.doReadTable();
  }

  render() {
    return <Window className='text-cn-color-white' title='Datensätze verknüpfen'>
      <VerticalLayout className='gap-3 lg:flex-row'>
        <VerticalLayout className='border-4 cn-border-radius-20 border-cn-color-primary p-4 overflow-x-auto'>
          <div className='text-2xl mb-2'>Verknüpfte Datensätze</div>
          {this.state.entityElementLeft}
        </VerticalLayout>
        <VerticalLayout className='border-4 cn-border-radius-20 justify-center items-center border-cn-color-gray-500 p-4 gap-y-5' widthFit>
          <ButtonSmall onClick={(e) => this.moveRecordFromRightToLeft(
            e)}><ChevronLeft className='hidden lg:block'/><ChevronUp className='block lg:hidden'/></ButtonSmall>
          <ButtonSmall onClick={(e) => this.moveRecordFromRightToRight(
            e)}><ChevronRight className='hidden lg:block'/><ChevronDown className='block lg:hidden'/></ButtonSmall>
          {/*<ButtonSmall><ChevronsLeft/></ButtonSmall>*/}
          {/*<ButtonSmall><ChevronsRight/></ButtonSmall>*/}
        </VerticalLayout>
        <VerticalLayout className='border-4 cn-border-radius-20 border-cn-color-static-red p-4 overflow-x-auto'>
          <div className='text-2xl mb-2'>Nicht verknüpfte Datensätze</div>
          {this.state.entityElementRight}
        </VerticalLayout>
      </VerticalLayout>
    </Window>
  }
}

RelationForm.propTypes = {
  dataSourceMaster: PropTypes.object, tabMainContainer: PropTypes.object, campaignId: PropTypes.number, relationInfo: PropTypes.object
}

export default RelationForm

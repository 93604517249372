
class Dto {

  _class;
  properties;
  credential;

  constructor(_class) {
    this._class = (_class !== undefined ? _class : 'po2\\service\\Dto');
  }

  getClass() {
    return this._class;
  }

  setClass(_class) {
    this._class = _class;
  }

  getProperties() {
    return this.properties;
  }

  setProperties(properties) {
    this.properties = properties;
  }

  getCredential() {
    return this.credential;
  }

  setCredential(credential) {
    this.credential = credential;
  }
}

export default Dto;

import Dto from "../../Dto";
import CampaignService from "../../../../../appl/model/service/campaign/CampaignService";

class EmptyTableData extends Dto {

  constructor(campaignId = '', tableName) {
    super('po2\\service\\entity\\dto\\EmptyTableData');
    this.campaignId = campaignId;
    this.tableName = tableName;
    this.setProperties(CampaignService.createCampaignRedirectProperty(this.campaignId));
  }
}

export default EmptyTableData;

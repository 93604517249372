import Dto from "../../../../../lib/model/service/Dto";

class UpdateCampaignStatus extends Dto {
  constructor(campaignId = '', status = '') {
    super('appl\\service\\campaign\\dto\\UpdateCampaignStatus');
    this.campaignId = campaignId;
    this.status = status;
  }

  getCampaignId() {
    return this.campaignId;
  }

  setCampaignId(campaignId) {
    this.campaignId = campaignId;
  }

  getStatus() {
    return this.status;
  }

  setStatus(status) {
    this.status = status;
  }

}

export default UpdateCampaignStatus;

import Dto from "../../Dto";

class SysLastValueRead extends Dto {

  constructor(valueid, _class = 'po2\\service\\system\\dto\\SysLastValueRead') {
    super(_class);
    this.valueid = valueid;
  }

  getId() {
    return this.valueid;
  }

  setId(valueid) {
    this.valueid = valueid;
  }
}

export default SysLastValueRead;
import CM from "../../../../model/system/CM";
import BaseComponent from "../../../../../lib/components/BaseComponent";
import './LoginForm.css';
import RequestPasswordResetDialog from "../../../components/passwordReset/RequestPasswordResetDialog";
import Link from "../../../../../lib/components/Link/Link";
import {ChevronRight} from "react-feather";
import {toast} from "react-toastify";
import Authenticate from "../../../../../lib/model/service/oauth/dto/Authenticate";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {createRef} from "react";

class LoginForm extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      passwordResetDialog: null, hCaptcha: null
    }
    this.captchaToken = null;
    this.hCaptchaRef = createRef();
  }

  doOAuthAuthenticationRequest(e) {
    let self = this;
    let login = document.querySelector("input[name=login]").value;
    let password = document.querySelector("input[name=password]").value;

    if (login !== '' && password !== '') {
      CM.get().doJsonAjax(CM.get().getServiceUrl('oauth'), new Authenticate(login, password, this.captchaToken), function(response) {
        if (response.success) {
          if (response.data !== null) {
            CM.get().setSessionValue('oauth_token', response.data.token, 'oauth');
            CM.get().setRoute('/cner');
          }
        } else {
          if (response.data.useCaptcha) {
            if (self.hCaptchaRef.current === null) {
              self.state.hCaptcha =
                <HCaptcha ref={self.hCaptchaRef} sitekey={CM.get().getHCaptchaSiteKey()} onVerify={(token, eKey) => self.handleVerificationSuccess(
                  token, eKey)}/>;
            } else {
              self.hCaptchaRef.current.resetCaptcha();
            }
            self.setState(self.state);
          }
          toast.error(response.message);
        }
      });
    } else {
      toast.error('Bitte Benutzername und Passwort eingeben');
    }
  }

  async onKeyPressed(e) {
    if (e.key === 'Enter') {
      this.doOAuthAuthenticationRequest();
    }
  }

  onLoginClick(e) {
    this.doOAuthAuthenticationRequest(e);
  }

  openResetPasswordDialog(e) {
    this.state.passwordResetDialog = <RequestPasswordResetDialog onCloseButtonClick={(e) => {
      this.state.passwordResetDialog = null;
      this.setState(this.state);
    }}/>;
    this.setState(this.state);
  }

  handleVerificationSuccess(token, ekey) {
    this.captchaToken = token;
  }

  render() {
    return <div className='bg-slate-50 md:bg-transparent flex items-center justify-center md:justify-end h-screen'>
      <div className='rounded-3xl bg-cn-color-primary md:min-h-[420px] md:min-w-[260px] md:mr-14 relative w-full md:w-fit'>
        <div className='p-4 bg-slate-50 md:rounded-3xl md:absolute md:right-10 md:top-10 md:bg-cn-color-landing-gray03 md:min-w-[260px] md:min-h-[400px]'>
          <p className="text-xs mt-12 uppercase">Bereits Kunde?</p>
          <p className="text-2xl uppercase">Anmelden</p>
          <div className='space-y-4'>
            <input className='login-form-input' placeholder='E-Mail' name='login' autoFocus/>
            <div className='flex gap-3'>
              <input id="password" className='login-form-input' placeholder='Password' type='password' name='password' autoComplete="off"
                     onKeyDown={(event) => this.onKeyPressed(event)}/>
              <button className='p-0.5 bg-black rounded-full flex justify-center items-center'
                      onClick={(e) => this.onLoginClick(e)}>
                <ChevronRight className='stroke-slate-50'/>
              </button>
            </div>
          </div>
          <button onClick={(e) => this.openResetPasswordDialog(e)}>
            <p className="mt-8 text-xs block uppercase hover:text-cn-color-primary">Passwort vergessen?</p>
          </button>
          {this.state.hCaptcha}
          {/*<p className="mt-24 text-xs uppercase">Noch kein Konto?</p><Link to="/landing/contact">*/}
          {/*<p className="text-xs block uppercase hover:text-cn-color-primary cursor-pointer">Hier Kontakt aufnehmen</p></Link>*/}
        </div>
      </div>
      {this.state.passwordResetDialog}
    </div>
  }
}

export default LoginForm;

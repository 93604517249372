import ReadVariableGroups from "./ReadVariableGroups";

class ReadVariableInfos extends ReadVariableGroups {

  constructor(campaignId = '', version = 'std', pageId = '') {
    super(campaignId, version, pageId, 'appl\\service\\campaign\\dto\\ReadVariableInfos');
  }
}

export default ReadVariableInfos;

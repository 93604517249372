import BaseComponent from "../../../../lib/components/BaseComponent";
import PropTypes from "prop-types";
import WizardData from "./WizardData";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import {ArrowLeft} from "react-feather";
import ButtonSmall from "../buttons/ButtonSmall";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import VM from "../../../../lib/model/system/VM";

class WizardContentBase extends BaseComponent {

  static getWizardData(dataHolder) {
    let dataContainer = VM.get().getData('MailingWizard');
    if (dataContainer === null) {
      dataContainer = new WizardData();
      VM.get().setData('MailingWizard', dataContainer);
    }
    return dataContainer;
  }

  constructor(props) {
    super(props);
    this.data = WizardContentBase.getWizardData();
    this.state = {
      prevButton: <HorizontalLayout className='cursor-pointer self-center' widthFit onClick={(e) => this.back(e)}>
        <ArrowLeft/>
        <div>zurück</div>
      </HorizontalLayout>,
      nextButton: <ButtonSmall className='ml-2 bg-cn-color-blue-690' onClick={(e) => this.next(e)}>weiter</ButtonSmall>
    }
  }

  back(e) {
    this.props.wizardNavRef.current.goToStep(this.props.wizardNavRef.current.props.wizardBase.current.state.actualIndex - 1);
  }

  next(e) {
    this.props.wizardNavRef.current.goToStep(this.props.wizardNavRef.current.props.wizardBase.current.state.actualIndex + 1);
  }

  getContent() {
    return null;
  }

  render() {
    return <VerticalLayout>
      {this.getContent()}
      <HorizontalLayout className='justify-between mt-4'>
        {this.state.prevButton}
        <HorizontalLayout widthFit>
          {this.state.nextButton}
        </HorizontalLayout>
      </HorizontalLayout>
    </VerticalLayout>;
  }
}

WizardContentBase.propTypes = {
  campaignId: PropTypes.any.isRequired,
  entityName: PropTypes.string.isRequired,
  wizardNavRef: PropTypes.any
}

export default WizardContentBase;

import Dto from "../../Dto";

class Import extends Dto {
  constructor(entityName = '', collectionId = '', fieldMapping = '', deleteAllRecordsBefore = '') {
    super('po2\\service\\entity\\dto\\Import');
    this.entityName = entityName;
    this.collectionId = collectionId;
    this.fieldMapping = fieldMapping;
    this.deleteAllRecordsBefore = deleteAllRecordsBefore;
  }

  getEntityName() {
    return this.entityName;
  }

  setEntityName(entityName) {
    this.entityName = entityName;
  }

  getCollectionId() {
    return this.collectionId;
  }

  setCollectionId(collectionId) {
    this.collectionId = collectionId;
  }

  getFieldMapping() {
    return this.fieldMapping;
  }

  setFieldMapping(fieldMapping) {
    this.fieldMapping = fieldMapping;
  }

  getDeleteAllRecordsBefore() {
    return this.deleteAllRecordsBefore;
  }

  setDeleteAllRecordsBefore(deleteAllRecordsBefore) {
    this.deleteAllRecordsBefore = deleteAllRecordsBefore;
  }
}

export default Import;

import {Copy, Download, Edit, Filter, Plus, RefreshCw, Search, Send, Trash2, Upload} from "react-feather";
import T from "../../../../model/system/text_translations/Translator";
import PropTypes from "prop-types";
import BaseComponent from "../../../../../lib/components/BaseComponent";
import ButtonSmall from "../../buttons/ButtonSmall";
import ButtonGroup from "../../buttons/ButtonGroup";
import React from "react";
import TableEvents from "./TableEvents";
import shortid from "shortid";
import GF from "../../../../../lib/utils/GF";

class TableButtonGroup extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      status: GF.getValue(this.props.status, BaseComponent.STATUS_ENABLED)
    }
    this.tableEvents = new TableEvents();
  }

  onTableRefresh(e) {
    if (this.state.status === BaseComponent.STATUS_ENABLED) {
      this.tableEvents.onRefreshListeners.fire(e);
    }
  }

  onOpenFilter(e) {
    if (this.state.status === BaseComponent.STATUS_ENABLED) {
      this.tableEvents.onFilterListeners.fire(e);
    }
  }

  onOpenSimpleFilter(e) {
    if (this.state.status === BaseComponent.STATUS_ENABLED) {
      this.tableEvents.onSimpleSearchListeners.fire(e);
    }
  }

  onOpenSettings(e) {
    if (this.state.status === BaseComponent.STATUS_ENABLED) {
      this.tableEvents.onSettingsListeners.fire(e);
    }
  }

  onAddRecord(e) {
    if (this.state.status === BaseComponent.STATUS_ENABLED) {
      this.tableEvents.onAddRecordListeners.fire(e);
    }
  }

  onEditRecord(e) {
    if (this.state.status === BaseComponent.STATUS_ENABLED) {
      this.tableEvents.onEditRecordListeners.fire(e);
    }
  }

  onDeleteRecord(e) {
    if (this.state.status === BaseComponent.STATUS_ENABLED) {
      this.tableEvents.onDeleteRecordListeners.fire(e);
    }
  }

  onImport(e) {
    if (this.state.status === BaseComponent.STATUS_ENABLED) {
      this.tableEvents.onImportListeners.fire(e);
    }
  }

  onExport(e) {
    if (this.state.status === BaseComponent.STATUS_ENABLED) {
      this.tableEvents.onExportListeners.fire(e);
    }
  }

  onDuplicateRecords(e) {
    if (this.state.status === BaseComponent.STATUS_ENABLED) {
      this.tableEvents.onDuplicateRecordsListeners.fire(e);
    }
  }

  render() {
    let children = [];
    children.push(<ButtonSmall tooltip='Aktualisieren' key={shortid()} uid='REFRESH' onClick={(e) => this.onTableRefresh(
      e)}><RefreshCw className='p-0.5'/></ButtonSmall>);

    if (this.props.showExtendedFilterButton === undefined || this.props.showExtendedFilterButton === true) {
      children.push(<ButtonSmall tooltip={T.label_filter_open_extended()} key={shortid()} uid='FILTER' onClick={(e) => this.onOpenFilter(
        e)}><Filter className='p-0.5'/></ButtonSmall>);
    }

    children.push(<ButtonSmall tooltip={T.label_filter_open_simple()} key={shortid()} uid='SEARCH' onClick={(e) => this.onOpenSimpleFilter(
      e)}><Search className='p-0.5'/></ButtonSmall>);
    // children.push(<ButtonSmall key={shortid()} uid='SETTINGS' onClick={(e) => this.onSetState(e)}><Settings className='p-0.5'/></ButtonSmall>);

    if (this.props.editable) {
      children.push(<ButtonSmall tooltip={T.label_add()} key={shortid()} uid='ADD' onClick={(e) => this.onAddRecord(e)}>{
        <Plus/>}</ButtonSmall>);
      children.push(<ButtonSmall tooltip={T.label_edit()} key={shortid()} uid='EDIT' onClick={(e) => this.onEditRecord(e)}>{
        <Edit/>}</ButtonSmall>);
      children.push(<ButtonSmall tooltip={T.label_delete()} key={shortid()} uid='DELETE' onClick={(e) => this.onDeleteRecord(e)}>{
        <Trash2/>}</ButtonSmall>);
      children.push(<ButtonSmall tooltip={T.label_copy()} key={shortid()} uid='COPY' onClick={(e) => this.onDuplicateRecords(e)}>{
        <Copy/>}</ButtonSmall>);
      children.push(<ButtonSmall tooltip={T.label_import()} key={shortid()} uid='IMPORT' onClick={(e) => this.onImport(e)}>{
        <Upload/>}</ButtonSmall>);
      children.push(<ButtonSmall tooltip={T.label_export()} key={shortid()} uid='EXPORT' onClick={(e) => this.onExport(e)}>{
        <Download/>}</ButtonSmall>);
    }
    if (this.props.children) {
      React.Children.map(this.props.children, (child) => {
        children.push(child);
      });
    }

    return <><ButtonGroup className='py-2'>
      {children}
    </ButtonGroup>
      {this.props.showOMSButton &&
        <ButtonSmall onClick={this.props.omsButtonHandler} color='secondary'><Send/>Versand vorbereiten</ButtonSmall>}
    </>;
  }
}

TableButtonGroup.propTypes = {
  onClick: PropTypes.func,
  editable: PropTypes.bool,
  showExtendedFilterButton: PropTypes.bool,
  showOMSButton: PropTypes.bool,
  omsButtonHandler: PropTypes.func,
  status: PropTypes.string
}

export default TableButtonGroup;
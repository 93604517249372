/**
 * class Fr
 *
 * In dieser Klasse sind alle Übersetzungestexte für Französisch hinterlegt.
 */
class Fr {
  label_add = 'Insertion';
  label_create = 'Création';
  label_cancel = 'Annulation';
  label_discard = 'Rejeter';
  label_save = 'Enregistrer';
  label_edit = 'Éditer';
  label_delete = 'Supprimer';
  label_copy = 'Clonage';
  label_duplicate = 'Dupliquer';
  label_import = 'Importer une liste Excel';
  label_do_import = 'importer';
  label_clone = 'Clonage';
  label_update = 'Mettre à jour';
  label_help = 'Assistance';
  label_settings = 'Réglages';
  label_system = 'Système';
  label_account = 'Compte';
  action_change_thumbnail = 'Modifier l\'image d\'aperçu';
  action_logout = 'Se désinscrire';
  action_overwrite = 'Réinitialiser';
  action_synchronize = 'Synchroniser';
  label_export = 'Exportation';
  label_empty = 'Table vide';
  label_omsOverview = 'Aperçu des envois';
  label_filter_editing = 'En cours de traitement';
  label_filter_closed = 'Terminé';
  label_filter_published = 'Publié';
  label_filter_maintenance = 'En entretien';
  label_filter_open_simple = 'Ouvrir filtre simple';
  label_filter_open_extended = 'Ouvert étendu Filtre';
  label_create_new_campaign = 'Créer une nouvelle campagne';
  label_option_not_found = 'Nichts gefunden!';
  label_enter_text = 'Saisir l\'heure :';
  label_hour = 'Hour';
  label_minute = 'Minute';
  component_upload_hint = 'Glisser-déposer ou parcourir pour choisir le fichier';
  label_search = 'Cherchent';
  reset_filter = 'Réinitialiser le filtre';
  label_tables = 'Tableaux';
  label_variant = 'Variante';
  label_functions = 'Fonctions';
  label_activate_analytics = 'Activer les analyses';
  label_activities = 'Activités';
  label_overview = 'Aperçu';
  genericErrorMessage = 'Malheureusement, une erreur est survenue ! Veuillez vous adresser à Vögeli AG!';
  ext_filter_eq_label = 'est égal à';
  ext_filter_neq_label = 'est différent de';
  ext_filter_gt_label = 'est plus grand que';
  ext_filter_lt_label = 'est plus petit que';
  ext_filter_geq_label = 'est supérieur ou égal à';
  ext_filter_leq_label = 'est inférieur ou égal à';
  ext_filter_like_label = 'contient';
  ext_filter_and_label = 'Et';
  ext_filter_or_label = 'Ou';
  ext_filter_null_label = 'est vide';
  ext_filter_not_null_label = 'n\'est pas vide';
}

export default Fr;

import BaseComponent from "../BaseComponent";
import './Loader.css'

class Loading extends BaseComponent {

  render() {
    return <div className='relative'>
      <div className='loader'/>
      <div className='loader1'/>
    </div>;
  }
}

export default Loading;
/**
 * class En
 *
 * In dieser Klasse sind alle Übersetzungestexte für Englisch hinterlegt.
 */
class En {
  label_add = 'Add';
  label_create = 'Create';
  label_cancel = 'Cancel';
  label_discard = 'Discard';
  label_save = 'Save';
  label_edit = 'Edit';
  label_delete = 'Delete';
  label_copy = 'Copy';
  label_duplicate = 'Duplicate';
  label_import = 'Import Excel list';
  label_do_import = 'import';
  label_clone = 'Clone';
  label_update = 'Update';
  label_help = 'Help';
  label_settings = 'Settings';
  label_system = 'System';
  label_account = 'Account';
  action_change_thumbnail = 'Change thumbnail';
  action_logout = 'Sign out';
  action_overwrite = 'Reset';
  action_synchronize = 'Synchronize';
  label_export = 'Export';
  label_empty = 'Empty table';
  label_omsOverview = 'Dispatch overview';
  label_filter_editing = 'Editing';
  label_filter_closed = 'Closed';
  label_filter_published = 'Published';
  label_filter_maintenance = 'Under Maintenance';
  label_filter_open_simple = 'open simple Filter';
  label_filter_open_extended = 'open extended Filter';
  label_create_new_campaign = 'Create new Campaign';
  label_option_not_found = 'Nothing found!';
  label_enter_text = 'Specify time:';
  label_hour = 'Hour';
  label_minute = 'Minute';
  component_upload_hint = 'Drag and Drop or browse to choose file';
  label_search = 'Search';
  reset_filter = 'Reset filter';
  label_tables = 'Tables';
  label_variant = 'Variant';
  label_functions = 'Functions';
  label_activate_analytics = 'Activate analytics';
  label_activities = 'Activities';
  label_overview = 'Overview';
  genericErrorMessage = 'Unfortunately an error has occurred! Please contact Vögeli AG!';
  ext_filter_eq_label = 'is equal';
  ext_filter_neq_label = 'is not equal';
  ext_filter_gt_label = 'is greater than';
  ext_filter_lt_label = 'is less than';
  ext_filter_geq_label = 'is greater than or equal to';
  ext_filter_leq_label = 'is less than or equal to';
  ext_filter_like_label = 'contains';
  ext_filter_and_label = 'And';
  ext_filter_or_label = 'Or';
  ext_filter_null_label = 'is empty';
  ext_filter_not_null_label = 'is not empty';
}

export default En;

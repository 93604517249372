import Callback from "../../../../model/system/Callback";

class TableEvents {

  constructor() {
    this.onRefreshListeners = new Callback();
    this.onFilterListeners = new Callback();
    this.onSimpleSearchListeners = new Callback();
    this.onSettingsListeners = new Callback();
    this.onAddRecordListeners = new Callback();
    this.onEditRecordListeners = new Callback();
    this.onDeleteRecordListeners = new Callback();
    this.onImportListeners = new Callback();
    this.onExportListeners = new Callback();
    this.onDuplicateRecordsListeners = new Callback();
  }

  addOnRefreshListener(listener) {
    this.onRefreshListeners.addListener(listener);
  }

  addOnFilterListener(listener) {
    this.onFilterListeners.addListener(listener);
  }

  addOnSimpleSearchListener(listener) {
    this.onSimpleSearchListeners.addListener(listener);
  }

  addOnSettingsListener(listener) {
    this.onSettingsListeners.addListener(listener);
  }

  addOnAddRecordListener(listener) {
    this.onAddRecordListeners.addListener(listener);
  }

  addOnEditRecordListener(listener) {
    this.onEditRecordListeners.addListener(listener);
  }

  addOnDeleteRecordListener(listener) {
    this.onDeleteRecordListeners.addListener(listener);
  }

  addOnImportListener(listener) {
    this.onImportListeners.addListener(listener);
  }

  addOnDuplicateRecordsListener(listener){
    this.onDuplicateRecordsListeners.addListener(listener);
  }

  addOnExportListener(listener) {
    this.onExportListeners.addListener(listener);
  }

  removeOnRefreshListener(listener) {
    this.onRefreshListeners.removeListener(listener);
  }

  removeOnFilterListener(listener) {
    this.onFilterListeners.removeListener(listener);
  }

  removeOnSimpleSearchListener(listener) {
    this.onSimpleSearchListeners.removeListener(listener);
  }

  removeOnSettingsListener(listener) {
    this.onSettingsListeners.removeListener(listener);
  }

  removeOnAddRecordListener(listener) {
    this.onAddRecordListeners.removeListener(listener);
  }

  removeOnEditRecordListener(listener) {
    this.onEditRecordListeners.removeListener(listener);
  }

  removeOnDeleteRecordListener(listener) {
    this.onDeleteRecordListeners.removeListener(listener);
  }

  removeOnImportListener(listener) {
    this.onImportListeners.removeListener(listener);
  }

  removeOnExportListener(listener) {
    this.onExportListeners.removeListener(listener);
  }

  removeOnDuplicateRecordsListener(listener){
    this.onDuplicateRecordsListeners.removeListener(listener);
  }

}

export default TableEvents
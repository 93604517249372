import DataType from "../../../../../lib/model/types/DataType";
import {createRef} from "react";
import Privilege from "../../../../../lib/model/types/Privilege";

class BaseFieldGenerator {

  static generateState(privilege) {
    return ((privilege & Privilege.ALLOW_EDIT) === 0 ? "readonly" : undefined);
  }

  initField(fieldType, id, fieldValue, fieldLabel, fieldRef = null, dtoProperties = null, privilege = 65535) {
    if (fieldRef === null) {
      fieldRef = createRef();
    }
    let field = null;
    switch (fieldType.type) {
      case DataType.BOOLEAN:
        field = this.initBooleanField(id, fieldRef, fieldValue, fieldLabel, privilege);
        break;
      case DataType.INTEGER:
        field = this.initIntegerField(id, fieldRef, fieldValue, fieldLabel, privilege);
        break;
      case DataType.FLOAT:
        field = this.initFloatField(id, fieldRef, fieldValue, fieldLabel, privilege);
        break;
      case DataType.STRING:
        if (fieldType.multiline && !fieldType.isHtml) {
          field = this.initTextAreaField(id, fieldRef, fieldValue, fieldLabel, privilege);
        } else if (fieldType.isPassword) {
          field = this.initPasswordField(id, fieldRef, fieldValue, fieldLabel, privilege);
        } else if (fieldType.isHtml) {
          field = this.initHtmlField(id, fieldRef, fieldValue, fieldLabel, fieldType.valueMap, privilege);
        } else if (fieldType.isLink) {
          field = this.initLinkField(id, fieldRef, fieldValue, fieldLabel, privilege);
        } else if (fieldType.isColor) {
          field = this.initColorPickerField(id, fieldRef, fieldValue, fieldLabel, privilege);
        } else {
          field = this.initTextField(id, fieldRef, fieldValue, fieldLabel, privilege);
        }
        break;
      case DataType.DATETIME:
        if (fieldValue !== undefined && fieldValue !== null && fieldValue.length === 19) {
          fieldValue = fieldValue.substring(0, 16);
        }
        field = this.initDateTimePickerField(id, fieldRef, fieldValue, fieldLabel, privilege, fieldType.useTime);
        break;
      case DataType.ENUM:
        field = this.initEnumField(fieldType, id, fieldRef, fieldValue, fieldLabel, privilege);
        break;
      case DataType.DATA_EXT:
        field = this.initResourceField(id, fieldRef, fieldValue, fieldLabel, dtoProperties, privilege);
        break;
      case DataType.OBJECT:
        break;
      default:
        field = this.initTextField(id, fieldRef, fieldValue, fieldLabel, privilege);
        break;
    }
    return field;
  }

  initBooleanField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <div></div>;
  }

  initColorPickerField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <div></div>
  }

  initIntegerField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <div></div>;
  }

  initFloatField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <div></div>;
  }

  initTextAreaField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <div></div>;
  }

  initPasswordField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <div></div>;
  }

  initHtmlField(id, fieldRef, fieldValue, fieldLabel, valueMap, privilege) {
    return <div></div>;
  }

  initLinkField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <div></div>;
  }

  initTextField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <div></div>;
  }

  initDateTimePickerField(id, fieldRef, fieldValue, fieldLabel, privilege, useTime) {
    return <div></div>;
  }

  initEnumField(fieldType, id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <div></div>;
  }

  initResourceField(id, fieldRef, fieldValue, fieldLabel, dtoProperties, privilege) {
    return <div></div>;
  }
}

export default BaseFieldGenerator;

import LandingMain from "../view/site/landing/LandingMain";
import BaseComponent from "../../lib/components/BaseComponent";
import CnerMain from "../view/site/cner/CnerMain";
import SandBox from "../../test/view/site/sandbox/SandBox";
import RouterElement from "../../lib/components/router/RouterElement";
import CM from "../model/system/CM";
import PasswordReset from "./site/passwordReset/PasswordReset";

class App extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      dummy: ''
    };
    // wenn Seite reloadet wird, dann cache reseten.
    if (window.performance) {
      if (performance.navigation.type === 1) {
        CM.get().resetSessionValues();
      }
    }
  }

  render() {
    let mainDialog = CM.get().getMainDialog();

    return <div>
      {mainDialog}
      <RouterElement route='^$|^landing.*$'><LandingMain p={this}/></RouterElement>
      <RouterElement route='^cner.*$'><CnerMain p={this}/></RouterElement>
      <RouterElement route='^sb.*$'><SandBox p={this}/></RouterElement>
      <RouterElement route='^reset_password.*$'><PasswordReset p={this}/></RouterElement>
    </div>;
  }
}

export default App;

import PropTypes from "prop-types";
import BaseComponent from "../../../../../lib/components/BaseComponent";
import HorizontalLayout from "../../../../../lib/components/layouts/HorizontalLayout";
import {FolderPlus, PlusSquare, X} from "react-feather";

class CommandBar extends BaseComponent {

  render() {
    return <HorizontalLayout className='cn-border-radius-20 w-[90px] gap-x-0.5' widthFit>
      <button onClick={(e) => this.props.fieldPredicateRef.current.removeSelf(e)}><X/></button>
      <button onClick={(e) => this.props.fieldPredicateRef.current.addFieldPredicate(e)}><PlusSquare/></button>
      <button onClick={(e) => this.props.fieldPredicateRef.current.addBooleanPredicate(e)}><FolderPlus/></button>
    </HorizontalLayout>;
  }
}

CommandBar.propTypes = {
  fieldPredicateRef: PropTypes.object
}
export default CommandBar;

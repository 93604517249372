import PropTypes from "prop-types";
import "./ButtonStyles.css";
import ButtonBase from "./ButtonBase";

class ButtonLarge extends ButtonBase {

  render() {
    this.state.size = 'large';
    return super.render();
  }
}

ButtonLarge.propTypes = {
  uid: PropTypes.string,
  className: PropTypes.string,
  isInGroup: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'dark', 'light']),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'large', '']),
  widthFull: PropTypes.bool,
  tooltip: PropTypes.string
}

export default ButtonLarge;

import PropTypes from "prop-types";
import InputBase from "./InputBase";
import {createRef} from "react";
import ConstrainBase from "../../../model/validate/ConstrainBase";
import InputStateType from "../../../../lib/components/types/StateType";
import './container/Container.css';
import GF from "../../../../lib/utils/GF";

class InputText extends InputBase {

  constructor(props) {
    super(props);
    this.inputElementRef = createRef();
    this.constrain = this.props.constrain;
  }

  getValue() {
    return this.inputElementRef.current.value;
  }

  buildinputFieldDisabled() {
    return <input ref={this.inputElementRef} type='text' placeholder={this.props.placeholder} className={'bg-transparent focus:outline-0 w-full h-full ' +
      GF.getValue(this.props.className, '')} onBlur={(e) => this.onBlur(e)} onChange={(e) => this.fireOnChange(e)} defaultValue={this.state.defaultValue} disabled/>;
  }

  buildinputFieldDefault() {
    if (this.props.maxLength) {
      return <input maxLength={this.props.maxLength} ref={this.inputElementRef} type='text' placeholder={this.props.placeholder} className={'bg-transparent focus:outline-0 w-full h-full ' +
        GF.getValue(this.props.className, '')} onBlur={(e) => this.onBlur(e)} onChange={(e) => this.fireOnChange(e)} defaultValue={this.state.defaultValue}/>;
    }
    return <input ref={this.inputElementRef} type='text' placeholder={this.props.placeholder} className={'bg-transparent focus:outline-0 w-full h-full ' +
      GF.getValue(this.props.className, '')} onBlur={(e) => this.onBlur(e)} onChange={(e) => this.fireOnChange(e)} defaultValue={this.state.defaultValue}/>;

  }

  buildinputFieldDefaultNumber() {
    return <input ref={this.inputElementRef} type='number' placeholder={this.props.placeholder} className={'bg-transparent focus:outline-0 w-full h-full ' +
      GF.getValue(this.props.className, '')} onBlur={(e) => this.onBlur(e)} onChange={(e) => this.fireOnChange(e)} defaultValue={this.state.defaultValue}/>;
  }

  buildinputFieldDefaultNumberWithProps(min, max) {
    return <input ref={this.inputElementRef} type='number' placeholder={this.props.placeholder} className={'bg-transparent focus:outline-0 w-full h-full ' +
      GF.getValue(this.props.className, '')} onBlur={(e) => this.onBlur(e)} onChange={(e) => this.fireOnChange(e)} defaultValue={this.state.defaultValue} min={min} max={max}/>;
  }

  setFocus() {
    if (GF.checkNotNull(this.containerRef.current)) {
      this.inputElementRef.current.focus();
    }
  }

  render() {
    let inputField;
    if (this.state.state === InputStateType.disable || this.state.state === InputStateType.readonly) {
      inputField = this.buildinputFieldDisabled();
    } else {
      let formatElement = null;
      let formatParameter = null;
      if (GF.checkNotNull(this.props.inputFormat) && this.props.inputFormat !== '') {
        let formatElements = this.props.inputFormat.split('|');
        if (formatElements.length > 0) {
          formatElement = formatElements[0];
          formatParameter = formatElements[1];
        } else {
          formatElement = this.props.inputFormat;
        }
      }
      if (formatElement !== null) {
        switch (formatElement) {
          case 'number':
            if (formatParameter !== null) {
              let formatParameterElements = formatParameter.split(':');
              inputField = this.buildinputFieldDefaultNumberWithProps(formatParameterElements[0], formatParameterElements[1]);
            } else {
              inputField = this.buildinputFieldDefaultNumber();
            }
            break;
          default:
            inputField = this.buildinputFieldDefault();
            break;
        }
      } else {
        inputField = this.buildinputFieldDefault();
      }
    }
    this.state.children = inputField;

    return super.render();
  }
}

InputText.propTypes = {
  className: PropTypes.string,
  classNameOutline: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  inputLayout: PropTypes.oneOf(['inline', 'outline', '']),
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  constrain: PropTypes.objectOf(ConstrainBase),
  placeholder: PropTypes.string,
  inputFormat: PropTypes.string,
  maxLength: PropTypes.number
}
export default InputText;

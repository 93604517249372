import BaseComponent from "../../../../../lib/components/BaseComponent";
import HorizontalLayout from "../../../../../lib/components/layouts/HorizontalLayout";
import CampaignCardNew from "../../../../../appl/view/components/campaign/CampaignCardNew";
import CampaignCard from "../../../../../appl/view/components/campaign/CampaignCard";

class CampaignCardsTest extends BaseComponent {

  render() {
    var campaign = [{
      name: 'Astag',
      description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat labore et dolore magna aliquyam erat, sed diam voluptua.',
      baseUrl: 'https://voegeli.ch/',
      createdDate: '12.01.2023',
      status: 1
    }, {
      name: 'Massenmail Versand Für Alle die Eingeladen wurden ',
      description: 'Taxi Tag für die Kundenn',
      baseUrl: 'https://voegeli.ch/',
      createdDate: '12.01.2023',
      status: 2
    }, {
      name: 'Einladung Geschäftsessen', description: 'Taxi Tag für die Kundenn', baseUrl: 'https://voegeli.ch/', createdDate: '12.01.2023', status: 3
    }, {name: 'SwissSkills', description: 'Taxi Tag für die Kundenn', baseUrl: 'https://voegeli.ch/', createdDate: '12.01.2023', status: 1},];

    return <div className='sandbox-container flex flex-col gap-5'>
      <h1>case 03&nbsp;Campaign card</h1>
      <HorizontalLayout className={'flex-wrap justify-center self-center gap-8'}>
        <CampaignCardNew/>
        <CampaignCard campaign={campaign[0]}/>
        <CampaignCard campaign={campaign[2]}/>
        <CampaignCard campaign={campaign[0]}/>
        <CampaignCard campaign={campaign[1]}/>
        <CampaignCard campaign={campaign[3]}/>
        <CampaignCard campaign={campaign[2]}/>
        <CampaignCard campaign={campaign[2]}/>
        <CampaignCard campaign={campaign[3]}/>
        <CampaignCard campaign={campaign[1]}/>

      </HorizontalLayout>
    </div>
  }
}

export default CampaignCardsTest;

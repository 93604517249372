import BaseComponent from "../BaseComponent";
import HorizontalLayout from "../layouts/HorizontalLayout";
import PropTypes from "prop-types";
import ButtonGroup from "../../../appl/view/components/buttons/ButtonGroup";
import ButtonSmall from "../../../appl/view/components/buttons/ButtonSmall";
import InputDropDown from "../../../appl/view/components/inputFields/dropdown/InputDropDown";
import Menu from "../../../appl/view/components/menu/Menu";
import MenuItemGroup from "../menu/MenuItemGroup";
import MenuItem from "../menu/MenuItem";
import FrLogoSvg from "../images/FrLogoSvg";
import DeLogoSvg from "../images/DeLogoSvg";
import Checkbox from "../checkbox/Checkbox";
import CM from "../../../appl/model/system/CM";
import LoaderLogo from "./LoaderLogo";
import shortid from "shortid";
import InputSearchDropDown from "../../../appl/view/components/inputFields/dropdown/InputSearchDropDown";
import ChangeAccount from "../../model/service/system/dto/ChangeAccount";
import ButtonTab from "../tabbar/ButtonTab";
import React, {createRef} from "react";
import Settings from "../../../appl/view/components/settings/Settings";
import SettingsItem from "../../../appl/view/components/settings/SettingsItem";
import Domain_Dark from "../../fonts/icons/Domain_Darkmode.svg";
import Template_Dark from "../../fonts/icons/Template_Darkmode.svg";
import Module_Dark from "../../fonts/icons/Module_Darkmode.svg";
import Privilege_Image from '../../fonts/icons/Privilege_Image.png';
import Window from "../../../lib/components/window/Window";
import AccountPage from "../../../appl/view/components/account/AccountPage";
import Entity from "../../../appl/view/components/entity/Entity";
import DataSource from "../../model/service/dataSource/DataSource";
import {toast} from "react-toastify";
import TableButtonGroup from "../../../appl/view/components/entity/tableButtonGroup/TableButtonGroup";
import EntityDescribe from "../../model/service/entity/dto/EntityDescribe";
import T from "../../../appl/model/system/text_translations/Translator";

class Header extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      status: CM.STATUS_IDLE
    }
    CM.get().addOnStatusChangeListeners((status) => this.onStatusChange(status));
    this.mainPanel = CM.get().getPanel();
  }

  componentDidMount() {
    super.componentDidMount();
    setTimeout(() => {
      this.sideBar = this.mainPanel.layoutRef.current.sideBarRef.current;
    }, 1);
  }

  async onStatusChange(status) {
    this.state.status = status;
    this.setState(this.state);
  }

  onThemeSwitchChange(e) {
    if (e.target.checked) {
      CM.get().setTheme(CM.STD_LIGHT);
    } else {
      CM.get().setTheme(CM.STD_DARK);
    }
  }

  onLanguageDropDownChange(e, id, option) {
    CM.get().setLanguage(option);
  }

  onAccountChange(e, id, data) {
    CM.get().doJsonAjax(CM.get().getServiceUrl('SystemService'), new ChangeAccount(data),
      function(responseUserContext) {
        if (responseUserContext.success) {
          window.location.href = '/cner';
        }
      });
  }

  onOpenAccount(e) {
    this.sideBar.refreshMenu();
    this.mainPanel.tabContainerRef.current.addElement(<ButtonTab id='frmAccount'>{T.label_account()}</ButtonTab>,
      <AccountPage id='frmAccount'/>, true, {
        id: CM.get().createUniquId(), hook: (e, id) => {
          if (id === 'frmAccount') {
            this.sideBar.refreshMenu();
          }
        }
      });
  }

  onOpenSettings(e) {
    this.sideBar.refreshMenu();
    let self = this;
    let settingItems = [];
    let templatesEntityService = new EntityDescribe(['appl\\models\\entity\\doctrine\\CampaignTemplate']);
    CM.get().doJsonAjax(CM.get().getServiceUrl('DoctrineEntityService'), templatesEntityService, (response) => {
      self.generateDataSource(response, (templatesDataSource) => {
        self.createSettingsItem(templatesDataSource, settingItems, 'Templates', Template_Dark, self);
      });

      let domainsEntityService = new EntityDescribe(['po2\\system\\db\\doctrine\\entity\\SysDomain']);
      CM.get().doJsonAjax(CM.get().getServiceUrl('DoctrineEntityService'), domainsEntityService, (response) => {
        self.generateDataSource(response, (domainsDataSource) => {
          self.createSettingsItem(domainsDataSource, settingItems, 'Domänen', Domain_Dark, self);
        });

        let privilegesEntityService = new EntityDescribe(['po2\\system\\db\\doctrine\\entity\\SysPrivilege']);
        CM.get().doJsonAjax(CM.get().getServiceUrl('DoctrineEntityService'), privilegesEntityService, (response) => {
          self.generateDataSource(response, (privilegesDataSource) => {
            self.createSettingsItem(privilegesDataSource, settingItems, 'Privilegien', Privilege_Image, self)
          });

          let settings = <Settings>
            {settingItems}

            {/*<SettingsItem title='Module' img={<img src={Module_Dark} alt='Module'/>}>*/}
            {/*  <Window>Coming Soon...</Window>*/}
            {/*</SettingsItem>*/}

          </Settings>;
          self.mainPanel.tabContainerRef.current.addElement(
            <ButtonTab id='frmSettings'>{T.label_settings()}</ButtonTab>, settings, true, {
              id: CM.get().createUniquId(), hook: (e, id) => {
                if (id === 'frmSettings') {
                  this.sideBar.refreshMenu();
                }
              }
            });
        });
      });
    });
  }

  createSettingsItem(dataSource, settingItems, title, image, self) {
    if (dataSource !== null) {
      settingItems.push(<SettingsItem title={title} img={<img src={image} className='p-8' alt={title}/>}>
        <Window><Entity headerButtons={<TableButtonGroup ref={createRef()} editable/>}
                        parent={self} dataSource={dataSource}/></Window></SettingsItem>);
    }
  }

  generateDataSource(response, onDoneCallback) {
    let dataSource = null;
    if (response.success) {
      dataSource = DataSource.generateDatasource(response.data.serviceDescription.entityDefs[0], null,
        (datasource, dto, dataSourceDtoProcessEvents) => {
          CM.get().doJsonAjax(CM.get().getServiceUrl('DoctrineEntityService'), dto, (response) => {
            dataSourceDtoProcessEvents.doResponse(response);
          });
        });
    } else {
      toast.error(response.message);
    }
    onDoneCallback(dataSource);
  }

  onOpenSystem(e) {
    let system = <Window>
      System
    </Window>

    this.mainPanel.tabContainerRef.current.addElement(<ButtonTab id='frmSystem'>{T.label_system()}</ButtonTab>, system,
      true, {
        id: CM.get().createUniquId(), hook: (e, id) => {
          if (id === 'frmSystem') {
            this.sideBar.refreshMenu();
          }
        }
      });
  }

  createHeaderButtons() {
    let buttonsForAdmin = [];
    if (CM.get().getUserContext() !== null && CM.get().getUserContext().isRootUser) {
      buttonsForAdmin.push(<ButtonSmall key={shortid()} color='secondary' onClick={(e) => this.onOpenSettings(
        e)}>{T.label_settings()}</ButtonSmall>);
      buttonsForAdmin.push(<ButtonSmall key={shortid()} color='secondary' onClick={(e) => this.onOpenSystem(
        e)}>{T.label_system()}</ButtonSmall>);
    }
    buttonsForAdmin.push(<ButtonSmall key={shortid()} color='secondary' onClick={(e) => this.onOpenAccount(
      e)}>{T.label_account()}</ButtonSmall>);
    return buttonsForAdmin;
  }

  getDefaultLang() {
    let defaultLang = CM.get().getLangauge();
    switch (defaultLang) {
      case CM.LANG_FR:
        return {option: 'fr', displayValue: <><FrLogoSvg/>FR</>}
      default:
        return {option: 'de', displayValue: <><DeLogoSvg/>DE</>};
    }
  }

  render() {
    let accounts;
    let selectedAccount;
    if (this.props.accounts !== undefined) {
      accounts = this.props.accounts.map((account) => {
        if (account.id === this.props.selectedAccount) {
          selectedAccount = {option: account.id, displayValue: account.id + ' ' + account.name};
        }
        return <MenuItem key={shortid()} searchValue={account.name} option={account.id}>{account.id + ' ' +
          account.name}</MenuItem>
      });
    }

    let checked = CM.get().getTheme() === CM.STD_LIGHT;

    let buttonsForAdmin = this.createHeaderButtons();

    let defaultLang = this.getDefaultLang();

    return <HorizontalLayout className="items-center justify-between text-cn-color-white h-full px-3">
      <HorizontalLayout className="w-1/3">
        <a className="flex items-center" href="/cner">
          <LoaderLogo status={this.state.status}/>
          <h2 className='ml-1 mt-2'>ampaigner</h2>
        </a>
        <p className="text-sm font-cn-inter-light self-center pl-1">{this.props.version}</p>
      </HorizontalLayout>

      <HorizontalLayout className="justify-center w-1/3 hidden md:block">
        <ButtonGroup color='secondary' className='font-cn-roboto-slab-medium justify-center'>
          {buttonsForAdmin}
        </ButtonGroup>
      </HorizontalLayout>

      <HorizontalLayout className="justify-end gap-5 w-1/3">
        <InputDropDown onChange={(e, id, option) => this.onLanguageDropDownChange(e, id, option)} color='primary'
                       defaultValue={defaultLang}>
          <Menu>
            <MenuItemGroup>
              <MenuItem option={'de'}><DeLogoSvg/>DE</MenuItem>
              <MenuItem option={'fr'}><FrLogoSvg/>FR</MenuItem>
              {/*<MenuItem option={<ItLogoSvg/>}><ItLogoSvg/>IT</MenuItem>*/}
              {/*<MenuItem option={<EnLogoSvg/>}><EnLogoSvg/>EN</MenuItem>*/}
            </MenuItemGroup>
          </Menu>
        </InputDropDown>
        <div className='self-center'>
          <Checkbox type='darklight' onChange={(e) => this.onThemeSwitchChange(e)} checked={checked}/>
        </div>
        {CM.get().getUserContext() !== null && CM.get().getUserContext().isRootUser &&
          <InputSearchDropDown key={shortid()} defaultValue={selectedAccount} inputLayout='outline' onChange={this.onAccountChange}>
            <Menu>
              <MenuItemGroup>
                {accounts}
              </MenuItemGroup>
            </Menu>
          </InputSearchDropDown>}

      </HorizontalLayout>
    </HorizontalLayout>
  }
}

Header.propTypes = {
  className: PropTypes.string,
  version: PropTypes.string,
  accounts: PropTypes.array.isRequired,
  selectedAccount: PropTypes.number
}
export default Header;


import BaseComponent from "../BaseComponent";
import {Chart as ChartJS, ArcElement, Tooltip, CategoryScale, LinearScale, BarElement} from 'chart.js';
import PropTypes from "prop-types";
import ChartEntry from "./ChartEntry";

ChartJS.register(ArcElement, Tooltip, CategoryScale, LinearScale, BarElement, Tooltip);

class Chart extends BaseComponent {

  createDataForChart() {
    let pieChartData = this.props.data;
    let labels = [];
    let colors = [];
    let keys = [];
    for (let i = 0; i < pieChartData.length; i++) {
      let pieEntry = pieChartData[i];
      labels.push(pieEntry.label);
      colors.push(pieEntry.color);
      keys.push(pieEntry.key);
    }

    return {
      labels: labels, datasets: [{
        data: keys, backgroundColor: colors, borderColor: 'transparent', barThickness: 100
      }]
    }
  }

  getContent() {
    return <></>
  }

  render() {
    return this.getContent()
  }
}

Chart.propTypes = {
  data: PropTypes.arrayOf(ChartEntry)
}
export default Chart


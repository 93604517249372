import Dto from "../../Dto";

class GetUserContext extends Dto {

  userId;

  constructor(userId = '') {
    super('po2\\service\\system\\dto\\GetUserContext');
    this.userId = userId;
  }

  getUserId() {
    return this.userId;
  }

  setUserId(userId) {
    this.userId = userId;
  }
}

export default GetUserContext;

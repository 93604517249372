import {Doughnut} from 'react-chartjs-2';
import SysChart from './Chart';
import PropTypes from "prop-types";

class PieChart extends SysChart {

  getContent() {
    return <Doughnut data={this.createDataForChart()}/>
  }
}

PieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}
export default PieChart

import React, {Component, createRef} from 'react';
import PropTypes from "prop-types";
import GF from "../../../utils/GF";
import {Editor} from "@tinymce/tinymce-react";
import './TinyMceStyles.css'

class TinyMceEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      variablesItems: GF.getValue(this.props.variablesItems, []), value: GF.getValue(this.props.defaultValue, ''),
    };
    this.editorRef = createRef();
  }

  getValue() {
    this.editorRef.current.getContent();
  }

  handleChange = (e, editor) => {
    this.state.value = editor.getContent();
    this.setState(this.state);
    if (GF.checkNotNull(this.props.onChange)) {
      this.props.onChange(null, editor.getContent());
    }
  };
  handleCustomDropdownClick = (value) => {
    let editor = this.editorRef.current;
    editor.insertContent(value);
  };

  render() {

    let variablesItems = [];
    this.state.variablesItems.map(item => {
      variablesItems.push({
        type: 'menuitem', text: item.value, onAction: () => this.handleCustomDropdownClick(item.key),
      });
    });

    return (<Editor
      ref={this.editorRef}
      onChange={this.handleChange}
      tinymceScriptSrc={'https://daten.voegeli.ch/cner21/tinymce/tinymce.min.js'}
      onInit={(evt, editor) => this.editorRef.current = editor}
      initialValue={this.props.defaultValue}
      init={{
        min_height: 400,
        width: '100%',
        menu: {
          edit: {title: 'Editieren', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace accordion'},
          insert: {
            title: 'Einfügen',
            items: 'image link media | charmap emoticons hr | pagebreak nonbreaking anchor | accordion'
          },
          format: {
            title: 'Formatieren',
            items: 'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | removeformat'
          },
          tools: {title: 'Tools', items: 'code fullscreen preview visualblocks '},
          table: {title: '', items: ''},
          file: {title: '', items: ''},
          view: {title: '', items: ''},
          help: {title: '', items: ''}
        },
        promotion: false,
        plugins: ['accordion', 'anchor', 'autolink', 'autoresize', 'charmap', 'code',
          'emoticons', 'fullscreen', 'help', 'image', 'importcss',
          'link', 'lists', 'media', 'nonbreaking', 'pagebreak', 'preview', 'searchreplace', 'table',
          'visualblocks', 'visualchars', 'wordcount'],
        toolbar: 'blocks | bold italic backcolor | alignjustify alignleft aligncenter alignright | ' +
          'bullist numlist | table | customDropdown',
        setup: (editor) => {
          if (variablesItems.length > 0) {
            editor.ui.registry.addMenuButton(('customDropdown'), {
              text: 'Variables', type: 'menubutton', fetch: (callback) => {
                callback(variablesItems);
              },
            });
          }
        },
      }}
    />);
  }
}

TinyMceEditor.propTypes = {
  onChange: PropTypes.func, defaultValue: PropTypes.any, variablesItems: PropTypes.array,
};

export default TinyMceEditor;

import PropTypes from "prop-types";
import SysFilterBuilder from "../../../../lib/components/extendedFilter/FilterBuilder";
import FieldPredicate from "./fieldPredicate/FieldPredicate";
import BooleanPredicate from "./BooleanPredicate";
import CommandBar from "./fieldPredicate/CommandBar";
import FieldSelection from "./fieldPredicate/FieldSelection";
import OperatorSelection from "./fieldPredicate/OperatorSelection";
import ValueField from "./fieldPredicate/ValueField";
import shortid from "shortid";
import Operator from "../../../../lib/model/query/Operator";
import {createRef} from "react";

class FilterBuilder extends SysFilterBuilder {

  getFieldDef(fieldName, selectFields) {
    return selectFields[fieldName.getFullFieldName()];
  }

  /**
   * @param _fieldPredicate: "Query".QueryFieldPredicate
   * @param onFilterSave: mixed
   * @returns {JSX.Element}
   */
  getFieldPredicate(_fieldPredicate = null, onFilterSave = null) {
    let fieldPredicateRef = createRef();
    let fieldPredicate;
    if (_fieldPredicate !== undefined && _fieldPredicate !== null) {
      let fieldName = _fieldPredicate.getFieldName();

      fieldPredicate = <FieldPredicate key={shortid()} ref={fieldPredicateRef} commandBar={
        <CommandBar fieldPredicateRef={fieldPredicateRef}/>} fieldSelection={
        <FieldSelection fieldPredicateRef={fieldPredicateRef} defaultValue={fieldName.label}>{this.props.selectFields}</FieldSelection>} operatorSelection={
        <OperatorSelection fieldPredicateRef={fieldPredicateRef} currentOperator={_fieldPredicate.getOperator()}/>} valueField={
        <ValueField ref={createRef()} labelElement='hier Wert eingeben...' value={_fieldPredicate.getValue1()} fieldPredicateRef={fieldPredicateRef} fieldDef={this.getFieldDef(
          fieldName,
          this.props.selectFields)} fieldDefs={this.props.selectFields}/>} filterBuilder={this} isExistingFilter={_fieldPredicate.isExistingNode} defaultFieldValue={_fieldPredicate.getValue1()} defaultFieldNameValue={_fieldPredicate.getFieldName().getFullFieldName()} defaultOperatorValue={_fieldPredicate.getOperator()} defaultFieldLabelValue={fieldName.label}/>;
    } else {
      fieldPredicate = <FieldPredicate key={shortid()} ref={fieldPredicateRef} commandBar={
        <CommandBar fieldPredicateRef={fieldPredicateRef}/>} fieldSelection={
        <FieldSelection fieldPredicateRef={fieldPredicateRef}>{this.props.selectFields}</FieldSelection>} operatorSelection={
        <OperatorSelection fieldPredicateRef={fieldPredicateRef}/>} valueField={
        <ValueField labelElement='hier Wert eingeben...' value='' fieldPredicateRef={fieldPredicateRef}/>} filterBuilder={this} isExistingFilter={false}/>;
    }
    return fieldPredicate;
  }

  /**
   *
   * @param _booleanPredicate: "Query".BooleanPredicate
   * @returns {JSX.Element}
   */
  getBooleanPredicate(_booleanPredicate = null) {
    let booleanPredicateRef = createRef();
    let booleanPredicate;
    if (_booleanPredicate !== undefined && _booleanPredicate !== null) {
      booleanPredicate =
        <BooleanPredicate key={shortid()} ref={booleanPredicateRef} operator={_booleanPredicate.getOperator()} filterBuilder={this}/>;
    } else {
      booleanPredicate =
        <BooleanPredicate key={shortid()} ref={booleanPredicateRef} operator={Operator.and} filterBuilder={this}/>;
    }
    return booleanPredicate;
  }
}

FilterBuilder.propTypes = {
  selectFields: PropTypes.object,
  queryPredicates: PropTypes.object,
  defaultFieldPredicate: PropTypes.object
}
export default FilterBuilder;

import PropTypes from "prop-types";
import shortid from "shortid";
import BaseComponent from "../../../../../lib/components/BaseComponent";
import MenuItemGroup from "../../../../../lib/components/menu/MenuItemGroup";
import Menu from "../../menu/Menu";
import MenuItem from "../../../../../lib/components/menu/MenuItem";
import GF from "../../../../../lib/utils/GF";
import InputSearchDropDown from "../../inputFields/dropdown/InputSearchDropDown";
import Operator from "../../../../../lib/model/query/Operator";

class OperatorSelection extends BaseComponent {

  componentDidMount() {
    super.componentDidMount();
    this.onOperatorValueChange(null);
  }

  onOperatorValueChange(e, id, value) {
    if (GF.checkNotNull(this.props.fieldPredicateRef) && GF.checkNotNull(this.props.fieldPredicateRef.current)) {
      this.props.fieldPredicateRef.current.onOperatorValueChange(e, id, value);
    }
  }

  mapOperatorToDefault(operator) {
    if (operator === '==') {
      operator = '=';
    }
    return operator;
  }

  render() {
    let currentOperator = Operator.extendWithLabel(
      this.mapOperatorToDefault(GF.getValue(this.props.currentOperator, '=')));
    let operatorListFromProps = (this.props.operatorList !== undefined ? this.props.operatorList : []);
    if (operatorListFromProps.length === 0) {
      operatorListFromProps = [[Operator.eq, Operator.get().eq_label], [Operator.neq, Operator.get().neq_label],
        [Operator.lt, Operator.get().lt_label], [Operator.gt, Operator.get().gt_label], [Operator.leq, Operator.get().leq_label],
        [Operator.geq, Operator.get().geq_label], [Operator.like, Operator.get().like_label], [Operator.null, Operator.get().null_label],
        [Operator.not_null, Operator.get().not_null_label]];
    }
    let operatorList = [];
    operatorListFromProps.map((element, index) => {
      operatorList.push(<MenuItem option={element[0]} key={shortid()}>{element[1]}</MenuItem>);
      return element;
    });

    return <div className='self-center mx-2'>
      <InputSearchDropDown className='min-w-[160px] h-full' inputLayout='outline' onChange={(e, id, value) => this.onOperatorValueChange(
        e, id, value)} defaultValue={{
        option: GF.getValue(currentOperator[0]), displayValue: GF.getValue(currentOperator[1])
      }}>
        <Menu>
          <MenuItemGroup>
            {operatorList}
          </MenuItemGroup>
        </Menu>
      </InputSearchDropDown>
    </div>;
  }
}

OperatorSelection.propTypes = {
  operatorList: PropTypes.arrayOf(PropTypes.object),
  fieldPredicateRef: PropTypes.object,
  currentOperator: PropTypes.string
}
export default OperatorSelection;

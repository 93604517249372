import LibMenu from "../../../../lib/components/menu/Menu";
import PropTypes from "prop-types";

class Menu extends LibMenu {

  constructor(props) {
    super(props);
    this.state.className += ' text-cn-color-white ';
  }
}

Menu.propTypes = {
  onChange: PropTypes.func, className: PropTypes.string, styleValue: PropTypes.object
}
export default Menu;

import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import GF from "../../../../../lib/utils/GF";
import InputDropDownBase from "./InputDropDownBase";
import {ChevronDown} from "react-feather";
import InputStateType from "../../../../../lib/components/types/StateType";
import ReactDOM from "react-dom";

class InputSearchDropDown extends InputDropDownBase {

  constructor(props) {
    super(props);
    this.state.iconRight = <ChevronDown onClick={(e) => this.onInputClick(e)} className='self-center mr-1 smooth-rotate cursor-pointer'/>;
    if (!GF.checkNotNull(this.state.selectedOption)) {
      this.state.selectedOption = {option: '', displayValue: ''};
    }
    if (GF.checkNotNull(this.props.defaultValue)) {
      this.state.selectedOption = this.props.defaultValue;
    }
    this.valueContentRef = createRef();
  }

  onEnterKeyUp(e) {
    if (e.key === 'Enter') {
      // let menuDom = ReactDOM.findDOMNode(this.menuRef.current);
      // if (menuDom !== undefined) {
      //   let item = document.body.getElementsByClassName('cn-menu-item')[0];
      //   if (item) {
      //
      //   }
      // }
    }
  }

  onInputChange(e) {
    this.menuRef.current.setFilter(e.currentTarget.value);
  }

  render() {
    let style = this.getStylePositionInfo(this.valueContentRef.current, 10);
    this.createPicker(this.state.filter);
    let className = this.props.className ? this.props.className : '';
    if (this.state.showPicker) {
      let inputStyle = 'dropdown-search-input cursor-pointer focus:outline-0 h-full p-0 bg-transparent w-full';
      if (this.state.state === InputStateType.readonly) {
        inputStyle = 'dropdown-search-input focus:outline-0 h-full p-0 bg-transparent w-full';
      }
      let rootNode = document.getElementById('root');
      this.state.children = <div className={'relative w-full h-full ' + className}>
        <input onChange={(e) => this.onInputChange(e)} onKeyUp={(e) => this.onEnterKeyUp(e)} autoComplete='off' autoFocus
               className={inputStyle}/>
        {ReactDOM.createPortal(<div className='fixed' style={style}>{this.state.menuPicker}</div>, rootNode)}
      </div>;
    } else {
      this.state.useFullHeightInOutlineLayout = !(GF.checkNotNull(this.state.selectedOption.displayValue) &&
        this.state.selectedOption.displayValue !== '');
      let bodyCss = 'overflow-hidden w-full cursor-pointer self-center whitespace-nowrap ';
      if (this.state.state === InputStateType.readonly) {
        bodyCss = 'overflow-hidden w-full self-center whitespace-nowrap ';
      }
      if (this.state.useFullHeightInOutlineLayout) {
        bodyCss += 'h-full ';
      }
      bodyCss += className;
      this.state.children = <div ref={this.valueContentRef} className={bodyCss} onClick={(e) => this.onInputClick(e)}>
        {this.state.selectedOption.displayValue}
      </div>;
    }
    return super.render();
  }
}

InputSearchDropDown.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'light', 'dark']),
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  inputLayout: PropTypes.oneOf(['inline', 'outline', '']),
  defaultValue: PropTypes.shape({option: PropTypes.any, displayValue: PropTypes.any}),
  onChange: PropTypes.func
}
export default InputSearchDropDown;

import Dto from "../../../../../lib/model/service/Dto";

class CreateAnalyticsSite extends Dto {

  campaignId;

  constructor(campaignId = '') {
    super('appl\\service\\campaign\\dto\\CreateAnalyticsSite');
    this.campaignId = campaignId;
  }

  getCampaignId() {
    return this.campaignId;
  }

  setCampaignId(campaignId) {
    this.campaignId = campaignId;
  }
}

export default CreateAnalyticsSite;

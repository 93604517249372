/**
 * class Listener
 */
import CM from "../../../appl/model/system/CM";

class Listener {

  id;
  hook;

  constructor(hook) {
    this.id = CM.get().createUniquId();
    this.hook = hook;
  }
}

export default Listener;

import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import BaseComponent from "../../../../../lib/components/BaseComponent";
import MenuItemGroup from "../../../../../lib/components/menu/MenuItemGroup";
import MenuItem from "../../../../../lib/components/menu/MenuItem";
import Menu from "../../menu/Menu";
import GF from "../../../../../lib/utils/GF";
import InputSearchDropDown from "../../inputFields/dropdown/InputSearchDropDown";

class FieldSelection extends BaseComponent {

  componentDidMount() {
    super.componentDidMount();
    this.onChange(null);
  }

  onChange(e, id, option) {
    if (GF.checkNotNull(this.props.fieldPredicateRef) && GF.checkNotNull(this.props.fieldPredicateRef.current)) {
      this.props.fieldPredicateRef.current.onFieldNameValueChange(e, option);
      this.props.fieldPredicateRef.current.onFieldLabelValueChange(e, option);
      this.props.fieldPredicateRef.current.onUpdateFieldType(e, option);
    }
  }

  render() {
    let fieldList = [];
    let firstMenuItem = null;
    let firstMenuItemKey = null;
    if (this.props.defaultValue !== undefined && this.props.defaultValue !== null) {
      firstMenuItem = this.props.defaultValue;
    }
    if (GF.checkNotNull(this.props.children)) {
      for (let id in this.props.children) {
        let element = this.props.children[id];
        fieldList.push(<MenuItem option={id} searchValue={element.label} key={shortid()}>{element.label}</MenuItem>);
        if (firstMenuItem === null) {
          firstMenuItem = element.label;
          firstMenuItemKey = element.id;
        }
      }
    }
    return <div className='self-center'>
      <InputSearchDropDown className='min-w-[200px] h-full' inputLayout='outline' searchable onChange={(e, id, option) => this.onChange(
        e, id, option)} defaultValue={{
        option: firstMenuItemKey,
        displayValue: firstMenuItem
      }}>
        <Menu>
          <MenuItemGroup>
            {fieldList}
          </MenuItemGroup>
        </Menu>
      </InputSearchDropDown>
    </div>;
  }
}

FieldSelection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fieldPredicateRef: PropTypes.object,
  defaultValue: PropTypes.any
}
export default FieldSelection;

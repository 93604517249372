import CM from "./CM";

/**
 * class UploadManager
 */
class UploadManager {

  private_simulateUpload(temp_uploadProgress, onSuccess, temp_numOfLoops, self) {
    if (temp_numOfLoops < 10) {
      temp_numOfLoops++;
      if (typeof temp_uploadProgress === 'function') {
        temp_uploadProgress(temp_numOfLoops * 10);
      }
      setTimeout(self.private_simulateUpload, 100, temp_uploadProgress, onSuccess, temp_numOfLoops, self);
    } else {
      //onSuccess(new Response(true));
    }
  }

  /**
   * @param dto
   * @param fileList -> dies sind die verschiedenen Dateien (File-Objekt) gesammelt aus einem oder mehreren <input type="file"...> tags
   * @param onSuccess
   * @param uploadProgress
   */
  upload(dto, fileList, onSuccess, uploadProgress) {
    let url = CM.get().getServiceUrl('Resource');

    let formData = new FormData();
    if (dto !== undefined) {
      formData.append("dto", JSON.stringify(dto));
    }
    for (let key in fileList) {
      if (fileList.hasOwnProperty(key)) {
        let file = fileList[key];
        formData.append("UploadManagerInfo_" + key, file);
      }
    }
    CM.get().doAjax(url, formData, (response) => {
      onSuccess(response);
    });
    this.private_simulateUpload(uploadProgress, onSuccess, 0, this);
  };
}

export default UploadManager;

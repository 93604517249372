import BaseComponent from "../../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import ProgressBar from "../../../../../lib/components/progressBar/ProgressBar";

class ProgressBarTest extends BaseComponent {

    render() {
        return <div className='sandbox-container flex flex-col gap-4'>
            <VerticalLayout className={' self-center'}>
                <h1>Progressbar</h1>
                <ProgressBar text='File: 123456789.jpg' />
            </VerticalLayout>
        </div>
    }
}

export default ProgressBarTest;

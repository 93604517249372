import Dto from "../../../../../lib/model/service/Dto";

class ReadVariableGroups extends Dto {

  campaignId;
  version;
  pageId;

  constructor(campaignId = '', version = 'std', pageId = '', _clazz = 'appl\\service\\campaign\\dto\\ReadVariableGroups') {
    super(_clazz);
    this.campaignId = campaignId;
    this.version = version;
    this.pageId = pageId;
  }

  getCampaignId() {
    return this.campaignId;
  }

  setCampaignId(campaignId) {
    this.campaignId = campaignId;
  }

  getVersion() {
    return this.version;
  }

  setVersion(version) {
    this.version = version;
  }

  getPageId() {
    return this.pageId;
  }

  setPageId(pageId) {
    this.pageId = pageId;
  }

}

export default ReadVariableGroups;

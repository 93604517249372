import InputBase from "./InputBase";
import {Download, Eye, Trash2, Upload} from "react-feather";
import PropTypes from "prop-types";
import Get from "../../../../lib/model/service/resource/dto/Get";
import {createRef} from "react";
import GF from "../../../../lib/utils/GF";
import DialogBase from "../../../../lib/components/dialogs/DialogBase";
import CM from "../../../model/system/CM";
import ResourceEntity from "../../../../lib/model/resource/ResourceEntity";

class InputUpload extends InputBase {

  constructor(props) {
    super(props);
    this.inputUploadReference = createRef();
    let defaultValue = GF.getValue(this.props.defaultValue, '');
    if (defaultValue instanceof ResourceEntity) {
      this.state.defaultValue = defaultValue;
    } else {
      this.state.defaultValue = new ResourceEntity(defaultValue, defaultValue);
    }
    this.state.grid = '';
  }

  getValue() {
    return this.state.defaultValue;
  }

  onUploadInputChange(e) {
    this.state.defaultValue.setFiles(e.target.files);
    this.state.defaultValue.setResourceId('');
    this.state.defaultValue.setResourceName(e.target.files[0].name);
    this.fireOnChange(e, this.state.defaultValue);
    this.setState(this.state);
  }

  previewImage(e) {
    let resourceName = this.state.defaultValue.getResourceName();
    let href = this.buildResourceLink(this.state.defaultValue.getResourceId(), resourceName);

    let dialog = <DialogBase title={this.props.labelElement}>
      <div className='flex items-center justify-center'>
        <img src={href} width='80%' className='cn-border-radius-20' alt={resourceName}/>
      </div>
    </DialogBase>;
    CM.get().setMainDialog(dialog);
  }

  onDeleteButtonClick(e) {
    this.state.defaultValue.setOldResourceId(this.state.defaultValue.getResourceId());
    this.state.defaultValue.setResourceId('');
    this.state.defaultValue.setResourceName('');
    this.fireOnChange(e, this.state.defaultValue);
    this.setState(this.state);
  }

  buildResourceLink(resourceId, resourceName) {
    let href = '';
    if (resourceId === resourceName) {
      href = CM.get().getCampaignUrl() + resourceId;
    } else {
      href = Get.buildResourceLink(resourceId, resourceName, GF.getValue(this.props.dtoProperties, null));
    }
    return href;
  }

  render() {
    let resourceId = this.state.defaultValue.getResourceId();
    let resourceName = this.state.defaultValue.getResourceName();

    let download, preview, trash;
    if (resourceName !== '') {
      let href = this.buildResourceLink(resourceId, resourceName);
      if (GF.isImageFile(resourceName)) {
        preview = <Eye onClick={(e) => this.previewImage(e)} className='stroke-cn-color-primary p-0.5 cursor-pointer'/>
      }
      download = <a target='_blank' href={href}><Download className='stroke-cn-color-primary p-0.5 cursor-pointer'/></a>;
      trash = <Trash2 onClick={(e) => this.onDeleteButtonClick(e)} className='stroke-cn-color-static-red p-0.5 cursor-pointer'/>
    }
    this.state.grid = 'grid-template-columns-100pc-35px';
    if (download && trash) {
      this.state.grid = 'grid-template-columns-100pc-85px';
    }
    if (preview) {
      this.state.grid = 'grid-template-columns-100pc-116px';
    }
    this.state.iconRight = <div className='flex gap-1 self-center mr-2'>
      {preview}
      {download}
      <Upload onClick={(e) => this.inputUploadReference.current.click(e)} className='stroke-cn-color-primary p-0.5 cursor-pointer'/>
      {trash}
    </div>;

    let uploadMode = '*';
    if (GF.checkNotNull(this.props.uploadMode)) {
      switch (this.props.uploadMode) {
        case 'only_images':
          uploadMode = 'image/*';
          break;
        default:
          break;
      }
    }
    this.state.children = <>
      <div className='pt-1.5 overflow-hidden w-full truncate'>{resourceName}</div>
      <input onChange={(e) => this.onUploadInputChange(e)} ref={this.inputUploadReference} className='hidden' type='file' accept={uploadMode}/>
    </>;

    return super.render();
  }
}

InputUpload.propTypes = {
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  inputLayout: PropTypes.oneOf(['inline', 'outline', '']),
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  accept: PropTypes.string,
  onDeleteClick: PropTypes.func,
  onViewClick: PropTypes.func,
  dtoProperties: PropTypes.object,
  uploadMode: PropTypes.oneOf(['all', 'only_images', '']),
}
export default InputUpload;

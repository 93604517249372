import Dto from "../../../../lib/model/service/Dto";

class FilterProperties extends Dto {

  static TYPE_QUERY = 'query';
  static TYPE_LIST = 'list';
  static TYPE_QUERY_DISPLAY = 'Abfragefilter';
  static TYPE_LIST_DISPLAY = 'Versandliste';

  constructor(listName, useForSending, favorite, type, value, createdDate) {
    super('appl\\model\\service\\system\\FilterProperties');
    this.listName = listName;
    this.useForSending = useForSending;
    this.favorite = favorite;
    this.type = type;
    this.value = value;
    this.createdDate = createdDate;
  }

  getListName() {
    return this.listName;
  }

  setListName(value) {
    this.listName = value;
  }

  isUseForSending() {
    return this.useForSending;
  }

  setUseForSending(value) {
    this.useForSending = value;
  }

  isFavorite() {
    return this.favorite;
  }

  setFavorite(value) {
    this.favorite = value;
  }

  getType() {
    return this.type;
  }

  setType(value) {
    this.type = value;
  }

  getValue() {
    return this.value;
  }

  setValue(value) {
    this.value = value;
  }

  getCreatedDate() {
    return this.createdDate;
  }

  setCreatedDate(createdDate) {
    this.createdDate = createdDate;
  }
}

export default FilterProperties;
import T from "../../../appl/model/system/text_translations/Translator";

class Operator {

  static eq = '=';
  static neq = '!=';
  static gt = '>';
  static lt = '<';
  static geq = '>=';
  static leq = '<=';
  static like = 'like';
  static and = 'AND';
  static or = 'OR';
  static null = 'null';
  static not_null = 'not_null';

  static instance: Operator;

  static get() {
    if (!Operator.instance) {
      Operator.instance = new Operator();
    }
    return Operator.instance;
  }

  constructor() {
    this.initLabels();
  }

  initLabels() {
    this.eq_label = T.ext_filter_eq_label();
    this.neq_label = T.ext_filter_neq_label();
    this.gt_label = T.ext_filter_gt_label();
    this.lt_label = T.ext_filter_lt_label();
    this.geq_label = T.ext_filter_geq_label();
    this.leq_label = T.ext_filter_leq_label();
    this.like_label = T.ext_filter_like_label();
    this.and_label = T.ext_filter_and_label();
    this.or_label = T.ext_filter_or_label();
    this.null_label = T.ext_filter_null_label();
    this.not_null_label = T.ext_filter_not_null_label();
  }

  static extendWithLabel(operator) {
    let operatorWithLabel = [];
    switch (operator) {
      case Operator.eq:
        operatorWithLabel = [Operator.eq, Operator.get().eq_label];
        break;
      case Operator.neq:
        operatorWithLabel = [Operator.neq, Operator.get().neq_label];
        break;
      case Operator.gt:
        operatorWithLabel = [Operator.gt, Operator.get().gt_label];
        break;
      case Operator.lt:
        operatorWithLabel = [Operator.lt, Operator.get().lt_label];
        break;
      case Operator.geq:
        operatorWithLabel = [Operator.geq, Operator.get().geq_label];
        break;
      case Operator.leq:
        operatorWithLabel = [Operator.leq, Operator.get().leq_label];
        break;
      case Operator.like:
        operatorWithLabel = [Operator.like, Operator.get().like_label];
        break;
      case Operator.and:
        operatorWithLabel = [Operator.and, Operator.get().and_label];
        break;
      case Operator.or:
        operatorWithLabel = [Operator.or, Operator.get().or_label];
        break;
      case Operator.null:
        operatorWithLabel = [Operator.null, Operator.get().null_label];
        break;
      case Operator.not_null:
        operatorWithLabel = [Operator.not_null, Operator.get().not_null_label];
        break;
      default:
        break;
    }
    return operatorWithLabel;
  }
}

export default Operator;

import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import VerticalLayout from "../layouts/VerticalLayout";
import React from "react";
import GF from "../../utils/GF";

class MenuItemGroup extends BaseComponent {

  onChange = (e, option, displayValue) => {
    if (this.props.onChange !== undefined) {
      this.props.onChange(e, option, displayValue);
    }
  }

  createChildren() {
    return React.Children.map(this.props.children, (child) => {
      if (this.props.filter === undefined || this.props.filter === null || child.props.searchValue === undefined ||
        child.props.searchValue === null || GF.includes(child.props.searchValue, this.props.filter)) {

        if (!GF.checkNotNull(child.props.onClick)) {
          return React.cloneElement(child, {onClick: this.onChange});
        } else {
          return child;
        }
      }
    });
  }

  render() {
    let children = this.createChildren();
    let classes = 'cn-menu-item-group ';
    classes += GF.getValue(this.props.className, '');
    return <VerticalLayout className={classes}>
      {this.props.header}
      {children}
    </VerticalLayout>
  }
}

MenuItemGroup.propTypes = {
  onChange: PropTypes.func, header: PropTypes.node, className: PropTypes.string
}
export default MenuItemGroup;

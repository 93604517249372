import MemoryColumn from "./MemoryColumn";
import FieldName from "../../../../query/FieldName";
import GF from "../../../../../utils/GF";

class MemoryTable {

  columns = [];
  entityDef = null;

  constructor(entityDef, rows) {
    this.init(entityDef, rows);
  }

  init(entityDef, rows) {
    this.columns = [];
    this.entityDef = entityDef;
    this.initColumns();
    this.initRows(rows);
  }

  initColumns() {
    let relationName = '';
    if (GF.checkNotNull(this.entityDef.baseDef) && GF.checkNotNull(this.entityDef.baseDef.name) && this.entityDef.baseDef.name !== '') {
      relationName = this.entityDef.baseDef.name;
    }
    for (let fieldName in this.entityDef.fieldsDef) {
      let fieldDef = this.entityDef.fieldsDef[fieldName];
      let fullFieldName = (new FieldName(fieldName, relationName)).getFullFieldName();
      this.columns.push(new MemoryColumn(fullFieldName, fieldDef));
    }
  }

  initRows(rows) {
    if (rows !== undefined && rows !== null && rows.length > 0) {
      for (let rowsId in rows) {
        this.create(rows[rowsId]);
      }
    }
  }

  create(record) {
    this.private_loopColumns((column, columnId) => {
      let columnName = column.getColumnName();
      let fieldValue = null;
      if (record.hasOwnProperty(columnName)) {
        fieldValue = record[columnName];
      }
      column.addColumnElement(fieldValue);
    });
    return this.private_buildRowObject(-1);
  }

  read(query) {
    return this.private_buildRowObjects(query);
  }

  update(record) {
    let keyColumn = this.private_getKeyColumn();
    let recId = parseInt(record[keyColumn.getColumnName()]);
    let rowId = keyColumn.getRowIdByRecId(recId);

    this.private_loopColumns((column, columnId) => {
      let columnName = column.getColumnName();
      if (record.hasOwnProperty(columnName)) {
        column.setColumnElement(recId, record[columnName]);
      }
    });
    return this.private_buildRowObject(rowId);
  }

  delete(record) {
    let keyColumn = this.private_getKeyColumn();
    let rowId = keyColumn.getRowIdByRecId(parseInt(record[keyColumn.getColumnName()]));
    this.private_loopColumns((column, columnId) => {
      column.removeRowElement(rowId);
    });
    return record;
  }

  getEntityDef() {
    return this.entityDef;
  }

  private_getKeyColumn() {
    let keyColumn = null;
    this.private_loopColumns((column, columnId) => {
      if (column.isKeyColumn()) {
        keyColumn = column;
      }
    });
    return keyColumn;
  }

  private_loopColumns(callBack) {
    for (let columnId in this.columns) {
      let column = this.columns[columnId];
      if (callBack !== undefined && callBack !== null) {
        callBack(column, columnId);
      }
    }
  }

  private_loopRows(rowCallBack, columnCallBack) {
    let numOfRows = 0;
    if (this.columns.length > 0) {
      numOfRows = this.columns[0].getNumOfRows();
    }
    for (let i = 0; i < numOfRows; i++) {
      if (rowCallBack !== undefined && rowCallBack !== null) {
        this.private_loopColumns((column, columnId) => {
          if (columnCallBack !== undefined && columnCallBack !== null) {
            columnCallBack(column, columnId, i);
          }
        });
        if (rowCallBack(i)) {
          break;
        }
      }
    }
  }

  private_buildRowObject(rowId) {
    let columns = {};
    this.private_loopColumns((column, columnId) => {
      columns[column.getColumnName()] = column.getRowElement(rowId);
    });
    return columns;
  }

  private_buildRowObjects(query) {
    let rows = [];
    let columns = {};
    this.private_loopRows((rowId) => {
      if (query === undefined || query === null || (rowId >= query.startRow && rowId <= query.endRow)) {
        rows.push(columns);
        columns = {};
      }
    }, (column, columnId, rowId) => {
      columns[column.getColumnName()] = column.getRowElement(rowId);
    });
    let totalRows = 0;
    if (this.columns.length > 0) {
      totalRows = this.columns[0].getNumOfRows();
    }
    return {rows: rows, totalRows: totalRows};
  }
}

export default MemoryTable;

import Dto from "../../Dto";

class AnalyseUpload extends Dto {

  constructor(id, entityName) {
    super('po2\\service\\entity\\dto\\AnalyseUpload');
    this.id = id;
    this.entityName = entityName;
  }

  getId() {
    return this.id;
  }

  setId(id) {
    this.id = id;
  }

  getEntityName() {
    return this.entityName;
  }

  setEntityName(entityName) {
    this.entityName = entityName;
  }
}

export default AnalyseUpload;

import Dto from "../../Dto";

class Records extends Dto {

  constructor(records = [], totalRows = undefined) {
    super('po2\\service\\entity\\dto\\Records');
    if (records === undefined || records === null) {
      records = [];
    }
    this.records = records;
    this.totalRows = totalRows !== undefined ? totalRows : totalRows = records.length;
  }

  getRecords() {
    return this.records;
  }

  setRecords(records) {
    this.records = records;
  }

  getTotalRows() {
    return this.totalRows;
  }

  setTotalRows(totalRows) {
    this.totalRows = totalRows;
  }
}

export default Records;

import BaseComponent from "../../../../../lib/components/BaseComponent";
import HorizontalLayout from "../../../../../lib/components/layouts/HorizontalLayout";
import DatePicker from "../../../../../lib/components/dateTimePicker/DatePicker";
import TimePicker from "../../../../../lib/components/dateTimePicker/TimePicker";
import DateTimePicker from "../../../../../lib/components/dateTimePicker/DateTimePicker";
import InputDateTimePicker from "../../../../../appl/view/components/inputFields/InputDateTimePicker";
import {createRef} from "react";

class DateTimePickersTest extends BaseComponent {

  constructor(props) {
    super(props);
    this.inputDateTimePickerRef = createRef();
    this.inputDatePickerRef = createRef();
    this.inputTimePickerRef = createRef();
  }

  pickerDateTimeHasChanged(e, _id, data) {
    console.log('DateTimePickersTest.data=[' + data + ']');
    let getValueValue = this.inputDateTimePickerRef.current.getValue();
    console.log('DateTimePickersTest.getValue=[' + getValueValue + ']');
  }

  pickerDateHasChanged(e, _id, data) {
    console.log('DateTimePickersTest.pickerDateHasChanged.data=[' + data + ']');
    let getValueValue = this.inputDatePickerRef.current.getValue();
    console.log('DateTimePickersTest.pickerDateHasChanged.getValue=[' + getValueValue + ']');
  }

  pickerTimeHasChanged(e, _id, data) {
    console.log('DateTimePickersTest.pickerTimeHasChanged.data=[' + data + ']');
    let getValueValue = this.inputTimePickerRef.current.getValue();
    console.log('DateTimePickersTest.pickerTimeHasChanged.getValue=[' + getValueValue + ']');
  }

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>
      <h1>DateTimePickers test</h1>
      <HorizontalLayout className='gap-5 flex-wrap justify-center'>
        <div>
          <h1>DateTime</h1>
          <DateTimePicker datetime={new Date(2023, 3, 1)}/>
        </div>
        <div>
          <h1>Date</h1>
          <DatePicker className='dp-container'/>
        </div>
        <div>
          <h1>Time</h1>
          <TimePicker className='tp-container'/>
        </div>
        <div>
          <h1>DateTime</h1>
          <InputDateTimePicker ref={this.inputDateTimePickerRef} labelElement='DateTimePicker' useTime={false} onChange={(e, _id, data) => this.pickerDateTimeHasChanged(e, _id, data)}/>
        </div>
        <div>
          <h1>Date</h1>
          <InputDateTimePicker ref={this.inputDatePickerRef} pickerVariant='DATE' labelElement='DatePicker' onChange={(e, _id, data) => this.pickerDateHasChanged(e, _id, data)}/>
        </div>
        <div>
          <h1>Time</h1>
          <InputDateTimePicker ref={this.inputTimePickerRef} pickerVariant='TIME' labelElement='TimePicker' onChange={(e, _id, data) => this.pickerTimeHasChanged(e, _id, data)}/>
        </div>
      </HorizontalLayout>
    </div>
  }
}

export default DateTimePickersTest;

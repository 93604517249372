import BaseComponent from "../../../../../lib/components/BaseComponent";
import PropType from "prop-types";

class ModulFunctionGroup extends BaseComponent {



  render() {

    return<div className=''>
      <h2 className='font-cn-gotham-medium text-cn-color-primary text-xl mt-3 uppercase'>{this.props.title}</h2>
      <ul className='list-disc px-5 text-cn-color-landing-gray01 font-cn-gotham-medium marker:font-cn-inter-regular text-sm '>
        {this.props.children}
      </ul>
    </div>
  }
}

ModulFunctionGroup.propTypes = {
  title: PropType.string.isRequired,
}

export default ModulFunctionGroup;

import BaseComponent from "../../../../lib/components/BaseComponent";
import React from "react";
import GF from "../../../../lib/utils/GF";
import ResetPasswordDialog from "../../components/passwordReset/ResetPasswordDialog";

class PasswordReset extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      dialog: null
    }
  }

  componentDidMount() {
    let paramValue = GF.getParamValueFromURL('hash');
    this.state.dialog = <ResetPasswordDialog hash={paramValue}/>;
    this.setState(this.state);
  }

  render() {
    return <>
      <div className='fixed top-0 left-0 w-screen h-screen bg-bg-img-layout-dark bg-cover bg-center'/>
      {this.state.dialog}
    </>;
  }
}

export default PasswordReset;

import Callback from "../../../../appl/model/system/Callback";

class DataSourceEvents {

  constructor() {
    this.onCreateDoneListeners = new Callback();
    this.onDuplicateDoneListeners = new Callback();
    this.onDeleteDoneListeners = new Callback();
    this.onDescribeDoneListeners = new Callback();
    this.onErrorListeners = new Callback();
    this.onReadDoneListeners = new Callback();
    this.onRowChangedListeners = new Callback();
    this.onUpdateDoneListeners = new Callback();
    this.onConnectDoneListeners = new Callback();
    this.onDisconnectDoneListeners = new Callback();
    this.onGetDownLoadInfoListeners = new Callback();
    this.onExportListeners = new Callback();
  }

  addOnCreateDoneListener(listener) {
    this.onCreateDoneListeners.addListener(listener);
  }
  addOnDuplicateDoneListener(listener) {
    this.onDuplicateDoneListeners.addListener(listener);
  }

  addOnDeleteDoneListener(listener) {
    this.onDeleteDoneListeners.addListener(listener);
  }

  addOnDescribeDoneListener(listener) {
    this.onDescribeDoneListeners.addListener(listener);
  }

  addOnErrorListener(listener) {
    this.onErrorListeners.addListener(listener);
  }

  addOnReadDoneListener(listener) {
    this.onReadDoneListeners.addListener(listener);
  }

  addOnRowChangeListener(listener) {
    this.onRowChangedListeners.addListener(listener);
  }

  addOnUpdateDoneListener(listener) {
    this.onUpdateDoneListeners.addListener(listener);
  }

  addOnConnectListener(listener) {
    this.onConnectDoneListeners.addListener(listener);
  }

  addOnDisconnectListener(listener) {
    this.onDisconnectDoneListeners.addListener(listener);
  }

  addOnGetDownLoadInfoListeners(listener) {
    this.onGetDownLoadInfoListeners.addListener(listener);
  }

  addOnExportListenersListeners(listener) {
    this.onExportListeners.addListener(listener);
  }

  removeOnCreateDoneListener(listener) {
    this.onCreateDoneListeners.removeListener(listener);
  }
  removeOnDuplicateDoneListener(listener) {
    this.onDuplicateDoneListeners.removeListener(listener);
  }

  removeOnDeleteDoneListener(listener) {
    this.onDeleteDoneListeners.removeListener(listener);
  }

  removeOnDescribeDoneListener(listener) {
    this.onDescribeDoneListeners.removeListener(listener);
  }

  removeOnErrorListener(listener) {
    this.onErrorListeners.removeListener(listener);
  }

  removeOnReadDoneListener(listener) {
    this.onReadDoneListeners.removeListener(listener);
  }

  removeOnRowChangeListener(listener) {
    this.onRowChangedListeners.removeListener(listener);
  }

  removeOnUpdateDoneListener(listener) {
    this.onUpdateDoneListeners.removeListener(listener);
  }

  removeOnConnectListener(listener) {
    this.onConnectDoneListeners.removeListener(listener);
  }

  removeOnDisconnectListener(listener) {
    this.onDisconnectDoneListeners.removeListener(listener);
  }

  removeOnGetDownLoadInfoListeners(listener) {
    this.onGetDownLoadInfoListeners.removeListener(listener);
  }

  removeOnExportListenersListeners(listener) {
    this.onExportListeners.removeListener(listener);
  }
}

export default DataSourceEvents
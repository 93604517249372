import BaseComponent from "../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import Menu from "../../../../lib/components/menu/Menu";
import MenuItemGroup from "../../../../lib/components/menu/MenuItemGroup";
import {HelpCircle, LogOut} from "react-feather";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import CM from "../../../model/system/CM";
import PropType from "prop-types";
import React from "react";
import {toast} from "react-toastify";
import Close from "../../../../lib/model/service/oauth/dto/Close";
import shortid from "shortid";
import ButtonTab from "../../../../lib/components/tabbar/ButtonTab";
import HelpWindow from "../help/HelpWindow";
import T from "../../../model/system/text_translations/Translator";

class SideBar extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      menuElements: []
    };
  }

  addMenu(menu) {
    this.state.menuElements.push(menu);
  }

  clearMenu() {
    this.state.menuElements = [];
  }

  refreshMenu() {
    this.state.menuElements = [];
    this.setState(this.state);
  }

  onSignOutClick(e) {
    let key = CM.get().getSessionValue('oauth_token');
    CM.get().doJsonAjax(CM.get().getServiceUrl('oauth'), new Close(key), function(response) {
      if (response.success) {
        toast.success('Sie wurden ausgeloggt.');
        CM.get().logout();
      } else {
        toast.error('Leider ist etwas schief gelaufen, Sie konnten nicht ausgeloggt werden.');
      }
    });
  }

  render() {
    let menuElements = React.Children.map(this.state.menuElements, (menuElement) => {
      return React.cloneElement(menuElement, {key: shortid()});
    });
    let helpMenuHeader =
      <HorizontalLayout className='bg-cn-color-secondary text-cn-color-static-white p-4 cn-border-radius gap-x-3 cursor-pointer'
                        onClick={(e) => CM.get().getPanel().tabContainerRef.current.addElement(
                          <ButtonTab id={'helpVideos'}>Hilfe-Videos</ButtonTab>, <HelpWindow/>)}>
        <HelpCircle/>{T.label_help()}</HorizontalLayout>;
    let signOutMenuHeader =
      <HorizontalLayout className='bg-cn-color-static-red text-cn-color-static-white justify-center p-4 cn-border-radius gap-x-3 cursor-pointer'
                        onClick={(e) => this.onSignOutClick(e)}><LogOut/>{T.action_logout()}</HorizontalLayout>
    return (<VerticalLayout className='pt-5 items-center text-cn-color-white px-3 gap-y-4 font-cn-roboto-slab-medium'>
      <img className='w-24 h-24 rounded-full object-cover' src={this.props.avatar} alt=''/>
      <Menu className='border-none w-full cn-shadow-button-none'>
        {menuElements}
      </Menu>
      <Menu className='border-none w-full mt-20 cn-shadow-button-none gap-y-10 mb-6'>
        <MenuItemGroup className='hover:bg-transparent' header={helpMenuHeader}/>
        <MenuItemGroup className='hover:bg-transparent' header={signOutMenuHeader}/>
      </Menu>
    </VerticalLayout>);
  }
}

SideBar.propTypes = {
  avatar: PropType.string
}

export default SideBar
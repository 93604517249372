import React, {createRef} from 'react';
import {ChevronDown} from 'react-feather';
import ReactDOM from 'react-dom';
import InputBase from "../InputBase";
import BaseComponent from "../../../../../lib/components/BaseComponent";
import PropTypes from "prop-types";
import InputStateType from "../../../../../lib/components/types/StateType";

class InputDropDownBase extends InputBase {

  constructor(props) {
    super(props);
    this.state.showPicker = false;
    this.state.selectedOption = undefined;
    this.state.iconRight = <ChevronDown className='self-center mr-1 smooth-rotate'/>;
    this.state.menuPicker = null;
    this.menuRef = createRef();
    this.searchInputRef = createRef();
  }

  getValue() {
    return this.state.selectedOption.option;
  }

  setValue(optionKey, opsions) {
    let opsion = this.getDisplayValue(optionKey, opsions);
    this.state.selectedOption.option = optionKey;
    this.state.selectedOption.displayValue = opsion.displayValue;
    this.setState(this.state);
  }

  getDisplayValue(optionKey, opsions) {
    let option = {option: '', displayValue: ''};
    for (let index in opsions) {
      if (index === optionKey) {
        option = opsions[index];
        break;
      }
    }
    return option;
  }

  onInputClick = (e) => {
    if (this.state.state !== InputStateType.readonly) {
      if (this._status === BaseComponent.STATUS_ENABLED) {
        if (this.state.showPicker) {
          this.state.showPicker = false;
        } else {
          this.state.showPicker = true;
          setTimeout(() => {
            window.addEventListener('click', this.addBackDrop);
          }, 1);
        }
        this.setState(this.state);
      }
    }
  }

  addBackDrop = (e) => {
    //if a user opens a dialog, opens then the dropdown and closes the dialog
    // then the dom does not have the dropdown anymore so the event can be removed
    if (ReactDOM.findDOMNode(this.menuRef.current) !== null) {
      let wasAnInsideClick = ReactDOM.findDOMNode(this.menuRef.current).contains(e.target);
      if (this.state.showPicker && !wasAnInsideClick) {
        window.removeEventListener('click', this.addBackDrop);
        this.state.showPicker = false;
        this.setState(this.state);
      }
    } else {
      window.removeEventListener('click', this.addBackDrop);
    }
  }

  createPicker(filter, classes, top, left) {
    classes = 'z-60 bg-cn-color-black max-h-[300px] overflow-y-auto animated-dropdown ' +
      (classes !== undefined ? classes : '');
    if (this.state.showPicker) {
      this.state.menuPicker = React.cloneElement(this.props.children, {
        filter: filter,
        onChange: this.onMenuItemChange,
        className: this.props.children.props.className + ' ' + classes,
        ref: this.menuRef
      });
    } else {
      this.state.menuPicker = null;
    }
  }

  onMenuItemChange = (e, option, displayValue) => {
    this.state.selectedOption = {option: option, displayValue: displayValue};
    this.state.showPicker = false;
    window.removeEventListener('click', this.addBackDrop);
    this.setState(this.state);
    if (this.props.onChange !== undefined) {
      this.props.onChange(e, this._id, option);
    }
  }
}

InputDropDownBase.propTypes = {
  className: PropTypes.string,
  classNameOutline: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  inputLayout: PropTypes.oneOf(['inline', 'outline', 'no_container', '']),
  defaultValue: PropTypes.any,
  onChange: PropTypes.func
}
export default InputDropDownBase;

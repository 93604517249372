import Dto from "../../Dto";

class RequestPasswordResetLink extends Dto {

  constructor(email = '', captchaToken) {
    super('po2\\service\\passwordReset\\dto\\RequestPasswordResetLink');
    this.email = email;
    this.captchaToken = captchaToken;
  }
}

export default RequestPasswordResetLink;

import BaseComponent from "../../../../lib/components/BaseComponent";
import BreadCrumbBox from "../../../../lib/components/breadcrumbs/BreadCrumbBox";
import BreadCrumb from "../../../../lib/components/breadcrumbs/BreadCrumb";
import PropTypes from "prop-types";
import {createRef} from "react";
import GF from "../../../../lib/utils/GF";

class WizardNav extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      canOvertakeSteps: GF.getValue(this.props.canOvertakeSteps, false)
    };
    this.breadCrumbRef = [];
    this.breadCrumbRef.push(createRef());
    this.breadCrumbRef.push(createRef());
    this.breadCrumbRef.push(createRef());
    this.breadCrumbRef.push(createRef());
    this.breadCrumbRef.push(createRef());
  }

  goToStep(index) {
    let activeIndex = this.findActiveIndex();
    if (activeIndex !== index) {
      index = this.getNextIndex(activeIndex, index);
      index = this.setBounderies(index, this.breadCrumbRef.length);

      for (let i = 0; i < this.breadCrumbRef.length; i++) {
        this.breadCrumbRef[i].current.state.active = index === i;
      }
      this.props.wizardBase.current.goToStep(index);
      this.setState(this.state);
    }
  }

  getNextIndex(activeIndex, index) {
    activeIndex = parseInt(activeIndex);
    index = parseInt(index);
    if (activeIndex > index) {
      // wird momentan nicht benötigt.
      //index = (this.state.canOvertakeSteps ? index : activeIndex - 1);
    } else {
      index = (this.state.canOvertakeSteps ? index : activeIndex + 1);
    }
    return index;
  }

  setBounderies(index, dataLength) {
    index = parseInt(index);
    if (index >= dataLength) {
      index = 0;
    }
    if (index < 0) {
      index = 0;
    }
    return index;
  }

  findActiveIndex() {
    let index = -1;
    for (let indexFrombreadCrumbArray in this.breadCrumbRef) {
      let breadCrumbRef = this.breadCrumbRef[indexFrombreadCrumbArray];
      if (breadCrumbRef.current.state.active) {
        index = parseInt(indexFrombreadCrumbArray);
        break;
      }
    }
    return index;
  }

  render() {
    return <div className='mb-5'>
      <BreadCrumbBox>
        <BreadCrumb ref={this.breadCrumbRef[0]} onClick={(e) => this.goToStep(0)} active>Liste auswählen</BreadCrumb>
        <BreadCrumb ref={this.breadCrumbRef[1]} onClick={(e) => this.goToStep(1)}>Konfiguration</BreadCrumb>
        <BreadCrumb ref={this.breadCrumbRef[2]} onClick={(e) => this.goToStep(2)}>Versandtermin</BreadCrumb>
        <BreadCrumb ref={this.breadCrumbRef[3]} onClick={(e) => this.goToStep(3)}>Bemerkungen</BreadCrumb>
        <BreadCrumb ref={this.breadCrumbRef[4]} onClick={(e) => this.goToStep(4)}>Übersicht</BreadCrumb>
      </BreadCrumbBox>
    </div>
  }
}

WizardNav.propTypes = {
  wizardBase: PropTypes.object, canOvertakeSteps: PropTypes.bool
}
export default WizardNav;

import React, {Component} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import HorizontalLayout from "../layouts/HorizontalLayout";
import {ChevronsLeft, ChevronsRight} from "react-feather";
import InputSearch from "../../../appl/view/components/inputFields/InputSearch";
import DragAndDrop from "../images/DragAndDrop";
import GF from "../../utils/GF";

class DraggableList extends Component {
  state = {
    list1: this.props.list1, list2: this.props.list2,
  };

  handleItemClick = (itemId, currentList) => {
    const otherList = currentList === 'list1' ? 'list2' : 'list1';
    const item = this.state[currentList].find(item => item.id === itemId);

    const updatedCurrentList = this.state[currentList].filter(item => item.id !== itemId);
    const updatedOtherList = [...this.state[otherList], item];

    this.setState({
      [currentList]: updatedCurrentList, [otherList]: updatedOtherList,
    });
  };

  handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    // Update item positions in state
    const {source, destination} = result;
    const sourceList = source.droppableId === 'list1' ? 'list1' : 'list2';
    const destinationList = destination.droppableId === 'list1' ? 'list1' : 'list2';
    // Check if source and destination lists are the same
    if (sourceList === destinationList) {
      return;
    }
    const sourceItems = [...this.state[sourceList]];
    const destinationItems = [...this.state[destinationList]];

    const [removed] = sourceItems.splice(source.index, 1);
    destinationItems.splice(destination.index, 0, removed);

    this.setState({
      [sourceList]: sourceItems, [destinationList]: destinationItems,
    });
  };

  render() {
    let classes = 'flex justify-between bg-cn-color-black-804 m-2 px-4 cn-font-paragraph py-2 cn-border-radius last:mb-0 first:mt-0 w-full';
    let inputSearch1 = <InputSearch onChange={(e) => {
      let newList = [];
      this.props.list1.map((item) => {
        if (GF.includes(item.text, e.target.value)) {
          newList.push(item);
        }
      });
      this.state.list1 = newList;
      this.setState(this.state);
    }} className='m-2' inputLayout='outline' placeholder='Search'/>;
    let inputSearch2 = <InputSearch onChange={(e) => {
      let newList = [];
      this.props.list2.map((item) => {
        if (GF.includes(item.text, e.target.value)) {
          newList.push(item);
        }
      });
      this.state.list2 = newList;
      this.setState(this.state);
    }} className='m-2' inputLayout='outline' placeholder='Search'/>;
    return (<HorizontalLayout className='gap-x-5 bg-cn-color-black p-2'>
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <div className='w-full'>
          <HorizontalLayout className='justify-between'>
            <h3>Sichtbare Tabellenspalten</h3>
            <button className='cn-font-paragraph text-sm text-cn-color-static-red'>Ansicht zurücksetzen</button>
          </HorizontalLayout>
          {inputSearch1}
          <Droppable droppableId="list1">
            {(provided) => (<div className='w-full' ref={provided.innerRef} {...provided.droppableProps}>
              {this.state.list1.map((item, index) => (<Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className='flex items-center'>
                    <DragAndDrop className='stroke-cn-color-blue-690'/>
                    <div className={classes}>{item.text}
                      <ChevronsRight onClick={() => this.handleItemClick(item.id,
                        'list1')} className='stroke-cn-color-blue-690 cursor-pointer p-0.5'/>
                    </div>
                  </div>)}
              </Draggable>))}
              {provided.placeholder}
            </div>)}
          </Droppable>
        </div>
        <div className='min-h-full w-1 bg-cn-color-primary'/>
        <div className='w-full'>
          <h3>Verfügbare Tabellenspalten</h3>
          {inputSearch2}
          <Droppable droppableId="list2">
            {(provided) => (<div className='w-full' ref={provided.innerRef} {...provided.droppableProps}>
              {this.state.list2.map((item, index) => (<Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className='flex items-center'>
                    <DragAndDrop className='stroke-cn-color-blue-690'/>
                    <div className={classes}>{item.text}
                      <ChevronsLeft onClick={() => this.handleItemClick(item.id,
                        'list2')} className='stroke-cn-color-blue-690 cursor-pointer p-0.5'/>
                    </div>
                  </div>)}
              </Draggable>))}
              {provided.placeholder}
            </div>)}
          </Droppable>
        </div>
      </DragDropContext>
    </HorizontalLayout>);
  }
}

export default DraggableList;

import PropTypes from "prop-types";
import BaseComponent from "../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import Container from "./container/Container";
import InputStateType from "../../../../lib/components/types/StateType";
import InputLayoutType from "./InputLayoutType";
import Validator from "../../../model/validate/Validator";
import GF from "../../../../lib/utils/GF";
import {createRef} from "react";

/**
 * @property string labelText: LabelText
 * @property string state: Werte=disable/error/readonly
 * @property string helpText: Hilfetext
 * @property string use: Verwendung Werte=table oder leer
 */
class InputBase extends BaseComponent {

  constructor(props) {
    super(props);
    this.doValidate = false;
    this.labelElement = (props.labelElement !== undefined && props.labelElement !== null ? props.labelElement : '');
    this.helpTextElement = (props.helpTextElement !== undefined && props.helpTextElement !== null ?
      props.helpTextElement : '');
    this.state = {
      className: GF.getValue(props.className, ''),
      children: GF.getValue(props.children, [<div/>]),
      inputLayout: GF.getValue(props.inputLayout, InputLayoutType.inline),
      defaultValue: GF.getValue(props.defaultValue, ''),
      state: GF.getValue(props.state, InputStateType.none),
      iconLeft: GF.getValue(props.iconLeft, ''),
      iconRight: GF.getValue(props.iconRight, ''),
      useFullHeightInOutlineLayout: true,
      grid: ''
    };
    this.containerRef = createRef();
  }

  buildHelpTextElement(styleText) {
    let helpTextElement = '';
    if (this.helpTextElement !== undefined && this.helpTextElement !== '') {
      if ((typeof this.helpTextElement) === 'string') {
        helpTextElement =
          <div className={'mt-2 cn-font-description02 ' + styleText}><p>{this.helpTextElement}</p></div>;
      } else {
        helpTextElement = this.helpTextElement;
      }
    }
    return helpTextElement;
  }

  buildInlineContentElement(labelElement, backgroundStyle) {
    let bodyCss = 'self-center h-full px-2 w-full';
    if (this.state.useFullHeightInOutlineLayout) {
      bodyCss += ' h-full';
    }
    let inputElementClasses = 'min-h-[52px] ' + backgroundStyle;
    if (GF.checkNotNUllAndNotEmpty(this.state.grid)) {
      inputElementClasses = 'grid grid-rows-1 grid-cols-2 ' + this.state.grid + ' min-h-[52px] ' + backgroundStyle;
    }
    return <HorizontalLayout className={inputElementClasses} grid={this.state.grid !== ''}>
      {this.state.iconLeft}
      <VerticalLayout>
        {labelElement}
        <div className={bodyCss}>{this.state.children}</div>
      </VerticalLayout>
      {this.state.iconRight}
    </HorizontalLayout>;
  }

  buildLabelElement(labelStyle) {
    let labelElement = '';
    if (this.labelElement !== undefined && this.labelElement !== '') {
      if ((typeof this.labelElement) === 'string') {
        labelElement = <div className={labelStyle}>{this.labelElement}</div>;
      } else {
        labelElement = this.labelElement;
      }
    }
    return labelElement;
  }

  buildOutlineContentElement(labelStyle, styleText, backgroundStyle) {
    let bodyCss = 'self-center px-2 w-full';
    if (this.state.useFullHeightInOutlineLayout) {
      bodyCss += ' h-full';
    }
    let inputElementClasses = GF.getValue(this.props.classNameOutline, ' h-full min-h-[35px]') + backgroundStyle;
    if (GF.checkNotNUllAndNotEmpty(this.state.grid)) {
      inputElementClasses = GF.getValue(this.props.classNameOutline,
        ' grid grid-rows-1 grid-cols-2 ' + this.state.grid + ' h-full min-h-[35px]') + backgroundStyle;
    }
    return <HorizontalLayout className={inputElementClasses} grid={this.state.grid !== ''}>
      {this.state.iconLeft}
      <div className={bodyCss}>{this.state.children}</div>
      {this.state.iconRight}
    </HorizontalLayout>
  }

  fireOnChange(e, data = null) {
    if (data === null) {
      if (typeof e === 'object' && e.hasOwnProperty('currentTarget')) {
        data = e.currentTarget.value;
      }
    }
    if (this.props.onChange !== undefined) {
      this.props.onChange(e, this._id, data);
    }
  }

  onValidate(value) {
    if (/*this.doValidate &&*/ this.constrain !== undefined) {
      (new Validator(value, this.constrain.validate, (validatorState) => {
        let origState = this.state.state;
        if (validatorState.success) {
          this.state.state = InputStateType.none;
        } else {
          this.state.state = InputStateType.error;
        }
        if (origState !== this.state.state) {
          this.setState(this.state);
        }
      })).validate();
    }
  }

  onBlur(e) {
    //this.doValidate = true;
    this.onValidate(e);
  }

  setFocus() {
    if (GF.checkNotNull(this.containerRef.current)) {
      this.containerRef.current.bodyRef.current.focus();
    }
  }

  getHeight(element) {
    if (GF.checkNotNull(element)) {
      return element.clientHeight;
    }
    return 0;
  }

  getStylePositionInfo(element, offset = 0) {
    let elementHeight = this.getHeight(element);
    let position = BaseComponent.getPosition(element);
    let top = '';
    let left = '';
    let style = {};
    if (position.top !== -1) {
      top = (position.top + elementHeight + 2 + offset) + 'px';
      left = position.left + 'px';
      style.top = top;
      style.left = left;
      style.zIndex = 60;
    }
    return style;
  }

  render() {
    let contentStyle;
    let labelStyle;
    let styleText;
    let backgroundStyle = '';
    if (this.state.state === InputStateType.error) {
      contentStyle = 'text-cn-color-static-red border-cn-color-static-red border-solid border-[1px] cn-input-base-hover-active-error';
      labelStyle = 'text-cn-color-static-red cn-font-description02';
      styleText = 'text-cn-color-static-red';
    } else if (this.props.state === InputStateType.disable) {
      contentStyle = 'text-cn-color-gray-500 border-cn-color-gray-655 bg-cn-color-black-804 border-solid border-[1px] pointer-events-none';
      labelStyle = 'text-cn-color-gray-500 cn-font-description02';
      styleText = 'text-cn-color-gray-500';
      backgroundStyle = 'bg-cn-color-gray-655 cn-border-radius-8 m-[-1px] w-[calc(100%+2px)]';
    } else if (this.props.state === InputStateType.readonly) {
      contentStyle = 'text-cn-color-white border-cn-color-gray-655 bg-cn-color-black-804 border-solid border-[1px]';
      labelStyle = 'text-cn-color-white cn-font-description02';
      styleText = 'text-cn-color-white';
      backgroundStyle = 'bg-cn-color-gray-655 cn-border-radius-8 m-[-1px] w-[calc(100%+2px)]';
    } else {
      contentStyle = 'text-cn-color-white border-cn-color-input-border border-solid border-[1px] cn-input-base-hover-active';
      labelStyle = 'text-cn-color-blue-690 cn-font-description02';
      styleText = 'text-cn-color-white';
    }
    let outLineLabelElement;
    let helpTextElement;
    let content;

    switch (this.state.inputLayout) {
      case InputLayoutType.outline:
        outLineLabelElement = this.buildLabelElement('mt-1 ' + labelStyle);
        helpTextElement = this.buildHelpTextElement(styleText);
        content = this.buildOutlineContentElement(labelStyle, styleText, backgroundStyle);
        break;
      default:
        let labelElement = this.buildLabelElement('mx-2 mt-1 ' + labelStyle);
        helpTextElement = this.buildHelpTextElement(styleText);
        content = this.buildInlineContentElement(labelElement, backgroundStyle);
        break;
    }

    return <VerticalLayout ref={this.containerRef}>
      {outLineLabelElement}
      <Container state={this.state.state} className={contentStyle + ' ' + this.state.className}>{content}</Container>
      {helpTextElement}
    </VerticalLayout>
  }
}

InputBase.propTypes = {
  className: PropTypes.string,
  classNameOutline: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  inputLayout: PropTypes.oneOf(['inline', 'outline', 'no_container', '']),
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  grid: PropTypes.oneOf([undefined, 'none', 'grid-short', 'grid-long'])
}
export default InputBase;

import BaseComponent from "../../../../lib/components/BaseComponent";
import Table from "../../../../lib/components/table/Table";
import './entity.css'
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import Pager from "./pagination/Pager";
import PropTypes from "prop-types";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import React, {createRef} from 'react';
import Listener from "../../../../lib/model/system/Listener";
import Privilege from "../../../../lib/model/types/Privilege";
import DataType from "../../../../lib/model/types/DataType";
import FieldName from "../../../../lib/model/query/FieldName";
import GF from "../../../../lib/utils/GF";
import EntityColumn from "./EntityColumn";
import EntityRow from "./EntityRow";
import AccordionItem from "../../../../lib/components/accordion/AccordionItem";
import Row from "../../../../lib/components/table/Row";
import Column from "../../../../lib/components/table/Column";
import FilterBuilder from "../extendedFilter/FilterBuilder";
import shortid from "shortid";
import ButtonSmall from "../buttons/ButtonSmall";
import BooleanPredicate from "../../../../lib/model/query/BooleanPredicate";
import FieldPredicate from "../../../../lib/model/query/FieldPredicate";
import Operator from "../../../../lib/model/query/Operator";
import Query from "../../../../lib/model/query/Query";
import CleanPredicates from "./CleanPredicates";
import ColumnBox from "../../../../lib/components/columnBox/ColumnBox";
import Records from "../../../../lib/model/service/entity/dto/Records";
import CM from "../../../model/system/CM";
import AlertDialog from "../../../../lib/components/dialogs/AlertDialog";
import ButtonTab from "../../../../lib/components/tabbar/ButtonTab";
import Download from "./download/Download";
import Upload from "./upload/Upload";
import TableFieldGenerator from "./fields/TableFieldGenerator";
import FilterManager from "../../../../lib/components/filter/FilterManager";
import DialogBase from "../../../../lib/components/dialogs/DialogBase";
import {toast} from "react-toastify";
import InputFieldGenerator from "./fields/InputFieldGenerator";
import SimpleFilterBuilder from "./filter/SimpleFilterBuilder";
import UstoreModuleService from "../../../../lib/model/campaign/modules/ustore/v002/service/UstoreModuleService";
import CampaignService from "../../../model/service/campaign/CampaignService";
import T from "../../../model/system/text_translations/Translator";
import SortField from "../../../../lib/model/query/SortField";
import Resource from "../../../../lib/model/resource/Resource";

class Entity extends BaseComponent {

  constructor(props) {
    super(props);
    this.dataSource = null;
    this.initDatasourceListenerProperties();
    this.initButtonGroupListenerProperties();
    this.state = {
      rows: [], extendedFilter: null, editRow: -1, filterManagerDialog: null
    };
    this.config = GF.getValue(this.props.config, null);
    this.numOfColumns = -1;
    this.hasRecords = false;
    this.hasRecordsInGrid = false;
    this.simpleFilterIsActive = false;
    this.tableRef = createRef();
    this.filterBuilderRef = createRef();
    this.entityContainerRef = createRef();
    this.pagerRef = createRef();
    this.simpleFilterFieldList = [];
    this.detailFieldList = [];
    this.tableCmd = null;
    this.tableFieldGenerator = GF.getValue(this.props.tableFieldGenerator, new TableFieldGenerator());
    this.editFieldGenerator = GF.getValue(this.props.editFieldGenerator, new InputFieldGenerator());
    this.editModeState = 'close';
    this.simpleFilterBuilder = new SimpleFilterBuilder(this);
    this.filterManagerRef = createRef();
    this.simpleFilterTimerActive = 0;
    this.filterManagerDialogRef = createRef();
    this.uploadRef = createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.props.dataSource !== undefined && this.props.dataSource !== null) {
      this.dataSource = this.props.dataSource;
      this.initConfig();
      this.initTable();
      this.doAddDatasourceListeners();
      if (this.props.autoload === undefined || this.props.autoload === true) {
        this.dataSource.read();
      }
    } else {
      this.state.rows = GF.getValue(this.props.children, []);
    }
    this.doAddButtonGroupListeners();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.doRemoveDatasourceListeners();
    this.doRemoveButtonGroupListeners();
  }

  addRowBelow(rowIndex, mode) {
    let entityContainer = this.entityContainerRef.current;
    entityContainer.initBasePosition();
    let fields = this.generateEditFields(rowIndex, mode);
    const newRow = <Row rowIndex={-1} key={shortid()}>
      <Column colSpan={this.numOfColumns} className='bg-cn-color-black'>
        {/*-3 damit es nicht überfliesst*/}
        <AccordionItem opened styleValue={{width: entityContainer.position.width - 3}}>
          <ColumnBox>
            {fields}
          </ColumnBox>
          <HorizontalLayout className='justify-end p-2 gap-4'>
            <ButtonSmall onClick={(e) => this.onEditAbort(e)}>{T.label_cancel()}</ButtonSmall>
            <ButtonSmall color='secondary' onClick={(e) => this.onEditSave(e, mode)}>{T.label_save()}</ButtonSmall>
          </HorizontalLayout>
        </AccordionItem>
      </Column>
    </Row>;

    this.setState((prevState) => {
      const rows = [...prevState.rows];
      rows.splice(rowIndex + 1, 0, newRow);
      return {rows};
    });
  }

  onSimpleFilterChange(e) {
    let self = this;
    setTimeout(() => {
      this.simpleFilterTimerActive--;
      if (this.simpleFilterTimerActive === 0) {
        self.doReadTable();
      }
    }, 500);
    this.simpleFilterTimerActive++;
  }

  buildTableEntries() {
    this.hasRecordsInGrid = false;
    let records = this.dataSource.getRecords();
    this.hasRecordsInGrid = records.length > 0;
    for (let i = 0; i < records.length; i++) {
      let record = records[i];
      let columns = [];
      let recId = -1;
      for (let fieldName in record) {
        // let field = fieldName.split('#')[1];
        // if (!field.startsWith('sys', 0)) {
        let fieldValue = record[fieldName];
        let fieldType = this.getFieldTypeByName(fieldName);
        if (fieldType !== null) {
          if (fieldType.type !== DataType.OBJECT) {
            columns.push(this.initField(fieldType, fieldName, fieldValue));
          }
          if (fieldType.isKey) {
            recId = parseInt(fieldValue);
          }
        }
        // }
      }
      let row = <EntityRow key={shortid()} parent={this} onDoubleClick={(rowIndex, entityRow) => this.onRowDoubleClick(
        rowIndex, entityRow)} rowIndex={i + 1} recId={recId}>{columns}</EntityRow>;
      this.state.rows.push(row);
    }
    if (!this.hasRecordsInGrid) {
      let row = <Row rowIndex={1} key={shortid()} recId={1}>
        <Column colSpan={this.numOfColumns} className='border-0'>
          <HorizontalLayout className='justify-around table-row-empty-record'>
            <div className='bg-cn-color-white-960 text-cn-color-black-804 mt-1 mb-1 p-2 rounded-[10px]'>Keine
                                                                                                        Daten
                                                                                                        zum
                                                                                                        anzeigen
            </div>
          </HorizontalLayout>
        </Column>
      </Row>;
      this.state.rows.push(row);
      this.dataSource.setCurrentRecIds(1);
    }
    this.pagerRef.current.setTotalRows(this.dataSource.getTotalRows());
  }

  getKeyField(columns) {
    for (let fullFieldNameString in columns) {
      let column = columns[fullFieldNameString];

      if (column.type.isKey) {
        return {id: fullFieldNameString, column: column};
      }
    }
    return null;
  }

  getFieldsForQuery() {
    let fields = [];
    for (let fieldName in this.config.columns) {
      let column = this.config.columns[fieldName];
      if (column.type.type !== DataType.OBJECT && column.type.type !== DataType.DATA_EXT) {
        fields.push(fieldName);
      }
    }
    return fields;
  }

  getFieldType(id) {
    let column = this.config.columns[id];
    return column.type;
  }

  getRowIdByRecId(recId) {
    let rowId = -1;
    for (let index in this.state.rows) {
      let row = this.state.rows[index];

      if (row.props.rowType !== Row.ROW_TYPE_HEADER && row.props.rowType !== Row.ROW_TYPE_SIMPLE_FILTER && recId ===
        row.props.recId) {

        rowId = parseInt(index);
        break;
      }
    }
    return rowId;
  }

  onAbortFilter() {
    this.doOpenFilter();
  }

  buildQuery() {
    let predicates = this.filterBuilderRef.current.getFilterPredicates();
    predicates = this.cleanPredicates(predicates);
    return new Query(this.getFieldsForQuery(), predicates);
  }

  onDoFilter() {
    this.dataSource.read(this.buildQuery());
  }

  cleanPredicates(predicates) {
    return (new CleanPredicates()).iteratePredicates(predicates);
  }

  createFilterBuilder(columns, queryPredicates = null) {
    let keyColumn = this.getKeyField(columns);
    let fieldName = FieldName.create(keyColumn.id);
    fieldName['label'] = keyColumn.column.label;

    if (queryPredicates === null) {
      queryPredicates = new BooleanPredicate();
      queryPredicates.addPredicate(new FieldPredicate(fieldName));
    }
    return <VerticalLayout>
      <FilterBuilder ref={this.filterBuilderRef} selectFields={columns} queryPredicates={queryPredicates} defaultFieldPredicate={{
        fieldNameValue: keyColumn.id,
        fieldLabelValue: keyColumn.column.label,
        fieldValue: '',
        operatorValue: Operator.eq,
        _key: ''
      }}/>
      <HorizontalLayout className='justify-between pt-2 pb-2'>
        <HorizontalLayout className='justify-start gap-4' widthFit>
          <ButtonSmall className='bg-cn-color-blue-690' onClick={(e) => {
            let entityName = this.dataSource.getEntityDef().baseDef.name.toLowerCase().replace('\\', '/');
            this.state.filterManagerDialog =
              <DialogBase ref={this.filterManagerDialogRef} title='Gespeicherte Filter' onCloseButtonClick={() => {
                this.state.filterManagerDialog = null;
                this.setState(this.state);
              }}><FilterManager ref={this.filterManagerRef} entityName={entityName} entity={this} campaignId={this.props.campaignId} query={this.buildQuery()} onDoFilter={(e) => this.applyFilter(
                e)} parentRef={this.filterManagerDialogRef}/>
              </DialogBase>;
            this.setState(this.state);
            // this.filterBuilderRef.current.saveFilter();
          }}>Filtermanager</ButtonSmall>
          <ButtonSmall className='text-gray-500' disabled>Favoriten</ButtonSmall>
        </HorizontalLayout>
        <HorizontalLayout className='justify-end gap-4' widthFit>
          <ButtonSmall onClick={(e) => this.onAbortFilter(e)}>Abbrechen</ButtonSmall>
          <ButtonSmall className='bg-cn-color-blue-690' onClick={(e) => this.onDoFilter(e)}>Filter
                                                                                            anwenden</ButtonSmall>
        </HorizontalLayout>
      </HorizontalLayout>
    </VerticalLayout>;
  }

  applyFilter(e) {
    let currentRecords = this.filterManagerRef.current.dataSource.getCurrentRecords();
    if (currentRecords.length > 0) {
      let listName = currentRecords[0].listName;
      let filterQuery = this.filterManagerRef.current.getFilter(listName);
      if (filterQuery !== undefined && filterQuery !== null) {
        this.filterManagerDialogRef.current.closeDialog();
        this.filterBuilderRef.current.resetFilter(filterQuery.predicate);
        setTimeout(() => {
          this.onDoFilter();
        }, 1)
      }
    }
  }

  doUstoreModuleServiceRequest(dto, campaignId, callBack) {
    dto.setProperties(CampaignService.createCampaignRedirectProperty(campaignId));
    CM.get().doJsonAjax(CM.get().getServiceUrl(UstoreModuleService.NAME), dto, (response) => {
      if (response.success) {
        callBack(response);
      } else {
        toast.error(response.message);
      }
    });
  }

  doAddDatasourceListeners() {
    if (GF.checkNotNull(this.dataSource)) {
      this.dataSource.dataSourceEvents.addOnReadDoneListener(this.addOnReadDoneListener);
      this.dataSource.dataSourceEvents.addOnRowChangeListener(this.addOnRowChangeListener);
      this.dataSource.dataSourceEvents.addOnUpdateDoneListener(this.addOnUpdateDoneListener);
      this.dataSource.dataSourceEvents.addOnCreateDoneListener(this.addOnCreateDoneListener);
      this.dataSource.dataSourceEvents.addOnDeleteDoneListener(this.addOnDeleteDoneListener);
      this.dataSource.dataSourceEvents.addOnDuplicateDoneListener(this.addOnDuplicateDoneListener)
      this.dataSource.dataSourceEvents.addOnErrorListener(this.addOnErrorListener);
    }
  }

  doRemoveDatasourceListeners() {
    if (GF.checkNotNull(this.dataSource)) {
      this.dataSource.dataSourceEvents.removeOnReadDoneListener(this.addOnReadDoneListener);
      this.dataSource.dataSourceEvents.removeOnRowChangeListener(this.addOnRowChangeListener);
      this.dataSource.dataSourceEvents.removeOnUpdateDoneListener(this.addOnUpdateDoneListener);
      this.dataSource.dataSourceEvents.removeOnCreateDoneListener(this.addOnCreateDoneListener);
      this.dataSource.dataSourceEvents.removeOnDeleteDoneListener(this.addOnDeleteDoneListener);
      this.dataSource.dataSourceEvents.removeOnDuplicateDoneListener(this.addOnDuplicateDoneListener)
      this.dataSource.dataSourceEvents.removeOnErrorListener(this.addOnErrorListener);
    }
  }

  doAddButtonGroupListeners() {
    if (GF.checkNotNull(this.props.headerButtons) && GF.checkNotNull(this.props.headerButtons.ref) &&
      GF.checkNotNull(this.props.headerButtons.ref.current)) {

      this.props.headerButtons.ref.current.tableEvents.addOnRefreshListener(this.onRefreshListener);
      this.props.headerButtons.ref.current.tableEvents.addOnFilterListener(this.onFilterListener);
      this.props.headerButtons.ref.current.tableEvents.addOnSimpleSearchListener(this.onSimpleSearchListener);
      this.props.headerButtons.ref.current.tableEvents.addOnSettingsListener(this.onSettingsListener);
      this.props.headerButtons.ref.current.tableEvents.addOnAddRecordListener(this.onAddRecordListener);
      this.props.headerButtons.ref.current.tableEvents.addOnEditRecordListener(this.onEditRecordListener);
      this.props.headerButtons.ref.current.tableEvents.addOnDeleteRecordListener(this.onDeleteRecordListener);
      this.props.headerButtons.ref.current.tableEvents.addOnImportListener(this.onImportListener);
      this.props.headerButtons.ref.current.tableEvents.addOnExportListener(this.onExportListener);
      this.props.headerButtons.ref.current.tableEvents.addOnDuplicateRecordsListener(this.onDuplicateRecordsListener);
    }
  }

  doRemoveButtonGroupListeners() {
    if (GF.checkNotNull(this.props.headerButtons) && GF.checkNotNull(this.props.headerButtons.ref) &&
      GF.checkNotNull(this.props.headerButtons.ref.current)) {

      this.props.headerButtons.ref.current.tableEvents.removeOnRefreshListener(this.onRefreshListener);
      this.props.headerButtons.ref.current.tableEvents.removeOnFilterListener(this.onFilterListener);
      this.props.headerButtons.ref.current.tableEvents.removeOnSimpleSearchListener(this.onSimpleSearchListener);
      this.props.headerButtons.ref.current.tableEvents.removeOnSettingsListener(this.onSettingsListener);
      this.props.headerButtons.ref.current.tableEvents.removeOnAddRecordListener(this.onAddRecordListener);
      this.props.headerButtons.ref.current.tableEvents.removeOnEditRecordListener(this.onEditRecordListener);
      this.props.headerButtons.ref.current.tableEvents.removeOnDeleteRecordListener(this.onDeleteRecordListener);
      this.props.headerButtons.ref.current.tableEvents.removeOnImportListener(this.onImportListener);
      this.props.headerButtons.ref.current.tableEvents.removeOnExportListener(this.onExportListener);
      this.props.headerButtons.ref.current.tableEvents.removeOnDuplicateRecordsListener(
        this.onDuplicateRecordsListener);
    }
  }

  doClearCache() {
    this.dataSource.doClearCache();
  }

  doReadTable(filterQuery = null) {
    this.doClearCache();
    this.dataSource.read(filterQuery);
  }

  doOpenFilter() {
    if (this.state.extendedFilter === null) {
      this.state.extendedFilter = this.createFilterBuilder(this.config.columns);
    } else {
      this.state.extendedFilter = null;
      this.dataSource.read(new Query(this.getFieldsForQuery()));
    }
    this.setState(this.state);
  }

  doOpenSimpleFilter() {
    this.simpleFilterIsActive = !this.simpleFilterIsActive;
    if (this.simpleFilterIsActive) {
      for (let index in this.config.columns) {
        let column = this.config.columns[index];
        column.simpleFieldValue = null;
      }
      this.initTable();
    } else {
      this.dataSource.read(new Query(this.getFieldsForQuery()));
    }
  }

  doOpenSettings() {

  }

  doAddRecord() {
    this.toggleEditMode(null, 'add');
  }

  doEditRecord() {
    if (this.hasRecordsInGrid) {
      this.toggleEditMode(null, 'edit');
    } else {
      toast.warning('Es hat keine Datensätze!');
    }
  }

  doDeleteRecord_onCloseButtonClick(e) {
    if (e !== undefined) {
      let fiberNode = GF.findReactFiberNode(e);
      if (fiberNode !== null && fiberNode.pendingProps !== null && fiberNode.pendingProps.children !== null &&
        fiberNode.pendingProps.children.length === 2) {
        if (fiberNode.pendingProps.children[1] === T.label_duplicate()) {
          this.doReadTable();
        }
      }
    }
    this.tableCmd = null;
  }

  doDeleteRecord(e) {
    if (this.tableCmd === null) {
      if (this.hasRecordsInGrid) {
        this.tableCmd = 'delete';
        let dialogDeleteQuestion = createRef();
        let currentRecIds = this.dataSource.getCurrentRecIds();
        let messagePartRecords = '';
        if (currentRecIds.length > 1) {
          messagePartRecords = 'die ausgewählten Records [' + currentRecIds.join(',') + ']';
        } else {
          messagePartRecords = 'den ausgewählten Record [' + currentRecIds.join(',') + ']';
        }
        let question = <VerticalLayout>
          <div>{'Möchten Sie ' + messagePartRecords + ' wirklich löschen?'}</div>
          <HorizontalLayout className='justify-end gap-x-5 mt-5'>
            <ButtonSmall onClick={(e) => {
              dialogDeleteQuestion.current.onCloseButtonClick(e);
              this.tableCmd = null;
            }}>Nein</ButtonSmall>
            <ButtonSmall color='red' onClick={() => {
              dialogDeleteQuestion.current.onCloseButtonClick(e);
              this.dataSource.deleteRows(new Records(this.dataSource.getCurrentRecords()));
            }}>Ja</ButtonSmall>
          </HorizontalLayout>
        </VerticalLayout>;

        CM.get().setMainDialog(<AlertDialog ref={dialogDeleteQuestion} errorTitle='Löschen?' errorMessage={question}/>,
          (e) => this.doDeleteRecord_onCloseButtonClick(e));
      } else {
        toast.warning('Es hat keine Datensätze!');
      }
    }
  }

  doImport() {
    this.uploadTabId = this.props.tabContainer.addElement(<ButtonTab>{(this.props.campaignId !== undefined &&
        this.props.campaignId !== null && this.props.campaignId !== '' ? this.props.campaignId + ':' : '') +
        'import'}</ButtonTab>,
      <Upload ref={this.uploadRef} entity={this} dataSource={this.dataSource} campaignId={this.props.campaignId}/>);
  }

  doExport() {
    if (this.hasRecordsInGrid) {
      this.props.tabContainer.addElement(<ButtonTab>{(this.props.campaignId !== undefined && this.props.campaignId !==
        null && this.props.campaignId !== '' ? this.props.campaignId + ':' : '') + 'exportieren'}</ButtonTab>,
        <Download dataSource={this.dataSource}/>);
    } else {
      toast.warning('Es hat keine Datensätze!');
    }
  }

  doDuplicateRecords(e) {
    if (this.tableCmd === null) {
      if (this.hasRecordsInGrid) {
        this.tableCmd = 'duplicate';
        let dialogDuplicateQuestion = createRef();
        let currentRecIds = this.dataSource.getCurrentRecIds();
        let messagePartRecords = '';
        if (currentRecIds.length > 1) {
          messagePartRecords = 'die ausgewählten Records [' + currentRecIds.join(',') + ']';
        } else {
          messagePartRecords = 'den ausgewählten Record [' + currentRecIds.join(',') + ']';
        }
        let question = <VerticalLayout>
          <div>{'Möchten Sie ' + messagePartRecords + '  duplizieren?'}</div>
          <HorizontalLayout className='justify-end gap-x-5 mt-5'>
            <ButtonSmall onClick={(e) => {
              dialogDuplicateQuestion.current.onCloseButtonClick(e);
              this.tableCmd = null;
            }}>{T.label_cancel()}</ButtonSmall>
            <ButtonSmall color='secondary' onClick={(e) => {
              dialogDuplicateQuestion.current.onCloseButtonClick(e);
              this.dataSource.duplicateRows(new Records(this.dataSource.getCurrentRecords()));
            }}>{T.label_duplicate()}</ButtonSmall>
          </HorizontalLayout>
        </VerticalLayout>;

        CM.get().setMainDialog(
          <AlertDialog ref={dialogDuplicateQuestion} errorTitle='Duplizieren von ausgewählten Records' errorMessage={question}/>,
          (e) => this.doDeleteRecord_onCloseButtonClick(e));
      } else {
        toast.warning('Es hat keine Datensätze!');
      }
    }
  }

  generateEditFields(rowIndex, mode) {
    let fields = [];
    let records = this.dataSource.getCurrentRecords();
    let record = null;
    if (records.length) {
      record = records[0];
    }
    this.detailFieldList = [];
    for (let fieldName in this.config.columns) {
      let column = this.config.columns[fieldName];
      // TODO: faha added this if so the sys fields are not shown. should be done serverside but as of now temporary
      // peto: muss die EntityDescription zur hilfe genommen werden, um herauszufinden, welche Felder gezeigt werden und welche nicht.
      // let field = fieldName.split('#')[1];
      // if (field === undefined || !field.startsWith('sys', 0)) {
      if (column.type.type !== DataType.OBJECT && (mode === 'edit' || !column.type.isKey)) {
        let fieldValue = (record !== null ? record[fieldName] : '');
        fields.push(this.initDetailField(fieldName, (mode === 'edit' ? fieldValue : ''), column.type, column.label, column.privilege.privilege));
      }
      // }
    }
    return fields;
  }

  getColumnConfig(fieldName) {
    let config = null;
    if (this.config.columns.hasOwnProperty(fieldName)) {
      config = this.config.columns[fieldName];
    }
    return config;
  }

  getFieldTypeByName(fieldName) {
    let type = null;
    let config = this.getColumnConfig(fieldName);
    if (config !== null) {
      type = config.type;
    }
    return type;
  }

  initConfig() {
    this.config = {columns: {}};
    let entityDef = this.dataSource.getEntityDef();
    for (let fieldName in entityDef.fieldsDef) {
      let fieldDef = entityDef.fieldsDef[fieldName];
      let canView = Privilege.isPrivilegeAllowed(fieldDef.privilege.privilege, Privilege.ALLOW_VIEW);

      if (canView && fieldDef.type.type !== DataType.OBJECT) {
        fieldDef.type.privilege = fieldDef.privilege;
        let fullFieldName = (new FieldName(fieldName, entityDef.baseDef.name)).getFullFieldName();
        this.config.columns[fullFieldName] = {
          label: fieldDef.description, type: fieldDef.type, privilege: fieldDef.privilege, simpleFieldValue: null
        };
      }
    }
    this.numOfColumns = Object.keys(this.config.columns).length;
  }

  initField(fieldType, id, fieldValue, fieldLabel = null, fieldRef = null) {
    let dtoProperties = null;
    if (this.props.campaignId) {
      dtoProperties = CampaignService.createCampaignRedirectProperty(this.props.campaignId);
    }
    let field = this.tableFieldGenerator.initField(fieldType, id, fieldValue, fieldLabel, fieldRef, dtoProperties);
    return <EntityColumn key={shortid()}>{field}</EntityColumn>;
  }

  initDetailField(fieldName, fieldValue, fieldType, fieldLabel, privilege) {
    let tempRef = createRef();
    let dtoProperties = null;
    if (this.props.campaignId) {
      dtoProperties = CampaignService.createCampaignRedirectProperty(this.props.campaignId);
    }
    let field = this.editFieldGenerator.initField(fieldType, fieldName, fieldValue, fieldLabel, tempRef, dtoProperties, privilege);
    this.detailFieldList.push(tempRef);
    return field;
  }

  initDatasourceListenerProperties() {
    this.addOnReadDoneListener = new Listener((records) => this.onReadDone(records));
    this.addOnRowChangeListener = new Listener((records) => this.onRowChangeDone(records));
    this.addOnUpdateDoneListener = new Listener((records) => this.onUpdateRowsDone(records));
    this.addOnCreateDoneListener = new Listener((records) => this.onCreateRowsDone(records));
    this.addOnDeleteDoneListener = new Listener((records) => this.onDeleteRowsDone(records));
    this.addOnErrorListener = new Listener((records) => this.onDatasourceError(records));
    this.addOnDuplicateDoneListener = new Listener((records) => this.onDuplicateDone(records));
  }

  initButtonGroupListenerProperties() {
    this.onRefreshListener = new Listener(() => this.doReadTable());
    this.onFilterListener = new Listener(() => this.doOpenFilter());
    this.onSimpleSearchListener = new Listener(() => this.doOpenSimpleFilter());
    this.onSettingsListener = new Listener(() => this.doOpenSettings());
    this.onAddRecordListener = new Listener(() => this.doAddRecord());
    this.onEditRecordListener = new Listener(() => this.doEditRecord());
    this.onDeleteRecordListener = new Listener(() => this.doDeleteRecord());
    this.onImportListener = new Listener(() => this.doImport());
    this.onExportListener = new Listener(() => this.doExport());
    this.onDuplicateRecordsListener = new Listener(() => this.doDuplicateRecords());
  }

  initTable() {
    this.state.rows = [];
    if (this.simpleFilterIsActive) {
      this.state.rows.push(this.simpleFilterBuilder.buildFilterRow());
    }
    this.state.headerRow = this.initHeaderRow();
    this.state.rows.push(this.state.headerRow);
    this.buildTableEntries();
    this.setState(this.state);
  }

  initHeaderRow() {
    if (this.state.headerRow === undefined) {
      let columns = [];
      for (let columnName in this.config.columns) {
        let column = this.config.columns[columnName];
        columns.push(
          <EntityColumn key={shortid()} showSort onSortChange={(e, colOption, sortOption) => this.onChangeSort(e,
            colOption, sortOption)} className='whitespace-nowrap' colOption={columnName}>{column.label}</EntityColumn>);
      }
      return <EntityRow key={shortid()} parent={this} rowType={Row.ROW_TYPE_HEADER}>{columns}</EntityRow>;
    }
    return this.state.headerRow;
  }

  onChangeSort(e, columnName, sortOption) {
    let query = this.dataSource.getLastQuery();
    let found = false;
    for (let i = 0; i < query.getSortFields().length; i++) {
      let sortField = query.getSortFields()[i];
      if (sortField.getFieldName() === columnName) {
        if (sortOption === SortField.NONE) {
          query.getSortFields().splice(i, 1);
        }
        sortField.setDirection(sortOption);
        found = true;
        break;
      }
    }
    if (!found) {
      let sort = new SortField(columnName, sortOption);
      query.getSortFields().push(sort);
    }
    this.dataSource.read(query);
  }

  onPageChanged = (e, actualPage, pageSize) => {
    let query = this.dataSource.getLastQuery();
    let start = actualPage * pageSize - pageSize;
    let end = actualPage * pageSize - 1;
    query.setStartRow(start);
    query.setEndRow(end);

    this.dataSource.read(query);
  }

  onEditAbort(e) {
    this.removeRow(this.state.editRow);
  }

  onEditSave(e, mode) {
    if (this.tableCmd === null) {
      this.tableCmd = 'save';
      let record = {};

      // hole Daten
      for (let index in this.detailFieldList) {
        let fieldRef = this.detailFieldList[index];
        let id = fieldRef.current.getId();
        let fieldType = this.getFieldType(id);
        if (mode !== 'add' || !fieldType.type.isKey) {
          record[id] = fieldRef.current.getValue();
        }
      }
      this.prepareUploadFields(record, () => {
        this.prepareFieldValuesForServer(record);
        switch (mode) {
          case 'edit':
            this.dataSource.updateRows(new Records([record]));
            break;
          case 'add':
            this.dataSource.createRows(new Records([record]));
            break;
          default:
            break;
        }
        this.tableCmd = null;
        this.editModeState = 'close';
        this.setStatusOnButtonGroup(BaseComponent.STATUS_ENABLED);
      });
    }
  }

  loopThroughDataExtFields(record, doSomting) {
    for (let id in record) {
      let fieldValue = record[id];
      let fieldType = this.getFieldType(id);
      switch (fieldType.type) {
        case DataType.DATA_EXT:
          doSomting(id, fieldValue);
          break;
      }
    }
  }

  prepareUploadFields(record, prepareDoneCallback) {
    let collectedFiles = [];
    this.loopThroughDataExtFields(record, (id, fieldValue) => {
      if (typeof fieldValue === 'object') {
        collectedFiles.push(fieldValue);
      }
    });
    if (collectedFiles.length > 0) {
      let dtoProperties = CampaignService.createCampaignRedirectProperty(this.props.campaignId);
      let resource = new Resource(dtoProperties);

      resource.doManageResources(collectedFiles, () => {
        prepareDoneCallback();
      })
    } else {
      prepareDoneCallback();
    }
  }

  prepareFieldValuesForServer(record) {
    this.loopThroughDataExtFields(record, (id, fieldValue) => {
      record[id] = fieldValue.getResourceIdAndName();
    });
  }

  onDatasourceError(response) {
    let message = '';
    if (GF.checkNotNull(response.message)) {
      message = response.message;
    } else {
      message = response;
    }
    toast.error(message);
  }

  onCreateRowsDone(event) {
    this.dataSource.read();
    this.tableCmd = null;
    this.state.editRow = -1;
  }

  onUpdateRowsDone(response) {
    this.onReadDone(response);
    this.tableCmd = null;
    this.state.editRow = -1;
  }

  onDeleteRowsDone(event) {
    this.dataSource.read();
    this.state.editRow = -1;
  }

  onRowChangeDone(event) {
    this.markRowsActive();
    this.setState(this.state);
    this.state.editRow = -1;
  }

  markRowsActive() {
    let currentRecIds = this.dataSource.getCurrentRecIds();
    let rowsNew = [];

    for (let index in this.state.rows) {
      let row = this.state.rows[index];
      if (row.props.rowType !== Row.ROW_TYPE_HEADER && row.props.rowType !== Row.ROW_TYPE_SIMPLE_FILTER) {
        let rowIsActive = false;
        for (let index in currentRecIds) {
          let currentRecId = currentRecIds[index];
          if (currentRecId === row.props.recId) {
            rowIsActive = true;
            break;
          }
        }
        row = React.cloneElement(row, {
          isActive: rowIsActive
        });
      }
      rowsNew.push(row);
    }
    this.state.rows = rowsNew;
    this.setState(this.state);
  }

  onRowDoubleClick(rowIndex, entityRow) {
    this.toggleEditMode(rowIndex, 'edit');
  }

  onReadDone(response) {
    this.hasRecords = response.data.totalRows > 0;
    this.initTable();
    this.dataSource.setCurrentRecIds(this.dataSource.getCurrentRecIds());
    this.setState(this.state);
    this.tableCmd = null;
  }

  removeRow(rowIndex) {
    rowIndex++;
    this.setState((prevState) => {
      const rows = [...prevState.rows];
      rows.splice(rowIndex, 1);
      return {rows};
    });
    this.state.editRow = -1;
    this.editModeState = 'close';
    this.setStatusOnButtonGroup(BaseComponent.STATUS_ENABLED);
  };

  setStatusOnButtonGroup(status) {
    if (this.props.headerButtons !== undefined && this.props.headerButtons !== null) {
      BaseComponent.loopThrowChildren(this.props.headerButtons, (node) => {
        if (node !== undefined && node !== null && node.ref !== undefined && node.ref !== null && node.ref.current !==
          undefined && node.ref.current !== null) {

          node.ref.current._status = status;
        }
      });
    }
  }

  toggleEditMode(rowIndex = null, mode = 'edit') {
    if (this.props.readonly === undefined || this.props.readonly === null || this.props.readonly === false) {
      if (this.editModeState === 'close') {
        this.editModeState = 'open';
        this.setStatusOnButtonGroup(BaseComponent.STATUS_DISABLED);
        // this.props.headerButtons.ref.current.setStatus(TableButtonGroup.STATUS_INACTIVE);
        let currentRecId = this.dataSource.getCurrentRecIds()[0];
        if (rowIndex === null) {
          rowIndex = this.getRowIdByRecId(currentRecId);
        }
        rowIndex = parseInt(rowIndex);
        if (this.state.editRow === -1) {
          this.state.editRow = rowIndex;
          this.addRowBelow(rowIndex, mode);
        } else {
          this.removeRow(this.state.editRow);
        }
      }
    }
  }

  render() {
    let headerButtonsFromProps;
    if (this.props.headerButtons !== undefined && this.props.headerButtons !== null) {
      headerButtonsFromProps = this.props.headerButtons;
    }
    let functionElementsFromProps;
    if (this.props.functionElements !== undefined && this.props.functionElements !== null) {
      functionElementsFromProps = this.props.functionElements;
    }
    let headerButtons;
    if (this.props.headerButtons !== undefined || this.props.functionElements !== null) {
      headerButtons = <HorizontalLayout className='justify-between'>
        {headerButtonsFromProps}
        {functionElementsFromProps}
      </HorizontalLayout>;
    }

    return (<VerticalLayout ref={this.entityContainerRef} className='gap-y-4'>
      {this.state.saveFilterDialog && this.state.saveFilterDialog}
      {headerButtons}
      {this.state.extendedFilter}
      {this.state.filterManagerDialog}
      <Table ref={this.tableRef} className='table'>
        {this.state.rows}
      </Table>
      <Pager ref={this.pagerRef} onPageChanged={(e, actualPage, pageSize) => this.onPageChanged(e, actualPage,
        pageSize)}/>
    </VerticalLayout>);
  }

  onDuplicateDone(records) {
    this.dataSource.read();
    this.state.editRow = -1;
    toast.success('Kopieren erfolgreich.')
  }
}

Entity.propTypes = {
  parent: PropTypes.object,
  tabContainer: PropTypes.object,
  dataSource: PropTypes.object,
  config: PropTypes.object,
  campaignId: PropTypes.any,
  headerButtons: PropTypes.node,
  functionElements: PropTypes.node,
  autoload: PropTypes.bool,
  tableFieldGenerator: PropTypes.object,
  editFieldGenerator: PropTypes.object,
  readonly: PropTypes.bool
}
export default Entity

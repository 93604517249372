import Dto from "../../../../../../service/Dto";

class UpdateList extends Dto {

  listName = '';
  query = '';
  useQuery = false;

  constructor(listName = '', query = '', useQuery = false, _class = 'campaign\\module\\modules\\ustore\\v002\\service\\dto\\UpdateList') {
    super(_class);
    this.listName = listName;
    this.query = query;
    this.useQuery = useQuery;
  }

  getListName() {
    return this.userName;
  }

  setListName(value) {
    this.userName = value;
  }

  getQuery() {
    return this.query;
  }

  setQuery(query) {
    this.query = query;
  }

  getUseQuery() {
    return this.useQuery;
  }

  setUseQuery(useQuery) {
    this.useQuery = useQuery;
  }
}

export default UpdateList;

import shortid from "shortid";
import BaseFieldGenerator from "./BaseFieldGenerator";
import React from "react";
import ColumnLine from "../../../../../lib/components/columnBox/ColumnLine";
import {CheckSquare, Square} from "react-feather";
import ALink from "../../../../../lib/components/Link/ALink";
import InputFieldGenerator from "./InputFieldGenerator";

class DetailFieldGenerator extends BaseFieldGenerator {

  initBooleanField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    let field;
    if (fieldValue === true || fieldValue.toLowerCase() === 'ja' || fieldValue.toLowerCase() === 'yes') {
      field = <ColumnLine key={shortid()} label={fieldLabel} value={<CheckSquare/>}></ColumnLine>;
    } else {
      field = <ColumnLine key={shortid()} label={fieldLabel} value={<Square/>}></ColumnLine>;
    }
    return field;
  }

  initIntegerField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <ColumnLine key={shortid()} label={fieldLabel} value={fieldValue}></ColumnLine>;
  }

  initFloatField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return this.initIntegerField(id, fieldRef, fieldValue, fieldLabel, privilege);
  }

  initTextAreaField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return this.initTextField(id, fieldRef, fieldValue, fieldLabel, privilege);
  }

  initPasswordField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return this.initTextField(id, fieldRef, fieldValue, fieldLabel, privilege);
  }

  initHtmlField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return this.initTextField(id, fieldRef, fieldValue, fieldLabel, privilege);
  }

  initLinkField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    let field;
    if (fieldValue.substring(0, 16) === '{&quot;href&quot' || fieldValue.substring(0, 9) === '{"href":"') {
      let linkObj = InputFieldGenerator.extractLink(fieldValue);
      field = <ColumnLine key={shortid()} label={fieldLabel} value={<ALink target='_blank' title={linkObj.text} href={linkObj.href}/>}></ColumnLine>;
    } else {
      field = <ColumnLine key={shortid()} label={fieldLabel} value={<ALink title={fieldValue} href="#"/>}></ColumnLine>;
    }
    return field;
  }

  initTextField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <ColumnLine key={shortid()} label={fieldLabel} value={fieldValue}></ColumnLine>;
  }

  initDateTimePickerField(id, fieldRef, fieldValue, fieldLabel, privilege, useTime) {
    return this.initTextField(id, fieldRef, fieldValue, fieldLabel, privilege);
  }

  initEnumField(fieldType, id, fieldRef, fieldValue, fieldLabel, privilege) {
    return this.initTextField(id, fieldRef, fieldType.valueMap[fieldValue], fieldLabel, privilege);
  }

  initResourceField(id, fieldRef, fieldValue, fieldLabel, dtoProperties, privilege) {
    return this.initTextField(id, fieldRef, fieldValue, fieldLabel, privilege);
  }
}

export default DetailFieldGenerator;

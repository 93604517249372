import React from 'react';
import BaseComponent from "../BaseComponent";
import InputText from "../../../appl/view/components/inputFields/InputText";
import DialogBase from "./DialogBase";
import PropTypes from "prop-types";
import HorizontalLayout from "../layouts/HorizontalLayout";
import MenuItem from "../menu/MenuItem";
import Menu from "../../../appl/view/components/menu/Menu";
import MenuItemGroup from "../menu/MenuItemGroup";
import CM from "../../../appl/model/system/CM";
import Describe from "../../model/service/dto/Describe";
import Checkbox from "../checkbox/Checkbox";
import Create from "../../../appl/model/service/campaign/dto/Create";
import InputTextMultiline from "../../../appl/view/components/inputFields/InputTextMultiline";
import {toast} from "react-toastify";
import InputSearchDropDown from "../../../appl/view/components/inputFields/dropdown/InputSearchDropDown";
import Upload from "../Upload";
import shortid from "shortid";
import ButtonMedium from "../../../appl/view/components/buttons/ButtonMedium";
import T from "../../../appl/model/system/text_translations/Translator";
import Loading from "../images/Loading";

class DialogNewCampaign extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      domains: [],
      templates: [],
      selectedTemplate: null,
      selectedDomain: null,
      newCampaignName: null,
      allowAllUsers: false,
      uploadedThumbnail: null,
      isLoading: false,
      createButtonText: T.label_create()
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let self = this;
    CM.get().doJsonAjax(CM.get().getServiceUrl('campaign'), new Describe('Campaign', 'po2\\service\\dto\\Describe'),
      function(response) {
        if (response.success) {
          self.state.domains = response.data.serviceDescription.domains;
          self.state.templates = response.data.serviceDescription.templates;
          self.setState(self.state);
        } else {
          toast.error('Leider konnte die Anfrage nicht bearbeitet werden!');
        }
      });
  }

  onSaveButtonClick(e) {
    let self = this;
    if (!this.state.isLoading) {
      this.state.isLoading = true;
      let createCampaign = new Create(this.state.selectedTemplate, this.state.newCampaignName, '',
        this.state.selectedDomain, this.state.allowAllUsers, this.state.uploadedThumbnail);
      this.state.createButtonText = <Loading/>
      this.setState(this.state);
      CM.get().doJsonAjax(CM.get().getServiceUrl('campaign'), createCampaign, function(response) {
        self.state.isLoading = false;
        self.state.createButtonText = T.label_create();
        self.setState(self.state);
        if (response.success) {
          toast.success('Campaign erfolgreich erstellt');
          if (self.props.onSaveButtonClick) {
            self.props.onSaveButtonClick(e);
            self.props.onCloseButtonClick(e);
          }
        } else {
          toast.error('Campaign konnte nicht erstellt werden!\n' + response.message);
        }
      });
    }
  }

  private_getDomains() {
    let defaultValueForDomains = this.state.selectedDomain;
    let domains = this.state.domains.map((domain) => {
      let domainId = domain['po2\\system\\db\\doctrine\\entity\\SysDomain#id'];
      let domainName = domain['po2\\system\\db\\doctrine\\entity\\SysDomain#name'];
      if (defaultValueForDomains === null) {
        defaultValueForDomains = {option: domainId, displayValue: domainName};
        this.state.selectedDomain = domainId;
      } else {
        if (domainId === defaultValueForDomains) {
          defaultValueForDomains = {option: domainId, displayValue: domainName};
        }
      }
      return <MenuItem key={domainId} searchValue={domainName} option={domainId}>{domainName}</MenuItem>
    });

    return {domains: domains, defaultValueForDomains: defaultValueForDomains}
  }

  private_getTemplates() {
    let defaultValueForTemplates = this.state.selectedTemplate;
    let templates = this.state.templates.map((template) => {
      let templateId = template['appl\\models\\entity\\doctrine\\CampaignTemplate#id'];
      let templateName = template['appl\\models\\entity\\doctrine\\CampaignTemplate#name'];
      if (defaultValueForTemplates === null) {
        defaultValueForTemplates = {option: templateId, displayValue: templateName};
        this.state.selectedTemplate = templateId;
      } else {
        if (templateId === defaultValueForTemplates) {
          defaultValueForTemplates = {option: templateId, displayValue: templateName};
        }
      }
      return <MenuItem searchValue={templateName} key={templateId} option={templateId}>{templateName}</MenuItem>
    });

    return {templates: templates, defaultValueForTemplates: defaultValueForTemplates}
  }


  render() {

    let {domains, defaultValueForDomains} = this.private_getDomains();
    let {templates, defaultValueForTemplates} = this.private_getTemplates();

    return <DialogBase onCloseButtonClick={this.props.onCloseButtonClick} className='w-full xl:w-1/2'
                       closable={this.props.closable} title='Neue Campaign anlegen'>
      <div className='grid grid-cols-12 gap-x-5 mt-5'>
        <div className='col-span-full md:col-span-4 flex flex-col gap-y-3'>
          <InputText maxLength={28} labelElement='Name' onChange={(e) => this.state.newCampaignName = e.target.value}/>

          <InputSearchDropDown key={shortid()} defaultValue={defaultValueForTemplates} inputLayout='inline' labelElement='Template'
                               onChange={(e, id, data) => this.state.selectedTemplate = data}>
            <Menu><MenuItemGroup>{templates}</MenuItemGroup></Menu>
          </InputSearchDropDown>

          <InputSearchDropDown key={shortid()} defaultValue={defaultValueForDomains} inputLayout='inline' labelElement='Domain'
                               onChange={(e, id, data) => this.state.selectedDomain = data}>
            <Menu><MenuItemGroup>{domains}</MenuItemGroup></Menu>
          </InputSearchDropDown>

          <HorizontalLayout className='items-center gap-x-2'>
            <Checkbox type='checkbox' labelElement='Zugriff für alle Benutzer' onChange={(e) => this.state.allowAllUsers = e.target.checked}/>
          </HorizontalLayout>
        </div>
        <div className='col-span-full gap-y-3 md:col-span-8 flex flex-col mt-3 md:mt-0'>
          <InputTextMultiline height={154} labelElement='Beschreibung'/>
        </div>
        <div className='col-span-full gap-y-3 flex flex-col mt-8'>
          <Upload className='mb-12' title='Thumbnail hochladen' onUploadDone={(response) => this.onUploadDone(response)}
                  dtoProperties={this.props.dtoProperties}/>
        </div>
        <div className='col-span-full pt-3 mt-6'>
          <HorizontalLayout className='gap-x-5 justify-end'>
            <ButtonMedium onClick={this.props.onCloseButtonClick} className='text-cn-color-white' color='light'>{T.label_cancel()}</ButtonMedium>
            <ButtonMedium className='min-w-[100px]' widthFull color='secondary' onClick={(e) => this.onSaveButtonClick(
              e)}>
              {this.state.createButtonText}
            </ButtonMedium>
          </HorizontalLayout>
        </div>
      </div>

    </DialogBase>;
  }

  onUploadDone(response) {
    if (response.success) {
      this.state.uploadedThumbnail = response.data.fileUploadEntities[0].resourceId;
    } else {
      toast.error(response.message);
    }
  }

}

DialogNewCampaign.propTypes = {
  title: PropTypes.string,
  closable: PropTypes.bool,
  onCloseButtonClick: PropTypes.func,
  onSaveButtonClick: PropTypes.func
}
export default DialogNewCampaign;
import Operator from "./Operator";
import Predicate from "./Predicate";

class FieldPredicate extends Predicate {

  static _CLASS = 'po2\\service\\entity\\predicate\\FieldPredicate';

  fieldName;
  fieldName2;
  value1;
  value2;
  operator;
  parent;

  constructor(fieldName, fieldName2, value1, value2, operator, parent) {
    super(FieldPredicate._CLASS);
    this.fieldName = fieldName;
    this.fieldName2 = fieldName2;
    this.value1 = value1;
    this.value2 = value2;
    this.operator = (operator !== undefined ? operator : Operator.eq);
    this.parent = parent;
  }

  isBooleanPredicate() {
    return false;
  }

  isParentNode() {
    return this.isBooleanPredicate();
  }

  getParent() {
    return this.parent;
  }

  setParent(parent) {
    this.parent = parent;
  }

  getFieldName() {
    return this.fieldName;
  }

  setFieldName(fieldName) {
    this.fieldName = fieldName;
  }

  getFieldName2() {
    return this.fieldName2;
  }

  setFieldName2(fieldName2) {
    this.fieldName2 = fieldName2;
  }

  getValue1() {
    return this.value1;
  }

  setValue1(value1) {
    this.value1 = value1;
  }

  getValue2() {
    return this.value2;
  }

  setValue2(value2) {
    this.value2 = value2;
  }

  getOperator() {
    return this.operator;
  }

  setOperator(operator) {
    this.operator = operator;
  }
}

export default FieldPredicate;

import VariablesContent from "./VariablesContent";
import PropType from "prop-types";
import BaseComponent from "../../../../lib/components/BaseComponent";
import Window from "../../../../lib/components/window/Window";
import TabContent from "../../../../lib/components/tabbar/TabContent";
import React, {createRef} from "react";
import TabBarVertical from "../../../../lib/components/tabbar/TabBarVertical";
import ButtonTabVariables from "../tabbar/ButtonTabVariables";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import VariablesButtonGroup from "./VariablesButtonGroup";
import TabContainerVerticalVariables from "../tabbar/TabContainerVerticalVariables";
import CM from "../../../model/system/CM";
import ActualizeVariables from "../../../model/service/campaign/dto/ActualizeVariables";
import {toast} from "react-toastify";
import SynchronizeVariables from "../../../model/service/campaign/dto/SynchronizeVariables";
import UpdateVariableGroups from "../../../model/service/campaign/dto/UpdateVariableGroups";
import DataType from "../../../../lib/model/types/DataType";
import GF from "../../../../lib/utils/GF";
import ReadVariableGroups from "../../../model/service/campaign/dto/ReadVariableGroups";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import ButtonSmall from "../buttons/ButtonSmall";
import T from "../../../model/system/text_translations/Translator";
import AlertDialog from "../../../../lib/components/dialogs/AlertDialog";
import CampaignService from "../../../model/service/campaign/CampaignService";
import Resource from "../../../../lib/model/resource/Resource";

class VariablesForm extends BaseComponent {

  constructor(props) {
    super(props);
    this.tabContainerRef = createRef();
    this.variableGroupButtonRefs = {};
    this.fileInProgress = 0;
    this.state = {
      variableGroupDefs: null, variables: null, pages: null
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.loadVariables();
  }

  loadVariables() {
    let self = this;
    this.state.variableGroupDefs = {};
    this.state.variables = {};
    this.state.pages = [];

    this.doDataCacheFetch(new ReadVariableGroups(this.props.campaignId), 'campaign', 'variables', (response) => {
      if (response.success) {
        self.state.variableGroupDefs = response.data.variableGroupDefinitions;
        self.state.variables = response.data.variables;
        self.state.pages = response.data.pages;

        self.tabContainerRef.current.clearAll();
        let firstIndex = '';
        for (let index in self.state.variableGroupDefs) {
          let variableGroupDef = self.state.variableGroupDefs[index];

          let variableGroupButtonRef = createRef();

          let variableGroupValues = self.state.variables[self.props.variant][variableGroupDef.name];
          if (variableGroupValues !== undefined) {
            let id = self.tabContainerRef.current.addElement(
              <ButtonTabVariables ref={variableGroupButtonRef} hideCloseButton>{variableGroupDef.description}</ButtonTabVariables>,
              <VariablesContent campaignId={this.props.campaignId} variableGroup={variableGroupDef}
                                onVariablesChange={(variableGroupDef, variableName, value) => self.onVariablesChange(variableGroupDef,
                                  variableName, value)} variableValues={self.state.variables[self.props.variant][variableGroupDef.name]}/>);

            self.variableGroupButtonRefs[variableGroupDef.name] = variableGroupButtonRef;
            if (parseInt(index) === 0) {
              firstIndex = id;
            }
          }
        }
        self.tabContainerRef.current.setActiveElement(firstIndex);
      } else {
        toast.error('Variablen konnten nicht geladen werden!');
      }
    });
  }

  getStorageId() {
    return 'VariablesForm_' + this.props.campaignId;
  }

  onVariablesChange(variableGroupDef, variableName, value) {
    let fieldValue = null;
    if (value.hasOwnProperty('currentTarget')) {
      fieldValue = value.currentTarget.value;
    } else {
      fieldValue = value;
    }
    let variableGroup = this.state.variables[this.props.variant][variableGroupDef.name];

    if (variableGroup[variableName].value !== fieldValue) {
      this.variableGroupButtonRefs[variableGroupDef.name].current.setIsChanged(true);
    }
    variableGroup[variableName].value = fieldValue;
    this.state.variables[this.props.variant][variableGroupDef.name] = variableGroup;
  }

  onOverwriteButtonClick(e) {
    CM.get().setMainDialog(<AlertDialog errorTitle={'Variablen überschreiben'} errorMessage={<VerticalLayout className='mt-3'>Möchten Sie die Variablen überschreiben?
      <HorizontalLayout className='justify-end mt-4 gap-4'>
        <ButtonSmall onClick={(e) => CM.get().closeMainDialog(e)}>{T.label_cancel()}</ButtonSmall>
        <ButtonSmall className='bg-cn-color-static-red' color='secondary' onClick={(e) => {
          CM.get().doJsonAjax(CM.get().getServiceUrl('campaign'), new ActualizeVariables(this.props.campaignId), (response) => {
            if (response.success) {
              this.clearCache(this.getStorageId());
              this.loadVariables();
              CM.get().closeMainDialog(e);
              toast.success('Variablen wurden erfolgreich überschrieben.');
            } else {
              if (response.message != null) {
                toast.error(response.message);
              } else {
                toast.error('Variablen konnten nicht überschrieben werden!');
              }
            }
          });
        }}>{T.action_overwrite()}</ButtonSmall>
      </HorizontalLayout>
    </VerticalLayout>}>
    </AlertDialog>);
  }

  onVariablesButtonGroupClick(e, buttonId) {
    switch (buttonId) {
      case VariablesButtonGroup.REFRESH:
        this.clearCache(this.getStorageId());
        this.loadVariables();
        break;
      case VariablesButtonGroup.OVERWRITE:

        this.onOverwriteButtonClick(e);
        break;
      case VariablesButtonGroup.SYNC:
        CM.get().doJsonAjax(CM.get().getServiceUrl('campaign'), new SynchronizeVariables(this.props.campaignId, this.props.variant),
          (response) => {
            if (response.success) {
              this.clearCache(this.getStorageId());
              this.loadVariables();
              toast.success('Variablen wurden erfolgreich synchronisiert.');
            } else {
              toast.error('Variablen konnten nicht synchronisiert werden!');
            }
          });
        break;
      case VariablesButtonGroup.CANCEL:
        this.clearCache(this.getStorageId());
        this.loadVariables();
        break;
      case VariablesButtonGroup.SHOW:
        window.open(this.props.campaignHref, '_blank');
        break;
      case VariablesButtonGroup.SAVE:
        this.doSaveVariables();
        break;
    }
  }

  doSaveVariables() {
    this.prepareFileUpload(this.state.variables, () => {
      let dtoUpdateVariableGroups = new UpdateVariableGroups(this.props.campaignId,
        this.prepareVariablesForServer(this.state.variables), []);

      CM.get().doJsonAjax(CM.get().getServiceUrl('campaign'), dtoUpdateVariableGroups, (response) => {
        if (response.success) {
          for (let index in this.variableGroupButtonRefs) {
            let variableGroupButtonRef = this.variableGroupButtonRefs[index];
            if (variableGroupButtonRef.current !== null) {
              variableGroupButtonRef.current.setIsChanged(false);
            }
          }
          toast.success('Variablen wurden erfolgreich gespeichert.');
          this.setState(this.state);
        } else {
          toast.error('Variablen konnten nicht gespeichert werden!');
        }
      });
    });
  }

  prepareFileUpload(variables, doSaveCallback) {
    let collectedFiles = [];
    this.iterateObjectTree(variables, (index, variant) => {
      return this.iterateObjectTree(variant, (index, variableGroup) => {
        return this.iterateObjectTree(variableGroup, (index, variableValue) => {
          if (variableValue !== undefined && variableValue !== null && Object.keys(variableValue).length > 0) {
            switch (variableValue.variableDef.type.type) {
              case DataType.DATA_EXT:
                if (typeof variableValue.value === 'object') {
                  collectedFiles.push(variableValue.value);
                }
                break;
              default:
                break;
            }
          }
          return variableValue;
        });
      });
    });
    if (collectedFiles.length > 0) {
      let dtoProperties = CampaignService.createCampaignRedirectProperty(this.props.campaignId);
      let resource = new Resource(dtoProperties);

      resource.doManageResources(collectedFiles, () => {
        doSaveCallback();
      })
    } else {
      doSaveCallback();
    }
  }

  prepareVariablesForServer(variables, doSaveCallback) {
    return this.iterateObjectTree(variables, (index, variant) => {
      return this.iterateObjectTree(variant, (index, variableGroup) => {
        return this.iterateObjectTree(variableGroup, (index, variableValue) => {
          let variableNew = null;
          if (variableValue !== undefined && variableValue !== null && Object.keys(variableValue).length > 0) {
            switch (variableValue.variableDef.type.type) {
              case DataType.STRING:
                if (variableValue.variableDef.type.isPassword && variableValue.value === GF.PASSWORD_PLACEHOLDER) {
                  // nicht übernehmen
                } else {
                  variableNew = variableValue.value;
                }
                break;
              case DataType.BOOLEAN:
                variableNew = variableValue.value === "1" || variableValue.value === "true" || variableValue.value ===
                  "ja" || variableValue.value === "JA" || variableValue.value === 1 || variableValue.value === true;
                break;
              case DataType.DATA_EXT:
                if (typeof variableValue.value === 'object') {
                  variableNew = variableValue.value.getResourceIdAndName();
                  variableValue.value = variableNew;
                } else {
                  variableNew = variableValue.value;
                }
                break;
              default:
                variableNew = variableValue.value;
                break;
            }
          }
          return variableNew;
        });
      });
    });
  }

  iterateObjectTree(objectData, callBack) {
    let objectNew = {};
    for (let index in objectData) {
      let value = objectData[index];
      let result = callBack(index, value);
      if (result !== null) {
        objectNew[index] = result;
      }
    }
    return objectNew;
  }

  render() {
    let hideOverwriteButton = this.props.variant !== 'std';
    return <Window classNameContent='pl-4 pb-4 pr-4 h-full pb-[135px]' className='h-full overflow-hidden text-cn-color-white' noPadding title='Variablen'>
      <VerticalLayout className='h-full'>
        <VariablesButtonGroup hideOverwriteButton={hideOverwriteButton} onClick={(e, data) => this.onVariablesButtonGroupClick(e, data)}/>
        <TabContainerVerticalVariables className='p-2 h-full' ref={this.tabContainerRef} tabBar={
          <TabBarVertical widthFit className='min-w-[50px] gap-1.5 pr-[6px]'/>} tabContent={
          <TabContent className='overflow-y-scroll pb-[8px] pr-[8px]'/>}/>
      </VerticalLayout>
    </Window>;
  }
}

VariablesForm.propTypes = {
  variant: PropType.string,
  variableGroupDefs: PropType.array,
  campaignId: PropType.number,
  campaignHref: PropType.string
}

export default VariablesForm;

import BaseService from "../../../../lib/model/service/BaseService";
import GF from "../../../../lib/utils/GF";

class CampaignService extends BaseService {

  static NAME = 'Campaign';

  static createCampaignRedirectProperty(campaignId, url = '') {
    if (GF.checkNotNUllAndNotEmpty(campaignId)) {
      return {
        redirect: {
          campaignId: campaignId, url: url
        }, campaignId: campaignId
      };
    } else {
      return null;
    }
  }
}

export default CampaignService;

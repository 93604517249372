import Dto from "../service/Dto";

class Predicate extends Dto {

  constructor(_class) {
    super((_class !== undefined ? _class : 'Predicate'));
  }

  isBooleanPredicate() {
    return true;
  }
}

export default Predicate;

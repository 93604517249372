import PropTypes from "prop-types";
import {MoreVertical} from "react-feather";
import './CampaignCard.css'
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import ALink from "../../../../lib/components/Link/ALink";

import React from "react";

import CampaignBase from "./CampaignBase";
import shortid from "shortid";

class CampaignCard extends CampaignBase {

  constructor(props) {
    super(props);
    this.storageId = 'CampaignCard_' + shortid();
  }

  render() {
    let campaign = this.props.campaign;
    if (this.state.image === null) {
      let campaignImageUrl = campaign.thumbnail !== undefined && campaign.thumbnail !== '' ? campaign.thumbnail :
        this.defaultImages[(this.props.index % 7 + 1)];
      this.state.image = <div><img alt='Thumbnail' src={campaignImageUrl} onClick={(e) => this.onCardClick(e)}
                                   className='h-[250px] cursor-pointer object-cover w-full'/></div>;
    }

    return <VerticalLayout widthFit className='campaign-card cn-border-radius-20'>
      {this.state.image}
      <div className='h-full relative'>
        {this.createStatusIcon()}
        <MoreVertical onClick={(e) => this.onMoreButtonClick(e)}
                      className="campaign-card-more-button z-50 w-8 h-8 stroke-cn-color-black"/>
        {this.state.mainMenu}
        {this.state.changeStateMenu}
        <VerticalLayout className="px-4 justify-between h-full">
          <p className="cn-font-paragraph pt-1 text-center text-cn-color-blue-690">{campaign.createdDate}</p>
          <h3 onClick={(e) => this.onCardClick(e)}
              className="text-cn-color-white truncate cursor-pointer">{this.props.campaign.name}</h3>
          <div
            className="text-cn-color-gray-500 break-normal mt-1 h-24 text-sm overflow-hidden">{campaign.description}</div>
          <ALink className='mb-3' href={this.props.campaign.baseUrl} target='_blank'
                 title={campaign.baseUrl}/>
        </VerticalLayout>
      </div>
    </VerticalLayout>;
  }
}

CampaignCard.propTypes = {
  campaign: PropTypes.object, onCardClick: PropTypes.func, reloadCampaigns: PropTypes.func, index: PropTypes.number
}
export default CampaignCard;
import BaseComponent from "../../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import InputTag from "../../../../../appl/view/components/inputFields/InputTag";

class TagsTest extends BaseComponent {

  onChange(e) {
    console.log(e);
  }

  render() {
    return <div className='sandbox-container'>
      <VerticalLayout className={'self-center'}>
        <h1>Tags</h1>
        {/*<Tag options={[{value: 'peng', label: 'peng'}]} />*/}
        <InputTag labelElement='hallo' helpTextElement='helptext' options={[{value: 'peng2', label: 'peng2'}]} onChange={(e) => this.onChange(e)}/>
      </VerticalLayout>
    </div>
  }
}

export default TagsTest;

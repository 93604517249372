import BaseComponent from "../BaseComponent";
import './Details.css';
import HorizontalLayout from "../layouts/HorizontalLayout";
import PropTypes from "prop-types";
import shortid from "shortid";

class ColumnBox extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      columnCount: (this.props.columnCount !== undefined ? this.props.columnCount : 3)
    }
  }

  render() {
    let columnTemp = [];
    let verticalLayouts = [];
    let total = this.props.children.length;
    let fraction = total / this.state.columnCount;

    let position = 0;
    for (let i = 0; i < this.state.columnCount; i++) {
      columnTemp = [];

      while (position < (fraction * (i + 1))) {
        columnTemp.push(this.props.children[position]);
        position++;
      }
      verticalLayouts.push(<div key={shortid()} className="w-full gap-1 grid grid-cols-2">{columnTemp}</div>);
    }
    return <HorizontalLayout className='flex-wrap lg:flex-nowrap justify-between gap-x-8'>
      {verticalLayouts}
    </HorizontalLayout>
  }
}


ColumnBox.propTypes = {
  columnCount: PropTypes.number
}

export default ColumnBox;

import Dto from "../../Dto";

class Export extends Dto {

  constructor(query = '', recordsToExport = '') {
    super('po2\\service\\entity\\dto\\Export');
    this.query = query;
    this.recordsToExport = recordsToExport;
  }

  getQuery(){
    return this.query;
  }
  setQuery(query){
    this.query = query;
  }

  getRecordsToExport() {
    return this.recordsToExport;
  }

  setRecordsToExport(recordsToExport) {
    this.recordsToExport = recordsToExport;
  }
}

export default Export;

import BaseComponent from "../BaseComponent";
import PropTypes from "prop-types";

class Table extends BaseComponent {

  render() {
    let classes = this.props.className ? this.props.className : '';
    let fullWidth = '';
    if (this.props.fullWidth) {
      fullWidth = ' w-full';
    }

    return <div className={'overflow-x-auto' + fullWidth}>
      <table className={classes}>
        <tbody>
        {this.props.children}
        </tbody>
      </table>
    </div>;
  }
}

Table.propTypes = {
  className: PropTypes.string, fullWidth: PropTypes.bool
}
export default Table
import CM from "../../../appl/model/system/CM";
import {toast} from "react-toastify";
import GF from "../../utils/GF";

class BaseService {

  static NAME = 'BaseService';

  static doRequest(dto, callBack, message = '') {
    CM.get().doJsonAjax(CM.get().getServiceUrl(this.NAME), dto, (response) => {
      if (response.success) {
        callBack(response);
      } else {
        let messageFromResponse;
        if (response.hasOwnProperty('message')) {
          messageFromResponse = response.message;
        } else {
          messageFromResponse = response;
        }
        if (GF.checkNotNUllAndNotEmpty(messageFromResponse)) {
          if (message !== '') {
            message += ', ';
          }
          message += messageFromResponse;
        }
        if (GF.checkNotNUllAndNotEmpty(message)) {
          message = 'Es ist ein Fehler aufgetreten! Bitte melden Sie Sich bei der Vögeli AG';
        }
        toast.error(message);
      }
    });
  }
}

export default BaseService;

import BaseComponent from "../BaseComponent";
import './TabBar.css';
import HorizontalLayout from "../layouts/HorizontalLayout";
import {X} from "react-feather";
import PropTypes from "prop-types";

class ButtonTab extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      onCloseClickActive: false
    }
  }

  onClick(e) {
    if (!this.state.onCloseClickActive) {
      if (this.props.onClick2 !== undefined) {
        this.props.onClick2(e, this.props.id);
      }
      if (this.props.onClick !== undefined) {
        this.props.onClick(e, this.props.id);
      }
    }
    this.state.onCloseClickActive = false; // damit wenn close geklickt wird nicht auch der normale klick ausgelöst wird.
  }

  onCloseClick(e) {
    this.state.onCloseClickActive = true;
    if (this.props.onCloseClick2 !== undefined) {
      this.props.onCloseClick2(e, this.props.id);
    }
    if (this.props.onCloseClick !== undefined) {
      this.props.onCloseClick(e, this.props.id);
    }
  }

  render() {
    let closeButton;
    if (!this.props.hideCloseButton) {
      closeButton =
        <X className='p-0.5 text-cn-color-white rounded-full hover:bg-cn-color-static-red' onClick={(e) => this.onCloseClick(
          e)}/>;
    }
    let activeClass = this.props.isActive ? ' active ' : ' ';
    return <HorizontalLayout widthFit onClick={(e) => this.onClick(e)}>
      <button className={'buttonTab' + activeClass + 'flex items-center gap-1'}>
        {this.props.children}
        {closeButton}
      </button>
    </HorizontalLayout>
  }
}

ButtonTab.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  onClick2: PropTypes.func,
  onCloseClick: PropTypes.func,
  onCloseClick2: PropTypes.func,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  parent: PropTypes.object
}
export default ButtonTab;

import Describe from "../../../service/dto/Describe";
import Read from "../../../service/entity/dto/Read";
import Create from "../../../service/entity/dto/Create";
import Update from "../../../service/entity/dto/Update";
import Delete from "../../../service/entity/dto/Delete";

class MemoryDataProvider {

  memoryEntity = null;

  constructor(memoryEntity) {
    this.memoryEntity = memoryEntity;
  }

  processDto(dto) {
    let result = {success: false, data: null, message: ''};
    let data = {};
    if (dto.hasOwnProperty('_class')) {
      switch(dto._class) {
        case (new Describe())._class:
          result.data = this.memoryEntity.getEntityDef();
          result.success = true;
          break;
        case (new Create())._class:
          data['records'] = this.create(dto);
          data['totalRows'] = data['records'].length;
          break;
        case (new Read())._class:
          let readResult = this.read(dto)
          data['records'] = readResult.rows;
          data['totalRows'] = readResult.totalRows;
          break;
        case (new Update())._class:
          data['records'] = this.update(dto);
          data['totalRows'] = data['records'].length;
          break;
        case (new Delete())._class:
          data['records'] = this.delete(dto);
          data['totalRows'] = data['records'].length;
          break;
        default:
          throw new DOMException('dto ist nicht implementiert!');
      }
      if (data.hasOwnProperty('records')) {
        result.success = true;
        result.data = data;
      }
    } else {
      throw new DOMException('Objekt muss vom Typ dto sein!');
    }
    return result;
  }

  create(dto) {
    let recordsResult = [];
    let recordsToCreate = dto.getRecordsToCreate();
    let records = recordsToCreate.getRecords();

    if (Array.isArray(records)) {
      for (let index in records) {
        let recordToCreate = records[index];
        recordsResult.push(this.memoryEntity.create(recordToCreate));
      }
    } else {
      recordsResult = this.memoryEntity.create(records);
    }
    return recordsResult;
  }

  read(dto) {
    return this.memoryEntity.read(dto.query);
  }

  update(dto) {
    let recordsResult = [];
    let changedRecords = dto.getChangedRecords();
    let records = changedRecords.getRecords();

    if (Array.isArray(records)) {
      for (let index in records) {
        let changedRecord = records[index];
        recordsResult.push(this.memoryEntity.update(changedRecord));
      }
    } else {
      recordsResult = this.memoryEntity.update(records);
    }
    return recordsResult;
  }

  delete(dto) {
    let recordsToDelete = dto.getRecordsToDelete();
    let records = recordsToDelete.getRecords();

    if (Array.isArray(records)) {
      for (let index in records) {
        let recordToDelete = records[index];
        this.memoryEntity.update(recordToDelete);
      }
    } else {
      this.memoryEntity.update(records);
    }
    return records;
  }
}

export default MemoryDataProvider;

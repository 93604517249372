import BaseComponent from "../../../../../lib/components/BaseComponent";
import Checkbox from "../../../../../lib/components/checkbox/Checkbox";

class AccordionTest extends BaseComponent {

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>
      <Checkbox label={"Test"} description={"test"} type={'checkbox'}/>
      <Checkbox label={"Das ist ein Test der Switches"} description={""} type={'switch'}/>
      <Checkbox label={"Das ist ein Test"} description={""} type={'switch'}/>
      <Checkbox label={""} description={""} type={'darklight'}/>
      <Checkbox label={""} description={""} type={'fav'}/>
    </div>
  }
}

export default AccordionTest;

import BaseComponent from "../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import {Mail} from "react-feather";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import ButtonSmall from "../buttons/ButtonSmall";
import DialogBase from "../../../../lib/components/dialogs/DialogBase";
import InputEmail from "../inputFields/InputEmail";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import CM from "../../../model/system/CM";
import {toast} from "react-toastify";
import RequestPasswordResetLink from "../../../../lib/model/service/passwordReset/dto/RequestPasswordResetLink";
import PasswordReset from "../../../../lib/model/service/passwordReset/PasswordReset";


class RequestPasswordResetDialog extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      email: null, captchaToken: null
    }
  }

  onResetPasswordClick(e) {
    let dto = new RequestPasswordResetLink(this.state.email, this.state.captchaToken);
    this.doDataCacheFetch(dto, PasswordReset.NAME, 'RequestPasswordResetDialog', (response) => {
      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
      this.props.onCloseButtonClick();
    }, true);
  }

  getStorageId() {
    return 'RequestPasswordResetDialog';
  }

  render() {
    let theme = CM.get().getTheme() === CM.STD_DARK ? 'dark' : 'light';
    return (<DialogBase ref={this.reference} title='Passwort zurücksetzen' onCloseButtonClick={(e) => {
      if (this.props.onCloseButtonClick) {
        this.props.onCloseButtonClick(e);
      }
    }}><VerticalLayout className='text-cn-color-white justify-center w-full mx-auto gap-y-4 p-4'>
      <p className='cn-font-paragraph'>Geben Sie bitte Ihre E-Mailadresse ein:</p>
      <InputEmail onChange={(e, id, value) => this.state.email = value} labelElement='E-Mailadresse' iconRight={
        <Mail className='self-center mx-2'/>}/>
      <HCaptcha theme={theme} sitekey={CM.get().getHCaptchaSiteKey()} onVerify={(token, eKey) => this.state.captchaToken = token}/>
      <HorizontalLayout className='justify-center gap-x-6'>
        <ButtonSmall onClick={(e) => {
          if (this.props.onCloseButtonClick) {
            this.props.onCloseButtonClick(e);
          }
        }} color='dark'>Abbrechen</ButtonSmall>
        <ButtonSmall onClick={(e) => this.onResetPasswordClick(
          e)} color='secondary' widthFull className='w-full'>Zurücksetzen</ButtonSmall>
      </HorizontalLayout>
    </VerticalLayout>
    </DialogBase>);
  }
}

export default RequestPasswordResetDialog
import BaseComponent from "../BaseComponent";
import PropTypes from "prop-types";

class RouterElement extends BaseComponent {

  normalizePathName(pathname) {
    let result = '';
    if (pathname !== undefined && pathname !== null) {
      if (typeof pathname === 'string') {
        let pathnamePartsNew = [];
        let pathnameParts = pathname.split('/');
        for (let i = 0; i < pathnameParts.length; i++) {
          let pathnamePart = pathnameParts[i];
          if (pathnamePart !== '') {
            pathnamePartsNew.push(pathnamePart);
          }
        }
        if (pathnamePartsNew.length > 0) {
          result = pathnamePartsNew.join('/');
        }
      }
    }
    return result;
  }

  render() {
    let query = this.normalizePathName(window.location.pathname);
    let regex = new RegExp(this.props.route);

    if (query.match(regex) !== null) {
      return <>{this.props.children}</>;
    } else {
      return <></>;
    }
  }
}

RouterElement.propTypes = {
  route: PropTypes.string, children:PropTypes.node
}
export default RouterElement;

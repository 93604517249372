import PropTypes from "prop-types";
import VerticalLayout from "../layouts/VerticalLayout";
import TimePicker from "./TimePicker";
import DatePicker from "./DatePicker";
import BaseComponent from "../BaseComponent";
import GF from "../../utils/GF";

class DateTimePicker extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      datetime: this.props.datetime ? this.props.datetime : new Date(),
      useTime: GF.getValue(this.props.useTime, true),
      canChooseUseTime: GF.getValue(this.props.canChooseUseTime, false)
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.fireOnChangeAndSetState();
  }

  fireOnChangeAndSetState() {
    if (this.props.onChange) {
      this.props.onChange(this.state.datetime, this.state.useTime);
    }
    this.setState(this.state);
  }

  onDateChange(newDate) {
    this.state.datetime = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(),
      this.state.datetime.getHours(), this.state.datetime.getMinutes());
    this.fireOnChangeAndSetState();
  }

  onTimeChange(newTime, useTime) {
    this.state.datetime = new Date(this.state.datetime.getFullYear(), this.state.datetime.getMonth(),
      this.state.datetime.getDate(), newTime.getHours(), newTime.getMinutes());
    this.state.useTime = useTime;
    this.fireOnChangeAndSetState();
  }

  render() {
    return (
      <VerticalLayout widthFit className='dp-container items-center' styleValue={GF.getValue(this.props.styleValue,
        {})}>
        <DatePicker datetime={this.state.datetime} onChange={(newDate) => this.onDateChange(newDate)}/>
        <TimePicker datetime={this.state.datetime} onChange={(newTime, useTime) => this.onTimeChange(newTime,
          useTime)} className='text-center' useTime={this.state.useTime} canChooseUseTime={this.state.canChooseUseTime}/>
      </VerticalLayout>)
  }
}

DateTimePicker.propTypes = {
  datetime: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  styleValue: PropTypes.object,
  useTime: PropTypes.bool,
  canChooseUseTime: PropTypes.bool
}

export default DateTimePicker
import PropTypes from "prop-types";
import BaseComponent from "../../../../lib/components/BaseComponent";
import GF from "../../../../lib/utils/GF";
import React, {createRef} from 'react';
import Row from "../../../../lib/components/table/Row";

class EntityRow extends BaseComponent {

  constructor(props) {
    super(props);
    this.htmlRowRef = createRef();
  }

  onDummyClick() {
    // dummy hier nichts tun.
  }

  onDoubleClick(e) {
    if (this.props.parent !== undefined && this.props.parent.editModeState === 'close') {
      if (GF.checkNotNull(this.props.onDoubleClick)) {
        this.props.onDoubleClick(this.props.rowIndex, this);
      }
    }
  }

  onRowClick(e) {
    if (this.props.parent !== undefined && this.props.parent.editModeState === 'close') {
      this.props.parent.dataSource.setCurrentRecIds(this.props.recId, e.metaKey);
    }
  }

  render() {
    let children = React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {parent: this});
    });
    let classes = this.props.rowType === Row.ROW_TYPE_HEADER ? 'header ' : 'row '
    classes += GF.getValue(this.props.className, '');
    classes += this.props.isActive ? 'table-row-active ' : ''
    let onClick;
    if (this.props.rowType === Row.ROW_TYPE_HEADER) {
      onClick = (e) => this.onDummyClick(e);
    } else {
      onClick = (e) => this.onRowClick(e);
    }
    return <tr ref={this.htmlRowRef} className={classes} onDoubleClick={(e) => this.onDoubleClick(e)} onClick={onClick}>
      {children}
    </tr>;
  }
}

EntityRow.propTypes = {
  onClick: PropTypes.func,
  // isHeader: PropTypes.bool,
  recId: PropTypes.number,
  onDoubleClick: PropTypes.func,
  className: PropTypes.string,
  parent: PropTypes.object,
  isActive: PropTypes.bool,
  rowIndex: PropTypes.number,
  rowType: PropTypes.string
}

export default EntityRow;

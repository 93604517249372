import Dto from "../../Dto";

class Connect extends Dto {

  constructor(baseRecord = '', recordsToConnect = '', relation = '') {
    super('po2\\service\\entity\\dto\\Connect');
    this.baseRecord = baseRecord;
    this.recordsToConnect = recordsToConnect;
    this.relation = relation;
  }

  getBaseRecord() {
    return this.baseRecord;
  }

  setBaseRecord(baseRecord) {
    this.baseRecord = baseRecord;
  }

  getRecordsToConnect() {
    return this.recordsToConnect;
  }

  setRecordsToConnect(recordsToConnect) {
    this.recordsToConnect = recordsToConnect;
  }

  getRelation() {
    return this.relation;
  }

  setRelation(relation) {
    this.relation = relation;
  }
}

export default Connect;

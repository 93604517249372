import PropTypes from "prop-types";
import BaseComponent from "../../../../lib/components/BaseComponent";

class CardContent extends BaseComponent {

  render() {
    return <div className={'p-3 ' + this.props.className}>
      {this.props.children}
    </div>;
  }
}

CardContent.propTypes = {
  className: PropTypes.string
}
export default CardContent;

import Dto from "../../Dto";

class Delete extends Dto {

  constructor(recordsToDelete = '') {
    super('po2\\service\\entity\\dto\\Delete');
    this.recordsToDelete = recordsToDelete;
  }

  getRecordsToDelete() {
    return this.recordsToDelete;
  }

  setRecordsToDelete(recordsToDelete) {
    this.recordsToDelete = recordsToDelete;
  }
}

export default Delete;

import InputLayoutType from "../inputFields/InputLayoutType";
import InputTextMultiline from "../inputFields/InputTextMultiline";
import PropTypes from "prop-types";
import {createRef} from "react";
import WizardContentBase from "./WizardContentBase";
import GF from "../../../../lib/utils/GF";

class WizardContentComments extends WizardContentBase {

  constructor(props) {
    super(props);
    this.inputTextMultilineRef = createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    let notiz = this.data.getNotiz();
    if (!GF.checkNotNull(notiz) || notiz === '') {
      notiz = this.inputTextMultilineRef.current.getValue();
    }
    this.onChange(null, notiz);
  }

  onChange(e, data) {
    this.data.setNotiz(data);
  }

  getContent() {
    return <InputTextMultiline ref={this.inputTextMultilineRef} labelElement='Bemerkungen' helpTextElement='Diese Bemerkung sehen nur Sie' inputLayout={InputLayoutType.inline} onChange={(e, id, data) => this.onChange(e, data)} defaultValue={this.data.getNotiz()}/>;
  }
}
WizardContentComments.propTypes = {
  campaignId: PropTypes.any, wizardNavRef: PropTypes.object
}
export default WizardContentComments;

import Dto from "../../../../../lib/model/service/Dto";

class ReadTutorialVideoList extends Dto {

  version;

  constructor(version = '2.1') {
    super('appl\\service\\campaign\\dto\\ReadTutorialVideoList');
    this.version = version;
  }
}

export default ReadTutorialVideoList;
import Operator from "./Operator";
import Predicate from "./Predicate";

class BooleanPredicate extends Predicate {

  operator = 'AND';
  predicates = [];
  parent;

  constructor(operator = Operator.and, predicates = []) {
    super('po2\\service\\entity\\predicate\\BooleanPredicate');
    if (operator === undefined || operator === null) {
      operator = Operator.and;
    }
    this.operator = operator;
    if (predicates === undefined || predicates == null) {
      predicates = [];
    }
    this.predicates = predicates;
  }

  addPredicate(predicate) {
    this.predicates.push(predicate);
  }

  isBooleanPredicate() {
    return true;
  }

  isParentNode() {
    return this.isBooleanPredicate();
  }

  getOperator() {
    return this.operator;
  }

  setOperator(operator) {
    this.operator = operator;
  }

  getChildren() {
    return this.predicates;
  }

  setChildren(predicates) {
    this.predicates = predicates;
  }

  getParent() {
    return this.parent;
  }

  setParent(parent) {
    this.parent = parent;
  }
}

export default BooleanPredicate;

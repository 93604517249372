import "./ProgressBar.css";
import BaseComponent from "../BaseComponent";
import {createRef} from "react";
import PropTypes from "prop-types";
import GF from "../../utils/GF";

class ProgressBar extends BaseComponent {

  constructor(props) {
    super(props);
    this.progressStep = createRef();
    this.progressStepText = createRef();
    this.progressStepContainer = createRef();
  }

  setProgressValue(value) {
    let progressBarWidth = this.progressStepContainer.current.clientWidth * value / 100;
    this.progressStep.current.setAttribute('style', 'width: ' + progressBarWidth + 'px');
    this.progressStepText.current.textContent = value + '%';
  }

  render() {
    let progressText;
    if (this.props.state === 'error') {
      progressText = <p className="cn-font-paragraph">{this.props.text}</p>;
    } else {
      progressText = <p className="cn-font-paragraph">{this.props.text}</p>;
    }

    let progressPercent;
    if (this.props.state === 'error') {
      progressPercent = <p ref={this.progressStepText} className="px-3 cn-font-paragraph text-right py-[1.5px] relative cn-font-paragraph">{this.props.percent}</p>;
    } else {
      progressPercent = <p ref={this.progressStepText} className="px-3 cn-font-paragraph text-right py-[1.5px] relative cn-font-paragraph">{this.props.percent}</p>;
    }

    let classes = GF.getValue(this.props.className, '');

    return <div ref={this.progressStepContainer} className={"relative h-[30px] border-cn-color-blue-690 border-solid border-[1px] rounded-[8px] rounded-tl-none " + classes}>
      <div ref={this.progressStep} className="absolute h-[29px] bg-cn-color-blue-690 rounded-[8px] rounded-tl-none flex flex-nowrap justify-between cn-progress-animation"></div>
      <div className="px-3 cn-font-paragraph py-[1.5px] float-left  relative">{progressText}</div>
      <div ref={this.progressStepText} className="px-3 cn-font-paragraph text-right py-[1.5px] float-right relative">{progressPercent}</div>
    </div>;
  }
}

ProgressBar.propTypes = {
  text: PropTypes.string,
  percent: PropTypes.number
};
export default ProgressBar;

import BaseComponent from "../../../../../lib/components/BaseComponent";
import ButtonLarge from "../../../../../appl/view/components/buttons/ButtonLarge";
import ButtonGroup from "../../../../../appl/view/components/buttons/ButtonGroup";
import ButtonSmall from "../../../../../appl/view/components/buttons/ButtonSmall";
import {createRef} from "react";
import Loading from "../../../../../lib/components/images/Loading";

class ButtonTest extends BaseComponent {

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>
      <ButtonSmall color="secondary"><Loading /></ButtonSmall>
      <ButtonSmall color="dark" widthFull>Test</ButtonSmall>
      <ButtonSmall color="dark">Test</ButtonSmall>
      <ButtonLarge color="secondary" >Test</ButtonLarge>
      <ButtonGroup color='primary' delimiterColor="white">
        <ButtonLarge ref={createRef()} className="bg-cn-color-primary" onClick={(e) => {
          alert('peng1');
        }}>dfsdfds</ButtonLarge>
        <ButtonLarge ref={createRef()} className="bg-cn-color-primary">dfsfs</ButtonLarge>
        <ButtonLarge ref={createRef()} color="primary" className="bg-cn-color-primary">dfsfs</ButtonLarge>
        <ButtonLarge ref={createRef()} color="primary" className="bg-cn-color-primary" onClick={(e) => {
          alert('peng2');
        }}>dfsfs</ButtonLarge>
        <ButtonLarge ref={createRef()} color="primary" className="bg-cn-color-primary">dsfsdf</ButtonLarge>
      </ButtonGroup>
    </div>
  }
}

export default ButtonTest;

import {Delete, Monitor, RefreshCw, Save} from "react-feather";
import PropTypes from "prop-types";
import React from "react";
import BaseComponent from "../../../../lib/components/BaseComponent";
import ButtonSmall from "../buttons/ButtonSmall";
import ButtonGroup from "../buttons/ButtonGroup";
import shortid from "shortid";
import T from "../../../model/system/text_translations/Translator";

class VariablesButtonGroup extends BaseComponent {

  static REFRESH = 'REFRESH';
  static OVERWRITE = 'OVERWRITE';
  static SYNC = 'SYNC';
  static CANCEL = 'CANCEL';
  static SAVE = 'SAVE';
  static SHOW = 'SHOW';

  render() {
    let children = [];
    children.push(<ButtonSmall key={shortid()} uid={VariablesButtonGroup.REFRESH} tooltip='Neuladen' onClick={this.props.onClick}>
      <RefreshCw className='p-0.5'/>
    </ButtonSmall>);
    if (!this.props.hideOverwriteButton) {
      children.push(<ButtonSmall key={shortid()} uid={VariablesButtonGroup.OVERWRITE} onClick={this.props.onClick}>
        {T.action_overwrite()}
      </ButtonSmall>);
    }
    children.push(<ButtonSmall key={shortid()} uid={VariablesButtonGroup.SYNC} onClick={this.props.onClick}>
      {T.action_synchronize()}
    </ButtonSmall>);
    children.push(<ButtonSmall key={shortid()} uid={VariablesButtonGroup.CANCEL} tooltip={T.label_discard()} onClick={this.props.onClick}>
      <Delete className='text-cn-color-static-red'/>
    </ButtonSmall>);
    children.push(<ButtonSmall key={shortid()} uid={VariablesButtonGroup.SAVE} tooltip={T.label_save()} onClick={this.props.onClick}>
      <Save className='text-cn-color-primary'/>
    </ButtonSmall>);
    children.push(<ButtonSmall key={shortid()} uid={VariablesButtonGroup.SHOW} tooltip='Vorschau' onClick={this.props.onClick}>
      <Monitor/>
    </ButtonSmall>);
    if (this.props.children) {
      React.Children.map(this.props.children, (child) => {
        children.push(child);
      });
    }

    return <ButtonGroup className='mb-2'>
      {children}
    </ButtonGroup>;
  }
}

VariablesButtonGroup.propTypes = {
  onClick: PropTypes.func, hideOverwriteButton: PropTypes.bool
}

export default VariablesButtonGroup;
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import InputText from "../inputFields/InputText";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import MenuItemGroup from "../../../../lib/components/menu/MenuItemGroup";
import MenuItem from "../../../../lib/components/menu/MenuItem";
import Menu from "../../../../lib/components/menu/Menu";
import InputSearchDropDown from "../inputFields/dropdown/InputSearchDropDown";
import UstoreModuleService from "../../../../lib/model/campaign/modules/ustore/v002/service/UstoreModuleService";
import ReadMailTemplates from "../../../../lib/model/campaign/modules/ustore/v002/service/dto/ReadMailTemplates";
import PropTypes from "prop-types";
import CampaignService from "../../../model/service/campaign/CampaignService";
import WizardContentBase from "./WizardContentBase";
import ButtonSmall from "../buttons/ButtonSmall";
import shortid from "shortid";
import {createRef} from "react";
import WizardContentOverview from "./WizardContentOverview";
import GF from "../../../../lib/utils/GF";
import VM from "../../../../lib/model/system/VM";
import Loading from "../../../../lib/components/images/Loading";

class WizardContentConfiguration extends WizardContentBase {

  constructor(props) {
    super(props);
    this.initComponents();
    this.requestActive = false;
    this.testMailAddressRef = createRef();
    this.testMailNumOfMailsRef = createRef();
    this.state.nextButton = <HorizontalLayout>
      <ButtonSmall onClick={(e) => this.onSendTestMail(e)}>testmail</ButtonSmall>
      <ButtonSmall className='ml-2 bg-cn-color-blue-690' onClick={(e) => this.next(e)}>weiter</ButtonSmall>
    </HorizontalLayout>;
    this.state.sendTestMailDialogText = 'senden';
  }

  onSendTestMail() {
    let defaultmail = VM.get().getData('MailingWizardTempMail');
    if (defaultmail === undefined || defaultmail === null) {
      defaultmail = '';
    }
    this.props.wizardBaseRef.current.openDialog(this.buildSendTestMailDialog(defaultmail));
  }

  buildSendTestMailDialog(defaultmail) {
    return <VerticalLayout className='mt-2 mb-6'>
      <InputText ref={this.testMailAddressRef} labelElement='E-Mail' defaultValue={defaultmail}/>
      <div className='mt-2'>
        <InputText ref={this.testMailNumOfMailsRef} labelElement='Anzahl Mails' inputFormat='number|1:20' defaultValue='1'/>
      </div>
      <HorizontalLayout className='mt-2 justify-between'>
        <ButtonSmall onClick={(e) => {
          VM.get().setData('MailingWizardTempMail', this.testMailAddressRef.current.getValue());
          this.props.wizardBaseRef.current.closeDialog();
        }}>abbrechen</ButtonSmall>
        <ButtonSmall className='ml-2 bg-cn-color-static-red' onClick={(e) => {
          let email = this.testMailAddressRef.current.getValue();
          if (GF.checkNotNull(email) && email !== '') {
            let numOfMails = parseInt(this.testMailNumOfMailsRef.current.getValue());
            VM.get().setData('MailingWizardTempMail', email);
            WizardContentOverview.sendMailing(this, this.data, this.props.campaignId, () => {
              this.props.wizardBaseRef.current.closeDialog();
            }, email, numOfMails);

            this.state.sendTestMailDialogText = <Loading/>;
            this.props.wizardBaseRef.current.openDialog(this.buildSendTestMailDialog(defaultmail));
          }
        }}>{this.state.sendTestMailDialogText}</ButtonSmall>
      </HorizontalLayout>
    </VerticalLayout>
  }

  componentDidMount() {
    super.componentDidMount();
    let config = this.data.getConfiguration();

    if (!config.isInitDone()) {
      let readMailTemplates = new ReadMailTemplates(this.props.campaignId);
      readMailTemplates.setProperties(CampaignService.createCampaignRedirectProperty(this.props.campaignId));
      UstoreModuleService.doRequest(readMailTemplates, (response) => {
        config.setSendFromText(response.data.data.mailToDisplayName.join(','));

        let options = this.initOptions(response.data.data.mailToAddresses);
        config.setSendFromAddressOptions(options);
        options = this.initOptions(response.data.data.templates);
        config.setTemplates(options);

        config.setInitDone(true);
        this.initComponents();
        this.setState(this.state);
      });
    } else {
      this.initComponents();
      this.setState(this.state);
    }
  }

  initOptions(optionsArray) {
    let optionValue = '';
    let elements = [];
    for (let index in optionsArray) {
      optionValue = optionsArray[index];
      elements.push({option: optionValue, displayValue: optionValue});
    }
    return elements;
  }

  onChangeSendFromMailText(e, data) {
    this.state.configTextSendFromMailText = data;
    this.data.getConfiguration().setSendFromText(this.state.configTextSendFromMailText);
    this.setState(this.state);
  }

  onChangeSendFromMailAddress = (e, option) => {
    this.state.configTextSendFromMailAddress = option;
    this.data.getConfiguration().setSendFromAddress(option);
    this.setState(this.state);
  }

  onChangeTemplatesInputSearchDropDown = (e, option) => {
    this.state.configTextTemplate = option;
    this.data.getConfiguration().setTemplate(option);
    this.setState(this.state);
  }

  initComponents() {
    let config = this.data.getConfiguration();
    this.state.configTextTemplate = config.getTemplate();
    this.state.configTextSendFromMailText = config.getSendFromText();
    this.state.configTextSendFromMailAddress = config.getSendFromAddress();

    this.state.sendFromMailText =
      <InputText className='w-[350px]' key={shortid()} inputLayout='inline' labelElement='Absender Name' defaultValue={this.state.configTextSendFromMailText} onChange={(e, id, data) => this.onChangeSendFromMailText(
        e, data)}/>;

    let dropDownSendFromAddress = this.createDropDown('Absender Mailadresse', config.getSendFromAddressOptions(),
      config.getSendFromAddress(), this.onChangeSendFromMailAddress);
    this.state.sendFromMailAddress = dropDownSendFromAddress.dropDown;
    let dropDownTemplate = this.createDropDown('Templates', config.getTemplates(), config.getTemplate(),
      this.onChangeTemplatesInputSearchDropDown);
    this.state.templatesInputSearchDropDown = dropDownTemplate.dropDown;

    this.onChangeSendFromMailText(null, this.state.configTextSendFromMailText);
    if (this.state.configTextSendFromMailAddress.option === '') {
      this.state.configTextSendFromMailAddress = dropDownSendFromAddress.defaltOption;
    }
    this.onChangeSendFromMailAddress(null, this.state.configTextSendFromMailAddress);
    if (this.state.configTextTemplate.option === '') {
      this.state.configTextTemplate = dropDownTemplate.defaltOption;
    }
    this.onChangeTemplatesInputSearchDropDown(null, this.state.configTextTemplate);
  }

  createDropDown(label, options, option, onChange) {
    let menuItems = [];
    for (let index in options) {
      let option = options[index];
      menuItems.push(<MenuItem searchValue={option.displayValue} option={{
        option: option.option, displayValue: option.displayValue
      }}>{option.displayValue}</MenuItem>);
    }
    if (options !== null && options.length && option.option === '') {
      option = options[0];
    }
    return {
      dropDown:
        <InputSearchDropDown labelElement={label} key={shortid()} className='w-[350px]' defaultValue={option} onChange={(e, id, option) => onChange(
          e, option)}>
          <Menu className='text-cn-color-white'>
            <MenuItemGroup>
              {menuItems}
            </MenuItemGroup>
          </Menu>
        </InputSearchDropDown>, defaltOption: option
    };
  }

  getContent() {
    return <HorizontalLayout className='flex-col lg:flex-row gap-5 lg:gap-10'>
      <VerticalLayout className='gap-3'>
        {this.state.templatesInputSearchDropDown}
        {this.state.sendFromMailText}
        {this.state.sendFromMailAddress}
      </VerticalLayout>
      <VerticalLayout className='min-w-[400px] gap-0'>
        <h3>Konfiguration</h3>
        <HorizontalLayout widthFit className='grid grid-cols-5'>
          <p className='cn-font-paragraph text-cn-color-primary leading-8 col-span-2'>Template</p>
          <p className='cn-font-paragraph leading-8 col-span-3'>{this.state.configTextTemplate.displayValue}</p>
        </HorizontalLayout>
        <HorizontalLayout widthFit className='grid grid-cols-5'>
          <p className='cn-font-paragraph text-cn-color-primary leading-8 col-span-2'>Name</p>
          <p className='col-span-3'>{this.state.configTextSendFromMailText}</p>
        </HorizontalLayout>
        <HorizontalLayout widthFit className='grid grid-cols-5'>
          <p className='cn-font-paragraph text-cn-color-primary leading-8 col-span-2'>Mailadresse</p>
          <p className='cn-font-paragraph leading-8 col-span-3'>{this.state.configTextSendFromMailAddress.displayValue}</p>
        </HorizontalLayout>
      </VerticalLayout>
    </HorizontalLayout>
  }
}

WizardContentConfiguration.propTypes = {
  campaignId: PropTypes.any,
  wizardNavRef: PropTypes.any,
  mailingDialogRef: PropTypes.object,
  wizardBaseRef: PropTypes.object
}
export default WizardContentConfiguration;

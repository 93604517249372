import React, {createRef} from "react";
import Selecto from "react-selecto";
import shortid from "shortid";
import BaseComponent from "../BaseComponent";

class SelectableDragList extends BaseComponent {

  containerId = 'selectoContainer_' + shortid();
  data = {};
  selectoRef = createRef();

  handleSelect = (e) => {
    e.added.forEach((el) => {
      this.data[el.getAttribute('data-element-id')] = 0;
      el.classList.add('selected');
    });
    e.removed.forEach((el) => {
      let fieldName = el.getAttribute('data-element-id');
      if (this.data.hasOwnProperty(fieldName)) {
        delete this.data[fieldName];
      }
      el.classList.remove('selected');
    });
  };

  getData() {
    return this.data;
  }

  getContainerId() {
    return this.containerId;
  }

  setSelectedTargets(selectedTargets) {
    this.selectoRef.current.setSelectedTargets(selectedTargets);
  }

  setDefaultSelectedTargets() {

    let targets = document.getElementById(this.containerId).getElementsByClassName('selectoItem');
    let targetsArray = Array.from(targets);
    targetsArray.map((element) => {
      if (element.getAttribute('data-element-active') === 'true') {
        element.classList.add('selected');
        this.data[element.getAttribute('data-element-id')] = 0;
        return element;
      }
    });
    this.selectoRef.current.setSelectedTargets(targetsArray);
  }

  render() {
    return (<>
      <Selecto ref={this.selectoRef}
               dragContainer={'#' + this.containerId + '.elementsContainer'}
               selectableTargets={['#' + this.containerId + ' .selectoItem']}
               onSelect={this.handleSelect}
               hitRate={0}
               selectByClick={true}
               selectFromInside={true}
               continueSelect={false}
               continueSelectWithoutDeselect={true}
               toggleContinueSelect='shift'
               toggleContinueSelectWithoutDeselect={[['ctrl'], ['meta']]}
               ratio={0}/>
      <div className='elementsContainer selecto-area' id={this.containerId}>
        {this.props.children}
      </div>
    </>);
  }
}


export default SelectableDragList;

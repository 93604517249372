import InputBase from "../../../appl/view/components/inputFields/InputBase";
import PropType from "prop-types";
import shortid from "shortid";
import './Radio.css'
import PropTypes from "prop-types";
import {createRef} from "react";

class Radio extends InputBase {

  constructor(props) {
    super(props);
    this.inputId = shortid();
    this.inputElementRef = createRef();
  }

  getValue() {
    return this.inputElementRef.current.value;
  }

  render() {
    return <>
      <div className='flex items-center'>
        <input ref={this.inputElementRef} defaultChecked={this.props.checked} name={this.props.name} id={this.inputId} type='radio' value={this.props.value} className='radio-input'/>
        <label htmlFor={this.inputId} className='radio-label'>{this.props.labelElement}</label>
      </div>
      <p className="cn-font-description01 text-cn-color-background-gray01 mb-4">{this.props.description}</p>
    </>;
  }
}

Radio.propTypes = {
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  inputLayout: PropTypes.oneOf(['inline', 'outline', '']),
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  value: PropType.any.isRequired,
  description: PropType.string,
  checked: PropType.bool,
  name: PropType.string
}

export default Radio
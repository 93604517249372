import PropTypes from "prop-types";
import BaseComponent from "../../../../../lib/components/BaseComponent";
import ColumnBox from "../../../../../lib/components/columnBox/ColumnBox";
import FieldName from "../../../../../lib/model/query/FieldName";
import Listener from "../../../../../lib/model/system/Listener";
import AccordionLayout from "../../../../../lib/components/accordion/AccordionLayout";
import AccordionItem from "../../../../../lib/components/accordion/AccordionItem";
import AccordionItemHead from "../../../../../lib/components/accordion/AccordionItemHead";
import {createRef} from "react";
import DetailFieldGenerator from "../fields/DetailFieldGenerator";

class DetailFieldsViewForm extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      dummyCounter: 0
    }
    this.accordionItemRef = createRef();
    this.addOnReadDoneListener = new Listener((e) => this.onReadDone(e));
    this.addOnRowChangeListener = new Listener((e) => this.onRowChangeDone(e));
    this.addOnUpdateDoneListener = new Listener((e) => this.onUpdateDone(e));
    this.props.dataSource.dataSourceEvents.addOnReadDoneListener(this.addOnReadDoneListener);
    this.props.dataSource.dataSourceEvents.addOnRowChangeListener(this.addOnRowChangeListener);
    this.props.dataSource.dataSourceEvents.addOnUpdateDoneListener(this.addOnUpdateDoneListener);
    this.detailFieldGenerator = new DetailFieldGenerator();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.props.dataSource.dataSourceEvents.removeOnReadDoneListener(this.addOnReadDoneListener);
    this.props.dataSource.dataSourceEvents.removeOnRowChangeListener(this.addOnRowChangeListener);
    this.props.dataSource.dataSourceEvents.removeOnUpdateDoneListener(this.addOnUpdateDoneListener);
  }

  getFieldDef(fieldName) {
    let fieldDef = null;
    let entityDef = this.props.dataSource.getEntityDef();
    let fieldsDef = entityDef.fieldsDef;
    let fieldNameObj = FieldName.create(fieldName);

    if (fieldsDef.hasOwnProperty(fieldNameObj.getName())) {
      fieldDef = fieldsDef[fieldNameObj.getName()];
    }
    return fieldDef;
  }

  generateFields() {
    let fields = [];
    let currentRecords = this.props.dataSource.getCurrentRecords();

    if (currentRecords.length > 0) {
      let currentRecord = currentRecords[0];

      for (let fieldName in currentRecord) {
        let fieldValue = currentRecord[fieldName];
        let fieldDef = this.getFieldDef(fieldName);
        fields.push(this.initField(fieldName, fieldDef.description, fieldValue, fieldDef.type));
      }
    }
    return fields;
  }

  initField(fieldName, fieldLabel, fieldValue, fieldType, dtoProperties) {
    return this.detailFieldGenerator.initField(fieldType, fieldName, fieldValue, fieldLabel, dtoProperties);
  }

  onReadDone(e) {
    this.state.dummyCounter++;
    this.setState(this.state);
  }

  onRowChangeDone(e) {
    this.state.dummyCounter++;
    this.setState(this.state);
  }

  onUpdateDone(e) {
    this.state.dummyCounter++;
    this.setState(this.state);
  }

  render() {
    let fields = this.generateFields();

    return <AccordionLayout>
      <AccordionItem ref={this.accordionItemRef} accordionItemHead={
        <AccordionItemHead accordeonItemRef={this.accordionItemRef} title='Detailansicht'/>}>
        <div className='bg-cn-color-black-804 cn-border-radius-20 p-8'>
          <ColumnBox columnCount={3}>
            {fields}
          </ColumnBox>
        </div>
      </AccordionItem>
    </AccordionLayout>;
  }
}

DetailFieldsViewForm.propTypes = {
  dataSource: PropTypes.object
}
export default DetailFieldsViewForm;

import BaseComponent from "../../../../lib/components/BaseComponent";
import Nav from "./Nav";
import ColorpickerTest from "./cases/ColorpickerTest";
import CampaignCardsTest from "./cases/CampaignCardsTest";
import InputWysiwygTest from "./cases/InputWysiwygTest";
import TagsTest from "./cases/TagsTest";
import InputTextMultilineTest from "./cases/InputTextMultilineTest";
import DropDownsTest from "./cases/DropDownsTest";
import UploadTest from "./cases/UploadTest";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import DialogsTest from "./cases/DialogsTest";
import AccordionTest from "./cases/AccordionTest";
import GridTest from "./cases/GridTest";
import DateTimePickersTest from "./cases/DateTimePickersTest";
import DetailsTest from "./cases/DetailsTest";
import TabBarTest from "./cases/TabBarTest";
import BreadCrumbTest from "./cases/BreadCrumbTest";
import WindowTest from "./cases/WindowTest";
import ProgressBarTest from "./cases/ProgressBarTest";
import PieChartTest from "./cases/PieChartTest";
import BarChartTest from "./cases/BarChartTest";
import ExtendedFilterTest from "./cases/ExtendedFilterTest";
import InputTextFieldTest from "./cases/InputTextFieldTest";
import ButtonTest from "./cases/ButtonTest";
import WizardTest from "./cases/WizardTest";
import VariablenTest from "./cases/VariablenTest"
import DraggableListsTest from "./cases/DraggableListsTest";
import CheckboxTest from "./cases/CheckboxTest";
import RadioTest from "./cases/RadioTest";
import FilterManagerTest from "./cases/FilterManagerTest";
import PermissionTest from "./cases/PermissionTest";
import SettingTest from "./cases/SettingTest";
import ProfileTest from "./cases/ProfileTest";
import AccountTest from "./cases/AccountTest";
import DialogCloneCampaign from "../../../../lib/components/dialogs/DialogCloneCampaign";
import RouterElement from "../../../../lib/components/router/RouterElement";
import FahaTest from "./cases/FahaTest";
import AlertTest from "./cases/AlertTest";
import MemoryTableTest from "./cases/MemoryTableTest";
import TableExportTest from "./cases/TableExportTest";

class SandBox extends BaseComponent {

  render() {
    return <VerticalLayout className='text-cn-color-white-960 bg-cn-color-black min-h-screen'>
      <Nav/>
      <div className="cn-sandbox-nav-content-container">
        <RouterElement route='^sb$|^sb/inputfields$'><InputTextFieldTest p={this}/></RouterElement>
        <RouterElement route='^sb/colorpicker$'><ColorpickerTest p={this}/></RouterElement>
        <RouterElement route='^sb/inputwysiwyg$'><InputWysiwygTest p={this}/></RouterElement>
        <RouterElement route='^sb/tagstest$'><TagsTest p={this}/></RouterElement>
        <RouterElement route='^sb/inputmultilinetest$'><InputTextMultilineTest p={this}/></RouterElement>
        <RouterElement route='^sb/dropdownstest$'><DropDownsTest p={this}/></RouterElement>
        <RouterElement route='^sb/uploadtest$'><UploadTest p={this}/></RouterElement>
        <RouterElement route='^sb/dropdowntest$'><DropDownsTest p={this}/></RouterElement>
        <RouterElement route='^sb/dialogtest$'><DialogsTest p={this}/></RouterElement>
        <RouterElement route='^sb/accordiontest$'><AccordionTest p={this}/></RouterElement>
        <RouterElement route='^sb/gridtest$'><GridTest p={this}/></RouterElement>
        <RouterElement route='^sb/datepicker$'><DateTimePickersTest p={this}/></RouterElement>
        <RouterElement route='^sb/window$'><WindowTest p={this}/></RouterElement>
        <RouterElement route='^sb/campaigns$'><CampaignCardsTest p={this}/></RouterElement>
        <RouterElement route='^sb/progressbar$'><ProgressBarTest p={this}/></RouterElement>
        <RouterElement route='^sb/piechart$'><PieChartTest p={this}/></RouterElement>
        <RouterElement route='^sb/barchart$'><BarChartTest p={this}/></RouterElement>
        <RouterElement route='^sb/extendedfilter$'><ExtendedFilterTest p={this}/></RouterElement>
        <RouterElement route='^sb/dtptest$'><DateTimePickersTest p={this}/></RouterElement>
        <RouterElement route='^sb/windowtest$'><WindowTest p={this}/></RouterElement>
        <RouterElement route='^sb/detailstest$'><DetailsTest p={this}/></RouterElement>
        <RouterElement route='^sb/tabtest$'><TabBarTest p={this}/></RouterElement>
        <RouterElement route='^sb/bctest$'><BreadCrumbTest p={this}/></RouterElement>
        <RouterElement route='^sb/faha$'><FahaTest/></RouterElement>
        <RouterElement route='^sb/buttons$'><ButtonTest p={this}/></RouterElement>
        <RouterElement route='^sb/wizard$'><WizardTest p={this}/></RouterElement>
        <RouterElement route='^sb/variabeln$'><VariablenTest p={this}/></RouterElement>
        <RouterElement route='^sb/checkbox$'><CheckboxTest p={this}/></RouterElement>
        <RouterElement route='^sb/details$'><DetailsTest p={this}/></RouterElement>
        <RouterElement route='^sb/drag$'><DraggableListsTest/></RouterElement>
        <RouterElement route='^sb/radio$'><RadioTest p={this}/></RouterElement>
        <RouterElement route='^sb/filtermanager$'><FilterManagerTest p={this}/></RouterElement>
        <RouterElement route='^sb/permissions$'><PermissionTest p={this}/></RouterElement>
        <RouterElement route='^sb/settings$'><SettingTest p={this}/></RouterElement>
        <RouterElement route='^sb/profile$'><ProfileTest p={this}/></RouterElement>
        <RouterElement route='^sb/account$'><AccountTest p={this}/></RouterElement>
        <RouterElement route='^sb/clone$'><DialogCloneCampaign p={this}/></RouterElement>
        <RouterElement route='^sb/alert$'><AlertTest p={this}/></RouterElement>
        <RouterElement route='^sb/memoryTable$'><MemoryTableTest p={this}/></RouterElement>
        <RouterElement route='^sb/tableExport$'><TableExportTest p={this}/></RouterElement>
      </div>
    </VerticalLayout>
  }
}

export default SandBox;

import Dto from "../../Dto";

class Update extends Dto {

  constructor(changedRecords = null) {
    super('po2\\service\\entity\\dto\\Update');
    this.changedRecords = changedRecords;
  }

  getChangedRecords() {
    return this.changedRecords;
  }

  setChangedRecords(changedRecords) {
    this.changedRecords = changedRecords;
  }
}

export default Update;

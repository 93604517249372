import PropTypes from "prop-types";
import BaseComponent from "../../../../../lib/components/BaseComponent";
import InputText from "../../inputFields/InputText";
import "./ValueField.css";
import DataType from "../../../../../lib/model/types/DataType";
import React from "react";
import InputDateTimePicker from "../../inputFields/InputDateTimePicker";
import Checkbox from "../../../../../lib/components/checkbox/Checkbox";
import GF from "../../../../../lib/utils/GF";
import Menu from "../../menu/Menu";
import MenuItemGroup from "../../../../../lib/components/menu/MenuItemGroup";
import MenuItem from "../../../../../lib/components/menu/MenuItem";
import shortid from "shortid";
import InputSearchDropDown from "../../inputFields/dropdown/InputSearchDropDown";

class ValueField extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      fieldDef: GF.getValue(this.props.fieldDef)
    }
  }

  onChange(e, id, value) {
    this.props.fieldPredicateRef.current.onFieldValueChange(e, value);
  }

  onDateTimeChange(e, id, value) {
    this.props.fieldPredicateRef.current.onFieldValueChange(null, null, value);
  }

  initField() {
    let field;
    if (GF.checkNotNull(this.state.fieldDef)) {
      switch (this.state.fieldDef.type.type) {
        case DataType.BOOLEAN:
          field = <Checkbox key={shortid()} checked={false} type='checkbox' onChange={(e, id, value) => this.onChange(e, id, value)} inputLayout='no_container'/>;
          break;
        case DataType.INTEGER:
        case DataType.FLOAT:
        case DataType.STRING:
          field = <InputText className={'cn-filter-field h-full ' +
            this.props.className} classNameOutline='h-full' inputLayout='outline' placeholder={this.props.labelElement}
                             onChange={(e, id, value) => this.onChange(e, id, value)} defaultValue={this.props.value}/>;
          // if (this.props.fieldType.multiline) {
          //   field = <TextAreaField>{this.props.value}</TextAreaField>;
          // } else if (this.props.fieldType.isPassword) {
          //   field = <PasswordField>{this.props.value}</PasswordField>;
          // } else if (this.props.fieldType.isHtml) {
          //   field = <HtmlField>{this.props.value}</HtmlField>;
          // } else if (this.props.fieldType.isLink) {
          //   field = <LinkField>{this.props.value}</LinkField>;
          // } else {
          //   field = <TextField>{this.props.value}</TextField>;
          // }
          break;
        case DataType.DATETIME:
          field = <InputDateTimePicker defaultValue={this.props.value} onChange={(e, id, value) => this.onDateTimeChange(e, id, value)}
                                       inputLayout='outline' useTime={false} canChooseUseTime={true}/>;
          break;
        case DataType.ENUM:
          field = this.buildInputDropDown();
          break;
        case DataType.DATA_EXT:
        case DataType.OBJECT:
        default:
          field = <InputText className={'cn-filter-field h-full ' +
            this.props.className} classNameOutline='h-full' inputLayout='outline' placeholder={this.props.labelElement} onChange={(e, id, value) => this.onChange(
            e, id, value)} defaultValue={this.props.value}/>;
          break;
      }
    } else {
      field = <InputText className={'cn-filter-field h-full ' +
        this.props.className} classNameOutline='h-full' inputLayout='outline' placeholder={this.props.labelElement} onChange={(e, id, value) => this.onChange(
        e, id, value)} defaultValue={this.props.value}/>;
    }
    return field;
  }

  buildInputDropDown() {
    let enumValue = this.state.fieldDef.type.valueMap[this.props.value];
    let enumValueString = '';
    if (Array.isArray(enumValue)) {
      enumValueString = enumValue.join(',');
    } else {
      enumValueString = enumValue;
    }
    if (enumValueString === '') {
      enumValueString = '---';
    }
    let menuItems = [];
    for (let index in this.state.fieldDef.type.valueMap) {
      let element = this.state.fieldDef.type.valueMap[index];
      menuItems.push(<MenuItem key={shortid()} option={index} searchValue={element}>{element}</MenuItem>);
    }
    return <InputSearchDropDown className='min-w-[150px] h-full justify-center' inputLayout='outline'
                                onChange={(e, id, value) => this.onChange(e, id, value)}
                                defaultValue={{option: this.props.value, displayValue: enumValueString}}>
      <Menu>
        <MenuItemGroup>
          {menuItems}
        </MenuItemGroup>
      </Menu>
    </InputSearchDropDown>;
  }

  updateFieldType(e, option) {
    this.state.fieldDef = this.props.fieldDefs[option];
    this.setState(this.state);
  }

  render() {
    return this.initField();
  }
}

ValueField.propTypes = {
  labelElement: PropTypes.string,
  value: PropTypes.string,
  fieldPredicateRef: PropTypes.object,
  className: PropTypes.string,
  fieldDef: PropTypes.object,
  fieldDefs: PropTypes.object
}
export default ValueField;

import Dto from "../../Dto";

class Close extends Dto {

  key;

  constructor(key = '') {
    super('po2\\service\\oauth\\dto\\Close');
    this.key = key;
  }

  getKey() {
    return this.key;
  }

  setKey(value) {
    this.key = value;
  }
}

export default Close;

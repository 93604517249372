import CM from "../../../../appl/model/system/CM";
import BaseComponent from "../../../../lib/components/BaseComponent";
import "./SandBox.css";
import {createRef} from "react";
import './Nav.css';
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import {BarChart2, PieChart} from "react-feather";
import ButtonSmall from "../../../../appl/view/components/buttons/ButtonSmall";
import Link from "../../../../lib/components/Link/Link";

class Nav extends BaseComponent {

  constructor(props) {
    super(props);
    this.themeSwitcher = createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    let themeClassName = CM.get().getGlobalValue('theme');
    for (let i = 0; i < this.themeSwitcher.current.children.length; i++) {
      let option = this.themeSwitcher.current.children[i];
      option.selected = option.value === themeClassName;
    }
  }

  async onChangeLang(e) {
    CM.get().setLanguage(e.target.value);
  }

  async onChangeTheme(e) {
    CM.get().setTheme(e.target.value);
  }

  render() {
    return <HorizontalLayout className='flex-wrap items-center px-5'>
      <Link to='/'>
        <div className="flex-none"><h1>Sandbox-Testfälle</h1></div>
      </Link>
      <div className="flex-1 ml-2">
        <select ref={this.themeSwitcher} className="bg-cn-color-black-804" name="theme" id="theme" onChange={this.onChangeTheme}>
          <option value={CM.STD_DARK}>dark</option>
          <option value={CM.STD_LIGHT}>light</option>
        </select>
      </div>
      <HorizontalLayout className='flex-wrap' widthFit>
        <Link to='/sb/inputfields'>
          <ButtonSmall className='m-1' color='secondary'>InputTextField</ButtonSmall>
        </Link>
        <Link to='/sb/colorpicker'>
          <ButtonSmall className='m-1' color='secondary'>Colorpicker</ButtonSmall>
        </Link>
        <Link to='/sb/tagstest'>
          <ButtonSmall className='m-1' color='secondary'>Tags</ButtonSmall>
        </Link>
        <Link to='/sb/inputwysiwyg'>
          <ButtonSmall className='m-1' color='secondary'>WYSIWYG-Editor-Test</ButtonSmall>
        </Link>
        <Link to='/sb/inputmultilinetest'>
          <ButtonSmall className='m-1' color='secondary'>Input-Multiline-Test</ButtonSmall>
        </Link>
        <Link to='/sb/dropdownstest'>
          <ButtonSmall className='m-1' color='secondary'>DropDowns-Test</ButtonSmall>
        </Link>
        <Link to='/sb/uploadtest'>
          <ButtonSmall className='m-1' color='secondary'>Upload-Test</ButtonSmall>
        </Link>
        <Link to='/sb/accordiontest'>
          <ButtonSmall className='m-1' color='secondary'>Accordion-Test</ButtonSmall>
        </Link>
        <Link to='/sb/campaigns'>
          <ButtonSmall className='m-1' color='secondary'>Campaigns-Test</ButtonSmall>
        </Link>
        <Link to='/sb/progressbar'>
          <ButtonSmall className='m-1' color='secondary'>ProgressBar-Test</ButtonSmall>
        </Link>
        <Link to='/sb/gridtest'>
          <ButtonSmall className='m-1' color='secondary'>Grid-Test</ButtonSmall>
        </Link>
        <Link to='/sb/datepicker'>
          <ButtonSmall className='m-1' color='secondary'>DateTimePicker-Test</ButtonSmall>
        </Link>
        <Link to='/sb/window'>
          <ButtonSmall className='m-1' color='secondary'>Window-Test</ButtonSmall>
        </Link>
        <Link to='/sb/piechart'>
          <ButtonSmall className='m-1' color='secondary'><PieChart/>Overview-Test</ButtonSmall>
        </Link>
        <Link to='/sb/barchart'>
          <ButtonSmall className='m-1' color='secondary'><BarChart2/>BarChart-Test</ButtonSmall>
        </Link>
        <Link to='/sb/dialogtest'>
          <ButtonSmall className='m-1' color='secondary'>Dialog-Test</ButtonSmall>
        </Link>
        <Link to='/sb/extendedfilter'>
          <ButtonSmall className='m-1' color='secondary'>Erweiterter Filter-Test</ButtonSmall>
        </Link>
        <Link to='/sb/bctest'>
          <ButtonSmall className='m-1' color='secondary'>Breadcrumb-Test</ButtonSmall>
        </Link>
        <Link to='/sb/tabtest'>
          <ButtonSmall className='m-1' color='secondary'>Tabbar-Test</ButtonSmall>
        </Link>
        <Link to='/sb/faha'>
          <ButtonSmall className='m-1' color='secondary'>Fahas-Test</ButtonSmall>
        </Link>
        <Link to='/sb/buttons'>
          <ButtonSmall className='m-1' color='secondary'>Button-Test</ButtonSmall>
        </Link>
        <Link to='/sb/wizard'>
          <ButtonSmall className='m-1' color='secondary'>Wizard-Test</ButtonSmall>
        </Link>
        <Link to='/sb/variabeln'>
          <ButtonSmall className='m-1' color='secondary'>Variabeln-Test</ButtonSmall>
        </Link>
        <Link to='/sb/details'>
          <ButtonSmall className='m-1' color='secondary'>Detail-Test</ButtonSmall>
        </Link>
        <Link to='/sb/drag'>
          <ButtonSmall className='m-1' color='secondary'>Draggable List-Test</ButtonSmall>
        </Link>
        <Link to='/sb/checkbox'>
          <ButtonSmall className='m-1' color='secondary'>Checkbox-Test</ButtonSmall>
        </Link>
        <Link to='/sb/radio'>
          <ButtonSmall className='m-1' color='secondary'>Radio-Test</ButtonSmall>
        </Link>
        <Link to='/sb/filtermanager'>
          <ButtonSmall className='m-1' color='secondary'>FilterManager-Test</ButtonSmall>
        </Link>
        <Link to='/sb/permissions'>
          <ButtonSmall className='m-1' color='secondary'>Berechtigungen-Test</ButtonSmall>
        </Link>
        <Link to='/sb/settings'>
          <ButtonSmall className='m-1' color='secondary'>Einstellungen-Test</ButtonSmall>
        </Link>
        <Link to='/sb/profile'>
          <ButtonSmall className='m-1' color='secondary'>Profile-Test</ButtonSmall>
        </Link>
        <Link to='/sb/account'>
          <ButtonSmall className='m-1' color='secondary'>Account-Test</ButtonSmall>
        </Link>
        <Link to='/sb/clone'>
          <ButtonSmall className='m-1' color='secondary'>Clone-Test</ButtonSmall>
        </Link>
        <Link to='/sb/swd'>
          <ButtonSmall className='m-1' color='secondary'>SWD-Test</ButtonSmall>
        </Link>
        <Link to='/sb/alert'>
          <ButtonSmall className='m-1' color='secondary'>Alert-Test</ButtonSmall>
        </Link>
        <Link to='/sb/memoryTable'>
          <ButtonSmall className='m-1' color='secondary'>MemoryTable-Test</ButtonSmall>
        </Link>
        <Link to='/sb/tableExport'>
          <ButtonSmall className='m-1' color='secondary'>Tabellenexport-Test</ButtonSmall>
        </Link>
      </HorizontalLayout>
    </HorizontalLayout>
  }
}

export default Nav;

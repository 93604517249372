import Query from "../query/Query";
import Predicate from "../query/Predicate";
import BooleanPredicate from "../query/BooleanPredicate";
import FieldPredicate from "../query/FieldPredicate";
import FieldName from "../query/FieldName";
import FilterProperties from "../../../appl/model/service/system/FilterProperties";

class JsonTransformer {

  copyProperties(sourceElement, instance) {
    for (let propertyName in sourceElement) {
      let propertyValue = sourceElement[propertyName];

      if (propertyValue !== undefined && propertyValue !== null && typeof propertyValue === 'object') {
        instance[propertyName] = this.decodeObject(propertyValue);
      } else {
        instance[propertyName] = propertyValue;
      }
    }
  }

  createInstance(_class, instance = null) {
    switch (_class) {
      case 'po2\\service\\entity\\Query':
        instance = new Query();
        break;
      case 'Predicate':
        instance = new Predicate();
        break;
      case 'po2\\service\\entity\\predicate\\BooleanPredicate':
        instance = new BooleanPredicate();
        break;
      case 'po2\\service\\entity\\predicate\\FieldPredicate':
        instance = new FieldPredicate();
        break;
      case 'po2\\service\\entity\\predicate\\FieldName':
        instance = new FieldName();
        break;
      case 'appl\\model\\service\\system\\FilterProperties':
        instance = new FilterProperties();
        break;
      default:
        break;
    }
    return instance;
  }

  decodeObject(rootElementNode) {
    let instance = null;
    if (typeof rootElementNode === 'object') {
      if (rootElementNode.hasOwnProperty('_class')) {
        instance = this.createInstance(rootElementNode._class, rootElementNode);
      } else {
        if (Array.isArray(rootElementNode)) {
          instance = [];
        } else {
          instance = {};
        }
      }
      this.copyProperties(rootElementNode, instance);
    } else {
      throw new DOMException('Das Element muss ein Objekt sein!');
    }
    return instance;
  }

  static decodeString(rootElementString) {
    return (new JsonTransformer()).decodeObject(JSON.parse(rootElementString));
  }
}

export default JsonTransformer;

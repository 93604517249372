import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";

class Layout extends BaseComponent {

  constructor(props) {
    super(props);

    this.state = {
      content: <div ref={this.reference} />
    }
    if (this.props.content !== undefined && this.props.content !== null) {
      this.state.content = this.props.content;
    }
  }

  getContent() {
    return this.state.content;
  }

  render() {
    return this.getContent();
  };
}

Layout.propTypes = {
  content: PropTypes.node
}

export default Layout;

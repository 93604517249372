import Dto from "../../Dto";

class Authenticate extends Dto {

  login;
  password;
  captchaToken;

  constructor(login = '', password = '', captchaToken = null) {
    super('po2\\service\\oauth\\dto\\Authenticate');
    this.login = login;
    this.password = password;
    this.captchaToken = captchaToken;
  }

  getLogin() {
    return this.login;
  }

  getPassword() {
    return this.password;
  }

  getCaptchaToken() {
    return this.captchaToken;
  }

  setLogin(login) {
    this.login = login;
  }

  setPassword(password) {
    this.password = password;
  }

  setcaptchaToken(captchaToken) {
    this.captchaToken = captchaToken;
  }
}

export default Authenticate;

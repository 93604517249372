import BaseComponent from "../../../../../lib/components/BaseComponent";
import DialogBase from "../../../../../lib/components/dialogs/DialogBase";
import MailingWizard from "../../../../../appl/view/components/mailingWizard/MailingWizard";

class WizardTest extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      mydialog: null
    }
  }

  test(event) {
    this.state.mydialog = <DialogBase title='Wizard' onCloseButtonClick={() => {
      this.state.mydialog = null;
      this.setState(this.state)
    }} className='bg-cn-color-black-804 rounded-xl rounded-tl-none'>
      <MailingWizard/>
    </DialogBase>;
    this.setState(this.state);
  }

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>
      <h1>Wizard Test</h1>
      <button onClick={(e) => {
        this.test(e)
      }}>open wizard
      </button>
      {this.state.mydialog}
    </div>
  }
}

export default WizardTest;

import BaseComponent from "../../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import ChartEntry from "../../../../../lib/components/charts/ChartEntry";
import BarChart from "../../../../../lib/components/charts/BarChart";

class BarChartTest extends BaseComponent {

  render() {
    let data = [new ChartEntry(500, 'Gesamt', '#27ccbc'), new ChartEntry(200, 'Gesendet', '#4f8fbf'),
      new ChartEntry(100, 'Postausgang', '#70e4d8'), new ChartEntry(50, 'Blockiert', '#26648b'),
      new ChartEntry(260, 'Fehlerhaft', '#26648b')];

    return <VerticalLayout className='p-20 sandbox-container flex flex-col gap-4'>
      <div className={'max-h-80 w-full bg-cn-color-black p-5 flex justify-center'}>
        <BarChart width={800} data={data}/>
      </div>
    </VerticalLayout>
  }
}

export default BarChartTest;

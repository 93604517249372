import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import HorizontalLayout from "../../../../../lib/components/layouts/HorizontalLayout";
import shortid from "shortid";
import InputDropDownBase from "./InputDropDownBase";
import ReactDOM from "react-dom";

class InputDropDown extends InputDropDownBase {

  constructor(props) {
    super(props);
    this.dropDownContentRef = createRef();
  }

  render() {
    if (this.state.selectedOption === undefined || this.state.selectedOption === '') {
      this.state.selectedOption = this.props.defaultValue ? this.props.defaultValue : {option: '', displayValue: ''};
    }
    let className = this.props.className ? this.props.className : '';
    let fillColor;
    switch (this.props.color) {
      case 'primary':
        fillColor = ' bg-cn-color-primary ';
        break;
      case 'secondary':
        fillColor = ' bg-cn-color-secondary ';
        break;
      case 'dark':
        fillColor = ' bg-cn-color-black-804 ';
        break;
      case 'light':
      default:
        fillColor = ' bg-cn-color-black';
    }
    this.createPicker(null, 'top-10');
    let rounded = this.props.isInGroup ? 'first:rounded-bl-lg' : 'rounded-lg rounded-tl-none';
    let style = this.getStylePositionInfo(this.dropDownContentRef.current);
    let rootNode = document.getElementById('root');
    return <div ref={this.dropDownContentRef} key={shortid()} className={`relative flex ${fillColor} ${rounded} cursor-pointer ` +
      className}>
      <HorizontalLayout className='items-center justify-between pl-2 gap-3' onClick={(e) => this.onInputClick(e)}>
        {this.state.selectedOption.displayValue}
        {this.state.iconRight}
      </HorizontalLayout>
      {ReactDOM.createPortal(<div className='fixed' style={style}>{this.state.menuPicker}</div>, rootNode)}
    </div>;
  }
}

InputDropDown.propTypes = {
  className: PropTypes.string,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  defaultValue: PropTypes.shape({option: PropTypes.any, displayValue: PropTypes.any}),
  onChange: PropTypes.func,
  color: PropTypes.oneOf(['primary', 'secondary', 'light', 'dark'])
}
export default InputDropDown;

import BaseComponent from "../BaseComponent";
import React from "react";
import PropTypes from "prop-types";
import GF from "../../utils/GF";

class SelectableItem extends BaseComponent {

  render() {
    return <div className={'selectoItem ' + GF.getValue(this.props.className, '')} id={this.props.elementId} data-element-active={this.props.active} data-element-id={GF.getValue(
      this.props.elementId, '')}>{this.props.children}</div>;
  }
}

SelectableItem.propTypes = {
  elementId: PropTypes.string, active: PropTypes.bool, className: PropTypes.string
}
export default SelectableItem;

import PropTypes from "prop-types";
import InputBase from "./InputBase";
import {createRef} from "react";
import TinyMceEditor from "../../../../lib/components/wysiwyg/tinyMce/TinyMceEditor";

class InputWysiwyg extends InputBase {

  constructor(props) {
    super(props);
    this.wysiwygElementRef = createRef();
  }

  getValue() {
    return this.wysiwygElementRef.current.getValue();
  }

  render() {
    this.state.children =
      <TinyMceEditor ref={this.wysiwygElementRef} defaultValue={this.props.defaultValue} variablesItems={this.props.variablesItems}
                     onChange={(e, value) => this.fireOnChange(e, value)}/>
    return super.render();
  }

}

InputWysiwyg.propTypes = {
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  inputLayout: PropTypes.oneOf(['inline', 'outline', '']),
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  variablesItems: PropTypes.array
}
export default InputWysiwyg;


class EntityDef {

  constructor(entityName) {
    this.entityDef = {
      baseDef: {
        name: entityName, privilege: {privilege: 65535}
      },
      fieldsDef: {}
    }
  }

  addField(fieldName, description, type, isKey = false) {
    let typeObj = type;
    if (typeof typeObj === 'string') {
      typeObj = {type: type, isKey: isKey};
    }
    this.entityDef.fieldsDef[fieldName] = {name: fieldName, description: description, type: typeObj, privilege: {privilege: 65535}};
  }

  getEntityDef() {
    return this.entityDef;
  }
}


export default EntityDef;

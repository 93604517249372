import shortid from "shortid";
import BaseFieldGenerator from "./BaseFieldGenerator";
import Checkbox from "../../../../../lib/components/checkbox/Checkbox";
import InputText from "../../inputFields/InputText";
import InputTextMultiline from "../../inputFields/InputTextMultiline";
import InputPassword from "../../inputFields/InputPassword";
import GF from "../../../../../lib/utils/GF";
import InputLink from "../../inputFields/InputLink";
import InputDateTimePicker from "../../inputFields/InputDateTimePicker";
import MenuItem from "../../../../../lib/components/menu/MenuItem";
import Menu from "../../menu/Menu";
import MenuItemGroup from "../../../../../lib/components/menu/MenuItemGroup";
import InputUpload from "../../inputFields/InputUpload";
import InputWysiwyg from "../../inputFields/InputWysiwyg";
import InputSearchDropDown from "../../inputFields/dropdown/InputSearchDropDown";
import InputColorPicker from "../../inputFields/InputColorPicker";
import React from "react";
import Privilege from "../../../../../lib/model/types/Privilege";

class InputFieldGenerator extends BaseFieldGenerator {

  static extractLink(value) {
    let text = '';
    let href = '';
    if (GF.checkNotNull(value) && value !== '') {
      let linkInfoString = value.replace(/&quot;/gm, '"');
      linkInfoString = linkInfoString.replace(/\\\//gm, '/');
      let linkInfo = JSON.parse(linkInfoString);
      if (linkInfo !== null) {
        text = linkInfo.text;
        href = linkInfo.href;
      }
    }
    return {text: text, href: href};
  }

  constructor(onChange = null, inputLayout = 'inline') {
    super();
    if (onChange === null) {
      onChange = (e, id, value) => {
        // dummy
      }
    }
    this.onChange = onChange;
    this.inputLayout = inputLayout;
  }

  initBooleanField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    let defaultValue;
    switch (typeof fieldValue) {
      case 'string':
        defaultValue = fieldValue.toLowerCase() === 'ja' || fieldValue.toLowerCase() === 'yes';
        break;
      default:
        defaultValue = fieldValue;
    }
    return <Checkbox _id={id} key={shortid()} ref={fieldRef} checked={defaultValue} type='checkbox' labelElement={fieldLabel} onChange={(e, id, value) => this.onChange(
      e, id, value)} inputLayout={this.inputLayout} readonly={(privilege & Privilege.ALLOW_EDIT) === 0}/>;
  }

  initIntegerField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return this.initTextField(id, fieldRef, fieldValue, fieldLabel, privilege);
  }

  initFloatField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return this.initTextField(id, fieldRef, fieldValue, fieldLabel, privilege);
  }

  initTextAreaField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <InputTextMultiline _id={id} key={shortid()} ref={fieldRef} labelElement={fieldLabel} defaultValue={fieldValue} onChange={(e, id, value) => this.onChange(
      e, id, value)} inputLayout={this.inputLayout} state={BaseFieldGenerator.generateState(privilege)}/>;
  }

  initPasswordField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <InputPassword _id={id} ref={fieldRef} key={shortid()} labelElement={fieldLabel} defaultValue={fieldValue} onChange={(e, id, value) => this.onChange(
      e, id, value)} inputLayout={this.inputLayout} state={BaseFieldGenerator.generateState(privilege)}/>;
  }

  initHtmlField(id, fieldRef, fieldValue, fieldLabel, valueMap, privilege) {
    return <InputWysiwyg _id={id} ref={fieldRef} key={shortid()} labelElement={fieldLabel} defaultValue={fieldValue} onChange={(e, id, value) => this.onChange(
      e, id, value)} inputLayout={this.inputLayout} variablesItems={valueMap} state={BaseFieldGenerator.generateState(privilege)}>{fieldValue}</InputWysiwyg>;
  }

  initLinkField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <InputLink _id={id} ref={fieldRef} key={shortid()} labelElement={fieldLabel} defaultValue={InputFieldGenerator.extractLink(
      fieldValue)} onChange={(e, id, value) => this.onChange(e, id, value)} inputLayout={this.inputLayout} state={BaseFieldGenerator.generateState(privilege)}/>;
  }

  initTextField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <InputText _id={id} ref={fieldRef} key={shortid()} labelElement={fieldLabel} defaultValue={fieldValue} onChange={(e, id, value) => this.onChange(
      e, id, value)} inputLayout={this.inputLayout} state={BaseFieldGenerator.generateState(privilege)}/>;
  }

  initDateTimePickerField(id, fieldRef, fieldValue, fieldLabel, privilege, useTime) {
    return <InputDateTimePicker _id={id} ref={fieldRef} key={shortid()} defaultValue={fieldValue} labelElement={fieldLabel} onChange={(e, id, value) => this.onChange(
      e, id, value)} inputLayout={this.inputLayout} useTime={useTime} canChooseUseTime={true} state={BaseFieldGenerator.generateState(privilege)}/>;
  }

  initColorPickerField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return <InputColorPicker _id={id} ref={fieldRef} key={shortid()} hex={fieldValue} labelElement={fieldLabel} onChange={(e, id, value) => this.onChange(
      e, id, value)} inputLayout={this.inputLayout} state={BaseFieldGenerator.generateState(privilege)}/>
  }

  initEnumField(fieldType, id, fieldRef, fieldValue, fieldLabel, privilege) {
    let menuItems = [];
    let defaultValue = '';
    let defaultKeyValue = '';
    if (GF.checkNotNull(fieldType.valueMap)) {
      if (typeof fieldType.valueMap === 'object') {
        for (let index in fieldType.valueMap) {
          let enumValue = fieldType.valueMap[index];
          index = parseInt(index);
          if (typeof enumValue === 'object') {
            if (Array.isArray(enumValue)) {
              if (index === fieldValue) {
                defaultValue = enumValue[0];
                defaultKeyValue = index;
              }
              menuItems.push(<MenuItem className='text-cn-color-white' _id={id} key={shortid()} searchValue={enumValue[0]} option={index}>{enumValue[0]}</MenuItem>);
            } else {
              if (enumValue.key === fieldValue) {
                defaultValue = enumValue.value;
                defaultKeyValue = enumValue.key;
              }
              menuItems.push(<MenuItem className='text-cn-color-white' _id={id} key={shortid()} searchValue={enumValue.value} option={enumValue.key}>{enumValue.value}</MenuItem>);
            }
          } else {
            if (index === fieldValue) {
              defaultValue = enumValue;
              defaultKeyValue = index;
            }
            menuItems.push(<MenuItem className='text-cn-color-white' _id={id} key={shortid()} searchValue={enumValue} option={index}>{enumValue}</MenuItem>);
          }
        }
      } else {
        menuItems = fieldType.valueMap.map(value => {
          return <MenuItem className='text-cn-color-white' key={shortid()} searchValue={value.value} option={value.key}>{value.value}</MenuItem>;
        });
      }
    }

    return <InputSearchDropDown _id={id} key={shortid()} ref={fieldRef} labelElement={fieldLabel} defaultValue={{
      option: defaultKeyValue, displayValue: defaultValue
    }} onChange={(e, id, value) => this.onChange(e, id, value)} inputLayout={this.inputLayout} state={BaseFieldGenerator.generateState(privilege)}>
      <Menu>
        <MenuItemGroup>
          {menuItems}
        </MenuItemGroup>
      </Menu>
    </InputSearchDropDown>;
  }

  initResourceField(id, fieldRef, fieldValue, fieldLabel, dtoProperties, privilege) {
    return <InputUpload _id={id} ref={fieldRef} key={shortid()} dtoProperties={dtoProperties} labelElement={fieldLabel} defaultValue={fieldValue} onChange={(e, id, value) => this.onChange(
      e, id, value)} inputLayout={this.inputLayout} state={BaseFieldGenerator.generateState(privilege)}/>;
  }
}

export default InputFieldGenerator;

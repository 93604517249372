import Dto from "../Dto";

class Describe extends Dto {

  _serviceName;

  constructor(_serviceName = '', _class = '') {
    super(_class);
    this._serviceName = _serviceName;
  }

  getServiceName() {
    return this._serviceName;
  }

  setServiceName(_serviceName) {
    this._serviceName = _serviceName;
  }

}

export default Describe;

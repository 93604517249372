import Dto from "../../../../../lib/model/service/Dto";

class CloneCampaign extends Dto {

  prevCampaignId;
  campaignName;
  campaignDescription;
  domainId;
  privilege;
  variableVariants;
  tables;

  constructor(prevCampaignId = '', campaignName = '', campaignDescription = '', domainId = '', privilege = '', variableVariants = [], tables = []) {
    super('appl\\service\\campaign\\dto\\CloneCampaign');
    this.prevCampaignId = prevCampaignId;
    this.campaignName = campaignName;
    this.campaignDescription = campaignDescription;
    this.domainId = domainId;
    this.privilege = privilege;
    this.variableVariants = variableVariants;
    this.tables = tables;
  }

  getCampaignId() {
    return this.campaignId;
  }

  setCampaignId(campaignId) {
    this.campaignId = campaignId;
  }

  getPageId() {
    return this.pageId;
  }

  setPageId(pageId) {
    this.pageId = pageId;
  }


  getPrevCampaignId() {
    return this.prevCampaignId;
  }

  setPrevCampaignId(value) {
    this.prevCampaignId = value;
  }

  getCampaignName() {
    return this.campaignName;
  }

  setCampaignName(value) {
    this.campaignName = value;
  }

  getCampaignDescription() {
    return this.campaignDescription;
  }

  setCampaignDescription(value) {
    this.campaignDescription = value;
  }

  getDomainId() {
    return this.domainId;
  }

  setDomainId(value) {
    this.domainId = value;
  }

  getPrivilege() {
    return this.privilege;
  }

  setPrivilege(value) {
    this.privilege = value;
  }

  getVariableVariants() {
    return this.variableVariants;
  }

  setVariableVariants(value) {
    this.variableVariants = value;
  }

  getTables() {
    return this.tables;
  }

  setTables(value) {
    this.tables = value;
  }
}

export default CloneCampaign;

import BaseComponent from "../../../../lib/components/BaseComponent";
import WizardBase from "../../../../lib/components/wizard/WizardBase";
import WizardNav from "./WizardNav";
import WizardContentSelectList from "./WizardContentSelectList";
import WizardContentConfiguration from "./WizardContentConfiguration";
import {createRef} from "react";
import WizardContentDate from "./WizardContentDate";
import WizardContentComments from "./WizardContentComments";
import WizardContentOverview from "./WizardContentOverview";
import PropTypes from "prop-types";

class MailingWizard extends BaseComponent {

  constructor(props) {
    super(props);
    this.wizardBase = createRef();
  }

  render() {
    return <WizardBase ref={this.wizardBase} wizardNav={<WizardNav wizardBase={this.wizardBase}/>} wizardContent={[
      <WizardContentSelectList campaignId={this.props.campaignId} entityName={this.props.entityName}/>, <WizardContentConfiguration/>, <WizardContentDate/>, <WizardContentComments/>,
      <WizardContentOverview/>]}/>
  }
}

MailingWizard.propTypes = {
  campaignId: PropTypes.any.isRequired, entityName: PropTypes.string.isRequired
}
export default MailingWizard;

import BaseComponent from "../BaseComponent";
import './ButtonStd.css';
import PropTypes from "prop-types";

class ButtonStd extends BaseComponent {

  onClick(e) {
    if (this.props.onClick !== undefined) {
      this.props.onClick(e);
    }
  }

  render() {
    let buttonStyle = this.props.className === undefined ? ' ' : this.props.className;

    buttonStyle = 'flex items-center justify-center gap-x-1 font-vgl-inter-regular ' + buttonStyle;
    if (this.props.disabled) {
      return <button disabled className={buttonStyle + ' btn-disabled'} onClick={(e) => this.onClick(e)}>
        {this.props.children}
      </button>
    } else {
      return <button className={buttonStyle} onClick={(e) => this.onClick(e)}>
        {this.props.children}
      </button>
    }
  }
}

ButtonStd.propTypes = {
  className: PropTypes.string, onClick: PropTypes.func
}

export default ButtonStd;

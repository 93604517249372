import {Search} from 'react-feather';
import PropTypes from "prop-types";
import InputBase from "./InputBase";
import T from "../../../model/system/text_translations/Translator";
import {createRef} from "react";

class InputSearch extends InputBase {

  constructor(props) {
    super(props);
    this.inputElementRef = createRef();
  }

  getValue() {
    return this.inputElementRef.current.value;
  }

  clear() {
    this.inputElementRef.current.value = '';
  }

  render() {
    this.state.iconLeft = <Search className='stroke-cn-color-primary self-center ml-1'/>;
    this.state.children = <>
      <input defaultValue={this.props.defaultValue} ref={this.inputElementRef} onChange={(e) => this.fireOnChange(
        e)} placeholder={T.label_search()} type='text' className='bg-transparent focus:ring-0 focus:outline-0 w-full h-full'/>
    </>;
    return super.render();
  }
}

InputSearch.propTypes = {
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  inputLayout: PropTypes.oneOf(['inline', 'outline', '']),
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
}
export default InputSearch;
import PropTypes from "prop-types";
import DataType from "../../../../../lib/model/types/DataType";
import EntityDef from "../../../../../lib/model/service/entity/EntityDef";
import UploadCardBase from "./UploadCardBase";

class UploadedFilesCard extends UploadCardBase {

  initEntityDef() {
    let entityDef = new EntityDef('');
    entityDef.addField('id', 'ID', DataType.INTEGER, true);
    entityDef.addField('filename', 'Dateiname', DataType.STRING);
    entityDef.addField('size', 'Grösse', DataType.STRING);

    return entityDef.getEntityDef();
  }

  render() {
    this.state.cardTitle = 'Heraufgeladene Dateien';

    return super.render();
  }
}

UploadedFilesCard.propTypes = {
  className: PropTypes.string
}
export default UploadedFilesCard;

import BaseComponent from "../BaseComponent";
import VerticalLayout from "../layouts/VerticalLayout";
import PropTypes from "prop-types";

class WizardBase extends BaseComponent {

  constructor(props) {
    super(props);
    this.dialogHook = null;
    this.state = {
      actualIndex: 0
    }
  }

  openDialog(content) {
    this.dialogHook = content;
    this.setState(this.state);
  }

  closeDialog() {
    this.dialogHook = null;
    this.setState(this.state);
  }

  goToStep(index) {
    this.state.actualIndex = index;
    this.setState(this.state);
  }

  back(e) {
    this.props.wizardNavRef.current.goToStep(this.state.actualIndex - 1);
  }

  next() {
    this.props.wizardNavRef.current.goToStep(this.state.actualIndex + 1);
  }

  render() {
    if (this.dialogHook !== null) {
      return this.dialogHook;
    } else {
      return <VerticalLayout>
        {this.props.wizardNav}
        {this.props.wizardContent[this.state.actualIndex]}
      </VerticalLayout>;
    }
  }
}

WizardBase.propTypes = {
  wizardNav: PropTypes.node,
  wizardContent: PropTypes.node,
  wizardNavRef: PropTypes.any
}
export default WizardBase;

import De from "./text_translations/De";
import Fr from "./text_translations/Fr";
import It from "./text_translations/It";
import En from "./text_translations/En";
import Callback from "./Callback";
import cner_config from "../../../cner.config";
import React from "react";
import GF from "../../../lib/utils/GF";
import VM from "../../../lib/model/system/VM";
import {toast} from "react-toastify";
import Operator from "../../../lib/model/query/Operator";

const md5 = require("blueimp-md5");

/**
 * class ClientManager
 */
class CM {

  static STD_LIGHT = 'std-light';
  static STD_DARK = 'std-dark';

  static LANG_DE = 'de';
  static LANG_FR = 'fr';
  static LANG_IT = 'it';
  static LANG_EN = 'en';

  static STATUS_IDLE = 'status_idle';
  static STATUS_WAITING = 'status_waiting';

  static HOMEPAGE = '';

  static instance: CM;

  static md5(value) {
    if (value === undefined || value === null) {
      value = '';
    }
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    return md5(value);
  }

  theme;
  langTranslation;
  onSetStateListeners = new Callback();
  onStatusChangeListeners = new Callback();
  onThemeChangeListeners = new Callback();
  uniqueId = 0;
  developer = '';
  status = '';
  panel = [];
  mainDialog = {mainDialog: null, isActive: false};
  onMainDialogCloseButtonClick = null;
  campaignUrl = '';

  constructor(theme = CM.STD_LIGHT) {
    this.theme = theme;
    this.uniqueId = 1;
    this.status = CM.STATUS_IDLE;
    window.addEventListener('popstate', (e) => this.private_onNavigate(e));
  }

  static get(theme = CM.STD_LIGHT) {
    if (!CM.instance) {
      CM.instance = new CM(theme);
    }
    return CM.instance;
  }

  /**
   * Translate
   *
   * Diese Funktion macht die Textübersetzung.
   *
   * @param text
   */
  static t(text) {
    let cm = CM.get();
    if (cm.langTranslation !== undefined && cm.langTranslation[text] !== undefined) {
      return cm.langTranslation[text];
    } else {
      return text;
    }
  }

  addOnSetStateListener(onSetStateListener) {
    this.onSetStateListeners.addListener(onSetStateListener);
  }

  addOnStatusChangeListeners(onStatusChangeListener) {
    this.onStatusChangeListeners.addListener(onStatusChangeListener);
  }

  addThemeChangeListener(themeChange) {
    this.onThemeChangeListeners.addListener(themeChange);
  }

  createUniquId() {
    return ++this.uniqueId;
  }

  /**
   * Macht ein XHR (Xml-Http-Request)
   * @param url
   * @param data
   * @param onSuccess
   * @returns {Promise<XMLHttpRequest>}
   */
  doAjax(url, data, onSuccess) {
    let self = this;
    let xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new window.ActiveXObject("Microsoft.XMLHTTP");

    xhr.open('POST', url, true);
    xhr.onreadystatechange = function() {
      // success
      if (xhr.readyState > 3 && xhr.status === 200) {
        if (typeof onSuccess === 'function') {
          let response = null;
          try {
            response = JSON.parse(xhr.responseText);
            if (response.message.includes('Bitte einloggen')) {
              toast.error('Bitte loggen sie sich ein!');
              self.logout();
            } else {
              onSuccess(response, xhr.readyState, xhr.status);
            }
          } catch (e) {
            onSuccess({success: false, message: GF.STD_ALERT_MESSAGE}, xhr.readyState, xhr.status);
          }
        } else {
          throw new DOMException('onSuccess muss eine Funktion sein!');
        }
      }
      self.status = CM.STATUS_IDLE;
      self.onStatusChangeListeners.fire(self.status);
    };
    let token = this.getToken();
    if (token !== undefined && token !== null && token !== '') {
      xhr.setRequestHeader('oauth-token', token);
    }
    //xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    //xhr.setRequestHeader('Content-Type', 'application/json');
    //xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    //xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(data);
    this.status = CM.STATUS_WAITING;
    this.onStatusChangeListeners.fire(this.status);

    return xhr;
  }

  logout() {
    this.setSessionValue('oauth_token', null, 'oauth');
    VM.get().clearDataAll();
    this.setRoute('/');
  }

  /**
   * Macht ein XHR (Xml-Http-Request)
   * @param url
   * @param data
   * @param onSuccess
   * @returns {Promise<XMLHttpRequest>}
   */
  async doJsonAjax(url, data, onSuccess) {
    const formData = new FormData();
    formData.append('dto', JSON.stringify(data));

    return CM.get().doAjax(url, formData, onSuccess);
  }

  doJsonAjaxCache(url, data, onSuccess, id = '') {
    let keyMd5 = CM.md5(data);
    let response = VM.get().getData('CM.doJsonAjaxCache_' + id, keyMd5);
    if (response !== null) {
      setTimeout(() => {
        onSuccess(response);
      }, 1)
    } else {
      CM.get().doJsonAjax(url, data, (response, readyState, status) => {
        VM.get().setData('CM.doJsonAjaxCache_' + id, response, keyMd5);
        onSuccess(response);
      });
    }
  }

  fireOnSetState() {
    this.onSetStateListeners.fire();
  }

  onMainDialogClose(e) {
    if (GF.checkNotNull(this.onMainDialogCloseButtonClick)) {
      this.onMainDialogCloseButtonClick(e);
    }
    this.mainDialog.isActive = false;
    this.onSetStateListeners.fire();
  }

  getMainDialog() {
    let alertDialog;
    if (this.mainDialog.isActive) {
      alertDialog = this.mainDialog.mainDialog;
    } else {
      alertDialog = null;
    }
    this.mainDialog.isActive = false;
    return alertDialog;
  }

  closeMainDialog(e) {
    this.mainDialog.mainDialog = null;
    this.mainDialog.isActive = false;
    this.fireOnSetState();
  }

  getCampaignUrl() {
    return this.campaignUrl;
  }

  getBaseUrl() {
    return cner_config[this.developer].cner_base_url;
  }

  getLandingUrl() {
    return cner_config[this.developer].cner_landing_url;
  }

  getHCaptchaSiteKey() {
    return cner_config[this.developer].cner_hcaptcha_site_key;
  }

  getGlobalValue(id, scope = 'default') {
    return this.private_getStorageValue('global', id, scope);
  }

  getPanel(id = 'default') {
    return this.panel[id];
  }

  getServiceUrl(serviceName) {
    return cner_config[this.developer].cner_service_url + serviceName + '/';
  }

  getSessionValue(id, scope = 'default') {
    return this.private_getStorageValue('session', id, scope);
  }

  private_getStorageValue(mode, id, scope) {
    let globalBaseValueFromStorage = this.private_getStorageBaseValue(mode, scope);

    if (globalBaseValueFromStorage !== undefined && globalBaseValueFromStorage !== null && globalBaseValueFromStorage.hasOwnProperty(id)) {
      return globalBaseValueFromStorage[id];
    } else {
      return null;
    }
  }

  private_getStorageBaseValue(mode, scope) {
    let storageId;
    let storage;
    let globalBaseValueFromStorage;
    if (mode === 'global') {
      storageId = 'CM_LocalStorage_Value' + scope;
      storage = 'localStorage';
    } else {
      storageId = 'CM_SessionStorage_Value' + scope;
      storage = 'sessionStorage';
    }
    try {
      globalBaseValueFromStorage = JSON.parse(window[storage].getItem(storageId));
    } catch (e) {
      globalBaseValueFromStorage = null;
    }
    return globalBaseValueFromStorage;
  }

  getTheme() {
    return this.theme;
  }

  getToken() {
    return CM.get().getSessionValue('oauth_token', 'oauth');
  }

  init(theme = CM.STD_LIGHT) {
    this.initDeveloper();
    let themeFromGlobalValue = this.getGlobalValue('theme');
    if (themeFromGlobalValue !== undefined && themeFromGlobalValue !== null && themeFromGlobalValue !== '') {
      theme = themeFromGlobalValue;
    }
    this.theme = theme;
    this.modifyTheme();

    let lang = this.getGlobalValue('lang');
    if (lang) {
      this.setLanguage(lang);
    } else {
      this.setLanguage();
    }
  }

  initDeveloper() {
    let developer = 'prod';
    try {
      let CnerIgnoreGitConfig = require('../../../cner.ignoregit.config');
      developer = CnerIgnoreGitConfig.default.developer_geek;
    } catch (e) {
    }
    this.developer = developer;
  }

  modifyTheme() {
    let rootNode = document.getElementById('root');
    switch (this.theme) {
      case CM.STD_LIGHT:
        rootNode.className = 'cn_light-mode';
        break;
      case CM.STD_DARK:
        rootNode.className = 'cn_dark-mode';
        break;
      default:
        rootNode.className = 'cn_dark-mode';
        break;
    }
  }

  resetGlobalValues(scope = 'default') {
    window.localStorage.setItem('CM_LocalStorage_Value' + scope, '');
  }

  resetSessionValues(scope = 'default') {
    window.sessionStorage.setItem('CM_SessionStorage_Value' + scope, '');
  }

  setCampaignUrl(campaignUrl) {
    return this.campaignUrl = campaignUrl;
  }

  setMainDialog(mainDialog, onCloseButtonClick) {
    let self = this;
    this.onMainDialogCloseButtonClick = onCloseButtonClick;
    mainDialog = React.cloneElement(mainDialog, {
      onCloseButtonClick: (e) => self.onMainDialogClose(e)
    });
    this.mainDialog.mainDialog = mainDialog;
    this.mainDialog.isActive = true;
    this.onSetStateListeners.fire();
  }

  setGlobalValue(id, value, scope = 'default') {
    this.private_setStorageValue('global', id, value, scope);
  }

  setLanguage(language = 'de') {
    switch (language) {
      case CM.LANG_DE:
        this.langTranslation = new De();
        break;
      case CM.LANG_FR:
        this.langTranslation = new Fr();
        break;
      case CM.LANG_IT:
        this.langTranslation = new It();
        break;
      case CM.LANG_EN:
        this.langTranslation = new En();
        break;
      default:
        this.langTranslation = new De();
        break;
    }
    this.setGlobalValue('lang', language);
    Operator.get().initLabels();
    this.fireOnSetState();
  }

  getLangauge() {
    return this.getGlobalValue('lang');

  }

  setPanel(panel, id = 'default') {
    this.panel[id] = panel;
  }

  setRoute(url, title = '') {
    let data = {
      title: title, url: url
    };
    window.history.pushState(data, data.title, data.url);
    this.fireOnSetState();
  }

  setSessionValue(id, value, scope = 'default') {
    this.private_setStorageValue('session', id, value, scope);
  }

  private_setStorageValue(mode, id, value, scope) {
    let storageId;
    let globalBaseValueFromStorage = this.private_getStorageBaseValue(mode, scope);
    let storage;
    if (mode === 'global') {
      storageId = 'CM_LocalStorage_Value' + scope;
      storage = 'localStorage';
    } else {
      storageId = 'CM_SessionStorage_Value' + scope;
      storage = 'sessionStorage';
    }
    if (globalBaseValueFromStorage === undefined || globalBaseValueFromStorage === null) {
      globalBaseValueFromStorage = {};
    }
    globalBaseValueFromStorage[id] = value;
    window[storage].setItem(storageId, JSON.stringify(globalBaseValueFromStorage));
  }

  private_onNavigate(e) {
    this.fireOnSetState();
  }

  setTheme(theme = CM.STD_LIGHT, doPropagate = true) {
    this.theme = theme;
    this.setGlobalValue('theme', theme);
    this.modifyTheme();
    this.onThemeChangeListeners.fire(theme);
  }

  getUserContext() {
    return VM.get().getData('UserContext');
  }

  setUserContext(value) {
    VM.get().setData('UserContext', value);
  }

}

export default CM;

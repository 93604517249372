import Dto from "../../Dto";

class ChangeAccount extends Dto {

  constructor(accountId = '') {
    super('po2\\service\\system\\dto\\ChangeAccount');
    this.accountId = accountId;
  }

  getAccountId() {
    return this.accountId;
  }

  setAccountId(accountId) {
    this.accountId = accountId;
  }

}
export default ChangeAccount
import './TabBar.css';
import BaseElementContainer from "./BaseElementContainer";
import HorizontalLayout from "../layouts/HorizontalLayout";
import PropTypes from "prop-types";

class TabBarHorizontal extends BaseElementContainer {

  render() {
    let elements = [];
    for (let index in this.state.tabElements) {
      elements.push(this.state.tabElements[index].element);
    }
    let className = (this.props.className !== undefined ? this.props.className : '');
    return <HorizontalLayout className={'flex-wrap ' + className}>
      {elements}
    </HorizontalLayout>
  }
}

TabBarHorizontal.propTypes = {
  className: PropTypes.string,
  parent: PropTypes.object
}
export default TabBarHorizontal;

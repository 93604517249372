import BaseComponent from "../../../../../lib/components/BaseComponent";
import FilterBuilder from "../../../../../appl/view/components/extendedFilter/FilterBuilder";
import FieldName from "../../../../../lib/model/query/FieldName";
import BooleanPredicate from "../../../../../lib/model/query/BooleanPredicate";
import FieldPredicate from "../../../../../lib/model/query/FieldPredicate";
import Operator from "../../../../../lib/model/query/Operator";
import {createRef} from "react";
import ButtonLarge from "../../../../../appl/view/components/buttons/ButtonLarge";

class ExtendedFilterTest extends BaseComponent {

  constructor(props) {
    super(props);
    this.filterBuilder = createRef();
  }

  render() {
    let queryPredicates = new BooleanPredicate();
    queryPredicates.addPredicate(new FieldPredicate(FieldName.create('test#peng'), null, 'hallo', null, '=='));
    let queryPredicates2 = new BooleanPredicate(Operator.or);
    queryPredicates2.addPredicate(new FieldPredicate(FieldName.create('test#paff'), null, 'hallo', null, '=='));
    queryPredicates2.addPredicate(new FieldPredicate(FieldName.create('test#paff'), null, 'velo', null, '=='));
    queryPredicates.addPredicate(queryPredicates2);
    queryPredicates.addPredicate(new FieldPredicate(FieldName.create('test#puff'), null, 'velo', null, '!='));

    return <div className='sandbox-container flex flex-col gap-4'>
      <FilterBuilder ref={this.filterBuilder} queryPredicates={queryPredicates} selectFields={[{id: 'test#peng', label: 'test#peng'}, {id: 'test#puff', label: 'test#puff'}, {id: 'test#paff', label: 'test#paff'}]}/>
      <ButtonLarge onClick={(e) => {this.filterBuilder.current.addEmptyBooleanPredicate()}}>Add BooleanPredicate</ButtonLarge>
      <ButtonLarge onClick={(e) => {this.filterBuilder.current.addEmptyFieldPredicate()}}>Add FieldPredicate</ButtonLarge>
      <ButtonLarge onClick={(e) => {this.filterBuilder.current.resetFilter()}}>Reset Filter</ButtonLarge>
    </div>
  }
}

export default ExtendedFilterTest;

import SysLastValueRead from "./SysLastValueRead";

class SysLastValueDelete extends SysLastValueRead {

  constructor(valueid) {
    super(valueid, 'po2\\service\\system\\dto\\SysLastValueDelete');
  }
}

export default SysLastValueDelete;

import Dto from "../../../../../lib/model/service/Dto";

class SynchronizeVariables extends Dto {

  campaignId;
  pageId;
  constructor(campaignId = '', pageId = '') {
    super('appl\\service\\campaign\\dto\\SynchronizeVariables');
    this.campaignId = campaignId;
    this.pageId = pageId;
  }

  getCampaignId() {
    return this.campaignId;
  }

  setCampaignId(campaignId) {
    this.campaignId = campaignId;
  }

  getPageId(){
    return this.pageId;
  }

  setPageId(pageId){
    this.pageId = pageId;
  }

}

export default SynchronizeVariables;

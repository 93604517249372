import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import HorizontalLayout from "../layouts/HorizontalLayout";

class FieldPredicate extends BaseComponent {

  render() {
    return <HorizontalLayout className='cn-border-radius-20 border-2 border-cn-color-black cn-box-shadow'>
      <div>commandbar</div>
      <HorizontalLayout className=' cn-border-radius-20'>
        <div>fieldselection</div>
        <div>operatorselection</div>
        <div>valuefield</div>
      </HorizontalLayout>
    </HorizontalLayout>;
  }
}

FieldPredicate.propTypes = {
  className: PropTypes.string,
  fieldSelection: PropTypes.node,
  operatorSelection: PropTypes.node,
  valueField: PropTypes.node,
  commandBarEvent: PropTypes.object
}
export default FieldPredicate;

import Dto from "../../../../../lib/model/service/Dto";

class IsAnalyticsEnabled extends Dto {

  campaignId;

  constructor(campaignId = '') {
    super('appl\\service\\campaign\\dto\\IsAnalyticsEnabled');
    this.campaignId = campaignId;
  }

  getCampaignId() {
    return this.campaignId;
  }

  setCampaignId(campaignId) {
    this.campaignId = campaignId;
  }

}

export default IsAnalyticsEnabled;

import {Circle, Copy, Edit, RefreshCcw, Trash} from "react-feather";
import './CampaignCard.css'
import BaseComponent from "../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import Menu from "../../../../lib/components/menu/Menu";
import MenuItem from "../../../../lib/components/menu/MenuItem";
import MenuItemGroup from "../../../../lib/components/menu/MenuItemGroup";
import DialogCloneCampaign from "../../../../lib/components/dialogs/DialogCloneCampaign";
import CM from "../../../model/system/CM";
import RemoveCampaign from "../../../model/service/campaign/dto/RemoveCampaign";
import {toast} from "react-toastify";
import UpdateCampaignStatus from "../../../model/service/campaign/dto/UpdateCampaignStatus";
import React, {createRef} from "react";
import uploadThumbnail from './UploadThumbnail.png';
import Upload from "../../../../lib/components/Upload";
import T from "../../../model/system/text_translations/Translator";
import image1 from './defaultImages/bild_1.jpg';
import image2 from './defaultImages/bild_2.jpg';
import image3 from './defaultImages/bild_3.jpg';
import image4 from './defaultImages/bild_4.jpg';
import image5 from './defaultImages/bild_5.jpg';
import image6 from './defaultImages/bild_6.jpg';
import image7 from './defaultImages/bild_7.jpg';
import image8 from './defaultImages/bild_8.jpg';
import AddCampaignImg from "../../../model/service/campaign/dto/AddCampaignImg";
import CampaignService from "../../../model/service/campaign/CampaignService";
import shortid from "shortid";
import ReactDOM from "react-dom";
import AlertDialog from "../../../../lib/components/dialogs/AlertDialog";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import ButtonSmall from "../buttons/ButtonSmall";
import ConfirmationDialog from "../../../../lib/components/dialogs/ConfirmationDialog";
import UpdateCampaign from "../../../model/service/campaign/dto/UpdateCampaign";

class CampaignBase extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      mainMenu: null, changeStateMenu: null, image: null, isLoading: false, thumbnailChange: false
    }
    this.mainMenuRef = createRef();
    this.statusMenuRef = createRef();
    this.defaultImages = [image1, image2, image3, image4, image5, image6, image7, image8];
    this.storageId = 'CampaignCard_' + shortid();
  }

  addBackDropMainMenu = (e) => {
    if (this.mainMenuRef.current !== undefined) {
      //if a user opens a dialog, opens then the dropdown and closes the dialog
      // then the dom does not have the dropdown anymore so the event can be removed
      if (ReactDOM.findDOMNode(this.mainMenuRef.current) !== null) {
        let wasAnInsideClick = ReactDOM.findDOMNode(this.mainMenuRef.current).contains(e.target);
        if (this.state.mainMenu !== null && !wasAnInsideClick) {
          window.removeEventListener('click', this.addBackDropMainMenu);
          this.state.mainMenu = null;
          this.setState(this.state);
        }
      } else {
        window.removeEventListener('click', this.addBackDropMainMenu);
      }
    }
  }
  addBackDropStatusMenu = (e) => {
    if (this.statusMenuRef.current !== undefined) {
      //if a user opens a dialog, opens then the dropdown and closes the dialog
      // then the dom does not have the dropdown anymore so the event can be removed
      if (ReactDOM.findDOMNode(this.statusMenuRef.current) !== null) {
        let wasAnInsideClick = ReactDOM.findDOMNode(this.statusMenuRef.current).contains(e.target);
        if (this.state.changeStateMenu !== null && !wasAnInsideClick) {
          window.removeEventListener('click', this.addBackDropStatusMenu);
          this.state.changeStateMenu = null;
          this.setState(this.state);
        }
      } else {
        window.removeEventListener('click', this.addBackDropStatusMenu);
      }
    }
  }

  getColorClassFromStatus(status, doBorder) {
    switch (status) {
      case 1:
        return doBorder ? 'border-cn-color-secondary' : 'bg-cn-color-secondary';
      case 2:
        return doBorder ? 'border-amber-600' : 'bg-amber-600';
      case 3:
        return doBorder ? 'border-cn-color-primary' : 'bg-cn-color-primary';
      case 4:
        return doBorder ? 'border-cn-color-static-red' : 'bg-cn-color-static-red';
    }
  }

  createStatusIcon() {
    let status = this.props.campaign.status;
    let colorClass = this.getColorClassFromStatus(status);
    return <div className={'rounded-full absolute left-4 top-[-12px] border-4 border-cn-color-black cursor-pointer ' +
      colorClass}
                onClick={(e) => this.onChangeStateMenuItemClick(e)}>
      <Circle className="h-3 w-3 m-0.5 stroke-transparent"/></div>
  }

  onMoreButtonClick(e) {
    if (this.state.mainMenu === null) {
      this.state.mainMenu = <div className='relative' ref={this.mainMenuRef}>
        <Menu className='animated-dropdown absolute right-5 top-5 z-20 bg-cn-color-black text-cn-color-white-960'>
          <MenuItemGroup>
            <MenuItem onClick={(e) => this.onCloneClick(e)} option='clone'><Copy className='p-0.5'/>{T.label_clone()}
            </MenuItem>
            <MenuItem onClick={(e) => this.onUpdateClick(
              e)} option='update' className='text-cn-color-secondary'><RefreshCcw className='p-0.5'/>{T.label_update()}
            </MenuItem>
            <MenuItem onClick={(e) => this.onDeleteClick(
              e)} option='delete' className='text-cn-color-static-red'><Trash className='p-0.5'/>{T.label_delete()}
            </MenuItem>
            <MenuItem onClick={(e) => this.onThumbnailChangeClick(
              e)} option='rename'><Edit className='p-0.5'/>{T.action_change_thumbnail()}
            </MenuItem>
          </MenuItemGroup>
        </Menu>
      </div>;
      setTimeout(() => {
        window.addEventListener('click', this.addBackDropMainMenu);
      }, 1);
    } else {
      this.state.mainMenu = null;
      window.removeEventListener('click', this.addBackDropMainMenu);
    }
    this.setState(this.state);
  }

  onThumbnailChangeClick(e) {
    this.state.mainMenu = null;
    if (this.state.thumbnailChange) {
      this.state.thumbnailChange = false;
      this.state.image = null;
    } else {
      this.state.thumbnailChange = true;

      this.state.image = <Upload uploadArea={<img className='cursor-pointer object-cover' alt='Thumbnail'
                                                  src={uploadThumbnail}/>}

                                 className='h-full cursor-pointer' accept='image/*' draggable hideHint hideProgress
                                 onUploadDone={(response) => {
                                   if (response.success && response.data.id !== '') {
                                     let uploadedFile = response.data.fileUploadEntities[0];
                                     let dto = new AddCampaignImg(parseInt(this.props.campaign.id),
                                       uploadedFile.resourceId, uploadedFile.name);
                                     this.doDataCacheFetch(dto, CampaignService.NAME, 'CampaignCardThumbnailChange',
                                       dtoResponse => {
                                         if (dtoResponse.success) {
                                           toast.success('Thumbnail wurde erfolgreich aktualisiert.');
                                           this.props.reloadCampaigns();
                                         } else {
                                           toast.error('Beim Aktualisieren ist etwas schief gelaufen!');
                                         }
                                       }, true);
                                   } else {
                                     toast.error(response.message);
                                   }
                                 }} uploadDialogCancelled={() => {
        this.state.image = null;
        this.state.thumbnailChange = false;
        this.setState(this.state);
      }}/>

    }
    this.setState(this.state);
  }

  getStorageId() {
    return this.storageId;
  }

  onCampaignStatusChange(e, data) {
    let self = this;
    let dto = new UpdateCampaignStatus(this.props.campaign.id, parseInt(data));
    CM.get().doJsonAjax(CM.get().getServiceUrl('campaign'), dto, (response) => {
      if (response.success) {
        toast.success('Campaign Status wurde erfolgreich aktualisiert!');
        self.props.reloadCampaigns();
      } else {
        toast.error(response.message);
      }
    });
    this.state.changeStateMenu = null;
    this.setState(this.state);
  }

  onChangeStateMenuItemClick(e) {
    if (this.state.changeStateMenu === null) {
      this.state.changeStateMenu = <div className='relative' ref={this.statusMenuRef}>
        <Menu ref={this.statusMenuRef} className='animated-dropdown absolute z-20 bg-cn-color-black text-cn-color-white-960 top-5'
              onChange={(e, data) => this.onCampaignStatusChange(e, data)}>
          <MenuItemGroup>
            <MenuItem option='1'><Circle className='fill-cn-color-secondary stroke-transparent p-0.5'/>{T.label_filter_editing()}
            </MenuItem>
            <MenuItem option='2'><Circle className='fill-amber-600 stroke-transparent p-0.5'/>{T.label_filter_maintenance()}
            </MenuItem>
            <MenuItem option='3'><Circle className='fill-cn-color-primary stroke-transparent p-0.5'/>{T.label_filter_published()}
            </MenuItem>
            <MenuItem option='4'><Circle className='fill-cn-color-static-red stroke-transparent p-0.5'/>{T.label_filter_closed()}
            </MenuItem>
          </MenuItemGroup>
        </Menu>
      </div>;
      setTimeout(() => {
        window.addEventListener('click', this.addBackDropStatusMenu);
      }, 1);
    } else {
      this.state.changeStateMenu = null;
      window.addEventListener('click', this.addBackDropStatusMenu);
    }
    this.setState(this.state);
  }

  onDeleteClick(e) {
    this.state.mainMenu = null;
    let self = this;
    if (!this.state.isLoading) {
      this.state.isLoading = true;
      let campaignId = this.props.campaign.id;
      let message = <VerticalLayout className='p-3 gap-3'>
        <p className='cn-font-paragraph'>{'Möchten Sie die Campaign [' + this.props.campaign.name + '] löschen?'}</p>
        <HorizontalLayout className='gap-3 justify-end'>
          <ButtonSmall onClick={(e) => CM.get().closeMainDialog()}>Abbrechen</ButtonSmall>
          <ButtonSmall color='red' onClick={(e) => {
            CM.get().doJsonAjax(CM.get().getServiceUrl('campaign'), new RemoveCampaign(campaignId), (response) => {
              self.state.isLoading = false;
              if (response.success) {
                toast.success('Campaign wurde gelöscht.');
                CM.get().closeMainDialog();
                self.props.reloadCampaigns();
              } else {
                toast.error(response.message);
              }
            });
          }}>{T.label_delete()}</ButtonSmall>
        </HorizontalLayout>
      </VerticalLayout>
      CM.get().setMainDialog(<AlertDialog errorTitle='Campaign Löschen?' errorMessage={message}/>,
        (e) => this.state.isLoading = false);
    }
  }

  onCloneClick(e) {
    this.state.mainMenu = null;
    CM.get().setMainDialog(<DialogCloneCampaign onCloneButtonClick={(e) => {
      CM.get().closeMainDialog();
      this.props.reloadCampaigns();
    }} campaignId={this.props.campaign.id}/>);
  }

  onUpdateClick(e) {
    this.state.mainMenu = null;
    let message = 'Möchten Sie die Campaign [' + this.props.campaign.name + '] aktualisieren?';
    CM.get().setMainDialog(
      <ConfirmationDialog title='Campaign Aktualisieren?' message={message} onConfirmClick={(e) => {
        let update = new UpdateCampaign(this.props.campaign.id);
        CM.get().doJsonAjax(CM.get().getServiceUrl(CampaignService.NAME), update, (response) => {
          if (response.success) {
            toast.success('Campaign wurde erfolgreich aktualisiert');
            CM.get().closeMainDialog();
          } else {
            toast.error(response.message);
          }
        });
      }}/>);

  }

  onCardClick(e) {
    if (this.props.onCardClick !== undefined) {
      let data = this.props.campaign.id;
      this.props.onCardClick(e, data);
    }
  }

}

export default CampaignBase;
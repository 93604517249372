import BaseComponent from "../../../../../lib/components/BaseComponent";
import PropType from "prop-types";

class ModulFunction extends BaseComponent {



  render() {

    return<li>{this.props.name}</li>
  }
}

ModulFunction.propTypes = {
  name: PropType.string.isRequired,
}

export default ModulFunction;

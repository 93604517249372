import Dto from "../service/Dto";


class SortField extends Dto {

  static ASC = 'asc';
  static DESC = 'desc';
  static NONE = '';
  fieldName;
  direction;

  constructor(fieldName, direction) {
    super('po2\\service\\entity\\predicate\\SortField');
    this.fieldName = fieldName;
    this.direction = direction;
  }

  getFieldName(){
    return this.fieldName;
  }
  setDirection(direction){
    this.direction = direction;
  }
}

export default SortField;
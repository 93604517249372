import BaseComponent from "../BaseComponent";
import PropTypes from "prop-types";

class AccordionLayout extends BaseComponent {

  render() {
    let classes = 'flex flex-col h-full overflow-hidden ';
    classes = this.props.className ? classes + this.props.className : classes;
    return <div className={classes}>
      {this.props.children}
    </div>
  }
}

AccordionLayout.propTypes = {
  className: PropTypes.string
}

export default AccordionLayout;
import VerticalLayout from "../layouts/VerticalLayout";
import BaseElementContainer from "./BaseElementContainer";
import PropTypes from "prop-types";

class TabBarVertical extends BaseElementContainer {

  render() {
    let elements = [];
    for (let index in this.state.tabElements) {
      elements.push(this.state.tabElements[index].element);
    }
    let className = (this.props.className !== undefined ? this.props.className : '');
    let widthFit = (this.props.widthFit !== undefined ? this.props.widthFit : false);
    return <VerticalLayout widthFit={widthFit} className={'overflow-y-scroll ' + className}>
      {elements}
    </VerticalLayout>
  }
}

TabBarVertical.propTypes = {
  className: PropTypes.string,
  widthFit: PropTypes.bool,
  parent: PropTypes.object
}
export default TabBarVertical;

import BaseComponent from "../../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import CampaignDashBoard from "../../../../../appl/view/components/campaignDashBoard/CampaignDashBoard";

class PieChartTest extends BaseComponent {

  render() {
    return <VerticalLayout className='p-20 sandbox-container flex flex-col gap-4'>
      <h1>Campaign Overview</h1>
      <CampaignDashBoard/>
    </VerticalLayout>
  }
}

export default PieChartTest;

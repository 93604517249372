import BaseComponent from "../../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import Entity from "../../../../../appl/view/components/entity/Entity";

import CM from "../../../../../appl/model/system/CM";
import ReadCampaignTableList from "../../../../../appl/model/service/campaign/dto/ReadCampaignTableList";
import EntityDescribe from "../../../../../lib/model/service/entity/dto/EntityDescribe";
import CampaignService from "../../../../../appl/model/service/campaign/CampaignService";

class GridTest extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      dto: null
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let self = this;
    CM.get().doJsonAjax(CM.get().getServiceUrl('campaign'), new ReadCampaignTableList(97), function(response) {
      if (response.success) {
        console.log(response.data)
        let tables = response.data.campaignTables;
        let describe = new EntityDescribe([tables[0].name]);
        describe.setProperties(CampaignService.createCampaignRedirectProperty(97));
        self.state.dto = describe;
        self.setState(self.state);
      } else {
        console.log(response)
      }
    });
  }

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>
      <VerticalLayout className=' self-center gap-y-5 '>
        <Entity />
      </VerticalLayout>
    </div>
  }
}

export default GridTest;

import './TabBar.css';
import PropTypes from "prop-types";
import React from "react";
import VerticalSeparator from "../seperator/VerticalSeparator";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import TabContainerBase from "../../../../lib/components/tabbar/TabContainerBase";

class TabContainerVerticalVariables extends TabContainerBase {

  render() {
    let tabBar = React.cloneElement(this.props.tabBar, {
      ref: this.tabBarRef, parent: this
    });
    let tabContent = React.cloneElement(this.props.tabContent, {
      ref: this.tabContentRef, parent: this
    });
    return <HorizontalLayout className={this.props.className}>
      {tabBar}
      <VerticalSeparator className='bg-cn-color-primary mx-2'/>
      {tabContent}
    </HorizontalLayout>
  }

  setElementIsChanged(id) {
    if (id) {
      this.tabBarRef.current.setActiveElement(id);
      this.tabBarRef.current.setState(this.tabBarRef.current.state);
    }
  }
}

TabContainerVerticalVariables.propTypes = {
  className: PropTypes.string,
}

export default TabContainerVerticalVariables;

import BaseComponent from "../BaseComponent";
import PropTypes from "prop-types";
import CM from "../../../appl/model/system/CM";

class Link extends BaseComponent {

  onClick(e) {
    if (this.props.hasOwnProperty('onClick')) {
      this.props.onClick(e);
    }
    CM.get().setRoute(this.props.to);
  }

  render() {
    return <div className={'cursor-pointer ' + this.props.className} onClick={(e) => this.onClick(e)}>{this.props.children}</div>;
  }
}

Link.propTypes = {
  to: PropTypes.string, className: PropTypes.string, onClick: PropTypes.func
}
export default Link;
import BaseComponent from "../../../../lib/components/BaseComponent";
import LoginForm from "./login/LoginForm";
import logoTeil from "./img/logo-cner-teil-color.png";
import CM from "../../../model/system/CM";

class LandingLogin extends BaseComponent {

  render() {
    if (CM.get().getToken() == null) {
      return <div className='bg-cn-bg-img-login bg-cover bg-center bg-no-repeat'>
        <LoginForm/>
        <img src={logoTeil} alt="" className=" bottom-[-60px] left-[-60px] lg:h-[480px] md:h-[380px] h-[280px] hidden md:block relative md:fixed"/>
      </div>
    } else {
      window.location.href = '/cner';
      return <></>
    }
  }
}

export default LandingLogin;

import SysTreeNodeBuilder from "../tree/leftTree/TreeNodeBuilder";

/**
 * Node
 * Voraussetzungen (interface) für ein Node ist:
 * es muss die Funktion isParentNode und getChildren implementiert werden!
 */
class TreeNodeBuilder extends SysTreeNodeBuilder {

  constructor(filterBuilder) {
    super();
    this.filterBuilder = filterBuilder;
  }

  buildChildNodeContent(_workingNode) {
    return this.filterBuilder.getFieldPredicate(_workingNode);
  }

  buildParentNodeContent(_workingNode) {
    return this.filterBuilder.getBooleanPredicate(_workingNode);
  }
}

export default TreeNodeBuilder;

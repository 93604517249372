import PropTypes from "prop-types";
import BaseComponent from "../../../../lib/components/BaseComponent";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import Operator from "../../../../lib/model/query/Operator";

class BooleanPredicate extends BaseComponent {

  constructor(props) {
    super(props);
    this._type = 'BooleanPredicateComponent';
    this.state = {
      operator: (this.props.operator !== undefined ? this.props.operator : Operator.and)
    };
  }

  clickAnd(e) {
    this.state.operator = Operator.and;
    this.setState(this.state);
  }

  clickOr(e) {
    this.state.operator = Operator.or;
    this.setState(this.state);
  }

  getOperator() {
    return this.state.operator;
  }

  render() {
    let buttonStyleLeft = '';
    let buttonStyleRight = '';

    switch (this.state.operator) {
      case Operator.or:
        buttonStyleLeft = 'rounded-tl-[8px] rounded-bl-[8px] rounded-rt-0 rounded-rb-0 m-[-1px] px-1';
        buttonStyleRight = 'rounded-tl-0 rounded-bl-0 rounded-tr-[8px] rounded-br-[8px] m-[-1px] px-[4px] bg-cn-color-primary';
        break;
      default:
        buttonStyleLeft = 'rounded-tl-0 rounded-bl-[8px] rounded-tr-0 rounded-br-0 m-[-1px] px-[4px] bg-cn-color-primary';
        buttonStyleRight = 'cn-border-radius m-[-1px] px-1';
        break;
    }

    return <HorizontalLayout className='mb-2'>
      <div className='cn-border-radius border-[1px] border-cn-color-primary-active'>
        <button className={buttonStyleLeft} onClick={(e) => this.clickAnd(e)}>und</button>
        <button className={buttonStyleRight} onClick={(e) => this.clickOr(e)}>oder</button>
      </div>
      {/*<button className='mx-2'>Umkehren</button>*/}
    </HorizontalLayout>;
  }
}

BooleanPredicate.propTypes = {
  className: PropTypes.string, operator: PropTypes.string
}
export default BooleanPredicate;

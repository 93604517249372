import {Calendar, Clock} from 'react-feather';
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import InputBase from "./InputBase";
import DatePicker from "../../../../lib/components/dateTimePicker/DatePicker";
import TimePicker from "../../../../lib/components/dateTimePicker/TimePicker";
import DateTimePicker from "../../../../lib/components/dateTimePicker/DateTimePicker";
import shortid from "shortid";
import React, {createRef} from "react";
import GF from "../../../../lib/utils/GF";

/**
 * @property string labelText: Werte=disable/error
 * @property string state: Werte=disable/error
 * @property string helpTextElement: Hilfetext
 * @property string use: Verwendung Werte=table oder leer
 */
class InputDateTimePicker extends InputBase {

  constructor(props) {
    super(props);
    this.state.showPicker = false;
    this.state.picker = null;
    this.pickerRef = createRef();
    this.dateTimePicker = createRef();
    this.inputContainerRef = createRef();
    this.inputFieldRef = createRef();
    this.shortKey = shortid();
    this.clickListenerisInstalled = false;
    this.previousValue = '';

    let defaultValue = this.props.defaultValue;
    this.state.useTime = GF.getValue(this.props.useTime, true);
    if ((typeof defaultValue) === 'string') {
      if (!this.state.useTime) {
        defaultValue = defaultValue.substring(0, 10);
      }
    }
    this.state.datetime = GF.getValue(GF.dateTimeToString(defaultValue), '');
    this.state.canChooseUseTime = GF.getValue(this.props.canChooseUseTime, true);
    this.state.iconRight = this.props.pickerVariant && this.props.pickerVariant === 'TIME' ?
      <div className='self-center pr-2' onClick={(e) => this.onInputClick(e)}><Clock/></div> :
      <div className='self-center pr-2' onClick={(e) => this.onInputClick(e)}><Calendar/></div>;
  }

  addBackDrop = (e) => {
    if (this.pickerRef.current !== undefined) {
      if (this.state.picker && GF.checkNotNull(this.pickerRef) && GF.checkNotNull(this.pickerRef.current)) {
        let inpickerClick = ReactDOM.findDOMNode(this.pickerRef.current).contains(e.target);
        let inInputFieldClick = ReactDOM.findDOMNode(this.inputFieldRef.current).contains(e.target);

        if (this.state.showPicker && !inpickerClick && !inInputFieldClick) {
          this.doClosePicker(e);
          this.doFireOnChange(e, this.inputFieldRef.current.value);
        }
      } else {
        this.removeAddBackDropListener();
      }
    }
  }

  addAddBackDropListener() {
    if (!this.clickListenerisInstalled) {
      this.clickListenerisInstalled = true;
      setTimeout(() => {
        window.addEventListener('click', this.addBackDrop);
      }, 1);
    }
  }

  removeAddBackDropListener() {
    window.removeEventListener('click', this.addBackDrop);
    this.clickListenerisInstalled = false;
  }

  doClosePicker(e) {
    this.state.showPicker = false;
    this.onClose();
    this.removeAddBackDropListener();
    this.setState(this.state);
  }

  doFireOnChange(e, value) {
    this.fireOnChange(e, this.getValue());
  }

  createPicker() {
    if (this.state.showPicker) {
      let rootNode = document.getElementById('root');
      let style = this.getStylePositionInfo(this.inputFieldRef.current, 10);
      if (this.props.pickerVariant) {
        if (this.props.pickerVariant === 'DATE') {
          this.state.picker = ReactDOM.createPortal(<div className='fixed z-60 text-cn-color-white' style={style}>
            <DatePicker ref={this.pickerRef} datetime={GF.stringToDateTime(
              this.inputFieldRef.current.value)} onChange={(newDateTime) => {
              this.setInputFieldValue(null, this.dateTimeToString(newDateTime));
            }} className='dp-container animated-dropdown'/>
          </div>, rootNode);
        } else if (this.props.pickerVariant === 'TIME') {
          this.state.picker = ReactDOM.createPortal(<div className='fixed z-60 text-cn-color-white' style={style}>
            <TimePicker ref={this.pickerRef} datetime={GF.stringToDateTime(
              this.inputFieldRef.current.value)} onChange={(newDateTime) => {
              this.setInputFieldValue(null, this.dateTimeToString(newDateTime));
            }} className='tp-container animated-dropdown'/>
          </div>, rootNode);
        }
      } else {
        let dateTime = this.inputFieldRef.current.value;
        if (this.inputFieldRef.current.value.length === 10) {
          let dateTimeObj = new Date();
          dateTime += ' ' + dateTimeObj.getHours() + ':' + dateTimeObj.getMinutes();
        }
        this.state.picker = ReactDOM.createPortal(<div className='fixed z-60 text-cn-color-white' style={style}>
          <div ref={this.pickerRef} className='animated-dropdown'>
            <DateTimePicker ref={this.dateTimePicker} datetime={GF.stringToDateTime(
              dateTime)} onChange={(newDateTime, useTime) => {
              this.setInputFieldValue(null, this.dateTimeToString(newDateTime, useTime));
            }} useTime={this.state.useTime} canChooseUseTime={this.state.canChooseUseTime}/>
          </div>
        </div>, rootNode);
      }
    } else {
      this.state.picker = null;
    }
  }

  dateTimeToString(newDateTime, useTime = false) {
    this.state.useTime = useTime;
    let year = newDateTime.getFullYear();
    let month = newDateTime.getMonth();
    let day = newDateTime.getDate();
    let hour = newDateTime.getHours();
    let minute = newDateTime.getMinutes();

    // Damit wenn keine Zeit verwendet wird, später das Query modifiziert werden kann: > 00:00 && <= 23:59
    if (year !== '' && !useTime && !this.props.pickerVariant) {
      hour = '';
      minute = '';
    }
    if (this.props.pickerVariant === 'DATE') {
      hour = '';
      minute = '';
    }
    if (this.props.pickerVariant === 'TIME') {
      year = '';
      month = '';
      day = '';
    }
    let result;
    if (this.state.useTime) {
      result = GF.buildDateTimeString(day, month, year, hour, minute);
    } else {
      result = GF.buildDateString(day, month, year);
    }
    return result;
  }

  getValue() {
    this.validateValue();

    let dateTime = '';
    if (this.inputFieldRef.current.value.length > 0) {
      if (this.props.pickerVariant === 'DATE') {
        dateTime = this.inputFieldRef.current.value.substring(0, 10);
      }
      if (!this.state.useTime && this.props.pickerVariant !== 'TIME' && this.props.pickerVariant !== 'DATE') {
        dateTime = this.inputFieldRef.current.value.substring(0, 10);
      } else {
        if (this.inputFieldRef.current.value.length === 10 && this.props.pickerVariant !== 'DATE') {
          dateTime = this.inputFieldRef.current.value + ' 00:00';
        } else {
          dateTime = this.inputFieldRef.current.value;
        }
      }
    }
    return dateTime;
  }

  onInputClick(e) {
    if (!this.state.showPicker) {
      this.state.showPicker = true;
      this.setState(this.state);
      this.addAddBackDropListener();
    }
  }

  setInputFieldValue(e, value = null) {
    if (e !== null && value === null) {
      value = e.currentTarget.value;
    }
    let valueTemp = '';
    if (value.length === 10 || value.length === 16 || value.length === 5) {
      valueTemp = value;
    }
    if (valueTemp.length > 0) {
      valueTemp = GF.dateTimeToString(valueTemp);
      if (this.props.pickerVariant === 'DATE') {
        valueTemp = valueTemp.substring(0, 10);
      }
      this.previousValue = valueTemp;
      this.inputFieldRef.current.value = valueTemp;
    }
  }

  validateValue() {
    if (this.inputFieldRef.current.value.length > 0) {
      if (this.state.useTime) {
        if (this.inputFieldRef.current.value.length === 16) {
          // datetime-regex Format xx.yy.zzzz aa:bb
          let regex = new RegExp(/^([0-3]\d)(\.)([0-1]\d)(\.)(\d{4}) ([0-2]\d)(:)([0-6]\d)$/);
          if (!regex.test(this.inputFieldRef.current.value)) {
            this.inputFieldRef.current.value = this.previousValue;
          }
        } else {
          this.inputFieldRef.current.value = this.previousValue;
        }
      } else {
        if (this.inputFieldRef.current.value.length === 10) {
          // date-regex Format xx.yy.zzzz
          let regex = new RegExp(/^([0-3]\d)(\.)([0-1]\d)(\.)(\d{4})$/);
          if (!regex.test(this.inputFieldRef.current.value)) {
            this.inputFieldRef.current.value = this.previousValue;
          }
        } else {
          this.inputFieldRef.current.value = this.previousValue;
        }
      }
    }
    if (this.state.useTime) {
      if (this.inputFieldRef.current.value.length === 10 && this.props.pickerVariant !== 'DATE') {
        this.inputFieldRef.current.value = this.inputFieldRef.current.value + ' 00:00';
      }
    } else {
      this.inputFieldRef.current.value = this.inputFieldRef.current.value.substring(0, 10);
    }
  }

  onClose() {
    if (GF.checkNotNull(this.props.onClose)) {
      this.props.onClose(this.props._id, this.getValue());
    }
  }

  onBlur(e) {
    if (this.state.picker === null) {
      this.doFireOnChange(e, this.inputFieldRef.current.value);
    }
  }

  render() {
    this.createPicker();
    this.state.children = [<div ref={this.inputContainerRef} key={this.shortKey} className='relative h-full'>
      <input onClick={(e) => this.onInputClick(
        e)} ref={this.inputFieldRef} className='bg-transparent cn-font-paragraph focus:outline-0 w-full h-full' onBlur={(e) => this.onBlur(
        e)} defaultValue={this.state.datetime}/>
      {this.state.picker}
    </div>]
    return super.render();
  }
}

InputDateTimePicker.propTypes = {
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  inputLayout: PropTypes.oneOf(['inline', 'outline', '']),
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  datetime: PropTypes.any,
  useTime: PropTypes.bool,
  canChooseUseTime: PropTypes.bool
}
export default InputDateTimePicker;

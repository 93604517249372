import BaseComponent from "../BaseComponent";
import './BreadCrumbs.css';
import PropTypes from "prop-types";

class BreadCrumb extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      active: (this.props.active !== undefined ? this.props.active : false)
    }
  }

  setIsActive(active) {
    this.state.active = active;
  }

  onClick(e) {
    this.props.onClick(e);
  }

  render() {
    let backgroundColor;
    if (this.state.active) {
      backgroundColor = "bg-cn-color-blue-690 text-cn-color-static-white";
    } else {
      backgroundColor = "bg-cn-color-black-804";
    }

    return <button className={"py-1 px-2 rounded-lg rounded-tl-none text-sm min-w-fit " + backgroundColor} onClick={(e) => this.onClick(e)}>
      {this.props.children}
    </button>
  }
}

BreadCrumb.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool
}
export default BreadCrumb;

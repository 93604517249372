import React from 'react';
import BaseComponent from '../BaseComponent';
import PropTypes from 'prop-types';
import DialogBase from "./DialogBase";
import HorizontalLayout from "../layouts/HorizontalLayout";
import CM from "../../../appl/model/system/CM";
import Loading from "../images/Loading";
import ButtonMedium from "../../../appl/view/components/buttons/ButtonMedium";

class ConfirmationDialog extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      confirmButtonText: 'Ja',
    }
  }

  onCloseButtonClick(e) {
    CM.get().closeMainDialog();
    if (this.props.onCancelClick !== undefined) {
      this.props.onCancelClick(e);
    }
  }

  onConfirmClick(e) {
    if (!this.isLoading) {
      if (this.props.onConfirmClick !== undefined) {
        this.state.confirmButtonText = <Loading />
        this.setState(this.state);
        this.props.onConfirmClick(e);
        this.isLoading = true;
      }
    }
  }

  render() {

    return <DialogBase title={this.props.title} onCloseButtonClick={(e) => this.onCloseButtonClick(e)}
                       className={'cn-shadow-primary border-cn-color-primary ' + this.props.className}>
      {this.props.message}
      <HorizontalLayout className='gap-3 justify-end mt-3'>
        <ButtonMedium className='min-w-[100px]' onClick={(e) => this.onCloseButtonClick(e)}>Nein</ButtonMedium>
        <ButtonMedium className='min-w-[100px]' onClick={(e) => this.onConfirmClick(e)} color='secondary'>{this.state.confirmButtonText}</ButtonMedium>
      </HorizontalLayout>
    </DialogBase>;
  }
}

ConfirmationDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.any,
  className: PropTypes.string,
  onCancelClick: PropTypes.func,
  onConfirmClick: PropTypes.func
};

export default ConfirmationDialog;

import CM from "../../../appl/model/system/CM";
import Delete from "../service/resource/dto/Delete";
import {toast} from "react-toastify";
import UploadManager from "../../../appl/model/system/UploadManager";
import Put from "../service/resource/dto/Put";
import GF from "../../utils/GF";

/**
 * class Resource
 */
class Resource {

  constructor(dtoProperties) {
    this.dtoProperties = dtoProperties;
    this.fileInProgress = 0;
  }

  /**
   * @param resourceEntities -> array vom Typ ResourceEntity
   * @param allDoneCallback
   */
  doManageResources(resourceEntities, allDoneCallback) {
    this.fileInProgress = 0;
    for (let index in resourceEntities) {
      let resourceEntity = resourceEntities[index];
      this.fileInProgress++;
      this.doManageFile(resourceEntity, () => {
        this.fileInProgress--;

        if (this.fileInProgress === 0) {
          allDoneCallback();
        }
      });
    }
  }

  doManageFile(resourceEntity, doneCallback) {
    if (typeof resourceEntity === 'object') {
      let resourceId = resourceEntity.getResourceId();
      let oldResourceId = resourceEntity.getOldResourceId();
      if (oldResourceId !== resourceId) {
        this.doDeleteResource(oldResourceId, (response) => {
          this.doUpload(resourceEntity, () => {
            setTimeout(() => {
              doneCallback();
            }, 1);
          });
        });
      } else {
        this.doUpload(resourceEntity, () => {
          setTimeout(() => {
            doneCallback();
          }, 1);
        });
      }
    } else {
      setTimeout(() => {
        doneCallback();
      }, 1);
    }
  }

  doUpload(resourceEntity, uploadDoneResponseCallback) {
    if (resourceEntity.getFiles() !== null) {
      let uploadManager = new UploadManager();
      let dtoPut = new Put();
      this.finalizeDto(dtoPut);
      uploadManager.upload(dtoPut, resourceEntity.getFiles(), (response) => {
        if (response.success) {
          let responseBlob = null;
          if (GF.checkNotNull(response) && GF.checkNotNull(response.data) &&
            GF.checkNotNull(response.data.fileUploadEntities) && response.data.fileUploadEntities.length > 0) {

            responseBlob = response.data.fileUploadEntities[0];
          }
          if (responseBlob !== null) {
            resourceEntity.setResourceId(responseBlob.resourceId);
            resourceEntity.setResourceName(responseBlob.name);

            uploadDoneResponseCallback();
          } else {
            toast.error('Der Upload hat nicht funktioniert!');
          }
        } else {
          toast.error(response.message);
        }
      });
    } else {
      uploadDoneResponseCallback();
    }
  }

  doDeleteResource(resourceId, deleteResponseCallback) {
    let dtoResourceDelete = new Delete(resourceId);
    this.finalizeDto(dtoResourceDelete);

    CM.get().doJsonAjax(CM.get().getServiceUrl('Resource'), dtoResourceDelete, function(response) {
      if (response.success) {
        deleteResponseCallback(response);
      } else {
        toast.error('Die Datei konnte nicht gelöscht werden!');
      }
    });
  }

  finalizeDto(dto) {
    if (GF.checkNotNull(this.dtoProperties)) {
      dto.setProperties(this.dtoProperties);
    }
  }
}

export default Resource;

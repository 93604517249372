import Dto from "../../Dto";

class Create extends Dto {

  constructor(recordsToCreate = '', query) {
    super('po2\\service\\entity\\dto\\Create');
    this.recordsToCreate = recordsToCreate;
    this.query = query;
  }

  getRecordsToCreate() {
    return this.recordsToCreate;
  }

  setRecordsToCreate(recordsToCreate) {
    this.recordsToCreate = recordsToCreate;
  }

  getQuery() {
    return this.query;
  }

  setQuery(query) {
    this.query = query;
  }
}

export default Create;

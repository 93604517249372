import PropTypes from 'prop-types';
import BaseComponent from '../BaseComponent';
import HorizontalLayout from '../layouts/HorizontalLayout';
import GF from "../../utils/GF";

class MenuItem extends BaseComponent {

  onClick = (e) => {
    if (this.props.onClick !== undefined) {
      this.props.onClick(e, this.props.option, this.props.children);
    }
  }

  render() {
    let classes = 'cn-menu-item items-center py-1 px-2 cursor-pointer gap-x-2 hover:bg-cn-color-black-804 cn-font-paragraph';
    classes += this.props.className ? ' ' + this.props.className : '';
    let children = GF.getValue(this.props.children, null);
    if (children === 'null' || children === '') {
      children = '---';
    }
    return (<HorizontalLayout onClick={this.onClick} className={classes}>
      {children}
    </HorizontalLayout>);
  }
}

MenuItem.propTypes = {
  onClick: PropTypes.func, className: PropTypes.string, option: PropTypes.any, searchValue: PropTypes.string
}
export default MenuItem;

import DataSourceEvents from "./DataSourceEvents";
import Connect from "../entity/dto/Connect";
import Create from "../entity/dto/Create";
import Update from "../entity/dto/Update";
import Delete from "../entity/dto/Delete";
import Read from "../entity/dto/Read";
import GetDownloadInfo from "../entity/dto/GetDownloadInfo";
import Disconnect from "../entity/dto/Disconnect";
import Export from "../entity/dto/Export";
import Query from "../../query/Query";
import FieldName from "../../query/FieldName";
import DataType from "../../types/DataType";
import GF from "../../../utils/GF";
import DataSourceDtoProcessEvents from "./DataSourceDtoProcessEvents";
import Get from "../resource/dto/Get";
import CampaignService from "../../../../appl/model/service/campaign/CampaignService";
import CM from "../../../../appl/model/system/CM";
import Duplicate from "../entity/dto/Duplicate";
import VM from "../../system/VM";

class DataSource {

  static STATUS_ACTIVE = 'active';
  static STATUS_INACTIVE = 'inactive';
  static STATUS_READ_ACTIVE = 'active';
  static STATUS_READ_INACTIVE = 'inactive';

  static generateDatasource(entityDef, campaignId = null, dataHandler = null, dtoProperties = null, persistanceKeyPrefix = '') {
    let dataSource = new DataSource('DoctrineEntityService', entityDef, dataHandler, persistanceKeyPrefix);
    if (campaignId !== null) {
      dataSource.setDtoProperties(CampaignService.createCampaignRedirectProperty(campaignId));
    }
    if (dtoProperties !== null) {
      dataSource.setDtoProperties(dtoProperties);
    }
    return dataSource;
  }

  static datasourceDataHandler(datasource, dto, dataSourceDtoProcessEvents) {
    if (dto instanceof Read) {
      let campaignId = '';
      if (GF.checkNotNull(datasource) && GF.checkNotNull(datasource.dtoProperties) &&
        GF.checkNotNull(datasource.dtoProperties.campaignId)) {

        campaignId = datasource.dtoProperties.campaignId;
      }
      datasource.persistanceKey = DataSource.buildEntityCacheKey(campaignId, datasource.entityDef.baseDef.name, 'Read');

      CM.get().doJsonAjaxCache(CM.get().getServiceUrl('DoctrineEntityService'), dto, (response) => {
        dataSourceDtoProcessEvents.doResponse(response);
      }, datasource.persistanceKeyPrefix + datasource.persistanceKey);
    } else {
      CM.get().doJsonAjax(CM.get().getServiceUrl('DoctrineEntityService'), dto, (response) => {
        dataSourceDtoProcessEvents.doResponse(response);
      });
    }
  }

  static buildEntityCacheKey(campaignId, entityName, actionName) {
    return campaignId + '-' + entityName + '-' + actionName;
  }

  constructor(serviceName, entityDef, dataHandler, persistanceKeyPrefix = '') {
    this.entityDef = entityDef;
    this.serviceName = serviceName;
    this.records = [];
    this.currentRecIds = [];
    this.totalRows = -1;
    this.dataSourceEvents = new DataSourceEvents();
    this.describeResult = null;
    this.lastQuery = null;
    this.waitingQuery = null;
    this.joinConditionString = null;
    this.relations = null;
    this.selectFields = [];
    this.dtoProperties = null;
    this.readStatus = DataSource.STATUS_READ_INACTIVE;
    this.requestStatus = DataSource.STATUS_INACTIVE;
    this.dataHandler = dataHandler;
    this.persistanceKey = '';
    this.persistanceKeyPrefix = persistanceKeyPrefix;
  }

  appendDtoProperties(dto) {
    if (this.dtoProperties != null) {
      if (dto.getProperties() === undefined || dto.getProperties() == null) {
        dto.setProperties(this.dtoProperties);
      }
    }
  };

  doClearCache() {
    VM.get().clearData('CM.doJsonAjaxCache_' + this.persistanceKeyPrefix + this.persistanceKey);
  }

  getEntityDef() {
    return this.entityDef;
  }

  getRecords() {
    return this.records;
  }

  getRecordByRecId(recId) {
    let record = null;
    let keyField = this.private_getKeyFieldName();
    for (let index in this.records) {
      record = this.records[index];

      let recIdFromRecord = record[keyField];
      if (recId === recIdFromRecord) {
        break;
      }
    }
    return record;
  }

  prepareQuery(query) {
    if (query === undefined || query === null) {
      query = this.getLastQuery();
    }
    if (query !== undefined) {
      if (query.selectFields === undefined || query.selectFields == null || query.selectFields.length === 0) {
        query.selectFields = this.getSelectFields();
      } else {
        this.setSelectFields(query.selectFields);
      }
      if (query.joinConditionString === undefined || query.joinConditionString == null || query.joinConditionString ===
        '') {
        query.joinConditionString = this.getJoinConditionString();
      } else {
        this.setJoinConditionString(query.joinConditionString);
      }
      if (query.relations === undefined || query.relations == null || query.relations.length === 0) {
        query.relations = this.getRelations();
      } else {
        this.setRelations(query.relations);
      }
      if ((query.joinType === undefined || query.joinType == null) && this.lastQuery != null &&
        this.lastQuery.joinType !== undefined) {
        query.joinType = this.lastQuery.joinType;
      }
      this.lastQuery = query;
    }
    return query;
  };

  addSelectField(selectField) {
    let found = false;
    for (let i = 0; i < this.selectFields.length; i++) {
      if (this.selectFields[i] === selectField) {
        found = true;
        break;
      }
    }
    if (found === false) {
      this.selectFields.push(selectField);
    }
  };

  private_addToCurrentRecIds(currentRecId) {
    let index = this.currentRecIds.indexOf(currentRecId);
    if (index === -1) {
      this.currentRecIds.push(currentRecId);
    } else {
      if (this.currentRecIds.length > 1) {
        this.currentRecIds.splice(index, 1);
      }
    }
  }

  cleanRecord(recordsObj) {
    let records = recordsObj.getRecords();
    let recordsNew = [];
    for (let index in records) {
      let record = records[index];

      let recordNew = {};
      for (let fieldName in record) {
        let fieldValue = record[fieldName];
        let fieldNameObj = FieldName.create(fieldName);
        let relationName = fieldNameObj.getRelationName();
        if (relationName !== undefined && relationName !== null && this.entityDef.baseDef.name !== undefined &&
          this.entityDef.baseDef.name !== null && relationName !== this.entityDef.baseDef.name) {

          throw new DOMException('Tabelle stimmt nicht überein.');
        }
        let fieldDef = this.entityDef.fieldsDef[fieldNameObj.getName()];
        switch (fieldDef.type.type) {
          case DataType.DATETIME:
            fieldValue = GF.dateTimeToString(fieldValue);
            break;
          default:
            break;
        }
        recordNew[fieldName] = fieldValue;
      }
      recordsNew.push(recordNew);
    }
    recordsObj.setRecords(recordsNew);
  }

  collectReadCmd(query) {
    this.waitingQuery = query;
  };

  connect(baseRecord, recordsToConnect, relation) {
    if (relation === undefined) {
      relation = this.getRelations();
    }
    let dtoConnect = new Connect(baseRecord, recordsToConnect, relation);
    this.doDto(dtoConnect, this.dataSourceEvents.onConnectDoneListeners, this.dataSourceEvents.onErrorListeners);
  }

  createRows(records) {
    let self = this;
    let dtoCreate = new Create(records, this.lastQuery);

    this.doDto(dtoCreate, this.dataSourceEvents.onCreateDoneListeners, this.dataSourceEvents.onErrorListeners,
      (response) => {
        for (let i = 0; i < response.data.records.length; i++) {
          let record = response.data.records[i];
          record = this.rebuildRecord(record)
          self.records.push(record);
        }
      });
  };

  duplicateRows(records) {
    let dtoDuplicate = new Duplicate(records, this.lastQuery);
    this.doDto(dtoDuplicate, this.dataSourceEvents.onDuplicateDoneListeners, this.dataSourceEvents.onErrorListeners);
  }

  deleteRows(records) {
    let self = this;
    let dtoDelete = new Delete(records);

    this.doDto(dtoDelete, this.dataSourceEvents.onReadDoneListeners, this.dataSourceEvents.onErrorListeners,
      (response) => {
        let keyFieldName = self.private_getKeyFieldName();
        let recordsNew = [];
        let currentRecId = -1;
        let lastCurrentRecId = -1;
        for (let record of self.records) {
          let isDeletedOne = false;
          for (let recordFromResponse of response.data.records) {
            if (record[keyFieldName] === recordFromResponse[keyFieldName]) {
              isDeletedOne = true;
              break;
            }
          }
          if (!isDeletedOne) {
            lastCurrentRecId = parseInt(record[keyFieldName]);
            if (currentRecId === -2) {
              currentRecId = lastCurrentRecId;
            }
            recordsNew.push(record);
          } else {
            if (currentRecId === -1) {
              currentRecId = -2;
            }
          }
        }
        if (currentRecId <= -1 && lastCurrentRecId >= 0) {
          currentRecId = lastCurrentRecId;
        }
        self.records = recordsNew;
        if (currentRecId !== -1) {
          self.setCurrentRecIds([currentRecId]);
        }
      });
  };

  disconnect(baseRecord, recordsToConnect, relation) {
    if (relation === undefined) {
      relation = this.getRelations();
    }
    let dtoDisconnect = new Disconnect(baseRecord, recordsToConnect, relation);
    this.doDto(dtoDisconnect, this.dataSourceEvents.onDisconnectDoneListeners, this.dataSourceEvents.onErrorListeners);
  }

  doDto(dto, onSuccessListeners, onErrorListeners, successCallBack, errorCallBack) {
    this.appendDtoProperties(dto);
    this.requestStatus = DataSource.STATUS_ACTIVE;
    if (GF.checkNotNull(this.dataHandler)) {
      let dataSourceDtoProcessEvents = new DataSourceDtoProcessEvents(onSuccessListeners, onErrorListeners,
        successCallBack, errorCallBack);
      this.dataHandler(this, dto, dataSourceDtoProcessEvents);
    }
    this.requestStatus = DataSource.STATUS_INACTIVE;
  };

  export(query, recordsToExport) {
    let self = this;
    let dtoExport = new Export(query, recordsToExport);
    this.doDto(dtoExport, this.dataSourceEvents.onReadDoneListeners, this.dataSourceEvents.onErrorListeners,
      (response) => {
        if (response.success) {
          window.open(Get.buildResourceLink(response.data.resourceId, response.data.fileName, self.dtoProperties),
            '_blank');
        }
      });
  };

  getCurrentRecords() {
    let records = [];
    if (GF.checkNotNull(this.records)) {
      for (let index in this.currentRecIds) {
        records.push(this.getRecordByRecId(this.currentRecIds[parseInt(index)]));
      }
    }
    return records;
  };

  getCurrentRecIds() {
    return this.currentRecIds;
  };

  getTotalRows() {
    return this.totalRows;
  };

  getDataSourceEvents() {
    return this.dataSourceEvents;
  };

  getDownLoadInfo(entityName) {
    let dtoDownLoadInfo = new GetDownloadInfo(entityName);
    this.doDto(dtoDownLoadInfo, this.dataSourceEvents.onGetDownLoadInfoListeners,
      this.dataSourceEvents.onErrorListeners);
  }

  getDtoProperties = function() {
    return this.dtoProperties;
  }

  private_getKeyFieldName() {
    let keyFieldName = null;
    for (let index in this.entityDef.fieldsDef) {
      let fieldsDef = this.entityDef.fieldsDef[index];
      if (fieldsDef.type.isKey) {
        keyFieldName = (new FieldName(fieldsDef.name, this.entityDef.baseDef.name)).getFullFieldName();
        break;
      }
    }
    return keyFieldName;
  }

  private_getRecIdFromRecord(record) {
    let recId = -1;
    let keyfieldname = this.private_getKeyFieldName();
    if (keyfieldname !== null && GF.checkNotNull(record)) {
      if (Object.keys(record).length) {
        recId = parseInt(record[keyfieldname]);
      }
    }
    return recId;
  }

  getJoinConditionString = function() {
    return this.joinConditionString;
  }

  getLastQuery = function() {
    if (this.lastQuery === undefined || this.lastQuery === null) {
      this.lastQuery = new Query();
    }
    return this.lastQuery;
  }

  getRequestStatus() {
    return this.requestStatus;
  }

  getRelations() {
    return this.relations;
  }

  getSelectFields() {
    if (this.selectFields.length === 0) {
      for (let fieldName in this.entityDef.fieldsDef) {
        let fieldDef = this.entityDef.fieldsDef[fieldName];
        if (fieldDef.type.type !== DataType.OBJECT) {
          this.selectFields.push(this.entityDef.baseDef.name + FieldName.DELEMITER + fieldDef.name);
        }
      }
    }
    return this.selectFields;
  }

  initDsFromEntityDescribeResult(describeResult) {
    this.entityDef = describeResult.data.serviceDescription.entityDefs[0];
    this.dataSourceEvents.onDescribeDoneListeners.fire(this.entityDef);
  }

  read(query) {
    let self = this;
    query = this.prepareQuery(query);
    if (this.readStatus === DataSource.STATUS_READ_INACTIVE) {
      this.readStatus = DataSource.STATUS_READ_ACTIVE;
      let dtoRead = new Read(query);
      this.doDto(dtoRead, this.dataSourceEvents.onReadDoneListeners, this.dataSourceEvents.onErrorListeners,
        (response) => {
          self.records = response.data.records;
          self.currentRecIds = [-1];
          if (self.records.length) {
            self.currentRecIds = [this.private_getRecIdFromRecord(self.records[0])];
          }
          self.readStatus = DataSource.STATUS_READ_INACTIVE;
          self.totalRows = response.data.totalRows;
          if (self.waitingQuery !== null) {
            self.read(self.waitingQuery);
            self.waitingQuery = null;
          }
        }, (response) => {
          self.readStatus = DataSource.STATUS_READ_INACTIVE;
        });
    } else {
      this.collectReadCmd(query);
    }
  };

  rebuildRecord(record) {
    let recordNew = {};
    for (let fieldName in this.entityDef.fieldsDef) {
      let fieldNameObj = new FieldName(fieldName, this.entityDef.baseDef.name);
      let fullFieldName = fieldNameObj.getFullFieldName();
      recordNew[fullFieldName] = record[fullFieldName];
    }
    return recordNew;
  }

  removeSelectField(selectField) {
    let index = this.selectFields.indexOf(selectField);
    if (index !== -1) {
      this.selectFields.splice(index, 1);
    }
  };

  setCurrentRecIds(currentRecIds, additional = false) {
    if (additional) {
      if (Array.isArray(currentRecIds)) {
        for (let index in currentRecIds) {
          let currentRecId = currentRecIds[index];
          this.private_addToCurrentRecIds(currentRecId);
        }
      } else {
        this.private_addToCurrentRecIds(currentRecIds);
      }
    } else {
      if (Array.isArray(currentRecIds)) {
        this.currentRecIds = currentRecIds;
      } else {
        this.currentRecIds = [currentRecIds];
      }
    }
    let currentRecords = this.getCurrentRecords();
    let currentRecord = (currentRecords.length ? currentRecords[0] : null);
    this.dataSourceEvents.onRowChangedListeners.fire({record: currentRecord, currentRecIds: this.currentRecIds});
  };

  setDtoProperties(_dtoProperties) {
    this.dtoProperties = _dtoProperties;
  };

  setJoinConditionString(_joinConditionString) {
    this.joinConditionString = _joinConditionString;
  };

  setLastQuery(_lastQuery) {
    this.lastQuery = _lastQuery;
  };

  setRelations(_relations) {
    this.relations = _relations;
  };

  setSelectFields(_selectFields) {
    this.selectFields = _selectFields;
  };

  updateRows(records) {
    let self = this;
    this.cleanRecord(records);
    let dtoUpdate = new Update(records);

    this.doDto(dtoUpdate, this.dataSourceEvents.onUpdateDoneListeners, null, (response) => {
      if (GF.checkNotNull(response.data) && GF.checkNotNull(response.data.records)) {
        for (let indexFromUpdatedRecord in response.data.records) {
          let updatedRecord = response.data.records[indexFromUpdatedRecord];
          let recIdFromUpdatedRecord = this.private_getRecIdFromRecord(updatedRecord);

          for (let index in self.records) {
            let recordFromPool = self.records[index];
            let recIdFromPool = this.private_getRecIdFromRecord(recordFromPool);
            if (recIdFromPool === recIdFromUpdatedRecord) {
              self.records.splice(index, 1, updatedRecord);
            }
          }
        }
      }
    });
  };
}

export default DataSource;

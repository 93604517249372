import BaseComponent from "../../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import InputTextMultiline from "../../../../../appl/view/components/inputFields/InputTextMultiline";
import InputLayoutType from "../../../../../appl/view/components/inputFields/InputLayoutType";

class InputTextMultilineTest extends BaseComponent {

    render() {
        return <div className='sandbox-container flex flex-col gap-4'>
            <VerticalLayout className={' self-center'}>
                <h1>InputTextMultilineTest</h1>
                <InputTextMultiline labelElement='TestInputHtml' helpTextElement='helpText hier' inputLayout={InputLayoutType.inline}/>
            </VerticalLayout>
        </div>
    }
}

export default InputTextMultilineTest;

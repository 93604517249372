import Dto from "../../Dto";

class Disconnect extends Dto {

  constructor(baseRecord = '', recordsToDisconnect = '', relation = '') {
    super('po2\\service\\entity\\dto\\Disconnect');
    this.baseRecord = baseRecord;
    this.recordsToDisconnect = recordsToDisconnect;
    this.relation = relation;
  }
}

export default Disconnect

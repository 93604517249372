import Dto from "../service/Dto";

class FieldName extends Dto {

  static DELEMITER = '#';

  name;
  relationName;
  delim;

  constructor(name, relationName, delim) {
    super('po2\\service\\entity\\predicate\\FieldName');
    this.name = name;
    this.relationName = relationName;
    this.delim = (delim !== undefined ? delim : FieldName.DELEMITER);
  }

  static create(fullFieldName, alternativeDelemiter) {
    let fieldName = new FieldName();

    if (alternativeDelemiter === undefined) {
      alternativeDelemiter = FieldName.DELEMITER;
    }
    let pos = fullFieldName.indexOf(alternativeDelemiter);
    if (pos !== -1) {
      fieldName.setRelationName(fullFieldName.substr(0, pos));
      fieldName.setName(fullFieldName.substr(pos + 1, fullFieldName.length + 1));
    } else {
      fieldName.setName(fullFieldName);
    }
    return fieldName;
  }

  getFullFieldName() {
    let fullFileName = '';
    if (this.name !== undefined && this.name !== null && this.name !== '') {
      fullFileName = this.name;
      if (this.relationName !== undefined && this.relationName !== null && this.relationName !== '') {
        fullFileName = this.relationName + FieldName.DELEMITER + fullFileName;
      }
    }
    return fullFileName;
  }

  getName() {
    return this.name;
  }

  setName(name) {
    this.name = name;
  }

  getRelationName() {
    return this.relationName;
  }

  setRelationName(relationName) {
    this.relationName = relationName;
  }

  getDelim() {
    return this.delim;
  }

  setDelim(delim) {
    this.delim = delim;
  }
}

export default FieldName;

import Dto from "../../../../../lib/model/service/Dto";

class CreateVariant extends Dto {

  constructor(campaignId = '', variantName = '', variantTemplate = '', pageId = '') {
    super('appl\\service\\campaign\\dto\\CreateVariant');
    this.campaignId = campaignId;
    this.variantName = variantName;
    this.variantTemplate = variantTemplate;
    this.pageId = pageId;
  }
}

export default CreateVariant;

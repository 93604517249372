import PropTypes from "prop-types";
import BaseComponent from "../../../../lib/components/BaseComponent";

class CardTitleBar extends BaseComponent {

  render() {
    return <div className={'bg-cn-color-gray-655 rounded-tr-[20px] p-3 ' + this.props.className}>
      {this.props.children}
    </div>;
  }
}

CardTitleBar.propTypes = {
  className: PropTypes.string
}
export default CardTitleBar;

import BaseComponent from "../../../../lib/components/BaseComponent";
import PropType from "prop-types";

export default class VerticalSeparator extends BaseComponent {
  render() {
    let classes = this.props.className ? this.props.className : '';
    let defaultBGColor = 'bg-cn-color-white';
    if (classes.includes('bg-cn-color')) {
      defaultBGColor = '';
    }
    return <div className={`w-[2px] min-h-full ${defaultBGColor} ${classes}`}/>
  }
}
VerticalSeparator.propTypes = {
  className: PropType.string
}
import VerticalLayout from "../../layouts/VerticalLayout";
import BaseComponent from "../../BaseComponent";
import PropTypes from "prop-types";
import HorizontalLayout from "../../layouts/HorizontalLayout";
import shortid from "shortid";

class TreeNode extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      content: (this.props.content === undefined ? null : this.props.content),
      children: (this.props.children === undefined ? null : this.props.children)
    };
  }

  buildChildNode(nodeChildren, childToAdd, pos, numOfChildren) {
    let borderNextChild = 'border-t-[1px]';
    if (pos < numOfChildren - 1) {
      borderNextChild = 'border-t-[1px] border-l-[1px]';
    }
    let nodeTreeElement = <VerticalLayout className='w-[10px]' widthFit>
      <div className={'grow border-l-[1px]'}/>
      <div className={'grow ' + borderNextChild}/>
    </VerticalLayout>;

    nodeChildren.push(<HorizontalLayout key={shortid()} className={this.props.className}>
      {nodeTreeElement}
      <div className='w-full'>{childToAdd}</div>
    </HorizontalLayout>);
  }

  render() {
    let nodeChildren = [];
    if (this.state.children !== undefined && this.state.children !== null) {
      if (this.state.children.hasOwnProperty('length')) {
        for (let i = 0; i < this.state.children.length; i++) {
          let child = this.state.children[i];
          this.buildChildNode(nodeChildren, child, i, this.state.children.length);
        }
      } else {
        this.buildChildNode(nodeChildren, this.state.children, 0, 1);
      }
    }
    let nextBranche = '';
    if (nodeChildren.length) {
      nextBranche = 'border-l-[1px]';
    }
    return <VerticalLayout className={this.props.className} widthFit>
      <HorizontalLayout>
        <VerticalLayout className='w-[10px]' widthFit>
          <div className='grow'/>
          <div className={'grow border-t-[1px] ' + nextBranche}/>
        </VerticalLayout>
        {this.state.content}
      </HorizontalLayout>
      {nodeChildren}
    </VerticalLayout>;
  }
}

TreeNode.propTypes = {
  className: PropTypes.string, content: PropTypes.node, children: PropTypes.node
}
export default TreeNode;

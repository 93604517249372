import BaseComponent from "../../../../../lib/components/BaseComponent";
import HorizontalLayout from "../../../../../lib/components/layouts/HorizontalLayout";
import InputColorPicker from "../../../../../appl/view/components/inputFields/InputColorPicker";

class ColorpickerTest extends BaseComponent {

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>
      <h1>ColorPicker</h1>
      <HorizontalLayout className={' self-center'}>
        {/*<ColorPicker labelText='TestColorPicker 1' colors={['#117a65', '#b03a2e',*/}
        {/*  '#f39c12']} hex='#888888' inputLayout={InputLayoutType.inline}/>*/}
        <InputColorPicker labelElement='Colorpicker' helpTextElement="Helptext Colorpicker 1" hex='#888888'/>
        {/*<ColorPicker labelText='TestColorPicker 2' colors={['#117A65', '#B03A2E', '#F39C12']} hex='#888888' helpText="Helptext Colorpicker 2" inputLayout={InputLayoutType.outline}/>*/}
      </HorizontalLayout>
      {/*<InputTextStandard labelText='Peng' helpText='help...' inputLayout={InputLayoutType.inline} icon={<div className='flex items-center h-full mr-2'><Circle /></div>} />*/}
    </div>
  }
}

export default ColorpickerTest;

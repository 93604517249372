import {Activity, Circle, Database, Info, PieChart, Plus, Send, Sliders, Trash, Trash2} from "react-feather";
import PropTypes from "prop-types";
import BaseComponent from "../../../../lib/components/BaseComponent";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import PieChartDiagram from "../../../../lib/components/charts/PieChart";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import ALink from "../../../../lib/components/Link/ALink";
import ChartEntry from "../../../../lib/components/charts/ChartEntry";
import shortid from "shortid";
import CM from "../../../model/system/CM";
import React, {createRef} from "react";
import ReadCampaignTableList from "../../../model/service/campaign/dto/ReadCampaignTableList";
import Menu from "../../../../lib/components/menu/Menu";
import MenuItemGroup from "../../../../lib/components/menu/MenuItemGroup";
import MenuItem from "../../../../lib/components/menu/MenuItem";
import Window from "../../../../lib/components/window/Window";
import {toast} from "react-toastify";
import ReadCampaignFunctionList from "../../../model/service/campaign/dto/ReadCampaignFunctionList";
import EntityDescribe from "../../../../lib/model/service/entity/dto/EntityDescribe";
import ButtonTab from "../../../../lib/components/tabbar/ButtonTab";
import VariablesForm from "../variables/VariablesForm";
import EntityForm from "../entity/form/EntityForm";
import DialogBase from "../../../../lib/components/dialogs/DialogBase";
import InputText from "../inputFields/InputText";
import ButtonSmall from "../buttons/ButtonSmall";
import CreateVariant from "../../../model/service/campaign/dto/CreateVariant";
import CampaignService from "../../../model/service/campaign/CampaignService";
import ReadVariableInfos from "../../../model/service/campaign/dto/ReadVariableInfos";
import IsAnalyticsActivated from "../../../model/service/campaign/dto/IsAnalyticsActivated";
import CreateAnalyticsSite from "../../../model/service/campaign/dto/CreateAnalyticsSite";
import ButtonLarge from "../buttons/ButtonLarge";
import WizardNav from "../mailingWizard/WizardNav";
import WizardContentSelectList from "../mailingWizard/WizardContentSelectList";
import WizardContentConfiguration from "../mailingWizard/WizardContentConfiguration";
import WizardContentDate from "../mailingWizard/WizardContentDate";
import WizardContentComments from "../mailingWizard/WizardContentComments";
import WizardContentOverview from "../mailingWizard/WizardContentOverview";
import WizardBase from "../../../../lib/components/wizard/WizardBase";
import VM from "../../../../lib/model/system/VM";
import RemoveVariant from "../../../model/service/campaign/dto/RemoveVariant";
import AlertDialog from "../../../../lib/components/dialogs/AlertDialog";
import GetAnalyticsLink from "../../../model/service/campaign/dto/GetAnalyticsLink";
import InputSearchDropDown from "../inputFields/dropdown/InputSearchDropDown";
import he from 'he';
import UstoreModuleService from "../../../../lib/model/campaign/modules/ustore/v002/service/UstoreModuleService";
import GetMailStatus from "../../../../lib/model/campaign/modules/ustore/v002/service/dto/GetMailStatus";
import T from "../../../model/system/text_translations/Translator";
import EmptyTableData from "../../../../lib/model/service/entity/dto/EmptyTableData";
import GF from "../../../../lib/utils/GF";
import IsAnalyticsEnabled from "../../../model/service/campaign/dto/IsAnalyticsEnabled";
import RemoveCampaign from "../../../model/service/campaign/dto/RemoveCampaign";

// noinspection JSUnresolvedReference
class CampaignDashBoard extends BaseComponent {

  constructor(props) {
    super(props);
    this.mainPanel = CM.get().getPanel();
    this.sideBar = this.mainPanel.layoutRef.current.sideBarRef.current;
    this.tabContainer = this.mainPanel.tabContainerRef.current;
    this.campaignId = this.props.data.campaignId.value;
    this.campaignHref = '';
    this.state = {
      createVariantDialog: null, addNewVariablesMenu: null
    }
    this.entityFormRef = [];
    this.wizardBaseRef = createRef();
    this.wizardNavRef = createRef();
    this.mailingDialogRef = createRef();
    this.analyticsDialogRef = createRef();
    this.clearMenuRequests();
  }

  componentDidMount() {
    this.createSideMenu();
  }

  private_createStatisticLabels(statisticElements) {
    let statisticsLegend = [];
    for (let entryIndex in statisticElements) {
      let entry = statisticElements[entryIndex];
      statisticsLegend.push(<div key={shortid()} className='flex justify-between gap-x-5'>
        <div className='flex whitespace-nowrap'>
          <Circle fill={entry.color} stroke='transparent' className='w-4 mr-4'/>{entry.label}</div>
        <div>{entry.key}</div>
      </div>)
    }
    return statisticsLegend;
  }

  private_createStatisticElements() {
    let statisticElements = [];
    for (let propertyName in this.props.data.statistic) {
      let elementValue = this.props.data.statistic[propertyName];
      statisticElements.push(new ChartEntry(elementValue.value, elementValue.label, elementValue.color));
    }
    return statisticElements;
  }

  createAdditionalTableButtons(entityDef, table) {
    let additionalTableButtons = [];
    if (table.isTypoModuleUsed !== '' && table.isTypoModuleUsed !== false) {
      additionalTableButtons.push(<ButtonSmall key={shortid()} ref={createRef()} uid='OMS'
                                               onClick={(e) => this.openOMS(e)}> OMS</ButtonSmall>);
    }
    return additionalTableButtons;
  }

  createMailingButton(table, campaignId) {
    let mailingButton = null;
    if (table.isTypoModuleUsed !== '' && table.isTypoModuleUsed !== false) {
      mailingButton =
        <ButtonLarge className='bg-cn-color-blue-690' onClick={(e) => this.openMailingDialog(e, campaignId,
          table.name)}><Send/>Versand vorbereiten</ButtonLarge>;
    }
    return mailingButton;
  }

  createSideMenu(forceFetch) {
    this.sideBar.clearMenu();
    this.clearMenuRequests();
    this.doAddTableMenu(forceFetch);
    this.doAddVariableMenu(forceFetch);
    this.doAddAnalyticsMenu(forceFetch);
    this.doAddFunctionMenu(forceFetch);
  }

  clearMenuRequests() {
    this.doAddTableMenuRequestDone = false;
    this.doAddFunctionMenuRequestDone = false;
    this.doAddVariableMenuRequestDone = false;
    this.doAddAnalyticsMenuRequestDone = false;
    this.tablesMenu = null;
    this.variablesMenu = null;
    this.functionMenu = null;
    this.analyticsMenu = null;
  }

  addTableTabToTabLayout(table, campaignId) {
    let describeTable = new EntityDescribe([table.name]);
    describeTable.setProperties(CampaignService.createCampaignRedirectProperty(this.campaignId));

    this.doDataCacheFetch(describeTable, 'DoctrineEntityService', 'addTableTabToTabLayout' + table.name, (response) => {
      if (response.success) {
        let entityDef = response.data.serviceDescription.entityDefs[0];
        let tableLabel = campaignId + ':' + entityDef.baseDef.description;
        let tabId = campaignId + ':' + table.name;

        let additionalTableButtons = this.createAdditionalTableButtons(entityDef, table, campaignId);
        let mailingButton = this.createMailingButton(table, campaignId);

        let entityFormRefKey = this.buildEntityFormRefKey(campaignId, table.name);
        this.entityFormRef[entityFormRefKey] = createRef();

        this.tabContainer.addElement(<ButtonTab id={tabId} onCloseClick2={(e, id) => {
            let foundKats = VM.get().findKat('CM.doJsonAjaxCache_' + tabId);
            for (let index in foundKats) {
              let foundKat = foundKats[index];
              VM.get().clearData(foundKat);
            }
          }}>{tableLabel}</ButtonTab>,
          <EntityForm ref={this.entityFormRef[entityFormRefKey]} isMainTable={table.isMainTable} tabId={tabId} campaignId={campaignId} entityDef={entityDef} tabMainContainer={this.tabContainer} functionElements={mailingButton} additionalTableButtons={additionalTableButtons}/>,
          true, {
            id: CM.get().createUniquId(), hook: (e, id) => {
              if (id === tabId) {
                this.createSideMenu();
              }
            }
          });
      } else {
        if (response.message !== undefined) {
          toast.error(response.message);
        } else {
          toast.error('Tabelleninhalt konnte nicht geladen werden!');
        }
      }
    });
  }

  buildEntityFormRefKey(campaignId, entityName) {
    return campaignId + '-' + entityName;
  }

  openMailingDialog(e, campaignId, entityName) {
    let entityFormRefKey = this.buildEntityFormRefKey(campaignId, entityName);
    this.entityFormRef[entityFormRefKey].current.state.mailingWizard =
      <DialogBase ref={this.mailingDialogRef} onCloseButtonClick={() => {
        this.entityFormRef[entityFormRefKey].current.state.mailingWizard = null;
        this.entityFormRef[entityFormRefKey].current.setState(this.entityFormRef[entityFormRefKey].current.state);
        VM.get().clearData('MailingWizard');
      }}><WizardBase ref={this.wizardBaseRef} wizardNavRef={this.wizardNavRef} wizardNav={
        <WizardNav ref={this.wizardNavRef} wizardBase={this.wizardBaseRef}/>} wizardContent={[
        <WizardContentSelectList campaignId={campaignId} entityName={entityName} wizardNavRef={this.wizardNavRef}/>,
        <WizardContentConfiguration campaignId={campaignId} wizardNavRef={this.wizardNavRef} wizardBaseRef={this.wizardBaseRef} mailingDialogRef={this.mailingDialogRef}/>,
        <WizardContentDate wizardNavRef={this.wizardNavRef}/>,
        <WizardContentComments wizardNavRef={this.wizardNavRef}/>,
        <WizardContentOverview wizardNavRef={this.wizardNavRef} campaignId={campaignId} mailingDialogRef={this.mailingDialogRef}/>]}/>
      </DialogBase>;
    this.entityFormRef[entityFormRefKey].current.setState(this.entityFormRef[entityFormRefKey].current.state);
  }

  openOMS(e) {
    let self = this
    let entityDescribe = new EntityDescribe(['appl\\model\\entity\\OutputTable']);
    let dtoProps = CampaignService.createCampaignRedirectProperty(this.campaignId, 'oms');
    entityDescribe.setProperties(dtoProps);
    CM.get().doJsonAjax(CM.get().getServiceUrl('DoctrineEntityService'), entityDescribe, (response) => {
      if (response.success) {
        self.tabContainer.addElement(<ButtonTab id={self.campaignId + ':OMS'}>{self.campaignId}:OMS</ButtonTab>,
          <EntityForm isMainTable={false} tabId={'OMS'} campaignId={self.campaignId} entityDef={response.data.serviceDescription.entityDefs[0]} dtoProperties={dtoProps}
                      tabMainContainer={self.tabContainer} additionalTableButtons={[
            <ButtonSmall key={shortid()} uid='MAILING_INFO' onClick={(e) => this.displayMailingInfos(
              e)} tooltip='Mailing-Infos'><Info/></ButtonSmall>]}/>);
      } else {
        toast.error(response.message);
      }
    });
  }

  displayMailingInfos(e) {
    let getMailStatus = new GetMailStatus(this.campaignId);
    getMailStatus.setProperties(CampaignService.createCampaignRedirectProperty(getMailStatus.getCampaignId()));
    CM.get().doJsonAjax(CM.get().getServiceUrl(UstoreModuleService.NAME), getMailStatus, (response) => {
      if (response.success) {
        let data = response.data.data;
        let dialog = <DialogBase title='Mailinginfos'>
          <VerticalLayout className='p-5'>
            <Menu>
              <MenuItemGroup>
                <MenuItem className='gap-x-10 justify-between'>
                  <h4 className='font-cn-inter-regular'>Offene Mailings</h4>
                  <p className='rounded-full px-2 py-0.5 bg-cn-color-secondary'>{data.numOfOpenDocs}</p>
                </MenuItem>
                <MenuItem className='gap-x-10 justify-between'>
                  <h4 className='font-cn-inter-regular'>Mailings am versenden</h4>
                  <p className='rounded-full px-2 py-0.5 bg-cn-color-secondary'>{data.numOfInProgressDocs}</p>
                </MenuItem>
                <MenuItem className='gap-x-10 justify-between'>
                  <h4 className='font-cn-inter-regular'>Erfolgreich versendete Mailings</h4>
                  <p className='rounded-full px-2 py-0.5 bg-cn-color-secondary'>{data.numOfFinishedDocs}</p>
                </MenuItem>
                <MenuItem className='gap-x-10 justify-between'>
                  <h4 className='font-cn-inter-regular'>Mailings mit Fehler</h4>
                  <p className='rounded-full px-2 py-0.5 bg-cn-color-secondary'>{data.numOfErrorDocs}</p>
                </MenuItem>
                <MenuItem className='gap-x-10 justify-between'>
                  <h4 className='font-cn-inter-regular'>Total Mailings</h4>
                  <p className='rounded-full px-2 py-0.5 bg-cn-color-secondary'>{data.numOfTotalDocs}</p>
                </MenuItem>
              </MenuItemGroup>
            </Menu>
          </VerticalLayout>
        </DialogBase>
        CM.get().setMainDialog(dialog);
      } else {
        let message = response.message != null ? response.message : T.genericErrorMessage();
        toast.error(message);
      }
    });

  }

  doAddTableMenu(forceFetch) {
    let self = this;
    let tableMenuHeader =
      <HorizontalLayout className='bg-cn-color-secondary text-cn-color-static-white p-4 cn-border-radius gap-x-3'>
        <Database/>{T.label_tables()}
      </HorizontalLayout>;
    let campaignId = this.props.data.campaignId.value;

    this.doDataCacheFetch(new ReadCampaignTableList(campaignId), 'campaign', 'doAddTableMenu', (response) => {
      if (response.success) {
        let campaignTables = [];
        for (let table of response.data.campaignTables) {
          campaignTables.push(
            <MenuItem className='px-4 py-1.5 justify-between hover:bg-transparent ' key={table.name} option={table.name}>
              <div className='w-full hover:text-cn-color-primary transition' onClick={(e) => self.addTableTabToTabLayout(
                table, campaignId)}>
                {table.label}
              </div>
              <Trash className='hover:bg-cn-color-static-red hover:p-1 transition-[background] transition-[padding] rounded-full p-0.5'
                     onClick={(e) => this.emptyTableData(e, campaignId, table.name, table.label)}/>
            </MenuItem>);
        }
        self.tablesMenu = <MenuItemGroup key={shortid()} header={tableMenuHeader}>
          {campaignTables}
        </MenuItemGroup>
      } else {
        if (response.message !== undefined) {
          toast.error(response.message);
        } else {
          toast.error('Tabellen konnten nicht ermittelt werden!');
        }
      }
      self.doAddTableMenuRequestDone = true;
      self.addElementsToMenu();
    }, forceFetch);
  }

  doAddFunctionMenu(forceFetch) {
    let self = this;
    let functionsMenuGroupHeader =
      <HorizontalLayout className='bg-cn-color-secondary text-cn-color-static-white p-4 cn-border-radius gap-x-3 items-center mb-2'>
        <Activity/>{T.label_functions()}
      </HorizontalLayout>;
    let campaignId = this.props.data.campaignId.value;
    let functionList = [];

    this.doDataCacheFetch(new ReadCampaignFunctionList(campaignId), 'campaign', 'doAddFunctionMenu', (response) => {
      if (response.success) {
        for (let campaignFunction of response.data.functions.function) {
          functionList.push(
            <MenuItem className='px-4 py-1.5 justify-between hover:bg-transparent hover:text-cn-color-primary transition' onClick={() => {
              window.open(self.getLinkOutOfATag() + campaignFunction['link'], '_blank');
            }} key={campaignFunction['link']} option={campaignFunction['link']}>{campaignFunction.label}</MenuItem>)
        }
        if (functionList.length > 0) {
          self.functionMenu = <MenuItemGroup key={shortid()} header={functionsMenuGroupHeader}>
            {functionList}
          </MenuItemGroup>
        }
      } else {
        toast.error('Die Funktionslite konnte nicht ermittelt werden!');
      }
      self.doAddFunctionMenuRequestDone = true;
      self.addElementsToMenu();
    }, forceFetch);
  }

  addElementsToMenu() {
    if (this.doAddTableMenuRequestDone && this.doAddVariableMenuRequestDone && this.doAddFunctionMenuRequestDone &&
      this.doAddAnalyticsMenuRequestDone) {
      let doSetState = false;
      if (this.tablesMenu !== null) {
        this.sideBar.addMenu(this.tablesMenu);
        doSetState = true;
      }
      if (this.variablesMenu !== null) {
        this.sideBar.addMenu(this.variablesMenu);
        doSetState = true;
      }
      if (this.analyticsMenu !== null) {
        this.sideBar.addMenu(this.analyticsMenu);
        doSetState = true;
      }
      if (this.functionMenu !== null) {
        this.sideBar.addMenu(this.functionMenu);
        doSetState = true;
      }
      if (doSetState) {
        this.sideBar.setState(this.sideBar.state);
      }
    }
  }

  doAddVariableMenu(forceFetch) {
    let self = this;
    let variablesMenuHeader =
      <HorizontalLayout className='bg-cn-color-secondary text-cn-color-static-white p-4 cn-border-radius gap-x-3 justify-between'>
        <Sliders className='rotate-90'/>{T.label_variant()}<Plus className='bg-cn-color-primary cn-border-radius-4 cursor-pointer'
                                                                 onClick={(e) => this.onNewVariablesClick(e)}/>
      </HorizontalLayout>;
    let campaignId = this.props.data.campaignId.value;
    let campaignVariables = [];
    this.doDataCacheFetch(new ReadVariableInfos(campaignId), 'campaign', 'doAddVariableMenu', (response) => {
      if (response.success) {
        for (let variantIndex in response.data.variants) {
          let variantName = response.data.variants[variantIndex];

          let trash;
          if (variantName !== 'std') {
            trash =
              <Trash2 className='hover:bg-cn-color-static-red hover:p-1 transition-[background] transition-[padding] rounded-full p-0.5'
                      onClick={(e) => this.removeVariant(e, variantName, campaignId)}/>;
          }
          // faha added this so users see standard, but it is std in the background
          let variantTmpName = variantName === 'std' ? 'Standard' : variantName;
          campaignVariables.push(
            <MenuItem key={variantIndex} option={variantIndex} className='px-4 py-1.5 justify-between hover:bg-transparent'>
              <div className='w-full hover:text-cn-color-primary transition' onClick={(e) => self.onVariableVariantClick(
                e, variantName, campaignId)}>
                {variantTmpName}
              </div>
              {trash}
            </MenuItem>)
        }
        self.variablesMenu = <MenuItemGroup key={shortid()} className='relative' header={variablesMenuHeader}>
          {campaignVariables}
        </MenuItemGroup>;
      } else {
        toast.error('Die Varianten konnten nicht ermittelt werden!');
      }
      self.doAddVariableMenuRequestDone = true;
      self.addElementsToMenu();
    }, forceFetch);
  }

  removeVariant(e, variantName, campaignId) {
    let self = this;
    let message = <VerticalLayout className='p-3 gap-3'>
      <p className='cn-font-paragraph'>{'Möchten Sie Variante[' + variantName + '] löschen?'}</p>
      <HorizontalLayout className='gap-3 justify-end'>
        <ButtonSmall onClick={(e) => CM.get().closeMainDialog()}>Abbrechen</ButtonSmall>
        <ButtonSmall color='red' onClick={(e) => {
          CM.get().doJsonAjax(CM.get().getServiceUrl('campaign'), new RemoveVariant(campaignId, variantName, null),
            (response) => {
              if (response.success) {
                toast.success('Variante [' + variantName + '] wurde erfolgreich gelöscht.');
                self.createSideMenu(true);
                self.tabContainer.removeElement(campaignId + ':' + variantName);
                CM.get().closeMainDialog();
              } else {
                toast.error(response.message);
              }
            });
        }}>Löschen</ButtonSmall>
      </HorizontalLayout>
    </VerticalLayout>
    CM.get().setMainDialog(<AlertDialog errorTitle='Löschen?' errorMessage={message}/>);
  }

  emptyTableData(e, campaignId, tableName, tableLabel) {
    let self = this;
    CM.get().setMainDialog(
      this.createAlertDialog('Tabelle leeren?', 'möchtest du die ausgewählte Tabelle [' + tableLabel + '] leeren?',
        'Leeren', (e) => {
          CM.get().doJsonAjax(CM.get().getServiceUrl('DoctrineEntityService'),
            new EmptyTableData(campaignId, tableName), (response) => {
              if (response.success) {
                let entityFormRef = this.entityFormRef[self.buildEntityFormRefKey(campaignId, tableName)];
                if (GF.checkNotNull(entityFormRef) && GF.checkNotNull(entityFormRef.current) &&
                  GF.checkNotNull(entityFormRef.current.entityRef) &&
                  GF.checkNotNull(entityFormRef.current.entityRef.current)) {

                  this.entityFormRef[self.buildEntityFormRefKey(campaignId,
                    tableName)].current.entityRef.current.doReadTable();
                }
                toast.success(response.message);
                CM.get().closeMainDialog();
              } else {
                toast.error(response.message);
              }
            });
        }));
  }

  getLinkOutOfATag() {
    let aHref = '';
    if (this.props.data.campaignLink !== undefined && this.props.data.campaignLink.value !== undefined) {
      let domParser = new DOMParser();
      let linkNode = domParser.parseFromString(this.props.data.campaignLink.value, 'text/xml');
      let aTag = linkNode.getElementsByTagName('a')[0];
      aHref = aTag.getAttribute('href');
    }
    return aHref;
  }

  getStorageId() {
    return 'CampaignDashBoard_' + this.props.id;
  }

  onNewVariablesClick() {
    let self = this;
    let variantName = '';
    let variantTemplate = '';
    CM.get().setMainDialog(<DialogBase title='Neue Variante erstellen'>
      <VerticalLayout className='gap-3 mt-3'>
        <InputText onChange={(e) => variantName = e.target.value} labelElement='Variante-Name'/>
        <InputSearchDropDown labelElement='Varianten-Template' onChange={(e, id, data) => variantTemplate = data.option}
                             defaultValue={{option: 'DE', displayValue: 'Deutsch'}}>
          <Menu className='text-cn-color-white'>
            <MenuItemGroup>
              <MenuItem searchValue='Custom' option={{option: '', displayValue: 'Custom'}}>Custom</MenuItem>
              <MenuItem searchValue='Deutsch' option={{option: 'DE', displayValue: 'Deutsch'}}>Deutsch</MenuItem>
              <MenuItem searchValue='Französisch' option={{
                option: 'FR',
                displayValue: 'Französisch'
              }}>Französisch</MenuItem>
              <MenuItem searchValue='Italienisch' option={{
                option: 'IT',
                displayValue: 'Italienisch'
              }}>Italienisch</MenuItem>
              <MenuItem searchValue='Englisch' option={{option: 'EN', displayValue: 'Englisch'}}>Englisch</MenuItem>
            </MenuItemGroup>
          </Menu>
        </InputSearchDropDown>
        <ButtonSmall widthFull className='w-full' color='secondary' onClick={(e) => {
          let dtoCreateVariant = new CreateVariant(this.campaignId, variantName, variantTemplate);
          CM.get().doJsonAjax(CM.get().getServiceUrl('Campaign'), dtoCreateVariant, (response) => {
            if (response.success) {
              toast.success('Variant wurde erfolgreich erstellt.');
              CM.get().closeMainDialog();
              self.createSideMenu(true);
            } else {
              toast.error('Variant konnte nicht erstellt werden! ' + response.message);
            }
          });
        }}>Erstellen</ButtonSmall>
      </VerticalLayout>
    </DialogBase>, (e) => CM.get().closeMainDialog(e))
  }

  onVariableVariantClick(e, variantName, campaignId) {
    let tabRef = createRef();

    // faha added this so users see standard, but it is std in the background
    let variantTmpName = variantName === 'std' ? 'Standard' : variantName;
    let tabId = campaignId + ':' + variantTmpName;

    this.clearCache('VariablesForm_' + campaignId);
    this.tabContainer.addElement(<ButtonTab id={tabId} ref={tabRef}>{tabId}</ButtonTab>,
      <VariablesForm id={tabId} variant={variantName} campaignId={campaignId} campaignHref={this.campaignHref}/>);
  }

  doAddAnalyticsMenu(forceFetch) {
    let self = this;
    let campaignId = this.props.data.campaignId.value;

    this.doDataCacheFetch(new IsAnalyticsEnabled(campaignId), 'campaign', 'isAnalyticsEnabled', (response) => {
      if (response.success) {
        if (response.data.data) {
          this.isAnalyticsActivated(campaignId, forceFetch);
        } else {
          self.doAddAnalyticsMenuRequestDone = true;
          self.addElementsToMenu();
        }
      } else {
        self.doAddAnalyticsMenuRequestDone = true;
        self.addElementsToMenu();
        toast.error('Der Analyticsstatus konnte nicht abgefragt werden!');
      }
    }, forceFetch);
  }

  isAnalyticsActivated(campaignId, forceFetch) {
    let self = this;
    this.doDataCacheFetch(new IsAnalyticsActivated(campaignId), 'campaign', 'isAnalyticsActivated', (response) => {
      if (response.success) {
        if (response.data.data) {
          this.prepareAnalyticsMenuWithAnalyticsLink(campaignId);
        } else {
          this.doAnalyticsDialog(campaignId);

          self.doAddAnalyticsMenuRequestDone = true;
          self.addElementsToMenu();
        }
      } else {
        self.doAddAnalyticsMenuRequestDone = true;
        self.addElementsToMenu();
        // toast.error('Analytics Fehler');
      }
    }, forceFetch);
  }

  prepareAnalyticsMenuWithAnalyticsLink(campaignId) {
    let self = this;
    this.doDataCacheFetch(new GetAnalyticsLink(campaignId), 'campaign', 'getAnalyticsLink',
      (analyticsResponse) => {
        if (analyticsResponse.success) {
          self.createAnalyticsMenu('Analytics', (e) => {
            if (analyticsResponse.data.loginUrl !== '') {
              window.open(analyticsResponse.data.loginUrl);
            } else {
              toast.warning('Analytics ist nicht aktiviert!')
            }
          });
        } else {
          toast.error(analyticsResponse.response.message);
        }
        self.doAddAnalyticsMenuRequestDone = true;
        self.addElementsToMenu();
      });
  }

  doAnalyticsDialog(campaignId) {
    let self = this;
    this.createAnalyticsMenu(T.label_activate_analytics(), (e) => {
      let dialog = <DialogBase ref={self.analyticsDialogRef} title='Analytics'>
        <VerticalLayout className='p-3 gap-3'>
          <p className='cn-font-paragraph'>{'Möchten Sie Analytics aktivieren?'}</p>
          <HorizontalLayout className='gap-3 justify-end'>
            <ButtonSmall onClick={(e) => {
              self.analyticsDialogRef.current.closeDialog();
            }}>Abbrechen</ButtonSmall>
            <ButtonSmall color='red' onClick={(e) => {
              self.doDataCacheFetch(new CreateAnalyticsSite(campaignId), 'campaign', 'createAnalyticsSite',
                (response) => {
                  if (response.success) {
                    toast.success('Analytics wurden aktiviert');
                    self.createSideMenu(true);
                  } else {
                    self.doAddAnalyticsMenuRequestDone = true;
                    self.addElementsToMenu();
                    toast.error(response.message);
                  }
                }, true);
              self.analyticsDialogRef.current.closeDialog();
            }}>Ja</ButtonSmall>
          </HorizontalLayout>
        </VerticalLayout>
      </DialogBase>
      CM.get().setMainDialog(dialog);
    });
  }

  createAnalyticsMenu(title, callBack) {
    let analyticsMenuHeader =
      <HorizontalLayout className='bg-cn-color-secondary text-cn-color-static-white p-4 cn-border-radius gap-x-3 cursor-pointer'
                        onClick={(e) => {
                          callBack(e);
                        }}>
        <PieChart/>{title}
      </HorizontalLayout>;
    this.analyticsMenu = <MenuItemGroup key={shortid()} className="hover:bg-transparent" header={analyticsMenuHeader}/>;
  }

  createAlertDialog(title, message, submitButtonText, onSubmitButtonClick) {
    return <AlertDialog errorTitle={title} errorMessage={<VerticalLayout className='p-3 gap-3'>
      <p className='cn-font-paragraph'>{message}</p>
      <HorizontalLayout className='gap-3 justify-end'>
        <ButtonSmall onClick={(e) => CM.get().closeMainDialog(e)}>{T.label_cancel()}</ButtonSmall>
        <ButtonSmall color='red' onClick={onSubmitButtonClick}>{submitButtonText}</ButtonSmall>
      </HorizontalLayout>
    </VerticalLayout>}/>
  }

  render() {
    let statisticElements = this.private_createStatisticElements();
    let statisticsLegend = this.private_createStatisticLabels(statisticElements);
    this.campaignHref = this.getLinkOutOfATag();
    return <Window showCloseButton classNameContent='flex text-cn-color-white flex-wrap lg:flex-nowrap'>
      <div className='border-r-0 xl:border-r my-0 xl:my-3 w-1/2'>
        <VerticalLayout className='p-4 lg:p-10'>
          <h2>{T.label_activities()}</h2>
          <HorizontalLayout className='gap-x-6 xl:gap-x-12 mt-5 gap-y-2 flex-wrap'>
            <div className='max-h-52 self-center'>
              <PieChartDiagram data={statisticElements}/>
            </div>
            <VerticalLayout className='justify-center cn-font-paragraph gap-y-1 mt-5 lg:mt-0 cn-font-paragraph' widthFit>
              {statisticsLegend}
            </VerticalLayout>
          </HorizontalLayout>
        </VerticalLayout>
      </div>
      <div className='w-1/2'>
        <VerticalLayout className='p-4 lg:p-10'>
          <div className='w-full xl:w-4/5 mx-auto'>
            <h2 className='mb-3'>{T.label_overview()}</h2>
            <HorizontalLayout className='justify-center'>
              <div className='grid grid-cols-2 w-full gap-y-2'>
                <div className='col-span-1 cn-font-paragraph'>campaign-ID:</div>
                <div className='col-span-1 cn-font-paragraph'>{this.props.data.campaignId.value}</div>
                <div className='col-span-1 cn-font-paragraph'>campaign-Name:</div>
                <div className='col-span-1 cn-font-paragraph'>{he.decode(this.props.data.name.value)}</div>
                <div className='col-span-1 cn-font-paragraph'>campaign-Beschreibung:</div>
                <div className='col-span-1 cn-font-paragraph'>{this.props.data.description.value}</div>
                <div className='col-span-1 cn-font-paragraph'>campaign-Status:</div>
                <div className='col-span-1 cn-font-paragraph'>{this.props.data.status.value}</div>
                <div className='col-span-1 cn-font-paragraph'>Erstellt von:</div>
                <div className='col-span-1 cn-font-paragraph'>{this.props.data.createdBy.value}</div>
                <div className='col-span-1 cn-font-paragraph'>Erstellt am:</div>
                <div className='col-span-1 cn-font-paragraph'>{this.props.data.createdDateTime.value}</div>
                {/*<div className='col-span-1 cn-font-paragraph'>Freigegeben am:</div>*/}
                {/*<div className='col-span-1 cn-font-paragraph'>n/a</div>*/}
                {/*<div className='col-span-1 font-cn-inter-light'>Zugelassene Benutzer:</div>*/}
                {/*<div className='col-span-1 font-cn-inter-light'>n/a</div>*/}
                <div className='col-span-1 cn-font-paragraph'>Link:</div>
                <div className='col-span-1 cn-font-paragraph relative top-[-10px]'>
                  <ALink title={this.campaignHref} href={this.campaignHref} target='_blank'/>
                </div>
              </div>
            </HorizontalLayout>
          </div>
        </VerticalLayout>
      </div>
    </Window>;
  }
}

CampaignDashBoard.propTypes = {
  parent: PropTypes.object, id: PropTypes.string
}
export default CampaignDashBoard

import PropTypes from "prop-types";
import BaseComponent from "../../../../../lib/components/BaseComponent";
import Window from "../../../../../lib/components/window/Window";
import TableCard from "./TableCard";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import HorizontalLayout from "../../../../../lib/components/layouts/HorizontalLayout";
import ButtonSmall from "../../buttons/ButtonSmall";
import {createRef} from "react";
import GF from "../../../../../lib/utils/GF";
import CM from "../../../../model/system/CM";
import AlertDialog from "../../../../../lib/components/dialogs/AlertDialog";
import React from "react";

class Download extends BaseComponent {

  constructor(props) {
    super(props);
    this.tableCardRef = createRef();
    this.noSelectionDialogRef = createRef();
  }

  doDownload() {
    let selectedFields = this.tableCardRef.current.getSelectedFields();
    if (GF.checkNotNull(selectedFields) && selectedFields.length > 0) {
      let query = this.props.dataSource.getLastQuery();
      query.setSelectFields(selectedFields);
      query.setStartRow(-1);
      query.setEndRow(-1);
      this.props.dataSource.export(query, undefined);
    } else {
      let question = <VerticalLayout>
        <div className='mt-4 mb-4'>Es sind keine Felder ausgewählt, bitte zuerst Felder auswählen!</div>
        <HorizontalLayout className='justify-end gap-x-5'>
          <ButtonSmall onClick={(e) => {
            this.noSelectionDialogRef.current.onCloseButtonClick(e);
          }}>Schliessen</ButtonSmall>
        </HorizontalLayout>
      </VerticalLayout>;

      CM.get().setMainDialog(
        <AlertDialog ref={this.noSelectionDialogRef} errorTitle='Export' errorMessage={question}/>);
    }
  }

  render() {
    return <Window className='text-cn-color-white' title='exportieren'>
      <VerticalLayout>
        <TableCard ref={this.tableCardRef} className='mb-4' dataSource={this.props.dataSource}/>
        <HorizontalLayout className='justify-end'>
          <ButtonSmall className='bg-cn-color-blue-690' onClick={(e) => this.doDownload(e)}>Download</ButtonSmall>
        </HorizontalLayout>
      </VerticalLayout>
    </Window>;
  }
}

Download.propTypes = {
  dataSource: PropTypes.object
}
export default Download;

import PropTypes from "prop-types";
import {MoreVertical} from "react-feather";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";

import React from "react";

import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";

import CampaignBase from "./CampaignBase";

class CampaignList extends CampaignBase {

  render() {
    let campaign = this.props.campaign;
    if (this.state.image === null) {
      let campaignImageUrl = campaign.thumbnail !== undefined && campaign.thumbnail !== '' ? campaign.thumbnail :
        this.defaultImages[(this.props.index % 7 + 1)];
      this.state.image = <img alt='Thumbnail' onClick={(e) => this.onChangeStateMenuItemClick(
        e)} src={campaignImageUrl} className={'cn-border-radius h-full w-[75px] object-cover border-2 cursor-pointer ' +
        this.getColorClassFromStatus(campaign.status, true)}/>;
    }

    return <HorizontalLayout widthFit className='gap-2 bg-cn-color-black w-[300px] h-[60px] items-center cn-border-radius text-cn-color-white'>
      <div className='relative p-1 h-full'>
        {this.state.image}
        {this.state.changeStateMenu}
      </div>
      <VerticalLayout className='cursor-pointer' onClick={(e) => this.onCardClick(e)}>
        <p className="w-[200px] truncate">{campaign.name}</p>
        <p className='text-sm text-cn-color-secondary '>{campaign.createdDate}</p>
      </VerticalLayout>
      <div>
        <MoreVertical onClick={(e) => this.onMoreButtonClick(e)} className="stroke-cn-color-white cursor-pointer"/>
        {this.state.mainMenu}
      </div>
    </HorizontalLayout>;
  }
}

CampaignList.propTypes = {
  campaign: PropTypes.object, onClick: PropTypes.func, reloadCampaigns: PropTypes.func, index: PropTypes.number
}
export default CampaignList;
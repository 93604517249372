
class MemoryColumn {

  numOfRows = 0;
  columnName = null;
  fieldDef = null;
  recIdPos = -1;
  isKey = false;
  rows = [];

  constructor(columnName, fieldDef) {
    this.columnName = columnName;
    this.fieldDef = fieldDef;
    this.isKey = fieldDef.type.isKey;
  }

  addColumnElement(fieldValue) {
    if (this.isKey) {
      fieldValue = ++this.recIdPos;
    }
    this.rows.push(fieldValue);
    this.numOfRows++;
  }

  getColumnName() {
    return this.columnName;
  }

  getNumOfRows() {
    return this.numOfRows;
  }

  getRowElement(rowId) {
    let row;
    if (rowId < 0) {
      row = this.rows[this.numOfRows - 1];
    } else {
      row = this.rows[rowId];
    }
    return row;
  }

  getRowIdByRecId(recId) {
    let rowId = -1;
    for (let index in this.rows) {
      let row = this.rows[index];

      if (row === recId) {
        rowId = index;
        break;
      }
    }
    return rowId;
  }

  isKeyColumn() {
    return this.isKey;
  }

  removeRowElement(rowId) {
    let rowsNew = [];
    for (let index in this.rows) {
      let row = this.rows[index];
      if (index !== rowId) {
        rowsNew.push(row);
      }
    }
    this.numOfRows = rowsNew.length;
    this.rows = rowsNew;
  }

  setColumnElement(rowId, value) {
    if (!this.isKeyColumn()) {
      this.rows[rowId] = value;
    }
  }
}

export default MemoryColumn;

import Dto from "../../../../../lib/model/service/Dto";

class RemoveVariant extends Dto {

  campaignId;
  variantName;
  pageId;

  constructor(campaignId = '', variantName = '', pageId = '') {
    super('appl\\service\\campaign\\dto\\RemoveVariant');
    this.campaignId = campaignId;
    this.variantName = variantName;
    this.pageId = pageId;
  }

  getCampaignId() {
    return this.campaignId;
  }

  setCampaignId(campaignId) {
    this.campaignId = campaignId;
  }

  getVariantName() {
    return this.variantName;
  }

  setVariantName(variantName) {
    this.variantName = variantName;
  }

  getPageId() {
    return this.pageId;
  }

  setPageId(pageId) {
    this.pageId = pageId;
  }

}

export default RemoveVariant;

import {components} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {XCircle} from "react-feather";
import PropTypes from "prop-types";
import './Tag.css';
import BaseComponent from "../BaseComponent";
import {createRef} from "react";

class Tag extends BaseComponent {

  constructor(props) {
    super(props);
    this.options = [];
    if (this.props.options !== undefined && this.props.options !== null) {
      this.options = this.props.options;
    }
    this.multiValueRemove = (props) => {
      return (<components.MultiValueRemove {...props}>
        <div className='flex flex-col justify-center h-full'>
          <XCircle className="cursor-pointer h-[16px] mt-[3px] ml-[4px] text-cn-color-static-red" />
        </div>
      </components.MultiValueRemove>);
    };
    this.tagElementRef = createRef();
  }

  getValue() {
    return this.tagElementRef.current.value;
  }

  onValueChange(value) {
    if (this.props.onChange !== undefined) {
      this.props.onChange(value);
    }
  }

  render() {
    return <CreatableSelect components={{ClearIndicator: this.multiValueRemove,
      DropdownIndicator: () => null, IndicatorSeparator: () => null, MultiValueRemove: this.multiValueRemove
    }} isOptionDisabled={(option) => option.disabled} defaultValue={this.options} isMulti onChange={(value) => this.onValueChange(value)} styles={{
      control: (baseStyles, state) => ({
        ...baseStyles,
        border: '0 solid',
        boxShadow: 'none',
        height: '100%',
        backgroundColor: 'var(--color-black)',
        color: 'var(--color-white-960)'
      }), multiValueRemove: (baseStyles) => ({
        ...baseStyles, height: 'h-full', ':hover': {
          backgroundColor: 'var(--color-black)'
        }, cursor: 'pointer'
      }), multiValue: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: 'var(--color-black-804)',
        borderRadius: '0 4px 4px 4px',
        color: 'var(--color-status-red)',
        height: '100%'
      }), multiValueLabel: (baseStyles) => ({
        ...baseStyles, color: 'var(--color-white-960)'
      }), input: (baseStyles) => ({
        ...baseStyles, color: 'var(--color-white-960)',
        cursor: 'text'
      }), option: (baseStyles) => ({
        ...baseStyles, display: 'none'
      }), menuList: (baseStyles) => ({
        ...baseStyles, display: 'none'
      }),clearIndicator: (baseStyles) => ({
        ...baseStyles,
        ':hover': {
          color: '#fff'
        }
      })
    }} noOptionsMessage={() => null} placeholder='hier Text eingeben und mit Enter bestätigen...' className='text-red-700' ref={this.tagElementRef}/>
  }
}

Tag.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func
}
export default Tag;

import React from 'react';
import BaseComponent from "../../../../../lib/components/BaseComponent";
import PropTypes from "prop-types";
import shortid from "shortid";
import ButtonSmall from "../../buttons/ButtonSmall";
import InputDropDown from "../../inputFields/dropdown/InputDropDown";
import Menu from "../../menu/Menu";
import MenuItemGroup from "../../../../../lib/components/menu/MenuItemGroup";
import MenuItem from "../../../../../lib/components/menu/MenuItem";
import ButtonGroup from "../../buttons/ButtonGroup";
import GF from "../../../../../lib/utils/GF";

class Pager extends BaseComponent {
  constructor(props) {
    super(props);
    this.dropDownKey = shortid();
    this.state = {
      pageSize: 10, actualPage: 1, buttons: [], totalRows: GF.getValue(this.props.totalRows, 0)
    };
    this.state.buttons = this.buildButtons();
  }

  buildButton(actualPage, buttonTitle, isActive) {
    let classes = isActive ? 'active' : '';
    return (<ButtonSmall onClick={(e) => this.handleClick(e, actualPage,
      this.state.pageSize)} className={classes} key={actualPage}>{buttonTitle}</ButtonSmall>);
  };

  handleClick(e, actualPage, pageSize) {
    this.state.actualPage = actualPage;
    this.state.buttons = this.buildButtons(this.state.totalRows);
    this.setState(this.state);
    if (this.props.onPageChanged) {
      this.props.onPageChanged(e, actualPage, pageSize);
    }
  };

  buildButtons(totalRows) {
    let pageSize = this.state.pageSize;
    let actualPageIndex = this.state.actualPage;
    let numOfPages = Math.ceil(totalRows / pageSize);
    let buttons = [];

    buttons.push(<InputDropDown color='secondary' className='min-w-[100px]' defaultValue={{
      option: this.state.pageSize,
      displayValue: this.state.pageSize
    }} onChange={(e, id, value) => {
      this.state.pageSize = parseInt(value);
      this.state.buttons = this.buildButtons(totalRows);
      this.setState(this.state);
      this.handleClick(e, 1, this.state.pageSize);
    }} key={this.dropDownKey}>
      <Menu>
        <MenuItemGroup>
          <MenuItem option='10'>10</MenuItem>
          <MenuItem option='20'>20</MenuItem>
          <MenuItem option='50'>50</MenuItem>
          <MenuItem option='80'>80</MenuItem>
          <MenuItem option='100'>100</MenuItem>
        </MenuItemGroup>
      </Menu>
    </InputDropDown>);

    buttons.push(<ButtonSmall key={shortid()}>Anzahl Datensätze: {totalRows}</ButtonSmall>);
    const buildButtonLessEqual10 = () => {
      for (let i = 1; i <= numOfPages; i++) {
        const isActive = actualPageIndex === i;
        buttons.push(this.buildButton(i, i, isActive));
      }
    };

    const buildButtonGrater10 = () => {
      let tempPageIndex = 0;
      // first
      buttons.push(this.buildButton(1, 'Erste', false));

      // prev_1_2    -1/2
      let pageIndex = actualPageIndex - Math.floor(numOfPages / 2);
      if (pageIndex >= 1) {
        tempPageIndex = pageIndex;
        buttons.push(this.buildButton(pageIndex, pageIndex, actualPageIndex === pageIndex));
      }

      // prev_1_10   -1/10
      pageIndex = actualPageIndex - Math.floor(numOfPages / 10);
      if (pageIndex >= 1 && pageIndex > tempPageIndex) {
        tempPageIndex = pageIndex;
        buttons.push(this.buildButton(pageIndex, pageIndex, actualPageIndex === pageIndex));
      }

      // prev_10     -10
      pageIndex = actualPageIndex - 10;
      if (pageIndex >= 1 && pageIndex > tempPageIndex) {
        tempPageIndex = pageIndex;
        buttons.push(this.buildButton(pageIndex, pageIndex, actualPageIndex === pageIndex));
      }

      // prev_1      -1
      pageIndex = actualPageIndex - 1;
      if (pageIndex >= 1 && pageIndex > tempPageIndex) {
        tempPageIndex = pageIndex;
        buttons.push(this.buildButton(pageIndex, pageIndex, actualPageIndex === pageIndex));
      }

      // actualPage
      pageIndex = actualPageIndex;
      if (actualPageIndex > tempPageIndex) {
        tempPageIndex = pageIndex;
        buttons.push(this.buildButton(actualPageIndex, actualPageIndex, actualPageIndex === pageIndex));
      }

      // next_1      +1
      pageIndex = actualPageIndex + 1;
      if (pageIndex <= numOfPages && pageIndex > tempPageIndex) {
        tempPageIndex = pageIndex;
        buttons.push(this.buildButton(pageIndex, pageIndex, actualPageIndex === pageIndex));
      }

      // next_10     +10
      pageIndex = actualPageIndex + 10;
      if (pageIndex <= numOfPages && pageIndex > tempPageIndex) {
        tempPageIndex = pageIndex;
        buttons.push(this.buildButton(pageIndex, pageIndex, actualPageIndex === pageIndex));
      }

      // next_1_10   +1/10
      pageIndex = actualPageIndex + Math.floor(numOfPages / 10);
      if (pageIndex <= numOfPages && pageIndex > tempPageIndex) {
        tempPageIndex = pageIndex;
        buttons.push(this.buildButton(pageIndex, pageIndex, actualPageIndex === pageIndex));
      }

      // next_1_2    +1/2
      pageIndex = actualPageIndex + Math.floor(numOfPages / 2);
      if (pageIndex <= numOfPages && pageIndex > tempPageIndex) {
        buttons.push(this.buildButton(pageIndex, pageIndex, actualPageIndex === pageIndex));
      }

      // last
      buttons.push(this.buildButton(numOfPages, 'Letzte', false));
    };

    if (numOfPages <= 10) {
      buildButtonLessEqual10();
    } else {
      buildButtonGrater10();
    }

    return buttons;
  };

  setTotalRows(totalRows) {
    this.state.totalRows = totalRows;
    this.state.buttons = this.buildButtons(this.state.totalRows);
    this.setState(this.state);
  }

  render() {
    return <ButtonGroup>{this.state.buttons}</ButtonGroup>;
  }
}

Pager.propTypes = {
  onPageChanged: PropTypes.func, totalRows: PropTypes.number
}

export default Pager;

/**
 * class ValidatorState
 */
class ValidationResult {

  constructor(success, error) {
    this.success = false;
    this.error = '';
  }
}

export default ValidationResult;

import BaseComponent from "../../../../lib/components/BaseComponent";
import PropTypes from "prop-types";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import CampaignCardNew from "../../components/campaign/CampaignCardNew";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import shortid from "shortid";
import CM from "../../../model/system/CM";
import ReadCampaignInfo from "../../../model/service/campaign/dto/ReadCampaignInfo";
import ReadCampaignStatus from "../../../model/service/campaign/dto/ReadCampaignStatus";
import CampaignDashBoard from "../../components/campaignDashBoard/CampaignDashBoard";
import ButtonTab from "../../../../lib/components/tabbar/ButtonTab";
import React from "react";
import {toast} from "react-toastify";
import he from 'he';
import {Grid, List} from "react-feather";
import CampaignList from "../../components/campaign/CampaignList";
import CampaignCard from "../../components/campaign/CampaignCard";

class CnerOverview extends BaseComponent {

  static LAYOUT_GRID = 'GRID';
  static LAYOUT_LIST = 'LIST';

  constructor(props) {
    super(props);
    this.mainPanel = CM.get().getPanel();
    this.sideBar = this.mainPanel.layoutRef.current.sideBarRef.current;
    this.state = {
      filterText: null, activeStatusFilter: null, campaigns: [], timeOutHandler: undefined
    }
  }

  componentDidMount() {
    this.state.filterText = this.getElementCache('filterText') !== null ? this.getElementCache('filterText') : '';
    this.state.activeStatusFilter = this.getElementCache('activeStatusFilter') !== null ? this.getElementCache('activeStatusFilter') : 0;
    this.fetchData('', this.state.filterText, this.state.activeStatusFilter);
    this.sideBar.clearMenu();
    this.sideBar.setState(this.sideBar.state);
  }

  getElementCache(id) {
    return this.pop('CnerOverview', id);
  }


  setElementCache(id, value) {
    return this.push('CnerOverview', value, id);
  }

  getStorageId() {
    return 'CnerOverview_' + this.props.id;
  }

  fetchData(campaignId = '', search = '', statusFilter = 0, forceFetch = false) {
    let self = this;
    this.doDataCacheFetch(new ReadCampaignInfo(campaignId, search, statusFilter), 'campaign', 'fetchData', (response) => {
      if (response.success) {
        if (response.data.campaigns !== undefined && response.data.campaigns !== null && Array.isArray(response.data.campaigns)) {
          let campaigns = response.data.campaigns;
          self.fetchData_addCampaigns(campaigns);
        }
      } else {
        toast.error('Campaigns konnten nicht gelesen werden!');
      }
    }, forceFetch);
  }

  fetchData_addCampaigns(campaigns) {
    if (campaigns !== null) {
      this.state.campaigns = [];
      let self = this;
      campaigns.map((campaign) => {
        let status = self.convertStatusToIntValue(campaign.status.text);
        this.state.campaigns.push({
          id: campaign.campaignId,
          name: he.decode(campaign.title),
          description: he.decode(campaign.description),
          baseUrl: campaign.link,
          createdDate: campaign.createdDate,
          status: status,
          thumbnail: campaign.img
        });
      });
      this.setState(this.state);
    }
  }

  convertStatusToIntValue = (status) => {
    switch (status) {
      case 'IN BEARBEITUNG':
        return 1;
      case 'IN WARTUNG':
        return 2;
      case 'VERÖFFENTLICHT':
        return 3;
      case 'ABGESCHLOSSEN':
        return 4;
    }

  }

  onCampaignCardClick(e, campaignId) {
    let self = this;
    CM.get().doJsonAjax(CM.get().getServiceUrl('campaign'), new ReadCampaignStatus(campaignId), function(response) {
      if (response.success) {
        let data = {
          description: response.data.info['Campaign-Beschreibung'],
          campaignId: response.data.info['Campaign-ID'],
          name: response.data.info['Campaign-Name'],
          createdDateTime: response.data.info['Erstellt am'],
          createdBy: response.data.info['Erstellt von'],
          releasedBy: response.data.info['Freigegeben am'],
          campaignLink: response.data.info['Link'],
          campaignBaseUrl: response.data.info['campaignBaseUrl'],
          status: response.data.info['Status'],
          statistic: response.data.statistic
        }
        let campaignIdForTabBar = 'tbId_' + response.data.info['Campaign-ID'].value;
        let campaignDashBoard = <CampaignDashBoard data={data} id={campaignIdForTabBar}/>;

        CM.get().setCampaignUrl(data.campaignBaseUrl);
        self.mainPanel.tabContainerRef.current.addElement(<ButtonTab id={campaignIdForTabBar}>{data.campaignId.value +
          ': ' + he.decode(data.name.value)}</ButtonTab>, campaignDashBoard);
      } else {
        toast.error('Campaigns konnten nicht geladen werden!');
      }
    });
  }

  onResetFilters(e) {
    this.state.activeStatusFilter = 0;
    this.state.filterText = '';
    this.fetchData('', this.state.filterText, this.state.activeStatusFilter, true);
    this.setElementCache('filterText', '');
  }

  onFilterInputChange(e, id, value) {
    if (this.state.timeOutHandler !== undefined) {
      clearTimeout(this.state.timeOutHandler);
    }
    this.state.filterText = value;
    this.setElementCache('filterText', value);
    this.state.timeOutHandler = setTimeout(() => {
      this.fetchData('', this.state.filterText, this.state.activeStatusFilter, true);
    }, 400);
  }

  setOnStatusFilterChange(e, newFilter) {
    this.state.activeStatusFilter = newFilter;
    this.setElementCache('activeStatusFilter', newFilter);
    this.fetchData('', this.state.filterText, this.state.activeStatusFilter, true);
  }

  changeLayout(layout) {
    CM.get().setGlobalValue('Campaign_Layout_Type', layout);
    this.setState(this.state);
  }

  render() {

    let campaigns = this.state.campaigns.map((campaign, index) => {
      if (CM.get().getGlobalValue('Campaign_Layout_Type') === CnerOverview.LAYOUT_GRID) {
        if (this.state.activeStatusFilter === campaign.status) {
          return <CampaignCard key={shortid()} index={index} campaign={campaign} reloadCampaigns={(e) => this.fetchData(
            '', '', 0, true)}
                               onCardClick={(e, campaignId) => this.onCampaignCardClick(e, campaignId)}/>
        } else {
          return <CampaignCard key={shortid()} index={index} campaign={campaign} reloadCampaigns={(e) => this.fetchData(
            '', '', 0, true)}
                               onCardClick={(e, campaignId) => this.onCampaignCardClick(e, campaignId)}/>
        }
      } else {
        if (this.state.activeStatusFilter === campaign.status) {
          return <CampaignList key={shortid()} index={index} campaign={campaign} reloadCampaigns={(e) => this.fetchData(
            '', '', 0, true)}
                               onCardClick={(e, campaignId) => this.onCampaignCardClick(e, campaignId)}/>
        } else {
          return <CampaignList key={shortid()} index={index} campaign={campaign} reloadCampaigns={(e) => this.fetchData(
            '', '', 0, true)}
                               onCardClick={(e, campaignId) => this.onCampaignCardClick(e, campaignId)}/>
        }
      }
    });

    return <HorizontalLayout className='py-[8px] justify-center md:justify-start flex-wrap md:flex-nowrap'>
      <VerticalLayout widthFit className='static mb-6 md:fixed'>
        <CampaignCardNew statusFilterDefaultValue={this.state.activeStatusFilter} onResetFilters={(e) => this.onResetFilters(
          e)}
                         reloadCampaigns={(e) => this.fetchData('', '', 0, true)}
                         onStatusFilterChange={(e, newFilter) => this.setOnStatusFilterChange(e, newFilter)}
                         onFilterInputChange={(e, id, value) => this.onFilterInputChange(e, id, value)}
                         searchInputDefaultValue={this.state.filterText}/>
      </VerticalLayout>
      <VerticalLayout className='md:ml-[320px] pl-2'>
        <HorizontalLayout className='flex-wrap justify-evenly gap-5'>
          <HorizontalLayout className='justify-center'>
            <HorizontalLayout widthFit className='bg-cn-color-black-804 p-1 rounded gap-x-2'>
              <Grid className='text-cn-color-white hover:bg-cn-color-black transition-colors' onClick={(e) => this.changeLayout(
                CnerOverview.LAYOUT_GRID)}/>
              <List className='text-cn-color-white hover:bg-cn-color-black transition-colors' onClick={(e) => this.changeLayout(
                CnerOverview.LAYOUT_LIST)}/>
            </HorizontalLayout>
          </HorizontalLayout>
          {campaigns}
        </HorizontalLayout>
      </VerticalLayout>
    </HorizontalLayout>;
  }
}

CnerOverview.propTypes = {
  layout: PropTypes.object, parent: PropTypes.object, id: PropTypes.string
}
export default CnerOverview;

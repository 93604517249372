export default function CnerLogoSvg(props) {
    return (<svg width="21" height="30" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1306 0.000344264C14.0395 0.0585394 15.9597 0.557674 17.5843 1.44627C17.8596 1.59623 17.9093 1.96779 17.6881 2.18714L13.2656 6.57416C12.891 6.94571 12.3833 7.14044 11.8553 7.14044C10.6617 7.14268 9.47029 7.59257 8.56096 8.49459C7.59748 9.45034 7.1462 10.7217 7.20035 11.9751C7.21614 12.3489 7.07625 12.7137 6.80999 12.9779L2.41226 17.3403C2.08282 17.667 1.52549 17.5842 1.31339 17.1746C0.476259 15.5631 0.0385166 13.7971 0.000157709 12.0244C-0.0043551 11.7893 0.0881576 11.5633 0.255132 11.3976L11.4898 0.253269C11.659 0.0853987 11.8914 -0.00637056 12.1306 0.000344264Z"
              fill={props.fillColor}/>
        <path d="M0.94335 10.0611L10.1472 0.931171C10.4564 0.624528 10.1833 0.105248 9.75236 0.18135C7.38539 0.599907 5.2057 1.72128 3.46827 3.44475C1.69473 5.20403 0.602632 7.38411 0.187454 9.66491C0.10848 10.0947 0.631966 10.3677 0.94335 10.0611Z"
              fill={props.fillColor}/>
        <path d="M15.4407 15.3209C15.2918 15.1732 15.0549 15.1642 14.8969 15.2985C13.0625 16.8519 10.2939 16.769 8.56094 15.0501C8.4752 14.965 8.39171 14.8755 8.31499 14.7859C8.03068 14.4569 7.52525 14.4368 7.21612 14.7412L3.17715 18.7477C2.89059 19.0319 2.88608 19.4885 3.16136 19.7818C3.2629 19.8892 3.36444 19.9944 3.47049 20.0996C4.79274 21.4112 6.36997 22.3737 8.09161 22.9444C8.56996 23.1034 9.09796 22.9758 9.45673 22.6221L15.443 16.684C15.8198 16.308 15.8198 15.6969 15.4407 15.3209Z"
              fill={props.fillColor}/>
        <path d="M19.931 3.12892C19.8565 3.06178 19.7843 2.99463 19.7076 2.92972C19.5316 2.77528 19.2653 2.78647 19.1006 2.94986L14.563 7.45103C14.4005 7.61219 14.4163 7.88302 14.6036 8.01732C14.7074 8.09342 14.8112 8.17399 14.9105 8.25905C15.0594 8.38663 15.285 8.37768 15.4249 8.2389L19.94 3.76012C20.116 3.58553 20.1137 3.29903 19.931 3.12892Z"
              fill={props.fillColor}/>
        <path d="M17.4443 18.3249L12.8029 22.929C12.5818 23.1483 12.76 23.5199 13.0714 23.4886C15.5828 23.2379 17.925 22.2105 19.8113 20.5229C20.0618 20.299 20.0731 19.9096 19.8339 19.6723L18.4733 18.3226C18.1867 18.0451 17.7286 18.0451 17.4443 18.3249Z"
              fill={props.fillColor}/>
    </svg>)
}
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import InputDateTimePicker from "../inputFields/InputDateTimePicker";
import PropTypes from "prop-types";
import {createRef} from "react";
import WizardContentBase from "./WizardContentBase";
import GF from "../../../../lib/utils/GF";

class WizardContentDate extends WizardContentBase {

  constructor(props) {
    super(props);
    this.initState();
    this.inputDateTimePickerRef = createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.data.getDeliveryDateTime() === null) {
      this.onChange(null, this.inputDateTimePickerRef.current.getValue());
    }
  }

  onChange(e, value) {
    this.data.setDeliveryDateTime(value);
    let valueString = GF.dateTimeToString(value);
    let dateTimeParts = valueString.split(' ');
    this.state.date = dateTimeParts[0];
    this.state.time = dateTimeParts[1];
    this.setState(this.state);
  }

  initState() {
    this.state.dateTime = this.data.getDeliveryDateTime();
    if (this.state.dateTime !== null) {
      let dateTimeString = GF.dateTimeToString(this.state.dateTime);
      let dateTimeStringParts = dateTimeString.split(' ');
      this.state.date = dateTimeStringParts[0];
      this.state.time = dateTimeStringParts[1];
    } else {
      this.state.dateTime = new Date();
      this.state.date = '';
      this.state.time = '';
    }
  }

  getContent() {
    return <div className='flex w-full flex-col lg:flex-row'>
      <VerticalLayout className='lg:w-1/3 lg:mr-10 mb-5 w-full'>
        <InputDateTimePicker ref={this.inputDateTimePickerRef} labelElement='Versanddatum' defaultValue={this.state.dateTime} datetime={this.state.dateTime} onChange={(e, id, value) => this.onChange(e, value)}/>
      </VerticalLayout>
      <VerticalLayout widthFit className='lg:px-5 lg:w-1/3 px-0 w-1/2'>
        <h3>Versanddatum</h3>
        <HorizontalLayout widthFit className='justify-between '>
          <div className='cn-font-paragraph text-cn-color-primary leading-8'>
            <p>Datum</p>
            <p>Zeit</p>
          </div>
          <div className='cn-font-paragraph leading-8'>
            <p>{this.state.date}</p>
            <p>{this.state.time}</p>
          </div>
        </HorizontalLayout>
      </VerticalLayout>
    </div>;
  }
}

WizardContentDate.propTypes = {
  wizardNavRef: PropTypes.any
}
export default WizardContentDate;

import Dto from "../../Dto";

/**
 * class Delete
 */
class Delete extends Dto {

  constructor(resourceId = '', resourceCollectionId, canDeleteDirectory) {
    super('po2\\service\\resource\\dto\\Delete');
    this.resourceId = resourceId;
    if (resourceCollectionId !== undefined) {
      this.resourceCollectionId = resourceCollectionId;
    }
    if (canDeleteDirectory !== undefined) {
      this.canDeleteDirectory = canDeleteDirectory;
    }
  }
}

export default Delete;

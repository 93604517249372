import BaseComponent from "../../../../../lib/components/BaseComponent";
import CM from "../../../../../appl/model/system/CM";
import AlertDialog from "../../../../../lib/components/dialogs/AlertDialog";

class AlertTest extends BaseComponent {

  showAlertDialog() {
    CM.get().setMainDialog(<AlertDialog errorTitle='AlertTest' errorMessage='Sie müssen unbedingt einen Apfel essen!'/>);
  }

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>
      <h1>Alert test</h1>
      <button onClick={(event) => this.showAlertDialog(event)}>
        zeige alarm
      </button>

    </div>
  }
}

export default AlertTest;

import BaseComponent from "../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import MenuItem from "../../../../lib/components/menu/MenuItem";
import {AlertCircle, CheckCircle} from "react-feather";
import InputPassword from "../inputFields/InputPassword";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import ButtonSmall from "../buttons/ButtonSmall";
import DialogBase from "../../../../lib/components/dialogs/DialogBase";
import PropTypes from "prop-types";
import CM from "../../../model/system/CM";
import PasswordReset from "../../../../lib/model/service/passwordReset/PasswordReset";
import ResetPassword from "../../../../lib/model/service/passwordReset/dto/ResetPassword";
import {toast} from "react-toastify";

class ResetPasswordDialog extends BaseComponent {

  constructor(props) {
    super(props);
    this.password = '';
    this.passwordConfirm = '';
    this.state = {
      validationPoints: null, canReset: false
    }
  }


  createValidationPoints() {
    let counter = 6;
    let checkIcon = <CheckCircle className='stroke-cn-color-primary'/>
    let alertIcon = <AlertCircle className='stroke-cn-color-static-red'/>
    let validationPoints = [];
    if (this.password.length > 8) {
      counter++;
      validationPoints.push(<MenuItem className='text-cn-color-white'>{checkIcon} Mindestens 8 Zeichen lang</MenuItem>);
    } else {
      validationPoints.push(<MenuItem className='text-cn-color-static-red'>{alertIcon} Mindestens 8 Zeichen lang</MenuItem>);
    }
    if (this.password.match(/[A-Z]/)) {
      counter++;
      validationPoints.push(<MenuItem className='text-cn-color-white'>{checkIcon} Mindestens 1 Grossbuchstabe</MenuItem>)
    } else {
      validationPoints.push(<MenuItem className='text-cn-color-static-red'>{alertIcon} Mindestens 1 Grossbuchstabe</MenuItem>);
    }
    if (this.password.match(/[a-z]/)) {
      counter++;
      validationPoints.push(<MenuItem className='text-cn-color-white'>{checkIcon} Mindestens 1 Kleinbuchstabe</MenuItem>);
    } else {
      validationPoints.push(<MenuItem className='text-cn-color-static-red'>{alertIcon} Mindestens 1 Kleinbuchstabe</MenuItem>);
    }
    if (this.password.match(/[0-9]+/)) {
      counter++;
      validationPoints.push(<MenuItem className='text-cn-color-white'>{checkIcon} Mindestens 1 Zahl</MenuItem>);
    } else {
      validationPoints.push(<MenuItem className='text-cn-color-static-red'>{alertIcon} Mindestens 1 Zahl</MenuItem>);
    }
    if (this.password.match(/[\W_]+/)) {
      counter++;
      validationPoints.push(<MenuItem className='text-cn-color-white'>{checkIcon} Mindestens 1 Sonderzeichen</MenuItem>);
    } else {
      validationPoints.push(<MenuItem className='text-cn-color-static-red'>{alertIcon} Mindestens 1 Sonderzeichen</MenuItem>);
    }
    if (this.password.length > 0 && this.password === this.passwordConfirm) {
      counter++;
      validationPoints.push(<MenuItem className='text-cn-color-white'>{checkIcon} Beide Passwörter müssen übereinstimmen</MenuItem>);
    } else {
      validationPoints.push(<MenuItem className='text-cn-color-static-red'>{alertIcon} Beide Passwörter müssen übereinstimmen</MenuItem>)
    }

    this.state.canReset = counter !== 6;
    console.log(this.state.canReset)

    return validationPoints;
  }

  onResetPasswordClick(e) {
    let dto = new ResetPassword(this.props.hash, this.password);
    CM.get().doJsonAjax(CM.get().getServiceUrl(PasswordReset.NAME), dto, (response) => {
      if (response.success) {
        toast.success(response.message);
        CM.get().setRoute('/');
      } else {
        if (response.data === 'redirect') {
          CM.get().setRoute('/');
        } else {
          toast.error(response.message);
        }
      }
    });
  }

  render() {
    this.state.validationPoints = this.createValidationPoints();
    let button = <ButtonSmall state='disabled' widthFull className='w-full'>Zurücksetzen</ButtonSmall>;
    if (this.state.canReset) {
      button = <ButtonSmall onClick={(e) => this.onResetPasswordClick(e)}
                            color='secondary' widthFull className='w-full'>Zurücksetzen</ButtonSmall>;
    }
    return (<DialogBase title='Passwort zurücksetzen' onCloseButtonClick={() => {
      if (this.props.onCloseButtonClick) {
        this.props.onCloseButtonClick();
      }
    }}><VerticalLayout className='text-cn-color-white justify-center w-full mx-auto gap-y-4 p-4'>
      <p className='cn-font-paragraph'>Neues Password eingeben</p>
      <InputPassword onChange={(e, id, value) => {
        this.password = value;
        this.setState(this.state);
      }} labelElement='Passwort'/>
      <InputPassword onChange={(e, id, value) => {
        this.passwordConfirm = value;
        this.setState(this.state);
      }} labelElement='Passwort bestätigen'/>
      <VerticalLayout>
        {this.state.validationPoints}
      </VerticalLayout>
      <HorizontalLayout className='justify-center gap-x-6'>
        <ButtonSmall color='dark'>Abbrechen</ButtonSmall>
        {button}
      </HorizontalLayout>
    </VerticalLayout>
    </DialogBase>);
  }

}

ResetPasswordDialog.propTypes = {
  onCloseButtonClick: PropTypes.func
}

export default ResetPasswordDialog
import ServiceResource from "../service/resource/Resource";
import GF from "../../utils/GF";

/**
 * class ResourceEntity
 */
class ResourceEntity {

  resourceId = '';
  resourceName = '';
  oldResourceId = '';
  oldResourceName = '';
  files = null;

  constructor(resourceString = '', oldResourceString = '', files = null) {
    this.initResource(resourceString);
    this.initOldResource(oldResourceString);
    this.files = files;
  }

  initResource(resourceString) {
    let {resourceId, resourceName} = ServiceResource.extractIdAndNameFromDefaultValue(resourceString);
    this.resourceId = resourceId;
    this.resourceName = resourceName;
  }

  initOldResource(oldResourceString) {
    let {resourceId, resourceName} = ServiceResource.extractIdAndNameFromDefaultValue(oldResourceString);
    this.oldResourceId = resourceId;
    this.oldResourceName = resourceName;
  }

  getResourceIdAndName() {
    let resourceIdAndName = '';
    if (GF.checkNotNull(this.resourceId) && this.resourceId !== '') {
      resourceIdAndName = this.resourceId + ':' + this.resourceName;
    }
    return resourceIdAndName;
  }

  getResourceId() {
    return this.resourceId;
  }

  getResourceName() {
    return this.resourceName;
  }

  getOldResourceIdAndName() {
    return this.oldResourceId + ':' + this.oldResourceName;
  }

  getOldResourceId() {
    return this.oldResourceId;
  }

  getOldResourceName() {
    return this.oldResourceName;
  }

  getFiles() {
    return this.files;
  }

  setResourceId(resourceId) {
    this.resourceId = resourceId;
  }

  setResourceName(resourceName) {
    this.resourceName = resourceName;
  }

  setOldResourceId(oldResourceId) {
    this.oldResourceId = oldResourceId;
  }

  setOldResourceName(oldResourceName) {
    this.oldResourceName = oldResourceName;
  }

  setFiles(files) {
    this.files = files;
  }
}

export default ResourceEntity;

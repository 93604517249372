import BaseComponent from "../../../../lib/components/BaseComponent";
import PropTypes from "prop-types";
import ButtonGroupStd from "../../../../lib/components/buttons/ButtonGroupStd";
import shortid from "shortid";
import React from "react";

class ButtonGroup extends BaseComponent {

  render() {
    let groupBackgroundColor = '';
    switch (this.props.color) {
      case 'primary':
        groupBackgroundColor = ' bg-cn-color-primary ';
        break;
      case 'secondary':
        groupBackgroundColor = ' bg-cn-color-secondary ';
        break;
      case 'dark':
        groupBackgroundColor = ' bg-cn-color-black-804 ';
        break;
      case 'light':
      default:
        groupBackgroundColor = ' bg-cn-color-black-804 ';
    }
    let buttonDelimiterColor = '';
    switch (this.props.delimiterColor) {
      case 'primary':
        buttonDelimiterColor = 'bg-cn-color-blue-690';
        break;
      default:
        buttonDelimiterColor = 'bg-cn-color-white-960';
        break;
    }

    let elements = [];
    let className = (this.props.className !== undefined ? this.props.className : '');
    React.Children.map(this.props.children, (child, index) => {
      let color = child.props.color ? child.props.color : this.props.color;
      elements.push(React.cloneElement(child, {isInGroup: true, color: color, key: shortid()}));
      if (index <= this.props.children.length - 2) {
        elements.push(<div key={shortid()} className={'w-[1px] py-2 ' + groupBackgroundColor}>
          <div className={' w-full h-full  ' + buttonDelimiterColor}/>
        </div>);
      }
    });

    return <ButtonGroupStd className={'flex-wrap text-cn-color-white ' + className}>
      {elements}
    </ButtonGroupStd>
  }
}

ButtonGroup.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'light', 'dark']),
  delimiterColor: PropTypes.oneOf(['white', 'primary'])
}

export default ButtonGroup;

import PropTypes from "prop-types";
import BaseComponent from "../../../../../lib/components/BaseComponent";
import './Container.css';
import StateType from "../../../../../lib/components/types/StateType";

class Container extends BaseComponent {

  constructor(props) {
    super(props);

    this.state = {
      state: (props.state !== undefined && props.state !== null ? props.state : StateType.none),
      className: (props.className !== undefined && props.className !== null ? props.className : ''),
      children: (props.children !== undefined && props.children !== null ? props.children : [])
    };
  }

  setContainerChildren(children) {
    this.state.children = children;
    this.setState(this.state);
  }

  setContainerState(state) {
    this.state.state = state;
    this.setState(this.state);
  }

  render() {
    let contentStyle;
    if (this.state.state === StateType.error) {
      contentStyle = 'text-cn-color-static-red border-cn-color-static-red cn-input-base-hover-active-error';
    } else if (this.state.state === StateType.disable) {
      contentStyle = 'border-cn-color-gray-655 bg-cn-color-black-804';
    } else if (this.state.state === StateType.readonly) {
      contentStyle = 'border-cn-color-gray-655 bg-cn-color-black-804';
    } else if (this.props.state === StateType.readonly) {
      contentStyle = 'border-cn-color-gray-655 bg-cn-color-black-804';
    } else {
      contentStyle = 'text-cn-color-white border-cn-color-input-border cn-input-base-hover-active';
    }
    return <div className={'border cn-border-radius-8 ' + contentStyle + ' ' + this.state.className}>{this.props.children}</div>
  };
}

Container.propTypes = {
  state: PropTypes.oneOf([StateType.none, StateType.enable, StateType.disable, StateType.error, StateType.readonly]),
  className: PropTypes.string
}
export default Container;

import React, {createRef} from "react";
import PropTypes from "prop-types";
import BaseComponent from "../../../../../lib/components/BaseComponent";
import ReadAuthorization from "../../../../../lib/model/service/system/dto/ReadAuthorization";
import SystemService from "../../../../../lib/model/service/system/SystemService";
import CM from "../../../../model/system/CM";
import Window from "../../../../../lib/components/window/Window";
import Permissions from "../../permissions/Permissions";
import SelectableItem from "../../../../../lib/components/selectableDragList/SelectableItem";
import WriteAuthorization from "../../../../../lib/model/service/system/dto/WriteAuthorization";
import {toast} from "react-toastify";
import shortid from "shortid";

class Authorization extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      permissions: null,
    };
    this.permissionRefs = [];
    this.userContext = null;
  }

  componentDidMount() {
    super.componentDidMount();
    this.init();
  }

  buildReadDto() {
    let dto;
    switch (this.props.mode) {
      case 'user':
        dto = new ReadAuthorization(parseInt(this.userContext.accounts[0]), undefined, this.props.recid);
        break;
      case 'userGroup':
        dto = new ReadAuthorization(parseInt(this.userContext.accounts[0]), this.props.recid, undefined);
        break;
      default:
        throw new DOMException('mode ist nicht angegeben!');
    }
    return dto;
  }

  buildWriteDto() {
    let dto;
    switch (this.props.mode) {
      case 'user':
        dto = new WriteAuthorization(parseInt(this.userContext.accounts[0]), null, this.props.recid);
        break;
      case 'userGroup':
        dto = new WriteAuthorization(parseInt(this.userContext.accounts[0]), this.props.recid, null);
        break;
      default:
        throw new DOMException('mode ist nicht angegeben!');
    }
    return dto;
  }

  init() {
    let self = this;
    this.userContext = CM.get().getUserContext();
    let readAuthorization = this.buildReadDto();
    CM.get().doJsonAjax(CM.get().getServiceUrl(SystemService.NAME), readAuthorization, (response) => {
      if (response.success) {
        let items = [];
        for (let index in response.data.privileges) {
          let privilege = response.data.privileges[index];

          // filtere entsprechende Einträge gemäss dem mode.
          switch (this.props.mode) {
            case 'user':
              if (!privilege.name.startsWith('User\\')) {
                privilege = null;
              }
              break;
            case 'userGroup':
              if (privilege.name.startsWith('User\\')) {
                privilege = null;
              }
              break;
            default:
              throw new DOMException('mode ist nicht angegeben!');
          }
          if (privilege !== null) {
            let permissionRef = createRef();
            this.permissionRefs.push(permissionRef);
            items.push(
              <SelectableItem ref={permissionRef} key={shortid()} elementId={privilege.name} active={privilege.connected}>{privilege.description}</SelectableItem>);
          }
        }
        self.state.permissions = <Permissions withButtonGroup onSave={(privileges) => this.onSave(
          privileges)} mode={this.props.mode}>{items}</Permissions>;
        self.setState(self.state);
      } else {
        toast.error(response.message);
      }
    });
  }

  onSave(privileges) {
    let dtoWriteAuthorization = this.buildWriteDto();
    dtoWriteAuthorization.setPrivileges(privileges);

    CM.get().doJsonAjax(CM.get().getServiceUrl(SystemService.NAME), dtoWriteAuthorization, (response) => {
      if (response.success) {
        toast.success('Berechtigungen erfolgreich gespeichert!');
      }
    });
  }

  render() {

    return <Window>
      {this.state.permissions}
    </Window>;
  }
}

Authorization.propTypes = {
  recid: PropTypes.number, mode: PropTypes.oneOf(['user', 'userGroup'])
}
export default Authorization;

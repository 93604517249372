import PropTypes from "prop-types";
import InputBase from "./InputBase";
import Tag from "../../../../lib/components/tag/Tag";
import {createRef} from "react";

class InputTag extends InputBase {

  constructor(props) {
    super(props);
    this.tag = createRef();
    this.tagElementRef = createRef();
  }

  getValue() {
    return this.tagElementRef.current.getValue();
  }

  onChange(value) {
    this.fireOnChange(null, value);
  }

  render() {
    this.state.children = <Tag key={this.tag} ref={this.tagElementRef} options={this.props.options} onChange={(value) => this.onChange(value)}/>;

    return super.render();
  }
}

InputTag.propTypes = {
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  inputLayout: PropTypes.oneOf(['inline', 'outline', '']),
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object)
}
export default InputTag;

import BaseComponent from "../../../../../lib/components/BaseComponent";
import BreadCrumb from "../../../../../lib/components/breadcrumbs/BreadCrumb";
import BreadCrumbBox from "../../../../../lib/components/breadcrumbs/BreadCrumbBox";

class BreadCrumbTest extends BaseComponent {

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>

      <BreadCrumbBox>
        <BreadCrumb>Liste auswählen</BreadCrumb>
        <BreadCrumb>Konfiguration</BreadCrumb>
        <BreadCrumb>Versandtermin</BreadCrumb>
        <BreadCrumb>Bemerkungen</BreadCrumb>
        <BreadCrumb active>Übersicht</BreadCrumb>
      </BreadCrumbBox>

    </div>
  }
}

export default BreadCrumbTest;

import './TabBar.css';
import VerticalLayout from "../layouts/VerticalLayout";
import PropTypes from "prop-types";
import React from "react";
import TabContainerBase from "./TabContainerBase";

class TabContainerHorizontal extends TabContainerBase {

  render() {
    let tabBar = React.cloneElement(this.props.tabBar, {
      ref: this.tabBarRef, parent: this
    });
    let tabContent = React.cloneElement(this.props.tabContent, {
      ref: this.tabContentRef, parent: this
    });
    return <VerticalLayout className={this.props.className}>
      {tabBar}
      {tabContent}
    </VerticalLayout>
  }
}

TabContainerHorizontal.propTypes = {
  className: PropTypes.string, tabBar: PropTypes.node, tabContent: PropTypes.node
}

export default TabContainerHorizontal;

import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import InputLayoutType from "../inputFields/InputLayoutType";
import InputTextMultiline from "../inputFields/InputTextMultiline";
import InputText from "../inputFields/InputText";
import PropTypes from "prop-types";
import ButtonSmall from "../buttons/ButtonSmall";
import WizardContentBase from "./WizardContentBase";
import GF from "../../../../lib/utils/GF";
import SendListByMail from "../../../../lib/model/campaign/modules/ustore/v002/service/dto/SendListByMail";
import CampaignService from "../../../model/service/campaign/CampaignService";
import UstoreModuleService from "../../../../lib/model/campaign/modules/ustore/v002/service/UstoreModuleService";
import {toast} from "react-toastify";
import Loading from "../../../../lib/components/images/Loading";
import React from "react";

class WizardContentOverview extends WizardContentBase {

  static sendMailing(parent, wizardData, campaignId, closeDialog, testMailReceiver = '', testMailNumOfSendings = 0) {
    let listName = wizardData.getMailingListName();
    let deliveryDateTime = wizardData.getDeliveryDateTime();
    let scheduledStartDateTime = GF.dateTimeToString(new Date());
    if (deliveryDateTime !== null) {
      scheduledStartDateTime = GF.dateTimeToString(wizardData.getDeliveryDateTime());
    }
    let config = wizardData.getConfiguration();
    let template = config.getTemplate().displayValue;
    let sendMailText = config.getSendFromText();
    let sendMailAddress = config.getSendFromAddress().displayValue;
    let notiz = wizardData.getNotiz();

    let sendListByMail = new SendListByMail(listName, template, scheduledStartDateTime, sendMailAddress, sendMailText,
      notiz, testMailReceiver, testMailNumOfSendings);

    sendListByMail.setProperties(CampaignService.createCampaignRedirectProperty(campaignId));

    if (!parent.requestActive) {
      parent.requestActive = true;

      UstoreModuleService.doRequest(sendListByMail, (response) => {
        toast.success('Die Versandaufgabe war erfolgreich.');
        if (GF.checkNotNull(closeDialog)) {
          closeDialog();
        }
      }, 'Die Versandaufgabe hat mit Fehler geendet!');
    }
  }

  constructor(props) {
    super(props);
    this.requestActive = false;
    this.state.nextButtonText = 'Versand aufgeben';
  }

  componentDidMount() {
    super.componentDidMount();
    this.props.wizardNavRef.current.state.canOvertakeSteps = true;
  }

  private_sendMailing(e) {
    WizardContentOverview.sendMailing(this, this.data, this.props.campaignId, this.props.mailingDialogRef.current.closeDialog);
    this.state.nextButtonText = <Loading/>;
    this.setState(this.state);
  }

  getContent() {
    this.state.nextButton =
      <ButtonSmall className='ml-2 bg-cn-color-static-red' onClick={(e) => this.private_sendMailing(e)}>{this.state.nextButtonText}</ButtonSmall>;

    let listName = this.data.getMailingListName();
    let dateTime = GF.dateTimeToString(this.data.getDeliveryDateTime());
    let config = this.data.getConfiguration();
    let template = config.getTemplate().displayValue;
    let sendMailText = config.getSendFromText();
    let sendMailAddress = config.getSendFromAddress().displayValue;
    let notiz = this.data.getNotiz();
    return <HorizontalLayout className='flex-col lg:flex-row gap-5 lg:gap-10'>
      <VerticalLayout className='gap-4'>
        <InputText className='min-w-[200px]' inputLayout='inline' state='readonly' labelElement='Liste' defaultValue={listName}/>
        <InputText className='min-w-[200px]' inputLayout='inline' state='readonly' labelElement='Datum' defaultValue={dateTime}/>
      </VerticalLayout>
      <VerticalLayout className='gap-4'>
        <InputText className='min-w-[200px]' inputLayout='inline' state='readonly' labelElement='Template' defaultValue={template}/>
        <InputText className='min-w-[200px]' inputLayout='inline' state='readonly' labelElement='Absender Name' defaultValue={sendMailText}/>
        <InputText className='min-w-[200px]' inputLayout='inline' state='readonly' labelElement='Absender Mailadresse' defaultValue={sendMailAddress}/>
      </VerticalLayout>
      <InputTextMultiline labelElement='Bemerkungen' state='readonly' inputLayout={InputLayoutType.inline} defaultValue={notiz}/>
    </HorizontalLayout>;
  }
}

WizardContentOverview.propTypes = {
  wizardNavRef: PropTypes.any,
  campaignId: PropTypes.any,
  mailingDialogRef: PropTypes.any
}
export default WizardContentOverview;

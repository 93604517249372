import BaseComponent from "../../../../lib/components/BaseComponent";
import CnerOverview from "./CnerOverview";
import Layout from "../../components/layout/Layout";
import React, {createRef} from "react";
import Header from "../../../../lib/components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import CM from "../../../model/system/CM";
import ButtonTab from "../../../../lib/components/tabbar/ButtonTab";
import TabContainerHorizontal from "../../../../lib/components/tabbar/TabContainerHorizontal";
import TabBarHorizontal from "../../../../lib/components/tabbar/TabBarHorizontal";
import TabContent from "../../../../lib/components/tabbar/TabContent";
import GetUserContext from "../../../../lib/model/service/system/dto/GetUserContext";

class CnerMain extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      accounts: [], selectedAccount: null, avatarLink: undefined
    }
    this.layoutRef = createRef();
    this.tabSideBarRef = createRef();
    this.tabContainerRef = createRef();
    CM.get().setPanel(this);
  }

  componentDidMount() {
    this.tabContainerRef.current.addElement(<ButtonTab isActive hideCloseButton>Übersicht</ButtonTab>, <CnerOverview/>);
    this.fetchData();
  }

  fetchData() {
    let self = this;
    CM.get().doJsonAjax(CM.get().getServiceUrl('SystemService'), new GetUserContext(), function(responseUserContext) {
      if (responseUserContext.success) {
        CM.get().setUserContext(responseUserContext.data);
        self.state.selectedAccount = responseUserContext.data.accounts[0];
        self.state.accounts = responseUserContext.data.allowedAccounts;
        self.state.avatarLink = responseUserContext.data.avatar;
        self.setState(self.state);
      }
    });
  }

  render() {
    return <Layout ref={this.layoutRef} topNav={
      <Header accounts={this.state.accounts} selectedAccount={this.state.selectedAccount} version={'2.1'}/>}
                   sideBar={<SideBar ref={this.tabSideBarRef} avatar={this.state.avatarLink}/>}>
      <TabContainerHorizontal className='p-2 h-full' ref={this.tabContainerRef} tabBar={<TabBarHorizontal/>} tabContent={
        <TabContent className='overflow-y-scroll h-full pb-[12px] pr-[12px]'/>}/>
    </Layout>;
  }
}

export default CnerMain;

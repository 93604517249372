import PropTypes from "prop-types";
import BaseComponent from "./BaseComponent";
import VerticalLayout from "./layouts/VerticalLayout";
import HorizontalLayout from "./layouts/HorizontalLayout";
import T from "../../appl/model/system/text_translations/Translator";
import {createRef} from "react";
import UploadManager from "../../appl/model/system/UploadManager";
import ProgressBar from "./progressBar/ProgressBar";
import Put from "../model/service/resource/dto/Put";
import GF from "../utils/GF";

class Upload extends BaseComponent {

  constructor(props) {
    super(props);
    this.inputField = createRef();
    this.progressBarContainerRef = createRef();
    this.labelContainer = createRef();
    this.state = {
      importId: GF.getValue(this.props.importId, '')
    }
  }

  async inputOnChange(e) {
    let self = this;
    let uploadManager = new UploadManager();
    let dtoPut = new Put(this.state.importId);
    if (GF.checkNotNull(this.props.dtoProperties)) {
      dtoPut.setProperties(this.props.dtoProperties);
    }

    uploadManager.upload(dtoPut, this.inputField.current.files, (response) => {
      self.onUploadDone(response);
      if (self.progressBarContainerRef.current !== null) {
        self.progressBarContainerRef.current.setProgressValue(100);
      }
    }, (progress) => {
      if (self.progressBarContainerRef.current !== null) {
        self.progressBarContainerRef.current.setProgressValue(progress);
      }
    });
  }

  setImportId(importId) {
    this.state.importId = importId;
  }

  onMouseOver() {
    // this.labelContainer.current.classList.toggle('bg-black');
  }

  onUploadDone(response) {
    if (GF.checkNotNull(this.props.onUploadDone)) {
      this.props.onUploadDone(response);
    }
  }

  render() {
    let hintBox = this.props.hideHint ? <></> : <div className='mb-[1px] self-end text-cn-color-gray-500'>{T.component_upload_hint()}</div>;
    let uploadArea =
      <div ref={this.labelContainer} className='flex justify-center items-center cn-border-radius-10 text-cn-color-static-white max-h-[200px] min-h-[50px] overflow-hidden bg-cn-color-gray-500'>upload-Bereich</div>;
    if (this.props.uploadArea) {
      uploadArea = this.props.uploadArea;
    }
    let progressBar = this.props.hideProgress ? <></> : <ProgressBar ref={this.progressBarContainerRef} className='mt-3' text='Upload-Fortschritt'/>
    return <VerticalLayout className={this.props.className}>
      <HorizontalLayout className='flex-wrap justify-between'>
        <h3 className='pb-0'>{this.props.title}</h3>
        {hintBox}
      </HorizontalLayout>
      <div className='cn-border-radius-10 border-[2px] border-dashed border-cn-color-blue-690'>
        <form className='relative'>
          <label htmlFor={this._id.toString()}>{uploadArea}</label>
          <input ref={this.inputField} id={this._id.toString()} accept={this.props.accept} className='absolute top-0 opacity-0 h-full w-full z-10 cursor-pointer' type="file" name="files[]" onMouseOver={(e) => this.onMouseOver(
            e)} onChange={(e) => this.inputOnChange(e)} multiple/>
        </form>
      </div>
      {progressBar}
    </VerticalLayout>;
  };
}

Upload.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onUploadDone: PropTypes.func,
  dtoProperties: PropTypes.object,
  uploadArea: PropTypes.node,
  hideHint: PropTypes.bool,
  accept: PropTypes.string,
  hideProgress: PropTypes.bool,
  uploadDialogCancelled: PropTypes.func,
  importId: PropTypes.string
}

export default Upload;

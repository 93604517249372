import BaseComponent from "../BaseComponent";
import VerticalLayout from "../layouts/VerticalLayout";
import HorizontalLayout from "../layouts/HorizontalLayout";
import PropTypes from "prop-types";
import GF from "../../utils/GF";

class Window extends BaseComponent {

  render() {
    let padding = (!this.props.noPadding ? 'p-4 ' : '');
    let title = null;
    if (this.props.title) {
      title = <HorizontalLayout className='py-2 px-3 justify-between items-center'>
        <h2 className='font-cn-roboto-slab-medium text-[28px]'>{this.props.title}</h2>
        {/*{this.props.showCloseButton &&*/}
        {/*  <X onClick={this.props.onCloseButtonClick} className='text-cn-color-white hover:bg-cn-color-static-red transition-[background] rounded-full p-0.5'/>}*/}
      </HorizontalLayout>
    }
    return <VerticalLayout id={GF.getValue(this.props.id)} className={'bg-cn-color-black cn-border-radius-20 cn-shadow-window ' +
      GF.getValue(this.props.className, '')}>
      {title}
      <div className={padding + GF.getValue(this.props.classNameContent, '')}>
        {this.props.children}
      </div>
    </VerticalLayout>;
  }
}

Window.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  showCloseButton: PropTypes.bool,
  className: PropTypes.string,
  classNameContent: PropTypes.string,
  onCloseButtonClick: PropTypes.func,
  noPadding: PropTypes.bool
}

export default Window;

import PropTypes from "prop-types";
import BaseComponent from "../../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import Window from "../../../../../lib/components/window/Window";
import Entity from "../Entity";
import TableButtonGroup from "../tableButtonGroup/TableButtonGroup";
import React, {createRef} from "react";
import TabBarHorizontal from "../../../../../lib/components/tabbar/TabBarHorizontal";
import TabContent from "../../../../../lib/components/tabbar/TabContent";
import TabContainerHorizontal from "../../../../../lib/components/tabbar/TabContainerHorizontal";
import DataSource from "../../../../../lib/model/service/dataSource/DataSource";
import CM from "../../../../model/system/CM";
import DetailFieldsViewForm from "./DetailFieldsViewForm";
import EntityDescribe from "../../../../../lib/model/service/entity/dto/EntityDescribe";
import ButtonTab from "../../../../../lib/components/tabbar/ButtonTab";
import DataSourceLink from "../../../../../lib/model/service/dataSource/DataSourceLink";
import GF from "../../../../../lib/utils/GF";
import InputDropDown from "../../inputFields/dropdown/InputDropDown";
import shortid from "shortid";
import Menu from "../../menu/Menu";
import MenuItemGroup from "../../../../../lib/components/menu/MenuItemGroup";
import MenuItem from "../../../../../lib/components/menu/MenuItem";
import {Link} from "react-feather";
import RelationForm from "../relation/RelationForm";

class EntityForm extends BaseComponent {

  static getForeignRelation(dataSource, mainRelation) {
    let relation = null;
    for (let i = 0; i < dataSource.entityDef.relations.length; i++) {
      let foreignRelation = dataSource.entityDef.relations[i];

      if (foreignRelation.name[0] === mainRelation.name[1] && foreignRelation.name[1] === mainRelation.name[0]) {
        relation = foreignRelation;
        break;
      }
    }
    return relation;
  }

  constructor(props) {
    super(props);
    this.detailContainerRef = createRef();
    this.relationTabContainerRef = createRef();
    this.entityRef = createRef();
    this.relatedEntitiesRef = {};
    this.relationsInfo = [];
    this.dataSourceMaster = this.initDataSource(this.props.entityDef, this.props.campaignId, this.props.dtoProperties,
      this.props.tabId);
    this.state = {
      omsDialog: null, mailingWizard: null
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.initEntityRelations();
  }

  addEntityToTab(entityDef, relationDataSource, foreignRelation, tableName) {
    let tableLabel = entityDef.baseDef.description;
    if (GF.checkNotNull(this.props.campaignId)) {
      tableLabel = this.props.campaignId + ':' + tableLabel;
    }
    let tabId = this.props.campaignId + ':' + tableName;
    this.relatedEntitiesRef[tabId] = createRef();

    new DataSourceLink(this.dataSourceMaster, relationDataSource, foreignRelation);

    if (this.relationTabContainerRef.current != null) {
      this.relationTabContainerRef.current.addElement(<ButtonTab id={tabId} hideCloseButton>{tableLabel}</ButtonTab>,
        <Window className='text-cn-color-white' id={tabId} title={entityDef.baseDef.description}><Entity dataSource={relationDataSource} ref={this.relatedEntitiesRef[tabId]} headerButtons={
          <TableButtonGroup ref={createRef()} editable/>} tabContainer={this.relationTabContainerRef.current} parent={this} campaignId={this.props.campaignId} autoload={false}/></Window>);
    }
  }

  doClearCache() {
    this.dataSourceMaster.doClearCache();

    for (let foreignTableName in this.relationsInfo) {
      let relationInfo = this.relationsInfo[foreignTableName];
      relationInfo.relationDataSource.doClearCache();
    }
  }

  initDataSource(entityDef, campaignId, dtoProperties, persistanceKeyPrefix) {
    return DataSource.generateDatasource(entityDef, campaignId,
      (datasource, dto, dataSourceDtoProcessEvents) => DataSource.datasourceDataHandler(datasource, dto,
        dataSourceDtoProcessEvents), dtoProperties, persistanceKeyPrefix);
  }

  initEntityRelations() {
    this.relationsInfo = [];
    let numOfRelations = Object.keys(this.props.entityDef.relations).length;
    let i = 0;
    for (let fieldKey in this.props.entityDef.relations) {
      if (this.props.entityDef.relations.hasOwnProperty(fieldKey)) {
        let relation = this.props.entityDef.relations[fieldKey];

        let dtoDescribeTable = new EntityDescribe([relation.foreignRelation]);
        dtoDescribeTable.setProperties(this.dataSourceMaster.getDtoProperties());

        CM.get().doJsonAjaxCache(CM.get().getServiceUrl('DoctrineEntityService'), dtoDescribeTable, (response) => {
          i++;
          if (response.success) {
            let foreignEntityDef = response.data.serviceDescription.entityDefs[0];
            let relationDataSource = this.initDataSource(foreignEntityDef, this.props.campaignId,
              this.props.dtoProperties, this.props.tabId);

            let foreignRelation = EntityForm.getForeignRelation(relationDataSource, relation);
            this.relationsInfo[foreignEntityDef.baseDef.name] = {
              relation: relation,
              foreignRelation: foreignRelation,
              relationEntityDef: foreignEntityDef,
              relationDataSource: relationDataSource
            };
            if (i === numOfRelations) {
              this.initEntityRelationsDone();
            }
          }
        }, DataSource.buildEntityCacheKey(this.props.campaignId, relation.foreignRelation, 'EntityDescribe'));
      }
    }
  }

  initEntityRelationsDone() {
    for (let foreignTableName in this.relationsInfo) {
      let relationInfo = this.relationsInfo[foreignTableName];
      this.addEntityToTab(relationInfo.relationEntityDef, relationInfo.relationDataSource, relationInfo.foreignRelation,
        foreignTableName);
    }
    this.setState(this.state);
  }

  initTableGroupButton() {
    let tableGroupButton;
    let additionalTableButtons = [];
    if (this.props.entityDef.relations.length > 0) {
      let menuItems = [];
      for (let foreignTableName in this.relationsInfo) {
        let relationInfo = this.relationsInfo[foreignTableName];
        menuItems.push(<MenuItem key={shortid()} option={relationInfo}>{relationInfo.relation.name[1]}</MenuItem>);
      }
      additionalTableButtons.push(
        <InputDropDown key={shortid()} ref={createRef()} className='min-w-[150px] last:rounded-bl-none last:rounded-r-lg' color='secondary' onChange={(e, id, relationInfo) => this.openRelationTab(
          relationInfo, this.props.campaignId)} defaultValue={{
          option: 0, displayValue: 'Datensätze verknüpfen'
        }}>
          <Menu>
            <MenuItemGroup>
              {menuItems}
            </MenuItemGroup>
          </Menu>
        </InputDropDown>);
    }
    for (let index in this.props.additionalTableButtons) {
      let tableButton = this.props.additionalTableButtons[index];
      additionalTableButtons.push(tableButton);
    }
    if (GF.checkNotNull(additionalTableButtons) && additionalTableButtons.length) {
      tableGroupButton = <TableButtonGroup ref={createRef()} editable>{additionalTableButtons}</TableButtonGroup>;
    } else {
      tableGroupButton = <TableButtonGroup ref={createRef()} editable/>;
    }
    return tableGroupButton;
  }

  openRelationTab(relationInfo, campaignId) {
    this.props.tabMainContainer.addElement(<ButtonTab id={relationInfo.relation.name[1]}>{relationInfo.relation.name[1]}<Link/></ButtonTab>,
      <RelationForm ref={createRef()} relationInfo={relationInfo} relation={relationInfo.relation} campaignId={campaignId} tabMainContainer={this.props.tabMainContainer} dataSourceMaster={this.dataSourceMaster}/>);
  }

  render() {
    let tableGroupButton = this.initTableGroupButton();

    return <VerticalLayout className='text-cn-color-white gap-5' id={this.props.tabId}>
      {this.state.mailingWizard}
      {this.state.omsDialog && this.state.omsDialog}
      <Window title={this.props.entityDef.baseDef.description}>
        <Entity ref={this.entityRef} dataSource={this.dataSourceMaster} headerButtons={tableGroupButton} parent={this} campaignId={this.props.campaignId} tabContainer={this.props.tabMainContainer} functionElements={this.props.functionElements}/>
      </Window>
      <Window ref={this.detailContainerRef}>
        <DetailFieldsViewForm dataSource={this.dataSourceMaster}/>
      </Window>
      <TabContainerHorizontal className='h-full' ref={this.relationTabContainerRef} tabBar={
        <TabBarHorizontal/>} tabContent={<TabContent className='pb-[8px]'/>}/>
    </VerticalLayout>;
  }
}

EntityForm.propTypes = {
  entityDef: PropTypes.object,
  tabMainContainer: PropTypes.object,
  campaignId: PropTypes.any,
  tabId: PropTypes.string,
  dtoProperties: PropTypes.object,
  additionalTableButtons: PropTypes.array,
  functionElements: PropTypes.node
}
export default EntityForm;

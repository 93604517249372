import PropTypes from "prop-types";
import "./ButtonStyles.css";
import ButtonBase from "./ButtonBase";

class ButtonText extends ButtonBase {

  render() {
    this.state.size = 'small';
    this.state.color = 'noColor';
    return super.render();
  }
}

ButtonText.propTypes = {
  uid: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'dark', 'light']),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'large', '']),
  widthFull: PropTypes.bool
}

export default ButtonText;

import BaseComponent from "../../../../../lib/components/BaseComponent";
import ButtonLarge from "../../../../../appl/view/components/buttons/ButtonLarge";
import MemoryTable from "../../../../../lib/model/system/data/memory/table/MemoryTable";
import DataType from "../../../../../lib/model/types/DataType";
import MemoryDataProvider from "../../../../../lib/model/system/data/memory/MemoryDataProvider";
import Create from "../../../../../lib/model/service/entity/dto/Create";
import Records from "../../../../../lib/model/service/entity/dto/Records";
import Read from "../../../../../lib/model/service/entity/dto/Read";
import React from "react";
import Entity from "../../../../../appl/view/components/entity/Entity";
import DataSource from "../../../../../lib/model/service/dataSource/DataSource";
import EntityDef from "../../../../../lib/model/service/entity/EntityDef";

class MemoryTableTest extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      dummy: null
    }
    this.memoryTable = null;
    this.memoryDataProvider = null;
  }

  initEntityDef() {
    let entityDef = new EntityDef('Entity\\Contacts');
    entityDef.addField('id', 'ID', DataType.INTEGER, true);
    entityDef.addField('firstname', 'Vorname', DataType.STRING);
    entityDef.addField('lastname', 'Nachname', DataType.STRING);

    return entityDef.getEntityDef();
  }

  onInitTable(e) {
    let records = [
      {'Entity\\Contacts#id': 1, 'Entity\\Contacts#firstname': 'peer1', 'Entity\\Contacts#lastname': 'tobler1'},
      {'Entity\\Contacts#id': 2, 'Entity\\Contacts#firstname': 'peer2', 'Entity\\Contacts#lastname': 'tobler2'}
    ];

    this.memoryTable = new MemoryTable(this.initEntityDef(), records);
    this.memoryDataProvider = new MemoryDataProvider(this.memoryTable);
  }

  onAddDataToTable(e) {
    let records = new Records([
      {'Entity\\Contacts#id': 1, 'Entity\\Contacts#firstname': 'peer3', 'Entity\\Contacts#lastname': 'tobler3'},
      {'Entity\\Contacts#id': 2, 'Entity\\Contacts#firstname': 'peer4', 'Entity\\Contacts#lastname': 'tobler4'}
    ]);
    let dtoCreate = new Create(records);
    this.memoryDataProvider.processDto(dtoCreate);
  }

  onTableLog(e) {
    let dtoRead = new Read();
    let records = this.memoryDataProvider.processDto(dtoRead);

    console.log(records);
  }

  datasourceDataHandler(datasource, dto, dataSourceDtoProcessEvents) {
    let response = this.memoryDataProvider.processDto(dto);
    dataSourceDtoProcessEvents.doResponse(response);
  }

  render() {
    let records = [
      {'Entity\\Contacts#id': 1, 'Entity\\Contacts#firstname': 'peer1', 'Entity\\Contacts#lastname': 'tobler1'},
      {'Entity\\Contacts#id': 2, 'Entity\\Contacts#firstname': 'peer2', 'Entity\\Contacts#lastname': 'tobler2'},
      {'Entity\\Contacts#id': 2, 'Entity\\Contacts#firstname': 'peer3', 'Entity\\Contacts#lastname': 'tobler3'},
      {'Entity\\Contacts#id': 2, 'Entity\\Contacts#firstname': 'peer4', 'Entity\\Contacts#lastname': 'tobler4'},
      {'Entity\\Contacts#id': 2, 'Entity\\Contacts#firstname': 'peer5', 'Entity\\Contacts#lastname': 'tobler5'},
      {'Entity\\Contacts#id': 2, 'Entity\\Contacts#firstname': 'peer6', 'Entity\\Contacts#lastname': 'tobler6'},
      {'Entity\\Contacts#id': 2, 'Entity\\Contacts#firstname': 'peer7', 'Entity\\Contacts#lastname': 'tobler7'},
      {'Entity\\Contacts#id': 2, 'Entity\\Contacts#firstname': 'peer8', 'Entity\\Contacts#lastname': 'tobler8'},
      {'Entity\\Contacts#id': 2, 'Entity\\Contacts#firstname': 'peer9', 'Entity\\Contacts#lastname': 'tobler9'},
      {'Entity\\Contacts#id': 2, 'Entity\\Contacts#firstname': 'peer10', 'Entity\\Contacts#lastname': 'tobler10'},
      {'Entity\\Contacts#id': 2, 'Entity\\Contacts#firstname': 'peer11', 'Entity\\Contacts#lastname': 'tobler11'},
      {'Entity\\Contacts#id': 2, 'Entity\\Contacts#firstname': 'peer12', 'Entity\\Contacts#lastname': 'tobler12'},
    ];
    this.memoryTable = new MemoryTable(this.initEntityDef(), records);
    this.memoryDataProvider = new MemoryDataProvider(this.memoryTable);

    let dataSource = DataSource.generateDatasource(this.initEntityDef(), null,
      (datasource, dto, dataSourceDtoProcessEvents) => this.datasourceDataHandler(
        datasource, dto, dataSourceDtoProcessEvents));

    return <div className='sandbox-container flex flex-col gap-4'>
      <h1>Memory-Tabellen-Test Test</h1>
      <ButtonLarge className='bg-cn-color-static-green' onClick={(e) => this.onInitTable(e)}><div className='text-cn-color-black'>init Table</div></ButtonLarge>
      <ButtonLarge className='bg-cn-color-static-red' onClick={(e) => this.onAddDataToTable(e)}><div className='text-cn-color-black'>Daten einfügen</div></ButtonLarge>
      <ButtonLarge className='bg-cn-color-static-red' onClick={(e) => this.onTableLog(e)}><div className='text-cn-color-black'>Tabelle ins Log schreiben</div></ButtonLarge>
      <br/>
      <Entity dataSource={dataSource}/>
    </div>
  }
}

export default MemoryTableTest;

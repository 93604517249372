import BaseComponent from "../../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import Domain_Dark from "../../../../../lib/fonts/icons/Domain_Darkmode.svg";
import Module_Dark from "../../../../../lib/fonts/icons/Module_Darkmode.svg";
import Template_Dark from "../../../../../lib/fonts/icons/Template_Darkmode.svg";
import SettingsItem from "../../../../../appl/view/components/settings/SettingsItem";
import Settings from "../../../../../appl/view/components/settings/Settings";

class SettingTest extends BaseComponent {

    render() {
    return <VerticalLayout className='self-center'>
      <Settings>
        <SettingsItem title='Domain' img={<img src={Domain_Dark} alt='Domains'/>}></SettingsItem>
        <SettingsItem title='Template' img={<img src={Template_Dark} alt='Templates'/>}></SettingsItem>
        <SettingsItem title='Module' img={<img src={Module_Dark} alt='Module'/>}></SettingsItem>
      </Settings>
    </VerticalLayout>
  }
}

export default SettingTest;

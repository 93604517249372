import Dto from "../../Dto";

class ResetPassword extends Dto {

  constructor(hash = '',  newPassword = '') {
    super('po2\\service\\passwordReset\\dto\\ResetPassword');
    this.hash = hash;
    this.newPassword = newPassword;
  }
}

export default ResetPassword;

import BaseComponent from "../../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import InputWysiwyg from "../../../../../appl/view/components/inputFields/InputWysiwyg";

class InputWysiwygTest extends BaseComponent {

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>
      <VerticalLayout className={' self-center'}>
        <h1>WYSIWYG</h1>
        <InputWysiwyg defaultValue={<div>test</div>} onChange={(e, _id, data) => {
        }}/>
      </VerticalLayout>

      <div id='test' className='bg-cn-color-blue-690 w-full min-h-[35px]'></div>

    </div>
  }
}

export default InputWysiwygTest;

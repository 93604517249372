import PropTypes from "prop-types";
import VerticalLayout from "../layouts/VerticalLayout";
import HorizontalLayout from "../layouts/HorizontalLayout";

import './TimePicker.css';
import BaseComponent from "../BaseComponent";
import T from "../../../appl/model/system/text_translations/Translator";
import GF from "../../utils/GF";
import Checkbox from "../checkbox/Checkbox";

class TimePicker extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      datetime: this.props.datetime ? this.props.datetime : new Date(),
      title: this.props.title ? this.props.title : T.label_enter_time(),
      useTime: GF.getValue(this.props.useTime, true),
      canChooseUseTime: GF.getValue(this.props.canChooseUseTime, false)
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.onTimeChange();
  }

  cleanTime(hour, minute) {
    hour = hour.toString().replace(/[^\d\.]/gim, '');
    minute = minute.toString().replace(/[^\d\.]/gim, '');
    hour = hour.substring(0, 2).padStart(2, '0');
    minute = minute.substring(0, 2).padStart(2, '0');
    return [hour, minute];
  }

  private_fireOnChange() {
    if (this.props.onChange) {
      this.props.onChange(this.state.datetime, this.state.useTime);
    }
  }

  onTimeChange(hour, minute) {
    if (hour === undefined || hour === null) {
      hour = this.state.datetime.getHours();
    }
    if (minute === undefined || minute === null) {
      minute = this.state.datetime.getMinutes();
    }
    let result = this.cleanTime(hour, minute);
    hour = result[0];
    minute = result[1];
    this.state.datetime = new Date(this.state.datetime.getFullYear(), this.state.datetime.getMonth(), this.state.datetime.getDate(), hour, minute);
    this.setState(this.state);
    this.private_fireOnChange();
  }

  onUseTimeClick(e) {
    this.state.useTime = e.currentTarget.checked;
    this.setState(this.state);
    this.private_fireOnChange();
  }

  render() {
    let styleOnTimeValues = {opacity: 0.1};
    if (this.state.useTime) {
      styleOnTimeValues = {opacity: 1};
    }
    let titleElement;
    if (this.state.canChooseUseTime) {
      titleElement = <HorizontalLayout className='justify-center'>
        <Checkbox type='checkbox' onChange={(e) => this.onUseTimeClick(e)} labelElement='Zeit eingeben' checked={this.state.useTime} />
      </HorizontalLayout>;
    } else {
      titleElement = <div className='font-cn-roboto-slab-medium ml-2 mb-2'>{this.state.title}</div>
    }
    return <VerticalLayout widthFit className={GF.getValue(this.props.className, '')} styleValue={GF.getValue(this.props.styleValue, {})}>
      {titleElement}
      <HorizontalLayout widthFit styleValue={styleOnTimeValues}>
        <VerticalLayout widthFit>
          <input maxLength={2} onChange={(event) => this.onTimeChange(event.target.value)} defaultValue={this.state.datetime.getHours().toString().padStart(2, '0')} className='hour-input'/>
          <div className='font-cn-inter-regular text-xs mt-2 text-center'>{T.label_hour()}</div>
        </VerticalLayout>
        <div className='text-6xl mx-4'>:</div>
        <VerticalLayout widthFit>
          <input maxLength={2} onChange={(event) => this.onTimeChange(null, event.target.value)} defaultValue={this.state.datetime.getMinutes().toString().padStart(2, '0')} className='minute-input'/>
          <div className='font-cn-inter-regular text-xs mt-2 text-center'>{T.label_minute()}</div>
        </VerticalLayout>
      </HorizontalLayout>
    </VerticalLayout>;
  }
}

TimePicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  styleValue: PropTypes.object,
  useTime: PropTypes.bool,
  canChooseUseTime: PropTypes.bool
}

export default TimePicker
import {hexToHsva} from '@uiw/color-convert'
import {createRef} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import CnerLogoSvg from "../../../../lib/components/images/CnerLogoSvg";
import ColorPicker from "../../../../lib/components/colorpicker/ColorPicker";
import shortid from "shortid";
import InputBase from "./InputBase";

/**
 * @property string   hex: Farbwert als Hex-Wert: Bsp. #225588
 * @property string[] colors: Schnell-Auswahlfarben: Bsp. ['#f44e3b', '#fe9200', '#fcdc00', '#dbdf00']
 */
class InputColorPicker extends InputBase {

  constructor(props) {
    super(props);
    this.state.hsva = hexToHsva(this.props.hex);
    this.state.hex = this.props.hex;
    this.state.showPicker = false;
    this.colorPickerRef = createRef();
    this.state.iconRight = this.createIcon();
    this.colorPicker = null;
    this.colorPickerId = shortid();
  }

  addBackDrop = (e) => {
    try {
      if (this.state.showPicker && this.colorPicker !== undefined &&
        !ReactDOM.findDOMNode(this.colorPicker.ref.current).contains(e.target)) {
        this.state.showPicker = false;
        window.removeEventListener('click', this.addBackDrop);
        this.setState(this.state);
      }
    } catch (ex1) {
    }
  }

  createIcon() {
    return <div className='self-center mr-2'><CnerLogoSvg fillColor={this.state.hex}/></div>;
  }

  getValue() {
    return this.state.hex;
  }

  setIcon() {
    this.state.iconRight = this.createIcon();
    this.setState(this.state);
  }

  onColorPickerChange(hex) {
    this.fireOnChange(null, hex);
    this.state.hex = hex;
    this.setIcon();
  }

  onInputClick(e) {
    let self = this;
    if (this.state.showPicker === false) {
      this.state.showPicker = true;
      this.setState(this.state);

      setTimeout(() => {
        window.addEventListener('click', self.addBackDrop);
      }, 1);
    }
  }

  render() {
    this.colorPicker = '';
    if (this.state.showPicker === true) {
      this.colorPicker = <div ref={this.colorPickerRef} className='absolute top-10 -left-3 max-w-[340px] z-10'>
        <ColorPicker onChange={(hex) => this.onColorPickerChange(hex)} colors={this.props.colors} hex={this.state.hex}/>
      </div>;
    }
    this.state.children = <div key={this.colorPickerId} className="relative h-full">
      <input onClick={(e) => this.onInputClick(e)}
             value={this.state.hex} readOnly className="bg-transparent focus:outline-0 w-full h-full pr-8"/>
      {this.colorPicker}
    </div>;

    return super.render()
  }
}

InputColorPicker.propTypes = {
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  inputLayout: PropTypes.oneOf(['inline', 'outline', '']),
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  hex: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string)
}
export default InputColorPicker

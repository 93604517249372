import BaseComponent from "../../../../../lib/components/BaseComponent";
import Download from "../../../../../appl/view/components/entity/download/Download";
import DataType from "../../../../../lib/model/types/DataType";

class TableExportTest extends BaseComponent {

  initDataSource() {
    return {
      getEntityDef: () => {
        return {baseDef: {name: 'Entity\\Contact'},
        fieldsDef: {
          id: {name: 'id', description: 'ID', type: {type: DataType.INTEGER, isKey: true}},
          firstname: {name: 'firstname', description: 'Vorname', type: {type: DataType.STRING, isKey: false}},
          lastname: {name: 'lastname', description: 'Nachname', type: {type: DataType.STRING, isKey: false}}
        }};
      }
    };
  }

  render() {
    return <Download dataSource={this.initDataSource()}/>;
  }
}

export default TableExportTest;

/**
 * class Resource
 */
import GF from "../../../utils/GF";

class Resource {

  static extractIdAndNameFromDefaultValue(stringValue) {
    let resourceId = '';
    let resourceName = '';
    if (GF.checkNotNull(stringValue) && stringValue !== '') {
      let valueParts = stringValue.split(':');
      if (valueParts.length > 1) {
        resourceId = valueParts[0];
        resourceName = valueParts[1];
      } else {
        resourceId = stringValue;
        resourceName = stringValue;
      }
    }
    return {resourceId, resourceName};
  }
}

export default Resource;

import GF from "../../../lib/utils/GF";

/**
 * class Callback
 */
class Callback {

  listeners = [];

  addListener(listener) {
    if (listener !== undefined) {
      this.listeners.push(listener);
    }
  }

  fire(...data) {
    for (let listener of this.listeners) {
      if (typeof listener.hook === 'function') {
        listener.hook(...data);
      } else {
        if (typeof listener === 'function') {
          listener(...data);
        } else {
          //console.log('Fehler: Der Zuhörer (Listener) ist keine Funktion!');
        }
      }
    }
  }

  removeListener(listener) {
    if (GF.checkNotNull(listener)) {
      let listenersNew = [];
      for (let i = 0; i < this.listeners.length; i++) {
        if (this.listeners[i].id !== listener.id) {
          listenersNew.push(this.listeners[i]);
        }
      }
      this.listeners = listenersNew;
    }
  }
}

export default Callback;

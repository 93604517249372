import PropTypes from "prop-types";
import NodeIterator from "../../../../lib/model/node/NodeIterator";
import TypePostProcess from "../../../../lib/model/query/TypePostProcess";

class CleanPredicates extends NodeIterator {

  parentNodeCallback(_workingNode, childNodesNew) {
    if (_workingNode.operator === '&&') {
      _workingNode.operator = 'and';
    }
    if (_workingNode.operator === '||') {
      _workingNode.operator = 'or';
    }
    _workingNode.predicates = childNodesNew;
    return _workingNode;
  }

  childNodeCallback(_workingNode) {
    // behandle Datetime, wenn diese ohne Zeit abgefragt werden.
    _workingNode = TypePostProcess.processDateTimeAsDate(_workingNode);
    return _workingNode;
  }
}

CleanPredicates.propTypes = {
  colSpan: PropTypes.number, width: PropTypes.string, className: PropTypes.string
}
export default CleanPredicates;
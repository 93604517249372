import BaseComponent from "../../../../lib/components/BaseComponent";
import './TabBar.css';
import PropTypes from "prop-types";
import GF from "../../../../lib/utils/GF";
import ButtonMedium from "../buttons/ButtonMedium";
import {Edit2} from "react-feather";

class ButtonTabVariables extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      isChanged: GF.getValue(this.props.isChanged, false)
    }
  }

  onClick(e) {
    if (this.props.onClick !== undefined) {
      this.props.onClick(e, this.props.id);
    }
  }

  setIsChanged(isChanged) {
    this.state.isChanged = isChanged;
    this.setState(this.state);
  }

  render() {
    let classNameFromProps = (this.props.className !== undefined ? this.props.className : '');
    let activeClass = this.props.isActive === true ? ' active ' : '';

    if (this.state.isChanged) {
      return <ButtonMedium className={'w-full bg-cn-color-static-blue-690 justify-between ' + activeClass +
          ' ' + classNameFromProps} onClick={(e) => this.onClick(
        e)}><div className='mx-2'>{this.props.children}</div><Edit2 className='p-0.5 stroke-amber-500'/></ButtonMedium>;

    } else {
      return <ButtonMedium className={'w-full bg-cn-color-static-blue-690  ' + activeClass + ' ' + classNameFromProps}
                          onClick={(e) => this.onClick(e)}>{this.props.children}</ButtonMedium>;
    }
  }
}

ButtonTabVariables.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  isChanged: PropTypes.bool,
  parent: PropTypes.object
}
export default ButtonTabVariables;

import PropTypes from "prop-types";
import "./ButtonStyles.css";
import ButtonBase from "./ButtonBase";

class ButtonMedium extends ButtonBase {

  render() {
    this.state.size = 'medium';
    return super.render();
  }
}

ButtonMedium.propTypes = {
  uid: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'dark', 'light', 'red']),
  onClick: PropTypes.func,
  widthFull: PropTypes.bool,
  tooltip: PropTypes.string
}

export default ButtonMedium;

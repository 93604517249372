import BaseComponent from "../../../../../lib/components/BaseComponent";
import VariablesForm from "../../../../../appl/view/components/variables/VariablesForm";

class WizardTest extends BaseComponent {

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>
      <h1>Variablen Test</h1>
      <VariablesForm/>
    </div>
  }
}

export default WizardTest;

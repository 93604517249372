import {createRef} from "react";
import shortid from "shortid";
import BaseComponent from "../BaseComponent";
import PropTypes from "prop-types";
import BooleanPredicate from "./BooleanPredicate";
import FieldPredicate from "./FieldPredicate";
import Operator from "../../model/query/Operator";
import PredicateBuilder from "../../components/tree/leftTree/PredicateBuilder";
import TreeNodeBuilder from "./TreeNodeBuilder";
import AddIsExistingNodeToPredicates from "../../../appl/view/components/extendedFilter/AddIsExistingNodeToPredicates";
import GF from "../../utils/GF";

class FilterBuilder extends BaseComponent {

  firstInitialisation = false;

  constructor(props) {
    super(props);
    this.state = {
      nodes: null
    };
    (new AddIsExistingNodeToPredicates()).iteratePredicates(this.props.queryPredicates);
    this.initFilter(this.props.queryPredicates);
  }

  addEmptyBooleanPredicate() {
    this.addEmptyPredicate(() => {
      let predicateBuilder = new PredicateBuilder();
      return predicateBuilder.buildBooleanPredicate({state: {operator: Operator.and}, _key: ''},
        [predicateBuilder.buildFieldPredicate({fieldNameValue: '', fieldValue: '', operatorValue: Operator.eq, _key: ''})]);
    });
  }

  addEmptyFieldPredicate() {
    this.addEmptyPredicate(() => {
      return (new PredicateBuilder()).buildFieldPredicate({fieldNameValue: '', fieldValue: '', operatorValue: Operator.eq, _key: ''});
    });
  }

  addEmptyPredicate(buildPredicateCallback) {
    let predicateBuilder = new PredicateBuilder();
    let predicate = predicateBuilder.iteratePredicates(this.state.nodes);
    let children = predicate.getChildren();

    let newPredicate = buildPredicateCallback();
    children.push(newPredicate);
    this.initFilter(predicate);
    this.setState(this.state);
  }

  addFieldPredicate(callerElementKey) {
    this.modifiePredicates(callerElementKey, PredicateBuilder.cmd_addFieldPredicate);
  }

  addBooleanPredicate(callerElementKey) {
    this.modifiePredicates(callerElementKey, PredicateBuilder.cmd_addBooleanPredicate);
  }

  buildPredicatesFromTreeNodes(node) {
    return (new PredicateBuilder()).iteratePredicates(node);
  }

  /**
   * soll überschrieben werden
   * @param _fieldPredicate
   * @param onFilterSave
   * @returns {JSX.Element}
   */
  getFieldPredicate(_fieldPredicate = null, onFilterSave = null) {
    let fieldPredicateRef = createRef();
    return <FieldPredicate key={shortid()} ref={fieldPredicateRef}/>;
  }

  /**
   * soll überschrieben werden
   * @param _booleanPredicate
   * @returns {JSX.Element}
   */
  getBooleanPredicate(_booleanPredicate = null) {
    let booleanPredicateRef = createRef();
    let booleanPredicate;
    if (_booleanPredicate !== undefined) {
      booleanPredicate = <BooleanPredicate key={shortid()} ref={booleanPredicateRef} operator={_booleanPredicate.getOperator()}/>;
    } else {
      booleanPredicate = <BooleanPredicate key={shortid()} ref={booleanPredicateRef}/>;
    }
    return booleanPredicate;
  }

  getFilterPredicates() {
    return this.buildPredicatesFromTreeNodes(this.state.nodes);
  }

  initEmptyFilter() {
    let predicateBuilder = new PredicateBuilder();
    let firstFieldPredicate = predicateBuilder.buildFieldPredicate(
      {fieldNameValue: '', fieldValue: '', operatorValue: Operator.eq, _key: ''});
    let booleanPredicate = predicateBuilder.buildBooleanPredicate({state: {operator: Operator.and}, _key: ''}, [firstFieldPredicate]);
    return this.initFilterFromQueryPredicates(booleanPredicate);
  }

  initFilter(queryPredicates) {
    if (queryPredicates === undefined || queryPredicates === null) {
      this.state.nodes = this.initEmptyFilter();
    } else {
      this.state.nodes = this.initFilterFromQueryPredicates(queryPredicates);
    }
    this.firstInitialisation = true;
  }

  initFilterFromQueryPredicates(queryPredicates) {
    return (new TreeNodeBuilder(this)).iteratePredicates(queryPredicates);
  }

  modifiePredicates(callerElementKey, cmd) {
    let predicate = (new PredicateBuilder(this.props.defaultFieldPredicate)).iteratePredicates(this.state.nodes,
      {cmd: cmd, key: callerElementKey});

    if (predicate !== null) {
      this.state.nodes = this.initFilterFromQueryPredicates(predicate);
    }
    this.setState(this.state);
  }

  removeElement(callerElementKey) {
    this.modifiePredicates(callerElementKey, PredicateBuilder.cmd_remove);
  }

  resetFilter(queryPredicates = null) {
    this.initFilter(queryPredicates);
    this.setState(this.state);
  }

  saveFilter(e) {
    let predicate = this.buildPredicatesFromTreeNodes(this.state.nodes);
    if (GF.checkNotNull(this.props.onSaveQueryClick)) {
      this.props.onSaveQueryClick(e, predicate);
    }
  }

  render() {
    return this.state.nodes;
  }
}

FilterBuilder.propTypes = {
  selectFields: PropTypes.object,
  queryPredicates: PropTypes.object,
  defaultFieldPredicate: PropTypes.object,
  onSaveQueryClick: PropTypes.func
}
export default FilterBuilder;

export default function DragAndDrop(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" className={props.className} height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
              strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="1"/>
    <circle cx="12" cy="5" r="1"/>
    <circle cx="12" cy="19" r="1"/>
    <circle cx="20" cy="12" r="1"/>
    <circle cx="20" cy="5" r="1"/>
    <circle cx="20" cy="19" r="1"/>
  </svg>
}

import PropTypes from "prop-types";
import EntityDef from "../../../../../lib/model/service/entity/EntityDef";
import DataType from "../../../../../lib/model/types/DataType";
import UploadCardBase from "./UploadCardBase";
import Records from "../../../../../lib/model/service/entity/dto/Records";

class FieldSelectionCard extends UploadCardBase {

  componentDidMount() {
    super.componentDidMount();
    this.dataSource.createRows(new Records(this.props.records));
  }

  initEntityDef() {
    let entityDef = new EntityDef('');
    entityDef.addField('id', 'ID', DataType.INTEGER, true);
    entityDef.addField('column', 'Spalte', DataType.STRING);
    entityDef.addField('value', 'Wert', DataType.STRING);
    entityDef.addField('fieldSelection', 'Feldauswahl',
      {type: DataType.ENUM, isKey: false, valueMap: this.props.fieldMapping});

    return entityDef.getEntityDef();
  }

  render() {
    this.state.cardTitle = 'Feldzuordnung';

    return super.render();
  }
}

FieldSelectionCard.propTypes = {
  className: PropTypes.string,
  fieldMapping: PropTypes.array,
  records: PropTypes.arrayOf(PropTypes.object),
  onReadDone: PropTypes.func,
  onUpdateRowsDone: PropTypes.func
}
export default FieldSelectionCard;

import PropTypes from "prop-types";
import "./ButtonStyles.css";
import ButtonBase from "./ButtonBase";

class ButtonSmall extends ButtonBase {

  render() {
    this.state.size = 'small';
    return super.render();
  }
}

ButtonSmall.propTypes = {
  uid: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'dark', 'light', 'red']),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'large', '']),
  widthFull: PropTypes.bool,
  tooltip: PropTypes.string
}

export default ButtonSmall;

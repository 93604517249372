import PropTypes from "prop-types";
import BaseComponent from "../../../../../lib/components/BaseComponent";
import Window from "../../../../../lib/components/window/Window";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import HorizontalLayout from "../../../../../lib/components/layouts/HorizontalLayout";
import ButtonSmall from "../../buttons/ButtonSmall";
import React, {createRef} from "react";
import UploadedFilesCard from "./UploadedFilesCard";
import Checkbox from "../../../../../lib/components/checkbox/Checkbox";
import SysUpload from "../../../../../lib/components/Upload";
import Records from "../../../../../lib/model/service/entity/dto/Records";
import CM from "../../../../model/system/CM";
import {toast} from "react-toastify";
import AnalyseUpload from "../../../../../lib/model/service/entity/dto/AnalyseUpload";
import FieldSelectionContainer from "./FieldSelectionContainer";
import Import from "../../../../../lib/model/service/entity/dto/Import";
import GF from "../../../../../lib/utils/GF";
import VM from "../../../../../lib/model/system/VM";
import DataSource from "../../../../../lib/model/service/dataSource/DataSource";
import T from "../../../../model/system/text_translations/Translator";

class Upload extends BaseComponent {

  constructor(props) {
    super(props);
    this.collectionId = '';
    this.uploadedFilesCardRef = createRef();
    this.fieldSelectionContainerRef = createRef();
    this.cbDeleteAll = createRef();
    this.sysUploadRef = createRef();

    let cbDeleteChecked = false;
    let storedState = VM.get().getData(this.getVmKey(), 'state');
    if (storedState !== null) {
      this.state = storedState;
    } else {
      this.state = {
        cbDeleteChecked: cbDeleteChecked
      };
    }
  }

  componentDidMount() {
    super.componentDidMount();

    this.collectionId = VM.get().getData(this.getVmKey(), 'collectionId');
    let records = VM.get().getData(this.getVmKey(), 'records');
    this.uploadedFilesCardRef.current.dataSource.createRows(new Records(records));
    this.setState(this.state);
  }

  analyseUpload(response) {
    let entityDef = this.props.dataSource.getEntityDef();
    let dtoAnalyseUpload = new AnalyseUpload(response.data.id, entityDef.baseDef.name);
    dtoAnalyseUpload.setProperties(this.props.dataSource.getDtoProperties());

    CM.get().doJsonAjax(CM.get().getServiceUrl('DoctrineEntityService'), dtoAnalyseUpload, (response) => {
      if (response.success) {
        this.collectionId = response.data.id;
        this.fieldSelectionContainerRef.current.initTable(response);
      } else {
        toast.error('Importfehler:' + response.message);
      }
    });
  }

  doImport() {
    if (GF.checkNotNull(this.fieldSelectionContainerRef.current)) {
      let fieldMapping = this.fieldSelectionContainerRef.current.getFieldMapping();
      if (GF.checkNotNull(fieldMapping) && fieldMapping.length > 0) {
        let deleteAllRecordsBefore = this.cbDeleteAll.current.getValue();
        let dtoImport = new Import(this.props.dataSource.getEntityDef().baseDef.name, this.collectionId, fieldMapping,
          deleteAllRecordsBefore);
        dtoImport.setProperties(this.props.dataSource.getDtoProperties());

        CM.get().doJsonAjax(CM.get().getServiceUrl('DoctrineEntityService'), dtoImport, (response) => {
          if (response.success) {
            VM.get().clearData('CM.doJsonAjaxCache_' +
              DataSource.buildEntityCacheKey(this.props.dataSource.dtoProperties.campaignId,
                this.props.dataSource.entityDef.baseDef.name, 'Read'));
            toast.success('Import erfolgreich');
            this.props.entity.props.tabContainer.removeElement(this.props.entity.uploadTabId);
          } else {
            toast.error('Importfehler:' + response.message);
          }
        });
      } else {
        toast.warning('Importieren: bitte zuerst eine Datei hochladen!');
      }
    } else {
      toast.warning('Importieren: bitte zuerst eine Datei hochladen!');
    }
  }

  getVmKey() {
    return this.props.campaignId + 'Upload';
  }

  onUploadDone(response) {
    let records = [];
    for (let index in response.data.fileUploadEntities) {
      let fileUploadEntity = response.data.fileUploadEntities[index];
      records.push({'filename': fileUploadEntity.name, 'size': fileUploadEntity.size});
    }
    this.collectionId = response.data.id;
    this.sysUploadRef.current.setImportId(response.data.id);

    this.addRecordsToVm(records);
    VM.get().setData(this.getVmKey(), this.collectionId, 'collectionId');

    this.uploadedFilesCardRef.current.dataSource.createRows(new Records(records));
    this.analyseUpload(response);
  }

  addRecordsToVm(records) {
    let storedRecords = VM.get().getData(this.getVmKey(), 'records');
    let recordsNew = [];
    for (let index in storedRecords) {
      let storedRecord = storedRecords[index];
      recordsNew.push(storedRecord);
    }
    for (let index in records) {
      let record = records[index];
      recordsNew.push(record);
    }
    VM.get().setData(this.getVmKey(), recordsNew, 'records');
  }

  onClose() {
    VM.get().clearData(this.getVmKey());
  }

  cbDeleteChanged(e, id, checked) {
    this.state.cbDeleteChecked = checked;
    VM.get().setData(this.getVmKey(), this.state, 'state');
  }

  render() {
    let dtoProperties = this.props.dataSource.getDtoProperties();
    return <Window className='text-cn-color-white' title={T.label_import()}>
      <VerticalLayout>
        <SysUpload ref={this.sysUploadRef} className='mb-12' title='Dateien hochladen' onUploadDone={(response) => this.onUploadDone(
          response)} dtoProperties={dtoProperties} importId={this.collectionId}/>
        <UploadedFilesCard ref={this.uploadedFilesCardRef} className='mb-12'/>
        <FieldSelectionContainer ref={this.fieldSelectionContainerRef} dataSource={this.props.dataSource} vmKey={this.getVmKey()}/>
        <HorizontalLayout className='justify-end gap-2'>
          <Checkbox ref={this.cbDeleteAll}
                    type='switch'
                    labelElement={<div className='text-cn-color-static-red'>Daten beim importieren überschreiben!</div>}
                    checked={this.state.cbDeleteChecked}
                    onChange={(e, id, checked) => this.cbDeleteChanged(e, id, checked)}/>
          <ButtonSmall className='bg-cn-color-blue-690' onClick={(e) => this.doImport(e)}>{T.label_do_import()}</ButtonSmall>
        </HorizontalLayout>
      </VerticalLayout>
    </Window>;
  }
}

Upload.propTypes = {
  dataSource: PropTypes.object, campaignId: PropTypes.any, entity: PropTypes.object
}
export default Upload;

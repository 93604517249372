import BaseFieldGenerator from "./BaseFieldGenerator";
import Checkbox from "../../../../../lib/components/checkbox/Checkbox";
import {ChevronsRight, Download, Link2} from "react-feather";
import HorizontalLayout from "../../../../../lib/components/layouts/HorizontalLayout";
import InputFieldGenerator from "./InputFieldGenerator";
import Get from "../../../../../lib/model/service/resource/dto/Get";
import he from 'he';
import GF from "../../../../../lib/utils/GF";

class TableFieldGenerator extends BaseFieldGenerator {

  initBooleanField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    let defaultValue = false;
    if (fieldValue !== null) {
      switch (typeof fieldValue) {
        case 'string':
          defaultValue = fieldValue.toLowerCase() === 'ja' || fieldValue.toLowerCase() === 'yes';
          break;
        default:
          defaultValue = fieldValue;
      }
    }
    return <Checkbox checked={defaultValue} type='checkbox' readonly/>;
  }

  initIntegerField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return this.initTextField(id, fieldRef, fieldValue, fieldLabel, privilege);
  }

  initFloatField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return this.initTextField(id, fieldRef, fieldValue, fieldLabel, privilege);
  }

  initTextAreaField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    let chevronsRight;
    if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
      chevronsRight = <ChevronsRight/>;
    }
    return <HorizontalLayout className='justify-between'>
      <div>{fieldValue}</div>
      {chevronsRight}</HorizontalLayout>;
  }

  initPasswordField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return this.initTextField(id, fieldRef, fieldValue, fieldLabel, privilege);
  }

  initHtmlField(id, fieldRef, fieldValue, fieldLabel, valueMap, privilege) {
    let chevronsRight;
    if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
      chevronsRight = <ChevronsRight/>;
    }
    return <HorizontalLayout className='justify-between'>
      <div className='max-w-[750px] max-h-[200px] overflow-scroll'>{fieldValue}</div>
      {chevronsRight}</HorizontalLayout>;
  }

  initLinkField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    let result = InputFieldGenerator.extractLink(fieldValue);
    return <a href={result.href} rel="noreferrer" target='_blank'><HorizontalLayout className='whitespace-nowrap hover:text-cn-color-primary-hover text-cn-color-primary'>{result.text}<Link2 className='ml-2'/></HorizontalLayout></a>;
  }

  initTextField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    if (typeof fieldValue === 'string') {
      fieldValue = he.decode(fieldValue);
    }
    return <div className='whitespace-nowrap'>{fieldValue}</div>;
  }

  initDateTimePickerField(id, fieldRef, fieldValue, fieldLabel, privilege, useTime) {
    let value = fieldValue;
    if (value === undefined || value === null) {
      value = '';
    }
    return <div className='whitespace-nowrap'>{value}</div>;
  }

  initEnumField(fieldType, id, fieldRef, fieldValue, fieldLabel, privilege) {
    if (typeof fieldValue !== 'number') {
      fieldValue = parseInt(fieldValue);
    }
    let enumValueString = '';
    let enumValues = fieldType.valueMap[fieldValue];
    if (Array.isArray(enumValues)) {
      //faha 20.12.23 todo for the gender field added hot fix so column is not so big
      // enumValueString = enumValues.join(',');
      enumValueString = enumValues[0];
    } else {
      enumValueString = enumValues;
    }
    if (enumValueString === '') {
      enumValueString = '---';
    }
    return <div className='whitespace-nowrap'>{enumValueString}</div>;
  }

  initResourceField(id, fieldRef, fieldValue, fieldLabel, dtoProperties, privilege) {
    let resourceField;
    let label = '';
    let href = '';
    if (GF.checkNotNUllAndNotEmpty(fieldValue)) {
      let resourceParts = fieldValue.split(':');
      label = resourceParts[1];
      href = Get.buildResourceLink(resourceParts[0], label, dtoProperties);

      resourceField =
        <a href={href} rel="noreferrer" target='_blank'><HorizontalLayout className='whitespace-nowrap justify-between'>{label}
          <Download className='ml-2'/>
        </HorizontalLayout></a>;
    } else {
      resourceField =
        <HorizontalLayout className='whitespace-nowrap justify-between'>{fieldLabel}</HorizontalLayout>;
    }
    return resourceField;
  }
}

export default TableFieldGenerator;


class Credential {

  _class = 'po2\\service\\Credential';
  userName = '';
  password = '';
  token = '';
  userGroups = [];
  valid = false;

  constructor(userName = '', password = '', token = '', userGroups = [], valid = false, _class = 'po2\\service\\Credential') {
    this.class = _class;
    this.userName = userName;
    this.password = password;
    this.token = token;
    this.userGroups = userGroups;
    this.valid = valid;
  }

  getUserName() {
    return this.userName;
  }

  setUserName(value) {
    this.userName = value;
  }

  getPassword() {
    return this.password;
  }

  setPassword(value) {
    this.password = value;
  }

  getToken() {
    return this.token;
  }

  setToken(value) {
    this.token = value;
  }

  getUserGroups() {
    return this.userGroups;
  }

  setUserGroups(value) {
    this.userGroups = value;
  }

  isValid() {
    return this.valid;
  }

  setValid(value) {
    this.valid = value;
  }
}

export default Credential;

import React from 'react';

import BaseComponent from "../BaseComponent";
import PropTypes from "prop-types";
import {X} from "react-feather";
import HorizontalLayout from "../layouts/HorizontalLayout";

class DialogHeader extends BaseComponent {

  onCloseButtonClick() {
    if (this.props.onCloseButtonClick !== undefined) {
      this.props.onCloseButtonClick();
    }
  }

  render() {
    let closeButton = null;
    if (this.props.closable === undefined || this.props.closable === true) {
      closeButton = <button onClick={(e) => this.onCloseButtonClick(
        e)} className='overflow-hidden rounded-full hover:bg-cn-color-static-red transition-[background] p-1'><X/>
      </button>;
    }
    return <HorizontalLayout className='justify-between items-center gap-5 text-cn-color-white'>
      <h3>{this.props.title}</h3>
      {closeButton}
    </HorizontalLayout>;
  }
}

DialogHeader.propTypes = {
  title: PropTypes.string, closable: PropTypes.bool, onCloseButtonClick: PropTypes.func
}

export default DialogHeader;

import BaseComponent from "../BaseComponent";
import PropTypes from "prop-types";

class Row extends BaseComponent {

  static ROW_TYPE_HEADER = 'header';
  static ROW_TYPE_SIMPLE_FILTER = 'simpleFilter';

  render() {
    let classes = this.props.className ? this.props.className : '';
    return <tr className={classes}>
      {this.props.children}
    </tr>;
  }
}

Row.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  rowIndex: PropTypes.number,
  recId: PropTypes.number,
  rowType: PropTypes.string
}

export default Row

import BaseComponent from "../BaseComponent";
import './LoaderLogo.css';
import CM from "../../../appl/model/system/CM";
import LogoCnerSvg from "../../../appl/view/site/cner/img/LogoCnerSvg";
import PropTypes from "prop-types";

class LoaderLogo extends BaseComponent {

  render() {
    let loaderDiv1;
    let loaderDiv2;
    if (this.props.status === CM.STATUS_WAITING) {
      loaderDiv1 = <div className='circle'/>;
      loaderDiv2 = <div className='circle1'/>;
    }
    let loaderDiv;
    if (this.props.status === CM.STATUS_IDLE) {
      loaderDiv = <div><LogoCnerSvg/></div>;
    } else {
      loaderDiv = <div className='relative left-[-5px]'>
        <div className='w-[75px] h-[64px]'>{loaderDiv1}{loaderDiv2}</div>
      </div>;
    }
    return loaderDiv;
  }
}

LoaderLogo.propTypes = {
  status: PropTypes.string.isRequired
}
export default LoaderLogo;
import './DatePicker.css';
import VerticalLayout from "../layouts/VerticalLayout";
import HorizontalLayout from "../layouts/HorizontalLayout";
import {ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight} from "react-feather";
import shortid from 'shortid';
import BaseComponent from "../BaseComponent";
import PropTypes from "prop-types";
import GF from "../../utils/GF";

const monthMap = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];

class DatePicker extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      datetime: this.props.datetime ? this.props.datetime : new Date(),
      preSelectedDatetime: this.props.datetime ? this.props.datetime : new Date()
    }
  }

  componentDidMount() {
    this.createCalendar();
    super.componentDidMount();
  }

  onDateClick(event, newDate) {
    let previousSelectedDate = event.target.parentElement.getElementsByClassName('selected')[0];
    if (previousSelectedDate) {
      previousSelectedDate.classList.remove('selected');
    }
    event.target.classList.add('selected');
    this.state.datetime = new Date(this.state.preSelectedDatetime.getFullYear(), this.state.preSelectedDatetime.getMonth(), newDate,
      this.state.preSelectedDatetime.getHours(), this.state.preSelectedDatetime.getHours());
    this.setState(this.state);
    if (this.props.onChange) {
      this.props.onChange(this.state.datetime);
    }
  }

  setYear = offset => {
    this.state.preSelectedDatetime = new Date(this.state.preSelectedDatetime.getFullYear() + offset, this.state.preSelectedDatetime.getMonth(),
      this.state.preSelectedDatetime.getDate(), this.state.preSelectedDatetime.getHours(), this.state.preSelectedDatetime.getHours());
    this.setState(this.state);
    this.createCalendar();
  }

  setMonth = offset => {
    let year = this.state.preSelectedDatetime.getFullYear();
    let month = this.state.preSelectedDatetime.getMonth() + offset;
    if (month === -1) {
      month = 11;
      year--;
    } else if (month === 12) {
      month = 0;
      year++;
    }
    this.state.preSelectedDatetime = new Date(year, month, this.state.preSelectedDatetime.getDate(), this.state.preSelectedDatetime.getHours(),
      this.state.preSelectedDatetime.getHours());
    this.setState(this.state);
    this.createCalendar();
  }

  isDayNumberEqualDateDayNumber(day, date, dateToCompare) {
    return day === date.getDate() && dateToCompare.getMonth() === date.getMonth() && dateToCompare.getFullYear() === date.getFullYear();
  }

  createCalendar() {
    //because js returns 0 for sunday and 6 for saturday. I had to make my own mapping of the days,
    // so I get the index of the day and take the value of my own mapping;
    let dayMapping = [6, 0, 1, 2, 3, 4, 5];
    let firstDayOfMonth = dayMapping[new Date(this.state.preSelectedDatetime.getFullYear(), this.state.preSelectedDatetime.getMonth(), 1).getDay()];//getting first day of month
    let lastDateOfMonth = new Date(this.state.preSelectedDatetime.getFullYear(), this.state.preSelectedDatetime.getMonth() + 1, 0).getDate() // getting last date of month
    let lastDayOfMonth = dayMapping[new Date(this.state.preSelectedDatetime.getFullYear(), this.state.preSelectedDatetime.getMonth(), lastDateOfMonth).getDay()];// getting last day of month
    let lastDateOfLastMonth = new Date(this.state.preSelectedDatetime.getFullYear(), this.state.preSelectedDatetime.getMonth(), 0).getDate(); // getting last date of previous month

    let days = [];
    for (let i = firstDayOfMonth; i > 0; i--) {
      days.push(<div className='dp-day-disabled' key={shortid.generate()}>{(lastDateOfLastMonth - i + 1)}</div>);
    }

    let currentDate = new Date();
    for (let i = 1; i <= lastDateOfMonth; i++) {
      let todayClass = this.isDayNumberEqualDateDayNumber(i, currentDate, this.state.preSelectedDatetime) ? ' today' : '';
      let currentlySelectedDateClass = this.isDayNumberEqualDateDayNumber(i, this.state.datetime, this.state.preSelectedDatetime) ? ' selected' : '';
      days.push(<div className={' dp-day' + currentlySelectedDateClass + todayClass} key={shortid.generate()}
                     onClick={(event) => this.onDateClick(event, i)}>{i}</div>);
    }

    //diese For Schleife füllt den rest der grid mit tag vom nächsten monat
    for (let i = lastDayOfMonth; i < 6; i++) {
      days.push(<div className='dp-day-disabled' key={shortid.generate()}>{i - lastDayOfMonth + 1}</div>);
    }
    this.state.days = days;
    this.setState(this.state)
  }

  render() {
    return (<VerticalLayout widthFit className={GF.getValue(this.props.className, '')} styleValue={GF.getValue(this.props.styleValue, {})}>
      <HorizontalLayout className='justify-center gap-1'>
        <div className='dp-change-button' onClick={() => this.setYear(-1)}><ChevronsLeft/></div>
        <div className='dp-change-button' onClick={() => this.setMonth(-1)}><ChevronLeft/></div>
        <HorizontalLayout className='justify-center gap-x-1'>
          <div className='dp-month'>{monthMap[this.state.preSelectedDatetime.getMonth()]}</div>
          <div className='dp-year'>{this.state.preSelectedDatetime.getFullYear()}</div>
        </HorizontalLayout>
        <div className='dp-change-button' onClick={() => this.setMonth(1)}><ChevronRight/></div>
        <div className='dp-change-button' onClick={() => this.setYear(1)}><ChevronsRight/></div>
      </HorizontalLayout>
      <div className='dp-week-days-container'>
        {['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'].map((d, i) => <div key={i} className='col-span-1 text-cn-color-blue-690'>{d}</div>)}
      </div>
      <div className='dp-days-container'>
        {this.state.days}
      </div>
    </VerticalLayout>)
  }
}

DatePicker.propTypes = {
  datetime: PropTypes.objectOf(Date), onChange: PropTypes.func, className: PropTypes.string, styleValue: PropTypes.object
}

export default DatePicker




/**
 * class ClientManager
 */
class GF {

  static PASSWORD_PLACEHOLDER = '********';
  static STD_ALERT_MESSAGE = 'Es ist ein Fehler aufgetreten! Bitte melden Sie Sich bei der Vögeli AG';

  static addClass(classString, value) {
    let classElements = classString.split(' ');
    classElements.push(value);
    return classElements.join(' ');
  }

  static cloneObject(object) {
    let objectNew = {};
    for (let i in object) {
      objectNew[i] = object[i];
    }
    return objectNew;
  }

  static checkNotNull(value) {
    return value !== undefined && value !== null;
  }

  static invoke(callable, ...args) {
    if (GF.checkNotNull(callable) && typeof callable === 'function') {
      return callable(...args);
    }
    return undefined;
  }

  static checkNotNUllAndNotEmpty(value) {
    return this.checkNotNull(value) && value !== '';
  }

  static dateTimeToString(dateTime) {
    if (dateTime !== undefined && dateTime !== null && typeof dateTime === 'object') {
      let day = dateTime.getDate();
      let month = dateTime.getMonth();
      let year = dateTime.getFullYear();
      let hour = dateTime.getHours();
      let minute = dateTime.getMinutes();
      return GF.buildDateTimeString(day, month, year, hour, minute);
    }
    return dateTime;
  }

  static buildDateString(day, month, year) {
    let date = '';
    if (day !== undefined && day !== '') {
      date = day.toString().padStart(2, '0') + '.' + (month + 1).toString().padStart(2, '0') + '.' +
        year.toString().padStart(4, '0');
    }
    return date;
  }

  static buildDateTimeString(day, month, year, hour, minute) {
    let date = GF.buildDateString(day, month, year);
    let time = '';
    if (hour !== undefined && minute !== undefined) {
      time = hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0');
    }
    return date + (date !== '' ? ' ' + time : time);
  }

  static getValue(propsValue, defaultValue = undefined) {
    if (propsValue !== undefined && propsValue !== null) {
      return propsValue;
    }
    return defaultValue;
  }

  static includes(val1, val2) {
    return val1.toLowerCase().includes(val2.toLowerCase());
  }

  static isBitSet(value, flag) {
    return (!!(value & flag));
  }

  static removeClass(classString, value) {
    let classElements = classString.split(' ');
    let classElementsNew = [];
    for (let classElement of classElements) {
      if (classElement !== value) {
        classElementsNew.push(classElement);
      }
    }
    return classElementsNew.join(' ');
  }

  static removeFromArray(array, key) {
    key = parseInt(key);
    let arrayNew = null;
    if (Array.isArray(array)) {
      arrayNew = [];
      for (let i = 0; i < array.length; i++) {
        if (key !== i) {
          arrayNew.push(array[i]);
        }
      }
    }
    return arrayNew;
  }

  static stringToDateTime(dateTimeString) {
    let newDateTime = null;
    if (typeof dateTimeString === 'string') {
      let dateTimeParts = dateTimeString.split(' ');
      let dateParts = [];
      if (dateTimeParts.length >= 1) {
        dateParts = dateTimeParts[0].split('.');
      }
      let result = {timeHour: 0, timeMinute: 0};
      if (dateParts.length === 3) {
        if (dateTimeParts.length === 2) {
          result = GF.splitTime(dateTimeParts[1]);
        }
        newDateTime = new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]),
          result.timeHour, result.timeMinute);
      } else if (dateParts.length === 1 && dateParts[0] !== '') {
        result = GF.splitTime(dateTimeString);
        newDateTime = new Date(0, 0, 0, result.timeHour, result.timeMinute);
      }
    }
    return newDateTime;
  }

  static splitTime(timeString) {
    let timeHour = 0;
    let timeMinute = 0;
    let timeParts = timeString.split(':');
    if (timeParts.length >= 2) {
      timeHour = parseInt(timeParts[0]);
      timeMinute = parseInt(timeParts[1]);
    }
    return {timeHour: timeHour, timeMinute: timeMinute};
  }

  static takeElementFromArray(array, key = -1) {
    let element = null;
    if (Array.isArray(array) && array.length > 0) {
      if (key === -1) {
        element = array[array.length - 1]; // dann nimm letzter Array-Eintrag
      } else {
        if (key >= 0 && key <= array.length - 1) {
          element = array[key];
        } else {
          if (key > array.length - 1) {
            element = GF.takeElementFromArray(array, array.length - 1);
          } else {
            throw new DOMException('Der Index [' + key + '] liegt ausserhalb des Arrays');
          }
        }
      }
    }
    return element;
  }

  static getParamValueFromURL(param) {
    let params = window.location.search.substring(1);
    let vars = params.split("&");
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      if (pair[0] === param) {
        return pair[1];
      }
    }
    return '';
  }

  static isImageFile(fileName) {
    // Define a list of common image file extensions
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

    // Get the file extension by splitting the fileName and getting the last part
    const fileExtension = fileName.split('.').pop().toLowerCase();

    // Check if the file extension is in the list of image extensions
    return imageExtensions.includes(fileExtension);
  }

  static findReactFiberNode(e) {
    let foundetElement = null;
    for (let index in e.currentTarget) {
      if (index.includes('__reactFiber')) {
        foundetElement = e.currentTarget[index];
        break;
      }
    }
    return foundetElement;
  }

  static findReactFiberProps(e) {
    let foundetProps = null;
    for (let index in e.currentTarget) {
      if (index.includes('__reactProps')) {
        foundetProps = e.currentTarget[index];
        break;
      }
    }
    return foundetProps;
  }
}

export default GF;

import BaseComponent from '../BaseComponent';
import './ColorPicker.css';

import {Circle, Hue, Material, Saturation} from '@uiw/react-color';
import {hexToHsva, hsvaToHex} from '@uiw/color-convert'

import {XCircle} from 'react-feather';
import PropTypes from "prop-types";
import VerticalLayout from "../layouts/VerticalLayout";
import HorizontalLayout from "../layouts/HorizontalLayout";

/**
 * @property string   hex: Farbwert als Hex-Wert: Bsp. #225588
 * @property string[] colors: Schnell-Auswahlfarben: Bsp. ['#f44e3b', '#fe9200', '#fcdc00', '#dbdf00']
 */
class ColorPicker extends BaseComponent {

  constructor(props) {
    super(props);
    let hsva = hexToHsva(this.props.hex);
    this.state = {
      color: props.color, hsva: hsva, hex: this.props.hex
    };
    this.colors = ['#ffffff', '#000000', '#6f00ff', '#4b0082'];
    if (props.colors !== undefined && props.colors !== null) {
      this.colors = this.colors.concat(this.props.colors);
    }
  }

  createColorPicker() {
    return <div ref={this.colorPickerRef} className="min-w-[300px] max-w-[340px]">
      <VerticalLayout className="bg-cn-color-black cn-border-radius-10 p-3 gap-2 cn-shadow-button">
        <HorizontalLayout className="justify-between">
          <div className="font-cn-inter-regular">Color Picker</div>
          {/*<button className="justify-self-end" onClick={(event) => {*/}
          {/*  this.state.showColorPicker = false;*/}
          {/*  this.setState(this.state);*/}
          {/*}}>*/}
          {/*  <XCircle className="text-cn-color-white-960"></XCircle>*/}
          {/*</button>*/}
        </HorizontalLayout>
        <Saturation
          hsva={this.state.hsva}
          onChange={(newColor) => {

            this.state.hsva = {...this.state.hsva, ...newColor, a: this.state.hsva.a};
            this.state.hex = hsvaToHex(this.state.hsva);
            this.onPickerChange();
            this.setState(this.state);
          }}
        />
        <Hue
          hue={this.state.hsva.h}
          onChange={(newHue) => {
            this.state.hsva = {h: newHue.h, s: this.state.hsva.s, v: this.state.hsva.v, a: this.state.hsva.a};
            this.state.hex = hsvaToHex(this.state.hsva);
            this.onPickerChange();
            this.setState(this.state);
          }}
        />
        {/*<Alpha*/}
        {/*    hsva={this.state.hsva}*/}
        {/*    onChange={(newAlpha) => {*/}
        {/*        console.log(newAlpha)*/}
        {/*        this.state.hsva = {h: this.state.hsva.h, s: this.state.hsva.s, v: this.state.hsva.v, a: newAlpha.a};*/}
        {/*        this.state.hex = hsvaToHex(this.state.hsva);*/}
        {/*        this.setState(this.state);*/}
        {/*    }}*/}
        {/*/>*/}

        <Material
          color={this.state.hex}
          onChange={(color) => {
            this.state.hex = color.hex;
            this.state.hsva = color.hsva;
            this.onPickerChange();
            this.setState(this.state);
          }}
        />

        <Circle
          className="mt-2"
          colors={this.colors}
          color={this.state.hex}
          onChange={(color) => {
            this.state.hex = color.hex;
            this.state.hsva = color.hsva;
            this.onPickerChange();
            this.setState(this.state);
          }}
        />
      </VerticalLayout>
    </div>;
  }

  onPickerChange() {
    if (this.props.onChange) {
      this.props.onChange(this.state.hex);
    }
  }

  render() {
    return this.createColorPicker()
  }
}

ColorPicker.propTypes = {
  onChange: PropTypes.func, hex: PropTypes.string, colors: PropTypes.arrayOf(PropTypes.string)
}
export default ColorPicker

import CM from "../CM";

function Translate(t) {
  return CM.t(t);
}

const Translator = {
  label_enter_time: () => Translate('label_enter_text'),
  text1: () => Translate('text1'),
  text2: () => Translate('text2'),
  landing_test_label_text: () => Translate('landing_test_label_text'),
  label_add: () => Translate('label_add'),
  label_create: () => Translate('label_create'),
  label_cancel: () => Translate('label_cancel'),
  label_discard: () => Translate('label_discard'),
  label_save: () => Translate('label_save'),
  label_edit: () => Translate('label_edit'),
  label_delete: () => Translate('label_delete'),
  label_import: () => Translate('label_import'),
  label_do_import: () => Translate('label_do_import'),
  label_export: () => Translate('label_export'),
  label_empty: () => Translate('label_empty'),
  label_copy: () => Translate('label_copy'),
  label_duplicate: () => Translate('label_duplicate'),
  label_clone: () => Translate('label_clone'),
  label_update: () => Translate('label_update'),
  label_help: () => Translate('label_help'),
  label_settings: () => Translate('label_settings'),
  label_system: () => Translate('label_system'),
  label_account: () => Translate('label_account'),
  label_omsOverview: () => Translate('label_omsOverview'),
  label_filter_editing: () => Translate('label_filter_editing'),
  label_filter_closed: () => Translate('label_filter_closed'),
  label_filter_published: () => Translate('label_filter_published'),
  label_filter_maintenance: () => Translate('label_filter_maintenance'),
  label_filter_open_simple: () => Translate('label_filter_open_simple'),
  label_filter_open_extended: () => Translate('label_filter_open_extended'),
  label_create_new_campaign: () => Translate('label_create_new_campaign'),
  label_option_not_found: () => Translate('label_option_not_found'),
  label_hour: () => Translate('label_hour'),
  label_search: () => Translate('label_search'),
  label_reset_filter: () => Translate('reset_filter'),
  label_tables: () => Translate('label_tables'),
  label_variant: () => Translate('label_variant'),
  label_functions: () => Translate('label_functions'),
  label_activate_analytics: () => Translate('label_activate_analytics'),
  label_activities: () => Translate('label_activities'),
  label_overview: () => Translate('label_overview'),
  label_minute: () => Translate('label_minute'),
  action_change_thumbnail: () => Translate('action_change_thumbnail'),
  action_logout: () => Translate('action_logout'),
  action_overwrite: () => Translate('action_overwrite'),
  action_synchronize: () => Translate('action_synchronize'),
  component_upload_hint: () => Translate('component_upload_hint'),
  genericErrorMessage: () => Translate('genericErrorMessage'),
  ext_filter_eq_label: () => Translate('ext_filter_eq_label'),
  ext_filter_neq_label: () => Translate('ext_filter_neq_label'),
  ext_filter_gt_label: () => Translate('ext_filter_gt_label'),
  ext_filter_lt_label: () => Translate('ext_filter_lt_label'),
  ext_filter_geq_label: () => Translate('ext_filter_geq_label'),
  ext_filter_leq_label: () => Translate('ext_filter_leq_label'),
  ext_filter_like_label: () => Translate('ext_filter_like_label'),
  ext_filter_and_label: () => Translate('ext_filter_and_label'),
  ext_filter_or_label: () => Translate('ext_filter_or_label'),
  ext_filter_null_label: () => Translate('ext_filter_null_label'),
  ext_filter_not_null_label: () => Translate('ext_filter_not_null_label'),
};

export default Translator;

import React, {Component, createRef} from 'react';
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import TabContainerHorizontal from "../../../../lib/components/tabbar/TabContainerHorizontal";
import TabBarHorizontal from "../../../../lib/components/tabbar/TabBarHorizontal";
import TabContent from "../../../../lib/components/tabbar/TabContent";
import Window from "../../../../lib/components/window/Window";

class Settings extends Component {

  constructor(props) {
    super(props);
    this.tabContainerRef = createRef();
  }

  render() {
    let children = React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {parent: this});
    });
    return <><Window className='text-cn-color-white'>
      <HorizontalLayout className='justify-center flex-wrap'>
        {children}
      </HorizontalLayout>
    </Window>
      <TabContainerHorizontal className='pt-2 h-full' ref={this.tabContainerRef} tabBar={
        <TabBarHorizontal/>} tabContent={<TabContent className='pb-[8px]'/>}/></>

  }
}

export default Settings;

import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import GF from "../../utils/GF";

class Column extends BaseComponent {

    onDoubleClick() {
        // let elements = this.reference.current.childNodes;
        // if (elements !== null) {
        //     for (const elementsKey in elements) {
        //         let element = elements[elementsKey];
        //         element.classList.toggle('hidden');
        //     }
        // }
    }

    render() {
        return <td onDoubleClick={() => this.onDoubleClick()} ref={this.reference} colSpan={this.props.colSpan}
                   width={this.props.width} className={GF.getValue(this.props.className, '')}>
            {this.props.children}
        </td>;
    }
}

Column.propTypes = {
    colSpan: PropTypes.number, width: PropTypes.string, className: PropTypes.string
}
export default Column;
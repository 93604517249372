import FilterManager from "../../../../lib/components/filter/FilterManager";
import PropTypes from "prop-types";
import WizardContentBase from "./WizardContentBase";

class WizardContentSelectList extends WizardContentBase {

  constructor(props) {
    super(props);
    this.state.prevButton = <div/>;
  }

  onChange(e) {
    if (e.record !== null) {
      this.data.setMailingListName(e.record.listName);
    }
  }

  getContent() {
    let entityName = this.props.entityName.toLowerCase().replace('\\', '/');
    return <FilterManager campaignId={this.props.campaignId} entityName={entityName} onChange={(e) => this.onChange(e)} defaultListName={this.data.getMailingListName()} readonly={true} showActionButton={false} showOnlyUseForSending/>;
  }
}

WizardContentSelectList.propTypes = {
  campaignId: PropTypes.any.isRequired,
  entityName: PropTypes.string.isRequired,
  wizardNavRef: PropTypes.any
}

export default WizardContentSelectList;

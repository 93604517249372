import Dto from "../service/Dto";

class DataType extends Dto {

  static BOOLEAN = 'DT_BOOLEAN';
  static INTEGER = 'DT_INTEGER';
  static FLOAT = 'DT_FLOAT';
  static STRING = 'DT_STRING';
  static DATETIME = 'DT_DATETIME';
  static ENUM = 'DT_ENUM';
  static DATA_EXT = 'DT_DATA_EXT';
  static OBJECT = 'DT_OBJECT';
  static MANY_TO_ONE = 'DT_MANY_TO_ONE';

  static ACCESS_MOD_PRIVATE = 'private';
  static ACCESS_MOD_PUBLIC = 'public';

  constructor(accountId = '') {
    super('po2\\types\\DataType');
  }

  static initConstrains(constrain) {
    // TODO: peto, noch programmieren
  }
}
export default DataType
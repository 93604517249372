import {createRef} from "react";
import shortid from "shortid";
import SysNodeIterator from "../../../../lib/model/node/NodeIterator";
import TreeNode from "../../../../lib/components/tree/leftTree/TreeNode";

/**
 * Node
 * Voraussetzungen (interface) für ein Node ist:
 * es muss die Funktion isParentNode und getChildren implementiert werden!
 */
class TreeNodeBuilder extends SysNodeIterator {

  buildChildNodeContent(_workingNode) {
    return <div>ChildNode</div>
  }

  buildParentNodeContent(_workingNode) {
    return <div>ParentNode</div>
  }

  parentNodeCallback(_workingNode, childNodesNew) {
    return <TreeNode key={shortid()} ref={createRef()} content={this.buildParentNodeContent(_workingNode)}>
      {childNodesNew}
    </TreeNode>;
  }

  childNodeCallback(_workingNode) {
    return <TreeNode key={shortid()} ref={createRef()} content={this.buildChildNodeContent(_workingNode)}/>;
  }
}

export default TreeNodeBuilder;

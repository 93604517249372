import BaseComponent from "../../../../../lib/components/BaseComponent";
import DraggableList from "../../../../../lib/components/draggableList/DraggableList";
import DialogBase from "../../../../../lib/components/dialogs/DialogBase";

class DraggableListsTest extends BaseComponent {
  render() {
    let initialList1 = [{id: 1, text: 'Purl'}, {id: 2, text: 'ID'}, {id: 3, text: 'Status'}, {id: 4, text: 'Sprache'},
      {id: 5, text: 'Nachname'}, {id: 6, text: 'Vorname'}, {id: 7, text: 'Firma'},];
    let initialList2 = [{id: 8, text: 'Bezeichnung'}, {id: 9, text: 'Erstellt am'}, {id: 10, text: 'Datum Rückmeldung'},];
    return <DialogBase><DraggableList list1={initialList1} list2={initialList2}/></DialogBase>

  }
}

export default DraggableListsTest;
import Dto from "../../../../../../service/Dto";

class GetMailStatus extends Dto {

  campaignId = '';

  constructor(campaignId = '', _class = 'campaign\\module\\modules\\ustore\\v002\\service\\dto\\GetMailStatus') {
    super(_class);
    this.campaignId = campaignId;
  }

  getCampaignId() {
    return this.campaignId;
  }

  setCampaignId(campaignId) {
    this.campaignId = campaignId;
  }
}

export default GetMailStatus;

import QueryBooleanPredicate from "../../../model/query/BooleanPredicate";
import QueryFieldPredicate from "../../../model/query/FieldPredicate";
import SysNodeIterator from "../../../model/node/NodeIterator";
import Operator from "../../../model/query/Operator";
import FieldName from "../../../model/query/FieldName";

/**
 * Node
 * Voraussetzungen (interface) für ein Node ist:
 * es muss die Funktion isParentNode und getChildren implementiert werden!
 */
class PredicateBuilder extends SysNodeIterator {

  static cmd_remove = 'remove';
  static cmd_addBooleanPredicate = 'addBooleanPredicate';
  static cmd_addFieldPredicate = 'addFieldPredicate';

  constructor(defaultFieldPredicate = null) {
    super();
    if (defaultFieldPredicate === undefined || defaultFieldPredicate === null) {
      defaultFieldPredicate = {fieldNameValue: '', fieldValue: '', operatorValue: Operator.eq, _key: ''};
    }
    this.defaultFieldPredicate = defaultFieldPredicate;
  }

  buildBooleanPredicate(_workingNode, childNodesNew) {
    let predicate = new QueryBooleanPredicate(_workingNode.state.operator, childNodesNew);
    predicate._key = _workingNode._key;
    return predicate;
  }

  buildFieldPredicate(_workingNode) {
    let fieldName = FieldName.create(_workingNode.fieldNameValue);
    fieldName['label'] = _workingNode.fieldLabelValue;
    let [value, operator] = this.handleNullNotNull(_workingNode.fieldValue, _workingNode.operatorValue);

    switch(operator) {
      case Operator.like:
        value = '%' + value + '%';
        break;
      default:
        break;
    }
    let predicate = new QueryFieldPredicate(fieldName, '', value, '', operator);
    predicate._key = _workingNode._key;
    if (_workingNode.hasOwnProperty('props')) {
      predicate.isExistingNode = _workingNode.props.isExistingFilter;
    }
    return predicate;
  }

  handleNullNotNull(value, operator) {
    switch (operator) {
      case Operator.null:
      case Operator.not_null:
        value = '';
        break;
      default:
        break;
    }
    return [value, operator];
  }

  /**
   * Für NodeIterator (interface)
   * @param _workingNode
   * @returns {FieldPredicate}
   * @override
   */
  childNodeCallback(_workingNode) {
    return this.buildFieldPredicate(_workingNode);
  }

  connectChildToParentCallback(childNodesNew, childNodeNew, filter) {
    if (childNodeNew !== null) {
      if (filter !== undefined && filter.hasOwnProperty('cmd')) {
        switch (filter.cmd) {
          case PredicateBuilder.cmd_remove:
            if (filter.key !== childNodeNew._key) {
              childNodesNew.push(childNodeNew);
            }
            break;
          case PredicateBuilder.cmd_addBooleanPredicate:
            if (filter.key === childNodeNew._key) {
              childNodesNew.push(childNodeNew);
              let firstFieldPredicate = this.childNodeCallback(
                this.defaultFieldPredicate);
              childNodesNew.push(
                this.parentNodeCallback({state: {operator: Operator.and}, _key: ''}, [firstFieldPredicate]));
            } else {
              childNodesNew.push(childNodeNew);
            }
            break;
          case PredicateBuilder.cmd_addFieldPredicate:
            if (filter.key === childNodeNew._key) {
              childNodesNew.push(childNodeNew);
              let fieldPredicate = this.childNodeCallback(
                this.defaultFieldPredicate);
              fieldPredicate.isExistingNode = false;
              childNodesNew.push(fieldPredicate);
            } else {
              childNodesNew.push(childNodeNew);
            }
            break;
          default:
            childNodesNew.push(childNodeNew);
            break;
        }
      } else {
        childNodesNew.push(childNodeNew);
      }
    }
  }

  /**
   * Für NodeIterator (interface)
   * * @param _workingNode
   * @param childNodesNew
   * @returns {BooleanPredicate}
   * @override
   */
  parentNodeCallback(_workingNode, childNodesNew) {
    return this.buildBooleanPredicate(_workingNode, childNodesNew);
  }

  prepareWorkingNode(_node) {
    let contentWork;
    let content = _node.ref.current.state.content;

    if (content.ref.current._type === 'BooleanPredicateComponent') {
      contentWork = content.ref.current;
      contentWork._key = content.key;
      contentWork.isParentNode = () => {
        return true;
      };
      contentWork.getChildren = () => {
        return _node.ref.current.state.children;
      }
    } else {
      contentWork = content.ref.current;
      contentWork.isParentNode = () => {
        return false;
      };
      contentWork._key = content.key;
    }
    return contentWork;
  }
}

export default PredicateBuilder;

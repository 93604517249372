import Dto from "../service/Dto";

class Query extends Dto {

  static JOIN_TYPE_INNER = 'INNER JOIN';
  static JOIN_TYPE_LEFT = 'LEFT JOIN';
  static JOIN_TYPE_IN = 'IN';
  static JOIN_TYPE_NOT_IN = 'NOT IN';

  constructor(selectFields = [], predicate = null, queryString = '', sortFields = [], groupBy = [], startRow = 0, endRow = 9, joinType = null, joinConditionString = null, relations = null) {
    super('po2\\service\\entity\\Query');
    this.queryString = queryString;
    this.predicate = predicate;
    this.selectFields = selectFields;
    this.sortFields = sortFields;
    this.groupBy = groupBy;
    this.startRow = startRow;
    this.endRow = endRow;
    this.joinType = joinType;
    this.joinConditionString = joinConditionString;
    this.relations = relations;
  }

  getQueryString() {
    return this.queryString;
  }

  setQueryString(queryString) {
    this.queryString = queryString;
  }

  getPredicate() {
    return this.predicate;
  }

  setPredicate(predicate) {
    this.predicate = predicate;
  }

  getSelectFields() {
    return this.selectFields;
  }

  setSelectFields(selectFields) {
    this.selectFields = selectFields;
  }

  getSortFields() {
    return this.sortFields;
  }

  setSortFields(sortFields) {
    this.sortFields = sortFields;
  }

  getGroupBy() {
    return this.groupBy;
  }

  setGroupBy(groupBy) {
    this.groupBy = groupBy;
  }

  getStartRow() {
    return this.startRow;
  }

  setStartRow(startRow) {
    this.startRow = startRow;
  }

  getEndRow() {
    return this.endRow;
  }

  setEndRow(endRow) {
    this.endRow = endRow;
  }

  getJoinType() {
    return this.joinType;
  }

  setJoinType(joinType) {
    this.joinType = joinType;
  }

  getJoinConditionString() {
    return this.joinConditionString;
  }

  setJoinConditionString(joinConditionString) {
    this.joinConditionString = joinConditionString;
  }

  getRelations() {
    return this.relations;
  }

  setRelations(relations) {
    this.relations = relations;
  }
}

export default Query;

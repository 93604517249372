import PropTypes from "prop-types";
import SysCard from "../../../../lib/components/card/Card";
import CardTitleBar from "./CardTitleBar";
import CardContent from "./CardContent";

class Card extends SysCard {

  render() {
    this.state.className = 'cn-border-radius-20 cn-shadow-window ' + this.state.className;
    this.state.cardTitle = <CardTitleBar>{this.state.cardTitle}</CardTitleBar>;
    this.state.cardContent = <CardContent>{this.state.cardContent}</CardContent>;

    return super.render();
  }
}

Card.propTypes = {
  className: PropTypes.string, cardTitle: PropTypes.any, cardContent: PropTypes.any
}
export default Card;

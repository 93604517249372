import Dto from "../../Dto";

class GetAccountInfo extends Dto {

  constructor(accountId = '') {
    super('po2\\service\\system\\dto\\GetAccountInfo');
    this.accountId = accountId;
  }

  getAccountId() {
    return this.accountId;
  }

  setAccountId(accountId) {
    this.accountId = accountId;
  }

}
export default GetAccountInfo
import BaseComponent from "../../../../../lib/components/BaseComponent";
import FilterManager from "../../../../../lib/components/filter/FilterManager";

class FilterManagerTest extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      mydialog: null
    }
  }

  test(event) {
    this.state.mydialog = <FilterManager onCloseButtonClick={() => {
      this.state.mydialog = null;
      this.setState(this.state);
    }} campaignId={1} entityName={'Contact'}/>
    this.setState(this.state);
  }

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>
      <h1>FilterManager test</h1>
      <button onClick={(event) => this.test(event)}>
        test
      </button>
      <div>
        {this.state.mydialog}
      </div>
    </div>
  }
}

export default FilterManagerTest;

import BaseComponent from "../../../../lib/components/BaseComponent";
import LandingLogin from "./LandingLogin";
import LandingPlan from "./LandingPlan";
import LandingAbout from "./LandingAbout";
import LandingContact from "./LandingContact";
import LandingReferences from "./LandingReferences";
import Nav from "./Nav";
import RouterElement from "../../../../lib/components/router/RouterElement";

class LandingMain extends BaseComponent {

  render() {
    return <div>
      <Nav/>
      <RouterElement route='^$|^landing/login$'><LandingLogin p={this}/></RouterElement>
      {/*<RouterElement route='^landing/plan$'><LandingPlan p={this}/></RouterElement>*/}
      {/*<RouterElement route='^landing/about$'><LandingAbout p={this}/></RouterElement>*/}
      {/*<RouterElement route='^landing/contact$'><LandingContact p={this}/></RouterElement>*/}
      {/*<RouterElement route='^landing/references$'><LandingReferences p={this}/></RouterElement>*/}
    </div>
  }
}

export default LandingMain;

import Dto from "../../Dto";

class SaveAccountImage extends Dto {

  constructor(accountId = '', resourceId = '') {
    super('po2\\service\\system\\dto\\SaveAccountImage');
    this.accountId = accountId;
    this.resource = resourceId;
  }
}
export default SaveAccountImage
import Dto from "../../Dto";

class Duplicate extends Dto {

  constructor(recordsToDuplicate = '', query) {
    super('po2\\service\\entity\\dto\\Duplicate');
    this.recordsToDuplicate = recordsToDuplicate;
    this.query = query;
  }

  getRecordsToDuplicate() {
    return this.recordsToDuplicate;
  }

  setRecordsToDuplicate(recordsToDuplicate) {
    this.recordsToDuplicate = recordsToDuplicate;
  }

  getQuery() {
    return this.query;
  }

  setQuery(query) {
    this.query = query;
  }
}

export default Duplicate;

import BaseComponent from "../BaseComponent";
import PropTypes from "prop-types";
import {createRef} from "react";
import shortid from "shortid";

class TabContainerBase extends BaseComponent {

  constructor(props) {
    super(props);
    this.tabBarRef = createRef();
    this.tabContentRef = createRef();
    this.state = {
      actualKey: 'last'
    };
  }

  addElement(tabElement, contentElement, doSetActive = true, onTabChangeListener = null) {
    let id;
    let idFromElement = (tabElement !== undefined && tabElement !== null && tabElement.props !== undefined &&
    tabElement.props !== null ? tabElement.props.id : null);
    if (idFromElement !== undefined && idFromElement !== null) {
      id = idFromElement;
    } else {
      id = shortid();
    }
    this.tabBarRef.current.addElement(tabElement, id);
    this.tabContentRef.current.addElement(contentElement, id, onTabChangeListener);
    if (doSetActive) {
      this.tabBarRef.current.setActiveElement(id);
      this.tabContentRef.current.setActiveElement(id);
      this.state.actualKey = id;
    }
    this.tabBarRef.current.setState(this.tabBarRef.current.state);
    this.tabContentRef.current.setState(this.tabContentRef.current.state);
    return id;
  }

  clearAll() {
    this.tabBarRef.current.clearAll();
    this.tabContentRef.current.clearAll();
    this.state.actualKey = 'last';
  }

  getActualKey() {
    let actualKey = this.state.actualKey;
    let exists = this.tabBarRef.current.state.tabElements[actualKey];
    // hole letzter index
    if (exists === undefined) {
      for (let index in this.tabBarRef.current.state.tabElements) {
        actualKey = index;
      }
    }
    return actualKey;
  }

  removeElement(keyToRemove) {
    this.tabBarRef.current.removeElement(keyToRemove);
    let actualKey = this.getActualKey();
    this.tabContentRef.current.removeElement(keyToRemove);
    this.tabBarRef.current.setActiveElement(actualKey);
    this.tabContentRef.current.setActiveElement(actualKey);
    this.tabBarRef.current.setState(this.tabBarRef.current.state);
    this.tabContentRef.current.setState(this.tabContentRef.current.state);
    this.state.actualKey = actualKey;
  }

  setActiveElement(id = 'last') {
    //setTimeout(() => {
    id = (id !== 'last' ? id : this.getActualKey());
    this.state.actualKey = id;
    this.tabBarRef.current.setActiveElement(id);
    this.tabContentRef.current.setActiveElement(id);
    this.tabBarRef.current.setState(this.tabBarRef.current.state);
    this.tabContentRef.current.setState(this.tabContentRef.current.state);
    //}, 1);
  }

  doSetState() {
    this.tabBarRef.current.setState(this.tabBarRef.current.state);
    this.tabContentRef.current.setState(this.tabContentRef.current.state);
  }

  overridePropsTab(id, props, doSetState = false) {
    this.tabBarRef.current.overrideProps(id, props);
    if (doSetState) {
      this.tabBarRef.current.setState(this.tabBarRef.current.state);
    }
  }

  overridePropsContent(id, props, doSetState = false) {
    this.tabContentRef.current.overrideProps(id, props);
    if (doSetState) {
      this.tabContentRef.current.setState(this.tabBarRef.current.state);
    }
  }
}

TabContainerBase.propTypes = {
  className: PropTypes.string,
}

export default TabContainerBase;

import BaseComponent from "../../../../../lib/components/BaseComponent";
import PropType from "prop-types";

class LizenceOption extends BaseComponent {

  render() {
    return<p className="text-cn-color-landing-gray01 w-full flex justify-between font-bold">{this.props.label} <span className="text-cn-color-primary text-right">{this.props.value}</span></p>
  }
}

LizenceOption.propTypes = {
  label: PropType.string.isRequired,
  value: PropType.node.isRequired
}

export default LizenceOption;


class Position {

  top = -1;
  left = -1;
  width = -1;
  height = -1;
  z = -1;

  getTop() {
    return this.top;
  }

  setTop(top) {
    this.top = top;
  }

  getLeft() {
    return this.left;
  }

  setLeft(left) {
    this.left = left;
  }

  getWidth() {
    return this.width;
  }

  setWidth(width) {
    this.width = width;
  }

  getHeight() {
    return this.height;
  }

  setHeight(height) {
    this.height = height;
  }

  getZ() {
    return this.z;
  }

  setZ(z) {
    this.z = z;
  }
}

export default Position;

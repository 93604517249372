import {Link2} from 'react-feather';
import PropTypes from "prop-types";
import InputBase from "./InputBase";
import {createRef} from "react";
import GF from "../../../../lib/utils/GF";

/**
 * @property string labelText: Werte=disable/error
 * @property string state: Werte=disable/error
 * @property string helpText: Hilfetext
 * @property string use: Verwendung Werte=table oder leer
 */
class InputLink extends InputBase {

  constructor(props) {
    super(props);
    let href = '';
    let text = '';
    if (GF.checkNotNull(this.props.defaultValue)) {
      if (GF.checkNotNull(this.props.defaultValue.href) && GF.checkNotNull(this.props.defaultValue.text)) {
        href = this.props.defaultValue.href;
        text = this.props.defaultValue.text;
      }
    }
    this.state.defaultValue = {
      href: href, text: text
    };
    this.state.iconRight = <div className='h-full self-center'>
      <a href={href} target='_blank'><Link2 className='mr-3 self-center stroke-cn-color-primary'/></a></div>;
    this.inputElementRef = createRef();
  }

  getValue() {
    return this.inputElementRef.current.value;
  }

  render() {
    if (this.state.state === 'readonly') {
      this.state.children =
        <input type='text' ref={this.inputElementRef} className={'bg-transparent focus:outline-0 w-full h-full ' +
          GF.getValue(this.props.className,
            '')} defaultValue={this.state.defaultValue.text} onChange={(e) => this.fireOnChange(e)} readOnly></input>;
    } else {
      this.state.children =
        <input type='text' ref={this.inputElementRef} className={'bg-transparent focus:outline-0 w-full h-full ' +
          GF.getValue(this.props.className,
            '')} defaultValue={this.state.defaultValue.text} onChange={(e) => this.fireOnChange(e)}></input>;
    }

    return super.render();
  }
}

InputLink.propTypes = {
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  inputLayout: PropTypes.oneOf(['inline', 'outline', '']),
  defaultValue: PropTypes.any,
  onChange: PropTypes.func
}
export default InputLink;

import BaseComponent from "../../../../lib/components/BaseComponent";
import Window from "../../../../lib/components/window/Window";
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import {Plus, Trash2} from "react-feather";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import InputText from "../inputFields/InputText";
import InputDropDown from "../inputFields/dropdown/InputDropDown";
import Menu from "../../../../lib/components/menu/Menu";
import MenuItemGroup from "../../../../lib/components/menu/MenuItemGroup";
import MenuItem from "../../../../lib/components/menu/MenuItem";
import InputPassword from "../inputFields/InputPassword";
import VerticalSeparator from "../seperator/VerticalSeparator";
import ButtonText from "../buttons/ButtonText";
import ButtonSmall from "../buttons/ButtonSmall";
import T from "../../../model/system/text_translations/Translator";

class ProfilePage extends BaseComponent {

  render() {
    return <Window classNameContent='px-16'>
      <HorizontalLayout widthFit className='w-fit gap-16'>
        <div className='w-2/6 justify-self-center'>
          <VerticalLayout className='justify-between h-full'>
            <VerticalLayout>
              <img alt='profile' className='cn-border-radius-8' src='https://picsum.photos/500/500'/>
              <HorizontalLayout widthFit className='justify-between'>
                <button className='flex gap-x-3'>
                  <Plus className='border cn-border-radius p-1'/>
                  <span className='text-cn-color-primary'>Foto hochladen</span>
                </button>
                <button><Trash2 className='border cn-border-radius p-1 text-cn-color-static-red ml-9'/></button>
              </HorizontalLayout>
            </VerticalLayout>
            <VerticalLayout>
              <HorizontalLayout className='justify-between'>
                <div className='self-center'>Ansicht</div>
                <ButtonText className='text-cn-color-static-red'>Zurücksetzen</ButtonText>
              </HorizontalLayout>
              <VerticalLayout className='gap-y-3'>
                <InputDropDown labelElement='Design' defaultValue={{option: 'Dark', displayValue: 'Dark'}} inputLayout='inline'>
                  <Menu>
                    <MenuItemGroup>
                      <MenuItem option='Dark'>Dark</MenuItem>
                      <MenuItem option='Light'>Light</MenuItem>
                    </MenuItemGroup>
                  </Menu>
                </InputDropDown>
                <InputDropDown labelElement='Sprache' defaultValue={{option: 'Deutsch', displayValue: 'Deutsch'}} inputLayout='inline'>
                  <Menu>
                    <MenuItemGroup>
                      <MenuItem option='Deutsch'>Deutsch</MenuItem>
                      <MenuItem option='Französisch'>Französisch</MenuItem>
                      <MenuItem option='Italienisch'>Italienisch</MenuItem>
                      <MenuItem option='Englisch'>Englisch</MenuItem>
                    </MenuItemGroup>
                  </Menu>
                </InputDropDown>
              </VerticalLayout>
            </VerticalLayout>
          </VerticalLayout>
        </div>
        <VerticalLayout widthFit className='gap-y-3 w-2/4 justify-self-center'>
          <h3>Profilinformationen</h3>
          <InputText labelElement='Vorname'/>
          <InputText labelElement='Nachname'/>
          <InputText labelElement='E-Mailadresse'/>
          <InputPassword labelElement='Passwort'/>
        </VerticalLayout>
        <VerticalSeparator />
        <VerticalLayout widthFit className='w-2/4 gap-y-3 justify-self-center'>
          <h3>Musteradresse</h3>
          <InputText labelElement='Firma'/>
          <InputText labelElement='Firmenzusatz'/>
          <InputText labelElement='Adresse'/>
          <InputText labelElement='PLZ'/>
          <InputText labelElement='Ort'/>
          <InputText labelElement='E-Mailadresse'/>
          <InputText labelElement='Telefon'/>
        </VerticalLayout>
      </HorizontalLayout>
      <HorizontalLayout className='justify-end gap-x-5 mt-5'>
        <ButtonSmall color='light'>{T.label_cancel()}</ButtonSmall>
        <ButtonSmall color='secondary'>{T.label_save()}</ButtonSmall>
      </HorizontalLayout>
    </Window>;
  }

}

export default ProfilePage;
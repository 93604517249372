import BaseComponent from "../BaseComponent";
import PropTypes from "prop-types";
import HorizontalLayout from "../layouts/HorizontalLayout";

class ButtonGroupStd extends BaseComponent {

  render() {
    let classes = '';
    classes = this.props.className ? classes + this.props.className : classes;

    return <HorizontalLayout widthFit className={classes}>
      {this.props.children}
    </HorizontalLayout>
  }
}

ButtonGroupStd.propTypes = {
  className: PropTypes.string
}

export default ButtonGroupStd;

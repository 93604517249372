import React, {createRef} from 'react';
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";
import ButtonGroup from "../buttons/ButtonGroup";
import ButtonSmall from "../buttons/ButtonSmall";
import SelectableDragList from "../../../../lib/components/selectableDragList/SelectableDragList";
import './permissions.css'
import HorizontalLayout from "../../../../lib/components/layouts/HorizontalLayout";
import PropTypes from "prop-types";
import GF from "../../../../lib/utils/GF";
import BaseComponent from "../../../../lib/components/BaseComponent";

class Permissions extends BaseComponent {

  constructor(props) {
    super(props);
    this.selectableDragListRef = createRef();
  }

  componentDidMount() {
    this.selectableDragListRef.current.setDefaultSelectedTargets();
  }

  saveSelectedPermissions(e) {

    let selectedElements = this.selectableDragListRef.current.getData();

    let privileges = {};
    for (let index in this.props.children) {
      let permission = this.props.children[index];

      let found = false;
      for (let elementId in selectedElements) {
        if (permission.props.elementId === elementId) {
          found = true;
        }
      }
      privileges[permission.props.elementId] = {connected: found};
    }
    if (GF.checkNotNull(this.props.onSave)) {
      this.props.onSave(privileges);
    }
  }

  render() {
    let buttonGroup;
    if (this.props.withButtonGroup) {
      buttonGroup = <ButtonGroup color={'dark'} delimiterColor={'primary'} className='my-4'>
        {/*<ButtonSmall color='dark' id={"admin"}>Admin</ButtonSmall>*/}
        {/*<ButtonSmall color='dark' id={"basevariables"}>Standart mit Variablen</ButtonSmall>*/}
        {/*<ButtonSmall color='dark' id={"base"}>Standart ohne Variablen</ButtonSmall>*/}
      </ButtonGroup>;
    } else {
      buttonGroup = <></>;
    }
    let title = 'Berechtigungen';
    if (GF.checkNotNull(this.props.mode) && this.props.mode !== '') {
      switch (this.props.mode) {
        case 'user':
          title = 'Berechtigungen (Benutzer)';
          break;
        case 'userGroup':
        default:
          title = 'Berechtigungen (Benutzergruppe)';
      }
    }
    return (<>
      <VerticalLayout>
        <h2 className='text-cn-color-white'>{title}</h2>
        {buttonGroup}
        <SelectableDragList ref={this.selectableDragListRef}>
          <VerticalLayout>
            <HorizontalLayout className='gap-1 flex-wrap text-cn-color-static-white'>
              {this.props.children}
            </HorizontalLayout>
          </VerticalLayout>
        </SelectableDragList>
      </VerticalLayout>
      <HorizontalLayout className='justify-end gap-x-5 mt-5'>
        <ButtonSmall color='secondary' onClick={(e) => this.saveSelectedPermissions(e)}>Berechtigungen
                                                                                        speichern</ButtonSmall>
      </HorizontalLayout>
    </>);
  }
}

Permissions.propTypes = {
  withButtonGroup: PropTypes.bool, onSave: PropTypes.func, mode: PropTypes.string
}
export default Permissions;

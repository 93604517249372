import React, {createRef} from "react";
import GF from "../../../../../lib/utils/GF";
import Column from "../../../../../lib/components/table/Column";
import shortid from "shortid";
import Row from "../../../../../lib/components/table/Row";
import SimpleFilterFieldGenerator from "../fields/SimpleFilterFieldGenerator";
import Operator from "../../../../../lib/model/query/Operator";
import DataType from "../../../../../lib/model/types/DataType";
import FieldName from "../../../../../lib/model/query/FieldName";
import FieldPredicate from "../../../../../lib/model/query/FieldPredicate";
import BooleanPredicate from "../../../../../lib/model/query/BooleanPredicate";
import TypePostProcess from "../../../../../lib/model/query/TypePostProcess";
import Listener from "../../../../../lib/model/system/Listener";

class SimpleFilterBuilder {

  constructor(entityObject) {
    this.simpleFilterFieldGenerator = new SimpleFilterFieldGenerator((e, _id) => this.onSimpleFilterChange(e, _id),
      'outline', (id, value) => this.onDateTimePickerClose(id, value));
    this.entityObject = entityObject;
    this.addOnReadDoneListener = new Listener((e) => this.onReadDone(e));
    this.eventInitialised = false;
    this.simpleFilterFieldList = [];
    this.simpleFilterTimerActive = 0;
    this.actualFieldId = null;
  }

  buildFilterRow() {
    let columns = [];
    this.simpleFilterFieldList = [];
    for (let columnName in this.entityObject.config.columns) {
      let column = this.entityObject.config.columns[columnName];

      let tempRef = createRef();
      let field = this.simpleFilterFieldGenerator.initField(column.type, columnName,
        GF.getValue(column.simpleFieldValue, null), null, tempRef);
      this.simpleFilterFieldList.push(tempRef);
      columns.push(
        <Column _id={columnName} key={shortid()} className='p-0.5 min-w-[100px]' colSpan={1}>{field}</Column>);
    }
    return <Row key={shortid()} className='bg-cn-color-black' rowType={Row.ROW_TYPE_SIMPLE_FILTER}>
      {columns}
    </Row>;
  }

  buildFilterQuery() {
    let query = null;
    if (this.simpleFilterFieldList.length > 0) {
      let fieldPredicates = [];
      for (let index in this.simpleFilterFieldList) {
        let simpleFilterFieldRef = this.simpleFilterFieldList[parseInt(index)];
        let id = simpleFilterFieldRef.current.getId();
        let value = simpleFilterFieldRef.current.getValue();
        this.entityObject.config.columns[id].simpleFieldValue = value;
        if (value !== '' && value !== 'null' && value !== null) {
          let fieldType = this.entityObject.getFieldType(id);
          let fieldPredicate = null;
          switch (fieldType.type) {
            case DataType.BOOLEAN:
              fieldPredicate = new FieldPredicate(FieldName.create(id), '', value, '', Operator.eq);
              break;
            case DataType.DATETIME:
              fieldPredicate = new FieldPredicate(FieldName.create(id), '', value, '', Operator.eq);
              fieldPredicate = TypePostProcess.processDateTimeAsDate(fieldPredicate);
              break;
            default:
              fieldPredicate = new FieldPredicate(FieldName.create(id), '', '%' + value + '%', '', Operator.like);
          }
          fieldPredicates.push(fieldPredicate);
        }
      }
      let booleanPredicate = new BooleanPredicate(Operator.and, fieldPredicates);
      query = this.entityObject.dataSource.getLastQuery();
      if (query !== null) {
        query.setPredicate(booleanPredicate)
      } else {
        throw new DOMException('kein Query vorhanden!');
      }
    }
    if (!this.eventInitialised) {
      this.eventInitialised = true;
      this.entityObject.dataSource.dataSourceEvents.addOnReadDoneListener(this.addOnReadDoneListener);
    }
    return query;
  }

  onReadDone() {
    // einen Zyklus warten, weil zuerst die Tabelle wieder gerendert werden muss.
    setTimeout(() => {
      for (let index in this.simpleFilterFieldList) {
        let element = this.simpleFilterFieldList[index];
        if (element.current !== null && element.current._id === this.actualFieldId) {
          element.current.setFocus();
          break;
        }
      }
    }, 1);
  }

  onDateTimePickerClose(id, value) {
    this.onSimpleFilterChange(null);
  }

  onSimpleFilterChange(e, _id) {
    let self = this;
    this.actualFieldId = _id;
    setTimeout(() => {
      this.simpleFilterTimerActive--;
      if (this.simpleFilterTimerActive === 0) {
        this.entityObject.doReadTable(self.buildFilterQuery());
      }
    }, 500);
    this.simpleFilterTimerActive++;
  }
}

export default SimpleFilterBuilder;

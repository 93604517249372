import BaseComponent from "../BaseComponent";
import HorizontalLayout from "../layouts/HorizontalLayout";
import {ChevronRight} from "react-feather";
import React from "react";

class BreadCrumbBox extends BaseComponent {

  render() {

    let childCount = this.props.children.length;
    let children = React.Children.map(this.props.children, (child, index) => {
      let separator = <ChevronRight></ChevronRight>;
      if (index + 1 < childCount) {
        return <div key={index} className='flex items-center mt-3'>{child}{separator}</div>
      } else {
        return <div key={index} className='flex mt-3'>{child}</div>
      }
    });

    return <HorizontalLayout className='flex-wrap'>
      {children}
      {/*{this.props.children}*/}
    </HorizontalLayout>

  }
}

export default BreadCrumbBox;

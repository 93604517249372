import FieldName from "../../query/FieldName";
import Query from "../../query/Query";

class DataSourceLink {

  constructor(parentDataSource, childDataSource, relation, notExists = false) {
    this.parentDataSource = parentDataSource;
    this.childDataSource = childDataSource;
    this.relation = relation;
    this.notExists = notExists;
    this.parentKeyFields = null;
    this.rowChangeBuffer = null;
    this.initDataSourceLink();
  }

  fetchChildDataSource(parentRecord) {
    if (parentRecord !== undefined && parentRecord != null) {
      let joinConditionString = '';

      for (let i = 0; i < this.parentKeyFields.length; i++) {
        let parentKeyField = this.parentKeyFields[i];
        let parentKeyFieldValue = this.getKeyFieldValue(parentRecord, parentKeyField);
        if (joinConditionString !== '') {
          joinConditionString += ' && ';
        }
        joinConditionString = parentKeyField + ' = ' + parentKeyFieldValue;
      }
      let query = new Query();
      query.relations = [this.relation];
      query.joinConditionString = joinConditionString;
      query.joinType = (this.notExists ? Query.JOIN_TYPE_NOT_IN : Query.JOIN_TYPE_INNER);
      this.childDataSource.read(query);
    }
  }

  getKeyFieldValue(record, sourceKeyField) {
    if (record.hasOwnProperty(sourceKeyField)) {
      return record[sourceKeyField];
    }
    return null;
  };

  buildLinkFields() {
    this.parentKeyFields = [];

    // hole key-fields
    for (let fieldName in this.parentDataSource.entityDef.fieldsDef) {
      let fieldDef = this.parentDataSource.entityDef.fieldsDef[fieldName];
      if (fieldDef.type.isKey) {
        this.parentKeyFields.push(
          (new FieldName(fieldDef.name, this.parentDataSource.entityDef.baseDef.name)).getFullFieldName());
      }
    }

    if (this.rowChangeBuffer != null) {
      this.fetchChildDataSource(this.rowChangeBuffer);
    }
  };

  describeDoneListener() {
    this.childDataSource.getDataSourceEvents().onDescribeDoneListeners.removeListener(this.describeDoneListener);
    this.buildLinkFields();
  };

  onRowChangeListener(event) {
    if (this.parentKeyFields != null) {
      this.fetchChildDataSource(event.record);
    } else {
      this.rowChangeBuffer = event.record;
    }
  };

  initDataSourceLink() {
    if (this.childDataSource.entityDef !== undefined && this.childDataSource.entityDef != null) {
      this.buildLinkFields();
    //} else {
      //this.childDataSource.getDataSourceEvents().addOnDescribeDoneListener(this.describeDoneListener);
    }
    if (this.parentDataSource.getCurrentRecIds().length > 0) {
      this.fetchChildDataSource(this.parentDataSource.getCurrentRecords()[0]);
    }
    this.parentDataSource.getDataSourceEvents().addOnRowChangeListener((e) => this.onRowChangeListener(e));
  };

  destroy() {
    this.childDataSource.getDataSourceEvents().onDescribeDoneListeners.removeListener(this.describeDoneListener);
    this.parentDataSource.getDataSourceEvents().onRowChangedListeners.removeListener(this.onRowChangeListener);
  }

  getChildDataSource(childDataSource) {
    return this.childDataSource;
  };

  getParentDataSource(parentDataSource) {
    return this.parentDataSource;
  };

  setChildDataSource(childDataSource) {
    this.childDataSource = childDataSource;
  };

  setParentDataSource(parentDataSource) {
    this.parentDataSource = parentDataSource;
  };
}

export default DataSourceLink;

export default function LogoCnerSvg(props) {
  return <svg width="40" height="50" viewBox="0 0 55 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1175_19889)">
      <path d="M33.2406 -3.87111e-05C38.4716 0.158498 43.7336 1.51825 48.1855 3.93899C48.9399 4.34752 49.0759 5.35972 48.47 5.95728L36.3508 17.9085C35.3244 18.9207 33.9331 19.4512 32.4863 19.4512C29.2153 19.4573 25.9506 20.6829 23.4587 23.1402C20.8185 25.7439 19.5818 29.2073 19.7302 32.6219C19.7735 33.6402 19.3901 34.6341 18.6605 35.3536L6.60935 47.2378C5.7066 48.128 4.17933 47.9024 3.59811 46.7865C1.30412 42.3963 0.104571 37.5853 -0.000544392 32.7561C-0.0129109 32.1158 0.240602 31.5 0.698163 31.0487L31.4846 0.688986C31.9483 0.231669 32.5852 -0.0183314 33.2406 -3.87111e-05Z" fill="#27CCBC"/>
      <path d="M2.58455 27.4088L27.806 2.53681C28.6531 1.70144 27.905 0.286806 26.724 0.494123C20.2377 1.63437 14.2647 4.68924 9.50361 9.38436C4.64357 14.177 1.65088 20.1161 0.513162 26.3295C0.296748 27.5002 1.73126 28.2441 2.58455 27.4088Z" fill="#27CCBC"/>
      <path d="M42.3116 41.7379C41.9035 41.3355 41.2543 41.3111 40.8214 41.6769C35.7945 45.9086 28.2076 45.683 23.4589 41.0001C23.2239 40.7684 22.9951 40.5245 22.7849 40.2806C22.0058 39.3842 20.6208 39.3294 19.7737 40.1586L8.70564 51.0733C7.92036 51.8476 7.908 53.0916 8.66235 53.8903C8.9406 54.183 9.21885 54.4696 9.50946 54.7562C13.1328 58.3294 17.4549 60.9513 22.1728 62.5062C23.4836 62.9391 24.9305 62.5915 25.9136 61.6281L42.3178 45.4513C43.3504 44.4269 43.3504 42.7623 42.3116 41.7379Z" fill="#27CCBC"/>
      <path d="M54.6166 8.52432C54.4126 8.34139 54.2147 8.15847 54.0045 7.98164C53.5222 7.56091 52.7926 7.59139 52.3412 8.03652L39.9067 20.2987C39.4615 20.7377 39.5048 21.4755 40.018 21.8414C40.3024 22.0487 40.5868 22.2682 40.8589 22.4999C41.267 22.8475 41.8853 22.8231 42.2687 22.4451L54.6414 10.2438C55.1237 9.76822 55.1175 8.98774 54.6166 8.52432Z" fill="#27CCBC"/>
      <path d="M47.8022 49.921L35.0832 62.4637C34.4773 63.0613 34.9658 64.0735 35.819 63.9881C42.701 63.3052 49.1192 60.5064 54.2884 55.9089C54.9748 55.2991 55.0057 54.2381 54.3503 53.5918L50.6218 49.9149C49.8365 49.1588 48.5813 49.1588 47.8022 49.921Z" fill="#27CCBC"/>
    </g>
    <defs>
      <clipPath id="clip0_1175_19889">
        <rect width="55" height="64" fill="white"/>
      </clipPath>
    </defs>
  </svg>
}


const cner_config = {
  peto: {
    cner_base_url: 'http://cner21.vgmac236.voegeli.int/',
    cner_service_url: 'http://cner20.vgmac236.voegeli.int/service/',
    cner_landing_url: 'http://cner20.vgmac236.voegeli.int/landing/',
    cner_hcaptcha_site_key: '10000000-ffff-ffff-ffff-000000000001'
  }, faha: {
    cner_base_url: 'http://cner21.vgmac222.voegeli.int/',
    cner_service_url: 'http://cner20.vgmac222.voegeli.int/service/',
    cner_landing_url: 'http://cner20.vgmac222.voegeli.int/landing/',
    cner_hcaptcha_site_key: '10000000-ffff-ffff-ffff-000000000001'
  }, raza: {
    cner_base_url: 'http://cner21.vgmac225.voegeli.int/',
    cner_service_url: 'http://cner20.vgmac225.voegeli.int/service/',
    cner_landing_url: 'http://cner20.vgmac225.voegeli.int/landing/',
    cner_hcaptcha_site_key: '10000000-ffff-ffff-ffff-000000000001'
  }, aika: {
    cner_base_url: 'http://cner21.vgmac228.voegeli.int/',
    cner_service_url: 'http://cner20.vgmac228.voegeli.int/service/',
    cner_hcaptcha_site_key: '10000000-ffff-ffff-ffff-000000000001'
  }, prod: {
    cner_base_url: 'https://campaigner.voegeli.ch/',
    cner_service_url: 'https://campaigner20.voegeli.ch/service/',
    cner_landing_url: 'https://campaigner20.voegeli.ch/landing/',
    cner_hcaptcha_site_key: 'ab3e26c6-bfe4-4aa8-8cb7-1fe1e5071d83'
  }
}

export default cner_config;

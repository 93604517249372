import PropTypes from "prop-types";
import InputBase from "./InputBase";
import {createRef} from "react";
import shortid from "shortid";
import ConstrainBase from "../../../model/validate/ConstrainBase";
import InputStateType from "../../../../lib/components/types/StateType";
import './container/Container.css';

class InputEmail extends InputBase {

  constructor(props) {
    super(props);
    this.inputElementRef = createRef();
    this.constrain = this.props.constrain;
  }

  getValue() {
    return this.inputElementRef.current.value;
  }

  render() {
    let inputField;
    if (this.state.state === InputStateType.disable || this.state.state === InputStateType.readonly) {
      inputField =
        <input key={shortid()} ref={this.inputElementRef} type='email' placeholder={this.props.placeholder} className={'bg-transparent focus:outline-0 w-full h-full ' +
          this.props.className} onBlur={(e) => this.onBlur(e)} onChange={(e) => this.fireOnChange(e,
          e.target.value)} defaultValue={this.state.defaultValue} disabled/>;
    } else {
      inputField =
        <input key={shortid()} ref={this.inputElementRef} type='email' placeholder={this.props.placeholder} className={'bg-transparent focus:outline-0 w-full h-full ' +
          this.props.className} onBlur={(e) => this.onBlur(e)} onChange={(e) => this.fireOnChange(e,
          e.target.value)} defaultValue={this.state.defaultValue}/>;
    }
    this.state.children = [inputField];

    return super.render();
  }
}

InputEmail.propTypes = {
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  labelElement: PropTypes.any,
  helpTextElement: PropTypes.node,
  state: PropTypes.oneOf(['none', 'enable', 'disable', 'error', 'readonly']),
  inputLayout: PropTypes.oneOf(['inline', 'outline', '']),
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  constrain: PropTypes.objectOf(ConstrainBase),
  placeholder: PropTypes.string
}
export default InputEmail;

import React from 'react';
import BaseComponent from '../BaseComponent';
import PropTypes from 'prop-types';
import DialogBase from "./DialogBase";

class AlertDialog extends BaseComponent {

  onCloseButtonClick(e) {
    if (this.props.onCloseButtonClick !== undefined) {
      this.props.onCloseButtonClick(e);
    }
  }

  render() {
    return <DialogBase title={this.props.errorTitle} onCloseButtonClick={(e) => this.onCloseButtonClick(
      e)} className={'cn-shadow-red border-cn-color-static-red ' + this.props.alertClass}>
      <div className='text-cn-color-static-red'>
        {this.props.errorMessage}
      </div>
    </DialogBase>;
  }
}

AlertDialog.propTypes = {
  errorTitle: PropTypes.string, errorMessage: PropTypes.any, alertClass: PropTypes.string, onCloseButtonClick: PropTypes.func
};

export default AlertDialog;

import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import React from "react";
import VerticalLayout from "../layouts/VerticalLayout";
import GF from "../../utils/GF";

class Menu extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      filter: null,
      className: GF.getValue(this.props.className, ''),
    }
  }

  onChange = (e, data, displayValue) => {
    if (this.props.onChange !== undefined) {
      this.props.onChange(e, data, displayValue);
    }
  }

  setFilter(filter) {
    this.state.filter = filter;
    this.setState(this.state);
  }

  render() {
    let children = React.Children.map(this.props.children, (child) => {
      if (this.props.onChange !== undefined) {
        return React.cloneElement(child, {onChange: this.onChange, filter: this.state.filter});
      } else {
        return child;
      }
    });
    return <VerticalLayout widthFit className={'gap-3 cn-shadow-button cn-border-radius ' + this.state.className} styleValue={GF.getValue(
      this.props.styleValue, {})}>
      {children}
    </VerticalLayout>;
  }
}

Menu.propTypes = {
  onChange: PropTypes.func, className: PropTypes.string, styleValue: PropTypes.object
}
export default Menu;

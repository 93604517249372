import PropTypes from "prop-types";
import '../Filter.css';
import BaseComponent from "../../../../../lib/components/BaseComponent";

class RadioDot extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      isActive: (this.props.isActive !== undefined ? this.props.isActive : false)
    }
  }

  render() {
    let fillElement;
    if (this.state.isActive) {
      fillElement = <div className='relative top-[2px] left-[2px] w-[24px] h-[24px] rounded-[12px] bg-cn-color-secondary'/>;
    }
    return <div className='w-[30px] h-[30px] rounded-[15px] border-[1px] border-cn-color-secondary'>{fillElement}</div>;
  }
}

RadioDot.propTypes = {
  isActive: PropTypes.bool
}
export default RadioDot;

import BaseComponent from "../../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import Upload from "../../../../../lib/components/Upload";

class UploadTest extends BaseComponent {

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>
      <VerticalLayout className={' self-center'}>
        <h1>Upload</h1>
        <Upload title='Bild hochladen'/>
      </VerticalLayout>
    </div>
  }
}

export default UploadTest;

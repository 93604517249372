import BaseComponent from "../../../../../lib/components/BaseComponent";
import InputText from "../../../../../appl/view/components/inputFields/InputText";
import InputStateType from "../../../../../lib/components/types/StateType";
import InputLayoutType from "../../../../../appl/view/components/inputFields/InputLayoutType";
import InputSearch from "../../../../../appl/view/components/inputFields/InputSearch";

class InputTextFieldTest extends BaseComponent {

  render() {
    return <div className='sandbox-container'>
      <div className='mb-3'>
        <InputText labelElement='Label' helpTextElement='Hilfe' defaultValue='state=none' state={InputStateType.none}></InputText>
      </div>
      <div className='mb-3'>
        <InputText labelElement='Label' helpTextElement='Hilfe' defaultValue='state=enable' state={InputStateType.enable}></InputText>
      </div>
      <div className='mb-3'>
        <InputText labelElement='Label' helpTextElement='Hilfe' defaultValue='state=enable' state={InputStateType.error}></InputText>
      </div>
      <div className='mb-3'>
        <InputText labelElement='Label' helpTextElement='Hilfe' defaultValue='state=disable' state={InputStateType.disable}></InputText>
      </div>
      <div className='mb-3'>
        <InputText labelElement='Label' helpTextElement='Hilfe' defaultValue='state=readonly' state={InputStateType.readonly}></InputText>
      </div>
      <div className='mb-3'>
        <InputSearch defaultValue='state=readonly' state={InputStateType.enable} inputLayout={InputLayoutType.outline}></InputSearch>
      </div>
      <br/>
      <div className='mb-3'>
        <InputText labelElement='Label' helpTextElement='Hilfe' defaultValue='state=none' state={InputStateType.none} inputLayout={InputLayoutType.outline}></InputText>
      </div>
      <div className='mb-3'>
        <InputText labelElement='Label' helpTextElement='Hilfe' defaultValue='state=enable' state={InputStateType.enable} inputLayout={InputLayoutType.outline}></InputText>
      </div>
      <div className='mb-3'>
        <InputText labelElement='Label' helpTextElement='Hilfe' defaultValue='state=enable' state={InputStateType.error} inputLayout={InputLayoutType.outline}></InputText>
      </div>
      <div className='mb-3'>
        <InputText labelElement='Label' helpTextElement='Hilfe' defaultValue='state=disable' state={InputStateType.disable} inputLayout={InputLayoutType.outline}></InputText>
      </div>
      <div className='mb-3'>
        <InputText labelElement='Label' helpTextElement='Hilfe' defaultValue='state=readonly' state={InputStateType.readonly} inputLayout={InputLayoutType.outline}></InputText>
      </div>
    </div>
  }
}

export default InputTextFieldTest;

import Dto from "../../../../../lib/model/service/Dto";

class Create extends Dto {

  constructor(templateId = '', campaignName = '', campaignDescription = '', domainId = '', allowAllUsers = '', thumbnailResourceId = '') {
    super('appl\\service\\campaign\\dto\\Create');
    this.templateId = templateId;
    this.campaignName = campaignName;
    this.campaignDescription = campaignDescription;
    this.domainId = domainId;
    this.allowAllUsers = allowAllUsers;
    this.thumbnailResourceId = thumbnailResourceId;
  }
}

export default Create;

import Checkbox from "../../../../../lib/components/checkbox/Checkbox";
import InputFieldGenerator from "./InputFieldGenerator";
import InputDateTimePicker from "../../inputFields/InputDateTimePicker";
import GF from "../../../../../lib/utils/GF";
import InputText from "../../inputFields/InputText";
import shortid from "shortid";
import BaseFieldGenerator from "./BaseFieldGenerator";
import React from "react";

class SimpleFilterFieldGenerator extends InputFieldGenerator {

  constructor(onChange = null, inputLayout = 'inline', onDateTimePickerClose = null) {
    super(onChange, inputLayout);
    if (onDateTimePickerClose === null) {
      onDateTimePickerClose = (e, id, value) => {
        // dummy
      }
    }
    this.onDateTimePickerCloseFunction = onDateTimePickerClose;
  }

  initBooleanField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    let defaultValue;
    switch (typeof fieldValue) {
      case 'string':
        defaultValue = fieldValue.toLowerCase() === 'ja' || fieldValue.toLowerCase() === 'yes';
        break;
      default:
        defaultValue = fieldValue;
    }
    return <Checkbox _id={id} ref={fieldRef} checked={defaultValue} type='checkboxWithNoneState' labelElement={fieldLabel} onChange={(e, id, value) => this.onChange(
      e, id, value)} inputLayout={this.inputLayout}/>;
  }

  initDateTimePickerField(id, fieldRef, fieldValue, fieldLabel, privilege, useTime) {
    return <InputDateTimePicker _id={id} ref={fieldRef} defaultValue={fieldValue} labelElement={fieldLabel} onClose={(id, value) => this.onDateTimePickerClose(
      id, value)} inputLayout={this.inputLayout} useTime={useTime} canChooseUseTime={false}/>;
  }

  initLinkField(id, fieldRef, fieldValue, fieldLabel, privilege) {
    return this.initTextField(id, fieldRef, fieldValue, fieldLabel, privilege);
  }

  initResourceField(id, fieldRef, fieldValue, fieldLabel, dtoProperties, privilege) {
    return <InputText _id={id} ref={fieldRef} key={shortid()} labelElement={fieldLabel} defaultValue={fieldValue} onChange={(e, id, value) => this.onChange(
      e, id, value)} inputLayout={this.inputLayout} state={BaseFieldGenerator.generateState(privilege)}/>;
  }

  onDateTimePickerClose(id, value) {
    if (GF.checkNotNull(this.onDateTimePickerCloseFunction)) {
      this.onDateTimePickerCloseFunction(id, value);
    }
  }
}

export default SimpleFilterFieldGenerator;

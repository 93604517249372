import Dto from "../../Dto";

class GetDownloadInfo extends Dto {

  constructor(entityName) {
    super('po2\\service\\entity\\dto\\GetDownloadInfo');
    this.entityName = entityName;
  }

  getEntityName() {
    return this.entityName;
  }

  setEntityName(entityName) {
    this.entityName = entityName;
  }
}

export default GetDownloadInfo;

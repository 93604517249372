import BaseComponent from "../../../../../lib/components/BaseComponent";
import VerticalLayout from "../../../../../lib/components/layouts/VerticalLayout";
import AccordionLayout from "../../../../../lib/components/accordion/AccordionLayout";
import AccordionItem from "../../../../../lib/components/accordion/AccordionItem";
import AccordionItemHead from "../../../../../lib/components/accordion/AccordionItemHead";
import {createRef} from "react";

class AccordionTest extends BaseComponent {

  constructor(props) {
    super(props);
    this.accordionItemRef = createRef();
  }

  render() {
    return <div className='sandbox-container flex flex-col gap-4'>
      <VerticalLayout className={' self-center'}>
        <h1>case 01&nbsp;Accordion</h1>
        <AccordionLayout>
          <AccordionItem ref={this.accordionItemRef} opened accordionItemHead={<AccordionItemHead accordionItemRef={this.accordionItemRef} title='Test'/>}>
            <p>Lorem ipsum dolor sit amet, consetetur sadipsci
               ng elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
               erat, sed di.</p>
          </AccordionItem>
        </AccordionLayout>
      </VerticalLayout>
    </div>
  }
}

export default AccordionTest;

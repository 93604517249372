import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import HorizontalLayout from "../layouts/HorizontalLayout";

class BooleanPredicate extends BaseComponent {

  render() {
    return <HorizontalLayout>
      <div className='cn-border-radius border-[1px] border-cn-color-primary-active mr-2'>
        <button className='bg-cn-color-primary rounded-tl-0 rounded-bl-[4px] rounded-rt-0 rounded-rb-0 px-1'>und</button>
        <button className='cn-border-radius px-1'>oder</button>
      </div>
      {/*<button>Umkehren</button>*/}
    </HorizontalLayout>;
  }
}

BooleanPredicate.propTypes = {
  className: PropTypes.string,
  operator: PropTypes.string
}
export default BooleanPredicate;

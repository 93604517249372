import PropTypes from "prop-types";
import '../Filter.css';
import RadioDot from "./RadioDot";
import BaseComponent from "../../../../../lib/components/BaseComponent";
import HorizontalLayout from "../../../../../lib/components/layouts/HorizontalLayout";
import GF from "../../../../../lib/utils/GF";

class FieldPredicate extends BaseComponent {

  constructor(props) {
    super(props);
    this._type = 'FieldPredicateComponent';
    this.fieldValue = GF.getValue(this.props.defaultFieldValue, '');
    this.fieldNameValue = GF.getValue(this.props.defaultFieldNameValue, '');
    this.fieldLabelValue = GF.getValue(this.props.defaultFieldLabelValue, '');
    this.operatorValue = GF.getValue(this.props.defaultOperatorValue, '');

    this.state = {
      fieldSelection: (this.props.fieldSelection !== undefined ? this.props.fieldSelection : undefined),
      operatorSelection: (this.props.operatorSelection !== undefined ? this.props.operatorSelection : undefined),
      valueField: (this.props.valueField !== undefined ? this.props.valueField : undefined),
      commandBar: (this.props.commandBar !== undefined ? this.props.commandBar : undefined),
      isExistingFilter: (this.props.isExistingFilter !== undefined ? this.props.isExistingFilter : false)
    }
  }

  removeSelf(e) {
    this.props.filterBuilder.removeElement(this._reactInternals.key);
  }

  saveToFilterManager(e) {
    this.props.filterBuilder.saveFilter(e);
  }

  addFieldPredicate(e) {
    this.props.filterBuilder.addFieldPredicate(this._reactInternals.key);
  }

  addBooleanPredicate(e) {
    this.props.filterBuilder.addBooleanPredicate(this._reactInternals.key);
  }

  onFieldValueChange(e, option, newDateTime) {
    if (GF.checkNotNull(option)) {
      this.fieldValue = option;
    } else {
      if (GF.checkNotNull(e)) {
        this.fieldValue = e.currentTarget.value;
      } else {
        this.fieldValue = newDateTime;
      }
    }
  }

  onFieldNameValueChange(e, option) {
    this.fieldNameValue = option;
  }

  onFieldLabelValueChange(e, option) {
    this.fieldLabelValue = e.currentTarget.textContent;
  }

  onOperatorValueChange(e, id, value) {
    this.operatorValue = value;
  }

  onUpdateFieldType(e, option) {
    this.state.valueField.ref.current.updateFieldType(e, option);
  }

  render() {
    return <HorizontalLayout id={this._id} className='cn-border-radius-20 border-2 border-cn-color-black cn-shadow-button mb-2'>
      {this.state.commandBar}
      <HorizontalLayout className='cn-border-radius-20 my-2'>
        {this.state.fieldSelection}
        {this.state.operatorSelection}
        {this.state.valueField}
        <HorizontalLayout className='inline items-center gap-3 justify-center'>
          <RadioDot isActive={this.state.isExistingFilter}/>
          <div>Existierender Filter</div>
        </HorizontalLayout>
        <HorizontalLayout className='inline items-center gap-3 justify-center'>
          <RadioDot isActive={!this.state.isExistingFilter}/>
          <div>Neuer Filter</div>
        </HorizontalLayout>
      </HorizontalLayout>
    </HorizontalLayout>;
  }
}

FieldPredicate.propTypes = {
  className: PropTypes.string,
  fieldSelection: PropTypes.node,
  operatorSelection: PropTypes.node,
  valueField: PropTypes.node,
  commandBar: PropTypes.node,
  filterBuilder: PropTypes.object,
  isExistingFilter: PropTypes.bool,
  defaultFieldValue: PropTypes.string,
  defaultFieldNameValue: PropTypes.string,
  defaultFieldLabelValue: PropTypes.string,
  defaultOperatorValue: PropTypes.string
}
export default FieldPredicate;

import Dto from "../../../../../../service/Dto";

class SendListByMail extends Dto {

  listName = '';
  template = '';
  scheduledStartDateTime = '';
  mailTo = '';
  mailToDisplayText = '';
  queueName = '';
  testMailReceiver = '';
  testMailNumOfSendings = '';

  constructor(listName = '', template = '', scheduledStartDateTime = '', mailTo = '', mailToDisplayText = '', queueName = '', testMailReceiver = '', testMailNumOfSendings = '', _class = 'campaign\\module\\modules\\ustore\\v002\\service\\dto\\SendListByMail') {
    super(_class);
    this.listName = listName;
    this.template = template;
    this.scheduledStartDateTime = scheduledStartDateTime;
    this.mailTo = mailTo;
    this.mailToDisplayText = mailToDisplayText;
    this.queueName = queueName;
    this.testMailReceiver = testMailReceiver;
    this.testMailNumOfSendings = testMailNumOfSendings;
  }

  getListName() {
    return this.listName;
  }

  setListName(value) {
    this.listName = value;
  }

  getTemplate() {
    return this.template;
  }

  setTemplate(value) {
    this.template = value;
  }

  getScheduledStartDateTime() {
    return this.scheduledStartDateTime;
  }

  setScheduledStartDateTime(value) {
    this.scheduledStartDateTime = value;
  }

  getMailTo() {
    return this.mailTo;
  }

  setMailTo(value) {
    this.mailTo = value;
  }

  getMailToDisplayText() {
    return this.mailToDisplayText;
  }

  setMailToDisplayText(value) {
    this.mailToDisplayText = value;
  }

  getQueueName() {
    return this.queueName;
  }

  setQueueName(value) {
    this.queueName = value;
  }

  getTestMailReceiver() {
    return this.testMailReceiver;
  }

  setTestMailReceiver(value) {
    this.testMailReceiver = value;
  }

  getTestMailNumOfSendings() {
    return this.testMailNumOfSendings;
  }

  setTestMailNumOfSendings(value) {
    this.testMailNumOfSendings = value;
  }
}

export default SendListByMail;

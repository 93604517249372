import Describe from "../../dto/Describe";

class EntityDescribe extends Describe {
  constructor(entityNames = [], _serviceName = 'DoctrineEntityService') {
    super(_serviceName, 'po2\\service\\entity\\dto\\EntityDescribe');
    this.entityNames = entityNames;
  }
}

export default EntityDescribe;

import BaseComponent from "../BaseComponent";
import './Details.css';
import PropTypes from "prop-types";

class ColumnLine extends BaseComponent {

  render() {
    return <>
      <div className='col-span-1 font-bold flex-grow-[2]'>{this.props.label}</div>
      <div className='col-span-1 cn-font-paragraph flex-grow-[3] break-words'>{this.props.value}</div>
    </>
  }
}

ColumnLine.propTypes = {
  label: PropTypes.string, value: PropTypes.any, className: PropTypes.string
}

export default ColumnLine;

import Dto from "../../../../../lib/model/service/Dto";

class ReadCampaignStatus extends Dto {

  campaignId;
  version;

  constructor(campaignId = '', version = '') {
    super('appl\\service\\campaign\\dto\\ReadCampaignStatus');
    this.campaignId = campaignId;
    this.version = version;
  }

  getCampaignId() {
    return this.campaignId;
  }

  setCampaignId(campaignId) {
    this.campaignId = campaignId;
  }

  getVersion() {
    return this.version;
  }

  setVersion(version) {
    this.version = version;
  }
}

export default ReadCampaignStatus;

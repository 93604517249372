import BaseComponent from "../../../../../lib/components/BaseComponent";
import seperator from "../img/seperator.svg";
import PropType from "prop-types";

class Lizence extends BaseComponent {


  render() {
    let addition;
    let popularCss1;
    let popularCss2;
    let popularNode;

    if (this.props.addition) {
      addition = <div>+ Projektkosten</div>;
    } else {
      addition = <div>&nbsp;</div>;
    }

    if (this.props.popular) {
      popularCss1 = "w-5/5 lg:w-1/5 mx-4 mt-10 lg:mt-0 border-cn-color-primary border-4 rounded-xl relative bg-cn-color-landing-gray03";
      popularCss2 = "flex justify-center flex-col bg-cn-color-primary text-cn-color-spzeial-white py-10";
      popularNode =
        <div className="bg-cn-color-static-white absolute -right-12 py-1 font-bold text-cn-color-landing-gray01 rotate-45 px-16 top-4">Beliebt</div>;
    } else {
      popularCss1 = "w-5/5 lg:w-1/5 mx-4 mt-10 lg:mt-0";
      popularCss2 = "flex justify-center flex-col bg-cn-color-primary text-cn-color-spzeial-white py-10 rounded-t-xl";
    }

    return <div className={popularCss1}>
      {popularNode}
      <div className={popularCss2}>
        {this.props.icon}
        <div className='text-4xl uppercase font-medium self-center'>{this.props.name}</div>
      </div>
      <img src={seperator} alt=""/>
      <h3 className='uppercase font-cn-inter-regular font-bold text-center text-cn-color-spzeial-white bg-cn-color-spzeial-black pt-3 pb-5'>
        {this.props.costs} CHF pro Jahr{addition}</h3>
      <div className="bg-cn-color-landing-gray03 p-6  rounded-b-xl">
        {this.props.children}
      </div>
    </div>
  }
}

Lizence.propTypes = {
  name: PropType.string.isRequired,
  costs: PropType.number.isRequired,
  addition: PropType.bool.isRequired,
  icon: PropType.node.isRequired,
  popular: PropType.bool
}

export default Lizence;

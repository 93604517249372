import SysLastValueRead from "./SysLastValueRead";

class SysLastValueWrite extends SysLastValueRead {

  constructor(valueid, value) {
    super(valueid, 'po2\\service\\system\\dto\\SysLastValueWrite');
    this.value = value;
  }

  getValue() {
    return this.value;
  }

  setValue(value) {
    this.value = value;
  }
}

export default SysLastValueWrite;

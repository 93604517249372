import Dto from "../../../../../lib/model/service/Dto";

class UpdateVariableGroups extends Dto {

  constructor(campaignId = null, variableGroups = null, version = [], pageId = null) {
    super('appl\\service\\campaign\\dto\\UpdateVariableGroups');
    this.campaignId = campaignId;
    this.variableGroups = variableGroups;
    this.version = version;
    this.pageId = pageId;
  }

  getCampaignId() {
    return this.campaignId;
  }

  setCampaignId(campaignId) {
    this.campaignId = campaignId;
  }

  getVariableGroups() {
    return this.variableGroups;
  }

  setVariableGroups(variableGroups) {
    this.variableGroups = variableGroups;
  }

  getVersion() {
    return this.version;
  }

  setVersion(version) {
    this.version = version;
  }

  getPageId() {
    return this.pageId;
  }

  setPageId(pageId) {
    this.pageId = pageId;
  }
}

export default UpdateVariableGroups;

import ValidationResult from "./ValidationResult";

/**
 * class ConstrainBase
 */
class ConstrainBase {

  validate(value) {
    return new ValidationResult(false, 'Fehler');
  }
}

export default ConstrainBase;

import Dto from "../../../../../../service/Dto";

class ReadList extends Dto {

  constructor(_class = 'campaign\\module\\modules\\ustore\\v002\\service\\dto\\ReadList') {
    super(_class);
  }
}

export default ReadList;

import ButtonLarge from "../../../../../appl/view/components/buttons/ButtonLarge";

export default function FahaTest() {
  return <div className='bg-cn-color-black-804 flex justify-center p-5'>
    <div className='w-56'>

      <ButtonLarge color='secondary' tooltip='test'>Test</ButtonLarge>

    </div>
  </div>
}
import BaseComponent from "../../../../lib/components/BaseComponent";
import Window from "../../../../lib/components/window/Window";
import ReadTutorialVideoList from "../../../model/service/campaign/dto/ReadTutorialVideoList";
import shortid from "shortid";
import VerticalLayout from "../../../../lib/components/layouts/VerticalLayout";

import Grid from "../../../../lib/components/layouts/Grid";

class HelpWindow extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      videos: null
    }
    this.uid = shortid();
  }

  componentDidMount() {
    this.doDataCacheFetch(new ReadTutorialVideoList(), 'campaign', 'HelpWindowVideos', response => {
      if (response.success) {
        this.state.videos = response.data;
        this.setState(this.state);
      }
    });
  }

  getStorageId() {
    return 'HelpWindow' + this.uid;
  }

  render() {

    let tutorials = [];
    for (let videoIndex in this.state.videos) {
      let video = this.state.videos[videoIndex];
      tutorials.push(<VerticalLayout className='col-span-full md:col-span-2 lg:col-span-1 gap-1' key={shortid()}>
        <h3>{video.title}</h3>
        <video controls>
          <source src={video.videofilename} type="video/mp4"/>
        </video>
        <p className='cn-font-paragraph'>{video.subtitle}</p>
      </VerticalLayout>)
    }
    return <Window>
      <Grid className='gap-4 grid-cols-4 text-cn-color-white'>
        {tutorials}
      </Grid>
    </Window>;
  }

}

export default HelpWindow;
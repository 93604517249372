import Dto from "../../../../../lib/model/service/Dto";

class ReadCampaignTableList extends Dto {

  campaignId;

  constructor(campaignId = '') {
    super('appl\\service\\campaign\\dto\\ReadCampaignTableList');
    this.campaignId = campaignId;
  }

  getCampaignId() {
    return this.campaignId;
  }
}

export default ReadCampaignTableList;

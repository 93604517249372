import SysNodeIterator from "../../../../lib/model/node/NodeIterator";

class AddIsExistingNodeToPredicates extends SysNodeIterator {

  childNodeCallback(_workingNode) {
    _workingNode.isExistingNode = true;
    return super.childNodeCallback(_workingNode);
  }

  parentNodeCallback(_workingNode, childNodesNew) {
    _workingNode.isExistingNode = true;
    return super.parentNodeCallback(_workingNode, childNodesNew);
  }
}

export default AddIsExistingNodeToPredicates;

import BaseComponent from "../../../../../lib/components/BaseComponent";
import Radio from "../../../../../lib/components/radio/Radio";

class RadioTest extends BaseComponent {

  render() {
    return <div className='sandbox-container'>
      <form>
        <Radio name='test' value='1' labelElement={"test"}/>
        <Radio name='test' value='2' labelElement={"test"} description={'test'}/>
      </form>
    </div>
  }
}

export default RadioTest;

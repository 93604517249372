import BaseComponent from "../BaseComponent";
import PropTypes from "prop-types";
import {Link2} from "react-feather";
import HorizontalLayout from "../layouts/HorizontalLayout";

export default class ALink extends BaseComponent {

  render() {
    let title = this.props.title ? this.props.title : this.props.href;
    let classes = this.props.className ? this.props.className : '';
    return <HorizontalLayout
      className={"justify-between items-baseline gap-x-1 border-b-2 border-b-cn-color-blue-690 text-cn-color-white cursor-pointer " + classes}>
      <a title={title} href={this.props.href} target={this.props.target}
         className="w-full text-ellipsis overflow-hidden cn-font-description02">{title}
      </a>
      <Link2 className="pl-1 pt-2"/>
    </HorizontalLayout>;
  }
}

ALink.propTypes = {
  title: PropTypes.string, href: PropTypes.string, className: PropTypes.string, target: PropTypes.oneOf(['', '_blank'])
}
import PropTypes from "prop-types";
import DataType from "../../../../../lib/model/types/DataType";
import Card from "../../card/Card";
import Entity from "../Entity";
import React, {createRef} from "react";
import DataSource from "../../../../../lib/model/service/dataSource/DataSource";
import EntityDef from "../../../../../lib/model/service/entity/EntityDef";
import MemoryTable from "../../../../../lib/model/system/data/memory/table/MemoryTable";
import MemoryDataProvider from "../../../../../lib/model/system/data/memory/MemoryDataProvider";
import Listener from "../../../../../lib/model/system/Listener";
import GF from "../../../../../lib/utils/GF";

class UploadCardBase extends Card {

  constructor(props) {
    super(props);
    this.initMemoryTable();
    this.entityRef = createRef();
  }

  initMemoryTable() {
    let entityDef = this.initEntityDef();
    this.memoryTable = new MemoryTable(entityDef);
    this.memoryDataProvider = new MemoryDataProvider(this.memoryTable);
    this.dataSource = DataSource.generateDatasource(entityDef, null,
      (datasource, dto, dataSourceDtoProcessEvents) => this.datasourceDataHandler(datasource, dto,
        dataSourceDtoProcessEvents));

    this.addOnReadDoneListener = new Listener((records) => this.onReadDone(records));
    this.dataSource.dataSourceEvents.addOnReadDoneListener(this.addOnReadDoneListener);

    this.addOnUpdateDoneListener = new Listener((records) => this.onUpdateRowsDone(records));
    this.dataSource.dataSourceEvents.addOnUpdateDoneListener(this.addOnUpdateDoneListener);
  }

  onUpdateRowsDone(records) {
    if (GF.checkNotNull(this.props.onUpdateRowsDone)) {
      this.props.onUpdateRowsDone(records);
    }
  }

  onReadDone(records) {
    if (GF.checkNotNull(this.props.onReadDone)) {
      this.props.onReadDone(records);
    }
  }

  initEntityDef() {
    let entityDef = new EntityDef('DummyTable');
    entityDef.addField('id', 'ID', DataType.INTEGER, true);

    return entityDef.getEntityDef();
  }

  datasourceDataHandler(datasource, dto, dataSourceDtoProcessEvents) {
    let response = this.memoryDataProvider.processDto(dto);
    dataSourceDtoProcessEvents.doResponse(response);
  }

  readRecords(query) {
    return this.memoryTable.read(query);
  }

  render() {
    //this.state.cardTitle = this.props.cardTitle;
    this.state.cardContent = <Entity ref={this.entityRef} dataSource={this.dataSource}/>;

    return super.render();
  }
}

UploadCardBase.propTypes = {
  className: PropTypes.string, cardTitle: PropTypes.string, onReadDone: PropTypes.func, onUpdateRowsDone: PropTypes.func
}
export default UploadCardBase;

import Dto from "../../../../../lib/model/service/Dto";

class ReadCampaignInfo extends Dto {

  campaignId;
  search;
  statusFilter;

  constructor(campaignId = '', search = '', statusFilter = 0) {
    super('appl\\service\\campaign\\dto\\ReadCampaignInfo');
    this.campaignId = campaignId;
    this.search = search;
    this.statusFilter = statusFilter;
  }

  getCampaignId() {
    return this.campaignId;
  }

  setCampaignId(campaignId) {
    this.campaignId = campaignId;
  }

  getSearch() {
    return this.search;
  }

  setSearch(search) {
    this.search = search;
  }

  getStatusFilter() {
    return this.statusFilter;
  }

  setStatusFilter(statusFilter) {
    this.statusFilter = statusFilter;
  }
}

export default ReadCampaignInfo;

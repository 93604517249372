import WizardConfigData from "./WizardConfigData";
import GF from "../../../../lib/utils/GF";

class WizardData {

  constructor(mailingListName = '', configuration = null, deliveryDateTime = null, notiz = '') {
    this.mailingListName = mailingListName;
    this.configuration = new WizardConfigData();
    this.deliveryDateTime = deliveryDateTime;
    this.notiz = notiz;
  }

  getMailingListName() {
    return this.mailingListName;
  }

  setMailingListName(value) {
    this.mailingListName = value;
  }

  getConfiguration() {
    return this.configuration;
  }

  setConfiguration(value) {
    this.configuration = value;
  }

  getDeliveryDateTime() {
    return this.deliveryDateTime;
  }

  setDeliveryDateTime(value) {
    this.deliveryDateTime = GF.stringToDateTime(value);
  }

  getNotiz() {
    return this.notiz;
  }

  setNotiz(value) {
    this.notiz = value;
  }
}

export default WizardData;

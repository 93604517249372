
class WizardConfigData {

  constructor(template = {option: '', displayValue: ''}, templates = [], sendFromText = '', sendFromAddressOptions = [], sendFromAddress = {option: '', displayValue: ''}) {
    this.template = template;
    this.templates = templates;
    this.sendFromText = sendFromText;
    this.sendFromAddressOptions = sendFromAddressOptions;
    this.sendFromAddress = sendFromAddress;
    this.initDone = false;
  }

  isInitDone() {
    return this.initDone;
  }

  setInitDone(initDone) {
    this.initDone = initDone;
  }

  getTemplate() {
    return this.template;
  }

  setTemplate(template) {
    this.template = template;
  }

  getTemplates() {
    return this.templates;
  }

  setTemplates(templates) {
    this.templates = templates;
  }

  getSendFromText() {
    return this.sendFromText;
  }

  setSendFromText(sendFromText) {
    this.sendFromText = sendFromText;
  }

  getSendFromAddressOptions() {
    return this.sendFromAddressOptions;
  }

  setSendFromAddressOptions(sendFromAddressOptions) {
    this.sendFromAddressOptions = sendFromAddressOptions;
  }

  getSendFromAddress() {
    return this.sendFromAddress;
  }

  setSendFromAddress(sendFromAddress) {
    this.sendFromAddress = sendFromAddress;
  }
}

export default WizardConfigData;

import Operator from "./Operator";
import FieldName from "./FieldName";
import FieldPredicate from "./FieldPredicate";
import BooleanPredicate from "./BooleanPredicate";

class TypePostProcess {

  static processDateTimeAsDate(fieldPredicate) {
    if (fieldPredicate._class === FieldPredicate._CLASS) {
      if ((typeof fieldPredicate.value1) === 'string') {
        let result = fieldPredicate.value1.match(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/g);
        if (result !== null && result.length > 0) {
          let booleanNodeOperator = Operator.and;
          let operatorFrom = Operator.geq;
          let operatorTo = Operator.leq;
          let fieldName = FieldName.create(fieldPredicate.fieldName.getFullFieldName());
          let valueFrom = fieldPredicate.value1 + ' 00:00';
          let valueTo = fieldPredicate.value1 + ' 23:59';

          switch(fieldPredicate.operator) {
            case Operator.neq:
              booleanNodeOperator = Operator.or;
              operatorFrom = Operator.lt;
              operatorTo = Operator.gt;
              break;
            case Operator.gt:
              operatorTo = Operator.gt;
              valueFrom = null;
              break;
            case Operator.geq:
              operatorTo = Operator.geq;
              valueTo = null;
              break;
            case Operator.lt:
              operatorFrom = Operator.lt;
              valueTo = null;
              break;
            case Operator.leq:
              operatorFrom = Operator.leq;
              valueFrom = null;
              break;
            default:
              break;
          }
          let fieldPredicateFrom = null;
          if (valueFrom !== null) {
            fieldPredicateFrom = new FieldPredicate(fieldName, '', valueFrom, '', operatorFrom);
          }
          let fieldPredicateTo = null;
          if (valueTo !== null) {
            fieldPredicateTo = new FieldPredicate(fieldName, '', valueTo, '', operatorTo);
          }
          if (fieldPredicateFrom !== null && fieldPredicateTo === null) {
            fieldPredicate = fieldPredicateFrom;
          } else if (fieldPredicateFrom === null && fieldPredicateTo !== null) {
            fieldPredicate = fieldPredicateTo;
          } else {
            fieldPredicate = new BooleanPredicate(booleanNodeOperator, [fieldPredicateFrom, fieldPredicateTo]);
          }
        }
      }
    }
    return fieldPredicate;
  }
}

export default TypePostProcess;

import React from 'react';
import {Bar} from 'react-chartjs-2';

import SysChart from './Chart';
import PropTypes from "prop-types";

class BarChart extends SysChart {

  getContent() {
    let options = this.props.options ? this.props.options : {
      scales: {
        x: {
          ticks: {
            color: '#fff',
          }, grid: {
            display: false
          }
        }, y: {
          ticks: {
            color: '#fff', font: {
              size: 14,
            }, stepSize: 100
          }, grid: {
            color: '#fff'
          }
        }
      }
    }
    let classes = this.props.className ? this.props.className : 'animated-dropdown ';
    return <Bar className={classes} options={options} width={this.props.width} data={this.createDataForChart()}/>
  }
}

BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object), width: PropTypes.number, className: PropTypes.string
}
export default BarChart

/**
 * class De
 *
 * In dieser Klasse sind alle Übersetzungestexte für Deutsch hinterlegt.
 */
class De {
  label_add = 'Neuer Datensatz einfügen';
  label_create = 'Erstellen';
  label_cancel = 'Abbrechen';
  label_discard = 'Verwerfen';
  label_save = 'speichern';
  label_edit = 'editieren';
  label_delete = 'löschen';
  label_copy = 'Kopieren';
  label_duplicate = 'Duplizieren';
  label_import = 'Excelliste importieren';
  label_do_import = 'Importieren';
  label_clone = 'Klonen';
  label_update = 'Aktualisieren';
  label_help = 'Hilfe';
  label_settings = 'Einstellungen';
  label_system = 'System';
  label_account = 'Konto';
  action_change_thumbnail = 'Vorschaubild ändern';
  action_logout = 'Abmelden';
  action_overwrite = 'Zurücksetzen';
  action_synchronize = 'Synchronisieren';
  label_export = 'Excelliste exportieren';
  label_empty = 'Tabelle leeren';
  label_omsOverview = 'Versandübersicht';
  label_filter_editing = 'In Bearbeitung';
  label_filter_closed = 'Abgeschlossen';
  label_filter_published = 'Veröffentlicht';
  label_filter_maintenance = 'In Wartung';
  label_filter_open_simple = 'öffne einfachen Filter';
  label_filter_open_extended = 'öffne erweiterter Filter';
  label_create_new_campaign = 'Neue Campaign erstellen';
  label_option_not_found = 'Nichts gefunden!';
  label_enter_text = 'Zeit eingeben:';
  label_hour = 'Stunde';
  label_minute = 'Minute';
  component_upload_hint = 'Ziehen und ablegen oder durchsuchen zur Auswahl der Datei';
  label_search = 'Suchen';
  reset_filter = 'Filter zurücksetzen';
  label_tables = 'Tabellen';
  label_variant = 'Variante';
  label_functions = 'Funktionen';
  label_activate_analytics = 'Analytics aktivieren';
  label_activities = 'Aktivitäten';
  label_overview = 'Übersicht';
  genericErrorMessage = 'Leider ist ein Fehler aufgetreten! Bitte wenden Sie Sich an die Vögeli AG';
  ext_filter_eq_label = 'ist gleich';
  ext_filter_neq_label = 'ist ungleich';
  ext_filter_gt_label = 'ist grösser als';
  ext_filter_lt_label = 'ist kleiner als';
  ext_filter_geq_label = 'ist grösser oder gleich';
  ext_filter_leq_label = 'ist kleiner oder gleich';
  ext_filter_like_label = 'enthält';
  ext_filter_and_label = 'Und';
  ext_filter_or_label = 'Oder';
  ext_filter_null_label = 'ist leer';
  ext_filter_not_null_label = 'ist nicht leer';
}

export default De;

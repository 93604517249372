import BaseComponent from '../BaseComponent';
import {ChevronDown, ChevronRight} from "react-feather";
import PropTypes from "prop-types";
import shortid from "shortid";

class AccordionItemHead extends BaseComponent {

  constructor(props) {
    super(props);
    this.key = shortid();
    this.state = {
      state: (this.props.opened === true ? 'open' : 'close')
    }
  }

  onClick(e) {
    if (this.state.state === 'close') {
      this.state.state = 'open';
    } else {
      this.state.state = 'close';
    }
    if (this.props.onChange !== undefined) {
      this.props.onChange(this.state.state);
    }
    this.setState(this.state);
  }

  render() {
    let icon = '';
    if (this.state.state === 'close') {
      icon = <ChevronRight className='smooth-rotate self-center select-none'/>
    } else {
      icon = <ChevronDown className='smooth-rotate self-center select-none'/>
    }

    return <div className='flex' onClick={(e) => this.onClick(e)}>
      {icon}
      <h2 className='select-none'>{this.props.title}</h2>
    </div>;
  }
}

AccordionItemHead.propTypes = {
  accordionItemRef: PropTypes.object,
  opened: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string
}

export default AccordionItemHead;
import "./index.css";
import {createRoot} from 'react-dom/client';
import CM from "./appl/model/system/CM";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./appl/view/App";

CM.get().init(CM.STD_DARK);

const container = document.getElementById('root');
const root = createRoot(container);
let theme = CM.get().getTheme() === CM.STD_DARK ? 'dark' : 'light';
root.render(<>
  <ToastContainer
    position='bottom-left'
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    rtl={false}
    closeOnClick
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme={theme}/>
  <App/>
</>);
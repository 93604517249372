import Dto from "../../Dto";

class Read extends Dto {

  constructor(query = '') {
    super('po2\\service\\entity\\dto\\Read');
    this.query = query;
  }

  getQuery() {
    return this.query;
  }

  setQuery(query) {
    this.query = query;
  }
}

export default Read;
